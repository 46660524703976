import { useEffect, useState, useContext } from "react";
import { UserContext, MenuOpenContext } from "../AppContexts";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../graphql/mutations";
import * as queries from "../graphql/queries";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "../i18n";
import {
  AppLayout,
  ContentLayout,
  Header,
  SpaceBetween,
  Button,
  BreadcrumbGroup,
  Container,
  FormField,
  Select,
  Input,
  ColumnLayout,
  Textarea,
  Box,
  Modal,
  RadioGroup,
  Multiselect,
  Flashbar,
} from "@cloudscape-design/components";
import { Authenticator } from "@aws-amplify/ui-react";
import {
  Divider,
  darkGray,
  denmarkMunicipalityList,
  polandMunicipalityList,
  SideNavigationContents,
} from "../shared";
import NavigationBar from "./NavigationBar";
import AccessDenied from "../AccessDenied";
import DataPrivacyPopup from "../DataPrivacyPopup";

export default function ProjectSetup() {
  const { t } = useTranslation();
  const { userDetails } = useContext(UserContext);
  const { isMenuOpen, setMenuOpen } = useContext(MenuOpenContext);
  const navigate = useNavigate();
  const [isPageLoading, setPageLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  /* FORM FIELDS */
  const [modal, showModal] = useState(false);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [latitude, setLatitude] = useState(null);
  const [longitude, setLongitude] = useState(null);
  const [size, setSize] = useState(null);
  const [households, setHouseholds] = useState(null);
  const [capacity, setCapacity] = useState(null);
  const [turbinesNum, setTurbinesNum] = useState(null);
  const [height, setHeight] = useState(null);
  const [diameter, setDiameter] = useState(null);
  const [preAgreement, setPreAgreement] = useState(true);
  const [relationNeighbours, setRelationNeighbours] = useState("");
  const [benefits, setBenefits] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [type, setType] = useState({
    value: "wind",
    label: "Wind Farm",
  });
  const [municipality, setMunicipality] = useState([]);
  const [organisation, setOrganisation] = useState("");
  const [documentTemplates, setDocumentTemplates] = useState([]);
  const [taskTemplates, setTaskTemplates] = useState([]);
  const [projectSubmitting, setProjectSubmitting] = useState(false);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([{ value: "", label: "" }]);

  const getCountries = async () => {
    const query = /* GraphQL */ `
      query {
        __type(name: "Municipality") {
          name
          enumValues {
            name
          }
        }
      }
    `;
    await API.graphql(graphqlOperation(query))
      .then(async (res) => {
        const enumSet = [
          ...new Set(res.data.__type.enumValues.map((m) => m.name.slice(0, 2))),
        ];
        const formatEnum = enumSet.map((country) => ({
          value: country,
          label: country === "DK" ? t("Denmark") : t("Poland"),
        }));
        setCountries(formatEnum);
      })
      .catch((error) => console.error(error));
  };

  // Create new project using AppSync
  const submitProject = async () => {
    setProjectSubmitting(true);
    const variables = {
      name,
      description,
      projectType: type.value,
      latitude,
      longitude,
      size,
      capacity,
      provides_households: households,
      turbines: turbinesNum,
      height,
      diameter,
      benefits,
      municipalities: municipality.map((item) => item.value),
      adminGroups: municipality.map((item) => item.value),
      preAgreementLandowners: preAgreement,
      relationNeighbours,
      additionalInfo,
      mainDeveloperId: userDetails.id,
      contact_organization: organisation,
      stage: "a_preplanning",
    };
    await API.graphql(
      graphqlOperation(mutations.createProject, {
        input: variables,
      })
    )
      .then(async (res) => {
        let projectId = res.data.createProject.id;
        await createDocumentsAndTasks(projectId);
        navigate("/project/" + projectId);
      })
      .catch((error) => {
        console.error(error);
        setErrorMessage(error.errors[0].message);
        setProjectSubmitting(false);
      });
  };

  const createDocumentsAndTasks = async (projectId) => {
    const documentIds = {};
    for (const documentTemplate of documentTemplates.toReversed()) {
      await createDocument(projectId, documentTemplate, documentIds);
    }
    const documentTasks = [];
    for (const taskTemplate of taskTemplates.toReversed()) {
      await createTask(projectId, taskTemplate, documentIds, documentTasks);
    }
    for (const documentTask of documentTasks) {
      await assignDocumentTask(documentTask);
    }
  };

  const assignDocumentTask = async ({ documentId, taskId }) => {
    await API.graphql(
      graphqlOperation(mutations.createDocumentTasks, {
        input: { documentId, taskId },
      })
    )
      .then((res) => {
        console.log("Successfully created new task document assignment!");
      })
      .catch((error) => console.error(error));
  };

  const createTask = async (
    projectId,
    taskTemplate,
    documentIds,
    documentTasks
  ) => {
    const task = {
      projectId,
      title: taskTemplate.taskTitle,
      description: taskTemplate.taskDescription,
      stage: taskTemplate.stage,
      mandatory: taskTemplate?.mandatory || false,
      done: false,
      ownerGroups: municipality.map((m) => m.value),
    };
    if (taskTemplate.assignedTo === "developers") {
      task["assigneeId"] = userDetails.id;
    }
    await API.graphql(
      graphqlOperation(mutations.createTask, {
        input: task,
      })
    )
      .then((res) => {
        console.log("Successfully created new task!");
        if (taskTemplate.relevantDocuments) {
          for (const relevantDocument of taskTemplate.relevantDocuments) {
            if (documentIds[relevantDocument]) {
              documentTasks.push({
                documentId: documentIds[relevantDocument],
                taskId: res.data.createTask.id,
              });
            }
          }
        }
      })
      .catch((error) => console.error(error));
  };

  const createDocument = async (projectId, documentTemplate, documentIds) => {
    const document = {
      projectId,
      name: documentTemplate.documentName,
      description: documentTemplate.documentDescription,
      mandatory: documentTemplate.documentMandatory,
      stage: documentTemplate.stage,
      publishable: documentTemplate?.publishable || false,
      status: "a_upload",
      ownerGroups: municipality.map((m) => m.value),
    };
    if (documentTemplate.assignedTo === "developers") {
      document["assigneeId"] = userDetails.id;
    }
    await API.graphql(
      graphqlOperation(mutations.createDocument, {
        input: document,
      })
    )
      .then((res) => {
        console.log("Successfully created new document!");
        documentIds[documentTemplate.documentName] = res.data.createDocument.id;
      })
      .catch((error) => console.error(error));
  };

  const getWorkflowTemplates = async () => {
    await API.graphql(
      graphqlOperation(queries.listDocumentTemplates, {
        filter: {
          stage: {
            eq: "a_preplanning",
          },
        },
      })
    )
      .then((res) => {
        setDocumentTemplates(
          res?.data?.listDocumentTemplates?.items.sort((a, b) => {
            const nameA = a.createdAt; // ignore upper and lowercase
            const nameB = b.createdAt; // ignore upper and lowercase
            if (nameA < nameB) return -1;
            if (nameA > nameB) return 1;
            return 0; // names must be equal
          })
        );
      })
      .catch((error) => console.error(error.message));
    await API.graphql(
      graphqlOperation(queries.listTaskTemplates, {
        filter: {
          stage: {
            eq: "a_preplanning",
          },
        },
      })
    )
      .then((res) => {
        setTaskTemplates(res?.data?.listTaskTemplates?.items);
        setPageLoading(false);
      })
      .catch((error) => console.error(error.message));
  };

  const validateLatitudeAndLongitude = (value, name, selectedCountry) => {
    if (value) {
      if (name === "latitude") {
        // Validate Denmark coordinates
        if (selectedCountry.value === "DK") {
          if (
            value <
            Math.min(
              ...municipality?.map(
                (mu) =>
                  denmarkMunicipalityList.find((m) => m.value === mu.value)
                    ?.min_lat
              )
            )
          )
            return "Value is too small";
          if (
            value >
            Math.max(
              ...municipality?.map(
                (mu) =>
                  denmarkMunicipalityList.find((m) => m.value === mu.value)
                    ?.max_lat
              )
            )
          )
            return "Value is too large";
        }
        // Validate Poland coordinates
        else if (selectedCountry.value === "PL") {
          if (
            value <
            Math.min(
              ...municipality?.map(
                (mu) =>
                  polandMunicipalityList.find((m) => m.value === mu.value)
                    ?.min_lat
              )
            )
          )
            return "Value is too small";
          if (
            value >
            Math.max(
              ...municipality?.map(
                (mu) =>
                  polandMunicipalityList.find((m) => m.value === mu.value)
                    ?.max_lat
              )
            )
          )
            return "Value is too large";
        }
      }
      // Validate longitude coordinates
      else {
        // Validate Poland coordinates
        if (selectedCountry.value === "DK") {
          if (
            value <
            Math.min(
              ...municipality?.map(
                (mu) =>
                  denmarkMunicipalityList.find((m) => m.value === mu.value)
                    ?.min_lon
              )
            )
          )
            return "Value is too small";
          if (
            value >
            Math.max(
              ...municipality?.map(
                (mu) =>
                  denmarkMunicipalityList.find((m) => m.value === mu.value)
                    ?.max_lon
              )
            )
          )
            return "Value is too large";
        }
        // Validate Poland coordinates
        else if (selectedCountry.value === "PL") {
          if (
            value <
            Math.min(
              ...municipality?.map(
                (mu) =>
                  polandMunicipalityList.find((m) => m.value === mu.value)
                    ?.min_lon
              )
            )
          )
            return "Value is too small";
          if (
            value >
            Math.max(
              ...municipality?.map(
                (mu) =>
                  polandMunicipalityList.find((m) => m.value === mu.value)
                    ?.max_lon
              )
            )
          )
            return "Value is too large";
        }
      }
    } else return;
  };

  const validateBenefitsInput = (string) => {
    // check if message contains a url
    if (
      new RegExp(
        "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?"
      ).test(string)
    ) {
      // ensure URL starts with HTTP/HTTPS (https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url)
      const urlRegExp =
        /https?:\/\/?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;
      if (string) {
        if (!string.match(urlRegExp)) return "Link must start with http(s)://";
      } else return;
    }
  };

  useEffect(() => {
    document.title = "EasyPermits®";
    getWorkflowTemplates();
  }, [navigate]);

  useEffect(() => {
    getCountries();
    if (userDetails?.organisation?.name)
      setOrganisation(userDetails?.organisation?.name);
  }, [userDetails?.organisation?.name]);

  return (
    <Authenticator>
      {
        // Show Loading text when page mounts instead of AccessDenied screen when user access ProjectSetup
        isPageLoading ? (
          <p style={{ textAlign: "center" }}>Loading...</p>
        ) : (
          // Make sure that userDetails is populated before deciding which page to render
          // this prevents React always showing AccessDenied page first as userDetails is not populated fast enough
          userDetails &&
          userDetails.role &&
          // Only Developers can see this page
          (userDetails?.role !== "developers" ? (
            <AccessDenied />
          ) : (
            <>
              {/* Data Privacy disclaimer popup  */}
              {userDetails?.dateOfPrivacyConsent === null && (
                <DataPrivacyPopup />
              )}

              <NavigationBar />
              <AppLayout
                navigationOpen={isMenuOpen}
                navigation={
                  <SideNavigationContents userRole={userDetails.role} />
                }
                onNavigationChange={(event) => setMenuOpen(event.detail.open)}
                toolsHide={true}
                content={
                  <ContentLayout
                    header={
                      <SpaceBetween size="l">
                        <div />
                        <BreadcrumbGroup
                          items={[
                            { text: t("Dashboard"), href: "/dashboard" },
                            { text: t("Project Setup Request") },
                          ]}
                          ariaLabel="Breadcrumbs"
                        />
                        <Header
                          variant="h1"
                          description={t(
                            "Fill out the below form and submit the form to send it to the Permitting Agent"
                          )}
                        >
                          {t("Project Setup Request")}
                        </Header>
                        <div />
                      </SpaceBetween>
                    }
                  >
                    <Container
                      header={
                        <Header variant="h2">{t("Project Summary")}</Header>
                      }
                    >
                      <div style={{ color: darkGray }}>
                        <p>
                          {t(
                            "Please provide essential information required for the permitting process. By completing this form, you'll offer a comprehensive overview of your proposed project. We'll gather vital details such as project location, project type and community engagement plans. This initial step will pave the way for a smooth and efficient permitting process."
                          )}
                        </p>
                      </div>
                      <br />

                      <SpaceBetween direction="vertical" size="xl">
                        <ColumnLayout columns={3}>
                          <div>
                            <FormField
                              stretch
                              label={
                                <>
                                  {t("Organisation")}
                                  <span style={{ color: "red" }}>*</span>
                                </>
                              }
                              description={t("Name of the organisation")}
                            >
                              <Input
                                placeholder="Organisation"
                                value={organisation}
                                onChange={(event) =>
                                  setOrganisation(event.detail.value)
                                }
                              />
                            </FormField>
                          </div>
                          <div>
                            <FormField
                              stretch
                              label={
                                <>
                                  {t("Project Name")}
                                  <span style={{ color: "red" }}>*</span>
                                </>
                              }
                              description={t("Name of the project")}
                            >
                              <Input
                                type="text"
                                placeholder={t("Project name")}
                                value={name}
                                onChange={(event) =>
                                  setName(event.detail.value)
                                }
                              />
                            </FormField>
                          </div>
                          <div>
                            <FormField
                              stretch
                              label={
                                <>
                                  {t("Project Type")}
                                  <span style={{ color: "red" }}>*</span>
                                </>
                              }
                              description={t("Specify the type of the project")}
                            >
                              <Select
                                placeholder={t("Please select")}
                                selectedAriaLabel={t("Project type selected")}
                                selectedOption={type}
                                disabled={true}
                                onChange={({ detail }) =>
                                  setType(detail.selectedOption)
                                }
                                options={[
                                  {
                                    value: "wind",
                                    label: "Wind Farm",
                                  },
                                  {
                                    value: "solar",
                                    label: "Solar",
                                  },
                                ]}
                              />
                            </FormField>
                          </div>
                        </ColumnLayout>

                        <FormField
                          stretch
                          label={
                            <>
                              {t("Project Overview")}
                              <span style={{ color: "red" }}>*</span>
                            </>
                          }
                          description={t(
                            "Detailed description of the proposed project, basic information, company description, etc."
                          )}
                        >
                          <Textarea
                            rows={2}
                            ariaLabel="project-overview"
                            placeholder={t("Enter description here...")}
                            onChange={({ detail }) =>
                              setDescription(detail.value)
                            }
                            value={description}
                          />
                        </FormField>
                        <ColumnLayout columns={3}>
                          <div>
                            <FormField
                              label={
                                <>
                                  {t("Country")}
                                  <span style={{ color: "red" }}>*</span>
                                </>
                              }
                              description={t("Country of the project")}
                            >
                              <Select
                                placeholder={t("Select a country")}
                                selectedAriaLabel="Country selected"
                                selectedOption={country}
                                onChange={({ detail }) => {
                                  setCountry(detail.selectedOption);
                                  setMunicipality([]); // reset municipality if user changes country
                                }}
                                options={countries}
                              />
                            </FormField>
                          </div>
                          <div>
                            <FormField
                              label={
                                <>
                                  {t("Municipality")}
                                  <span style={{ color: "red" }}>*</span>
                                </>
                              }
                              description={t("Municipality of the project")}
                              constraintText={
                                !country.value &&
                                t("Please select a country first")
                              }
                            >
                              <Multiselect
                                placeholder={t("Select municipalities")}
                                selectedAriaLabel="Municipality selected"
                                selectedOptions={municipality}
                                onChange={({ detail }) => {
                                  setMunicipality(detail.selectedOptions);
                                }}
                                // Municipality options will change depending on the country selected
                                options={
                                  country.value === "DK"
                                    ? denmarkMunicipalityList
                                    : polandMunicipalityList
                                }
                                disabled={!country.value}
                              />
                            </FormField>
                          </div>

                          <div>
                            <FormField
                              label={
                                <>
                                  {t("Number of turbines")}
                                  <span style={{ color: "red" }}>*</span>
                                </>
                              }
                              description={t("Total number of turbines")}
                            >
                              <Input
                                type="number"
                                placeholder="0"
                                value={turbinesNum}
                                onChange={(event) =>
                                  setTurbinesNum(event.detail.value)
                                }
                              />
                            </FormField>
                          </div>
                        </ColumnLayout>
                        <ColumnLayout columns={3}>
                          <div>
                            <FormField
                              label={
                                <>
                                  {t("Location - Latitude")}
                                  <span style={{ color: "red" }}>*</span>
                                </>
                              }
                              description={t(
                                "Latitude location of the project on the map"
                              )}
                              constraintText={
                                // Only show constraint text when a municipality has been selected
                                municipality?.length &&
                                //  Denmark latitude coordinates
                                (country.value === "DK"
                                  ? `${t("Value must be between")} ${Math.min(
                                      ...municipality?.map(
                                        (mu) =>
                                          denmarkMunicipalityList.find(
                                            (m) => m.value === mu.value
                                          )?.min_lat
                                      )
                                    )} ${t("and")} ${Math.max(
                                      ...municipality?.map(
                                        (mu) =>
                                          denmarkMunicipalityList.find(
                                            (m) => m.value === mu.value
                                          )?.max_lat
                                      )
                                    )}`
                                  : // Poland latitude coordinates
                                    `${t("Value must be between")} ${Math.min(
                                      ...municipality?.map(
                                        (mu) =>
                                          polandMunicipalityList.find(
                                            (m) => m.value === mu.value
                                          )?.min_lat
                                      )
                                    )} ${t("and")} ${Math.max(
                                      ...municipality?.map(
                                        (mu) =>
                                          polandMunicipalityList.find(
                                            (m) => m.value === mu.value
                                          )?.max_lat
                                      )
                                    )}`)
                              }
                              errorText={validateLatitudeAndLongitude(
                                latitude,
                                "latitude",
                                country
                              )}
                            >
                              <Input
                                type="number"
                                placeholder="0"
                                value={latitude}
                                onChange={(event) =>
                                  setLatitude(event.detail.value)
                                }
                              />
                            </FormField>
                          </div>
                          <div>
                            <FormField
                              label={
                                <>
                                  {t("Location - Longitude")}
                                  <span style={{ color: "red" }}>*</span>
                                </>
                              }
                              description={t(
                                "Longitude location of the project on the map"
                              )}
                              constraintText={
                                // Only show constraint text when a municipality has been selected
                                municipality?.length &&
                                //  Denmark longitude coordinates
                                (country.value === "DK"
                                  ? `${t("Value must be between")} ${Math.min(
                                      ...municipality?.map(
                                        (mu) =>
                                          denmarkMunicipalityList.find(
                                            (m) => m.value === mu.value
                                          )?.min_lon
                                      )
                                    )} ${t("and")} ${Math.max(
                                      ...municipality?.map(
                                        (mu) =>
                                          denmarkMunicipalityList.find(
                                            (m) => m.value === mu.value
                                          )?.max_lon
                                      )
                                    )}`
                                  : //  Poland longitude coordinates
                                    `${t("Value must be between")} ${Math.min(
                                      ...municipality?.map(
                                        (mu) =>
                                          polandMunicipalityList.find(
                                            (m) => m.value === mu.value
                                          )?.min_lon
                                      )
                                    )} ${t("and")} ${Math.max(
                                      ...municipality?.map(
                                        (mu) =>
                                          polandMunicipalityList.find(
                                            (m) => m.value === mu.value
                                          )?.max_lon
                                      )
                                    )}`)
                              }
                              errorText={validateLatitudeAndLongitude(
                                longitude,
                                "longitude",
                                country
                              )}
                            >
                              <Input
                                type="number"
                                placeholder="0"
                                value={longitude}
                                onChange={(event) =>
                                  setLongitude(event.detail.value)
                                }
                              />
                            </FormField>
                          </div>
                          <div>
                            <FormField
                              label={
                                <>
                                  {t("Expected Tip Height")}
                                  <span style={{ color: "red" }}>*</span>
                                </>
                              }
                              description={t(
                                "Height of the wind turbines in meters"
                              )}
                            >
                              <Input
                                type="number"
                                placeholder="0"
                                value={height}
                                onChange={(event) =>
                                  setHeight(event.detail.value)
                                }
                              />
                            </FormField>
                          </div>
                          <div>
                            <FormField
                              label={t("Expected Rotor Diameter")}
                              description={t(
                                "Rotor diameter of the wind turbines in meters"
                              )}
                            >
                              <Input
                                type="number"
                                placeholder="0"
                                value={diameter}
                                onChange={(event) =>
                                  setDiameter(event.detail.value)
                                }
                              />
                            </FormField>
                          </div>
                          <div>
                            <FormField
                              label={
                                <>
                                  {t("Overall Capacity")}
                                  <span style={{ color: "red" }}>*</span>
                                </>
                              }
                              description={t("Capacity in megawatts (MW)")}
                            >
                              <Input
                                type="number"
                                placeholder="0"
                                value={capacity}
                                onChange={(event) =>
                                  setCapacity(event.detail.value)
                                }
                              />
                            </FormField>
                          </div>
                          <div>
                            <FormField
                              label={t("Households")}
                              description={t(
                                "Average number of households to supply energy to"
                              )}
                            >
                              <Input
                                placeholder="0"
                                type="number"
                                value={households}
                                onChange={(event) =>
                                  setHouseholds(event.detail.value)
                                }
                              />
                            </FormField>
                          </div>
                        </ColumnLayout>
                        <ColumnLayout columns={3}>
                          <div>
                            <FormField
                              label={t("Size of project")}
                              description={t("Size of project in hectares")}
                            >
                              <Input
                                type="number"
                                placeholder="0"
                                value={size}
                                onChange={(event) =>
                                  setSize(event.detail.value)
                                }
                              />
                            </FormField>
                          </div>
                        </ColumnLayout>

                        <Divider />
                        <ColumnLayout columns={3}>
                          <div>
                            <FormField
                              label={
                                <>
                                  {t("Is there a pre-agreement with owners?")}
                                  <span style={{ color: "red" }}>*</span>
                                </>
                              }
                              description={t("Do you have a written document?")}
                            >
                              <RadioGroup
                                onChange={({ detail }) =>
                                  setPreAgreement(detail.value)
                                }
                                value={preAgreement}
                                items={[
                                  { value: true, label: t("Yes") },
                                  { value: false, label: t("No") },
                                ]}
                              />
                            </FormField>
                          </div>
                          <div>
                            <FormField
                              label={t("Relation to neighbors")}
                              description={t(
                                "Information about community engagement and the current relationship to neighbours"
                              )}
                            >
                              <Input
                                type="text"
                                placeholder={t("Enter information here...")}
                                value={relationNeighbours}
                                onChange={(event) =>
                                  setRelationNeighbours(event.detail.value)
                                }
                              />
                            </FormField>
                          </div>
                        </ColumnLayout>
                        <FormField
                          stretch
                          label={t("Benefits for the Community")}
                          description={t(
                            "Provide information regarding community benefits. You can also add a link to your project website. Link must start with http(s)://"
                          )}
                          constraintText={t(
                            "Recommendation to also add the community testimonial video on your project website."
                          )}
                          errorText={validateBenefitsInput(benefits)}
                        >
                          <Textarea
                            rows={2}
                            ariaLabel="project-benefits"
                            placeholder={t("Enter information here...")}
                            onChange={({ detail }) => setBenefits(detail.value)}
                            value={benefits}
                          />
                        </FormField>
                        <FormField
                          stretch
                          label={t("Additional information")}
                          description={t(
                            "Please provide any additional technical information relevant to the project eg. design, configuration, known issues, nature, etc."
                          )}
                        >
                          <Textarea
                            rows={2}
                            ariaLabel="project-overview"
                            placeholder={t("Enter information here...")}
                            onChange={({ detail }) =>
                              setAdditionalInfo(detail.value)
                            }
                            value={additionalInfo}
                          />
                        </FormField>
                        <b style={{ color: darkGray }}>
                          {t(
                            "Note: All the additional attachments and supporting documents can be conveniently added on the next page after submitting this form to ensure a seamless submission process."
                          )}
                        </b>
                      </SpaceBetween>
                    </Container>

                    {errorMessage !== "" && (
                      <Box margin={{ top: "l" }}>
                        <Flashbar
                          items={[
                            {
                              id: "error_message",
                              type: "error",
                              header:
                                "Failed to submit form due to the following error(s):",
                              content: errorMessage,
                              dismissible: false,
                            },
                          ]}
                        />
                      </Box>
                    )}

                    <div style={{ marginTop: 30, float: "right" }}>
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="link" href="/dashboard">
                          {t("Cancel")}
                        </Button>
                        <Button
                          variant="primary"
                          disabled={
                            // Only enable Submit button if all required fields are provided
                            !(
                              name &&
                              description &&
                              latitude &&
                              longitude &&
                              country &&
                              municipality &&
                              type &&
                              turbinesNum &&
                              height &&
                              capacity
                            ) || projectSubmitting
                          }
                          onClick={() => {
                            showModal(true);
                          }}
                        >
                          {projectSubmitting
                            ? t("Please wait") + "..."
                            : t("Submit New Project")}
                        </Button>
                      </SpaceBetween>
                    </div>

                    {/* Confirm form submission popup */}
                    <Modal
                      onDismiss={() => showModal(false)}
                      visible={modal}
                      closeAriaLabel="Close modal"
                      header={t("Are you sure you want to submit?")}
                      footer={
                        <Box float="right">
                          <SpaceBetween direction="horizontal" size="xs">
                            <Button
                              variant="link"
                              onClick={() => showModal(false)}
                            >
                              {t("Cancel")}
                            </Button>
                            <Button
                              variant="primary"
                              onClick={() => {
                                submitProject();
                                showModal(false);
                              }}
                            >
                              {t("Confirm Submission")}
                            </Button>
                          </SpaceBetween>
                        </Box>
                      }
                    >
                      <b>
                        {t(
                          "A notification will be sent to a Permitting Agent with your project submission request. You can get in touch with the Permitting Agent once an Agent is assigned on the project."
                        )}
                      </b>
                      <p>
                        {t(
                          "Note: All the additional attachments and supporting documents can be conveniently added on the next page after submitting this form."
                        )}
                      </p>
                    </Modal>
                  </ContentLayout>
                }
              />
            </>
          ))
        )
      }
    </Authenticator>
  );
}
