import "./App.css";
import "@aws-amplify/ui-react/styles.css";
import { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import * as queries from "./graphql/queries";
import * as mutations from "./graphql/mutations";
import awsconfig from "./aws-exports";
import { API, Auth, Amplify, graphqlOperation } from "aws-amplify";
import { useAuthenticator } from "@aws-amplify/ui-react";
import {
  UserContext,
  MenuOpenContext,
  TabletLayoutContext,
} from "./AppContexts";
import json2mq from "json2mq";
import useMediaQuery from "@mui/material/useMediaQuery";
// Components
import SignIn from "./SignIn";
import PageNotFound from "./PageNotFound";
import PrivateRoute from "./PrivateRoute";
import LandingPage from "./components/community/LandingPage";
import WindfarmLocationsMap from "./components/community/WindfarmLocationsMap";
import FAQs from "./components/community/FAQs";
import DataPrivacy from "./components/DataPrivacy";
import Support from "./components/Support";
import QuickSight from "./components/QuickSight";
import ProjectSetup from "./components/ProjectSetup";
import Dashboard from "./components/dashboard/Dashboard";
import WorkflowSetup from "./components/project-dashboard/WorkflowSetup";
import ProjectDashboard from "./components/project-dashboard/ProjectDashboard";
import ExpandedDocuments from "./components/project-dashboard/ExpandedDocuments";
import ExpandedTasks from "./components/project-dashboard/ExpandedTasks";

Amplify.configure(awsconfig);

function App() {
  const tabletLayout = useMediaQuery(
    json2mq({
      // minWidth: 600,
      maxWidth: 1200,
    })
  );
  const [isMenuOpen, setMenuOpen] = useState(null);
  const [userDetails, setUserDetails] = useState({});
  const { authStatus } = useAuthenticator((context) => [context.route]);

  // Save personal data fields that are subject to GDPR legislations so that it can be easily searched for afterwards
  const setGdprFields = async (persona) => {
    // first check if persona already exists to prevent duplicate data
    await API.graphql(
      graphqlOperation(queries.listGDPRS, {
        filter: {
          personaId: {
            eq: persona.id,
          },
        },
      })
    )
      .then(async (res) => {
        // If not already exists, then add entry to DB
        if (!res.data.listGDPRS.items)
          await API.graphql(
            graphqlOperation(mutations.createGDPR, {
              input: {
                personaId: persona.id,
                given_name: persona.given_name,
                family_name: persona.family_name,
                email: persona.email,
                phone_number: persona.phone_number,
                organisation: persona.organisation.name,
                municipality: [persona.municipality],
              },
            })
          );
      })
      .catch((error) => console.error(error));
  };

  const getUser = async () => {
    const { attributes } = await Auth.currentAuthenticatedUser(); // get current auth user ID
    await API.graphql(
      graphqlOperation(queries.listPersonas, {
        filter: {
          id: {
            eq: attributes.sub,
          },
        },
      })
    )
      .then(async (res) => {
        let result = res.data.listPersonas.items[0];
        setUserDetails(result);
        setGdprFields(result);
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    if (authStatus === "authenticated") getUser();
  }, [authStatus]);

  return (
    <UserContext.Provider value={{ userDetails }}>
      <MenuOpenContext.Provider value={{ isMenuOpen, setMenuOpen }}>
        <TabletLayoutContext.Provider value={{ tabletLayout }}>
          <Router>
            <Routes>
              {/* --------------------- PUBLIC ROUTES -------------------- */}
              <Route path="/" element={<LandingPage />} />
              <Route path="/signin/:lang" element={<SignIn />} />
              <Route
                path="/signin"
                element={
                  // redirect to English login page if user accesses /signin
                  <Navigate to="/signin/en" />
                }
              />
              <Route path="/map" element={<WindfarmLocationsMap />} />
              <Route path="/faqs" element={<FAQs />} />
              <Route path="/data-privacy" element={<DataPrivacy />} />
              <Route path="/support" element={<Support />} />
              <Route path="/quicksight" element={<QuickSight />} />
              <Route path="/404" element={<PageNotFound />} />
              <Route path="/*" element={<PageNotFound />} />
              {/* --------------------- PRIVATE ROUTES - AUTHENTICATION REQUIRED TO ACCESS ------------------- */}
              <Route
                path="/dashboard"
                element={
                  <PrivateRoute>
                    <Dashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/workflow-setup/:id"
                element={
                  <PrivateRoute>
                    <WorkflowSetup />
                  </PrivateRoute>
                }
              />
              <Route
                path="/project/:id"
                element={
                  <PrivateRoute>
                    <ProjectDashboard />
                  </PrivateRoute>
                }
              />
              <Route
                path="/project-setup"
                element={
                  <PrivateRoute>
                    <ProjectSetup />
                  </PrivateRoute>
                }
              />
              <Route
                path="/project/:id/documents"
                element={
                  <PrivateRoute>
                    <ExpandedDocuments />
                  </PrivateRoute>
                }
              />
              <Route
                path="/project/:id/tasks"
                element={
                  <PrivateRoute>
                    <ExpandedTasks />
                  </PrivateRoute>
                }
              />
            </Routes>
          </Router>
        </TabletLayoutContext.Provider>
      </MenuOpenContext.Provider>
    </UserContext.Provider>
  );
}

export default App;
