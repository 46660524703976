import { useEffect, createContext } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { primaryBlue, hoverBlue } from "./shared";
import { I18n } from "aws-amplify";
import {
  useAuthenticator,
  translations,
  SelectField,
} from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import {
  Authenticator,
  ThemeProvider,
  useTheme,
  View,
  Image,
  Heading,
} from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { Button } from "@cloudscape-design/components";
import logo from "./assets/easypermits-logo-black.svg";

// Internationalization
I18n.putVocabularies(translations);
I18n.putVocabularies({
  // Danish - Amplify i18n does not yet support Danish so we have to define translations for everything
  // (list of supported languages: https://ui.docs.amplify.aws/react/connected-components/authenticator/customization#internationalization-i18n)
  da: {
    // sign-in
    "Sign In": "Log Ind", // Tab header
    "Sign in": "Log ind", // Button label
    "Sign in to your account": "Log ind på din konto",
    Password: "Adgan", // Password label
    // sign-up
    "Given Name": "Fornavn",
    "Family Name": "Familienavn",
    "Create Account": "Opret konto", // Tab header
    "Create a new account": "Opret en ny konto", // Header text
    "Enter your Email": "Indtast din e-mail",
    "Enter your Given Name": "Indtast dit fornavn",
    "Enter your Family Name": "Indtast dit Familienavn",
    "Enter your Password": "Indtast din adgangskodeo",
    "Confirm Password": "Bekræft adgangskode",
    "Please confirm your Password": "Bekræft venligst din adgangskode",
    "Phone Number": "Telefonnummer",
    "Enter your Phone Number": "Indtast dit telefonnummer",
    // forgot password
    "Forgot your password?": "Har du glemt din adgangskode?",
    "Reset Password": "Nulstil adgangskode",
    "Enter your email": "Indtast din e-mail",
    "New Password": "Ny adgangskode",
    // Confirm code
    "Send code": "Send kode",
    "Confirm SMS Code": "Bekræft SMS-kode",
    "Resend Code": "Send kode igen",
    Confirm: "Bekræft",
    Submit: "Send ind",
    Code: "Kode",
    "Back to Sign In": "Tilbage til login",
  },
  // Polish
  pl: {
    "Enter your Given Name": "Wpisz swoje imię",
    "Enter your Family Name": "Wprowadź nazwę rodziny",
    "Enter your Password": "Wpisz swój hasło",
    "Please confirm your Password": "Potwierdź swoje hasło",
    "Enter your Phone Number": "Wpisz swój numer telefonu",
    "Enter your phone number": "Wpisz swój numer telefonu",
    "Reset Password": "Resetuj hasło",
    "Enter your email": "Wpisz swój adres e-mail",
  },
  // French
  fr: {
    "Given Name": "Prénom",
    "Family Name": "Nom de famille",
    "Enter your Given Name": "Saisissez votre prénom",
    "Enter your Family Name": "Saisissez votre nom de famille",
    "Enter your Password": "Saisissez votre mot de passe",
    "Please confirm your Password": "Veuillez confirmer votre mot de passe",
    "Enter your Phone Number": "Saisissez votre numéro de téléphone",
    "Enter your phone number": "Saisissez votre numéro de téléphone",
    "Reset Password": "Réinitialiser votre mot de passe",
    "Enter your email": "Saisissez votre adresse email",
  },
});

export const UserContext = createContext({});

export default function SignIn() {
  const { t, i18n } = useTranslation();
  const { lang } = useParams();
  const { tokens } = useTheme();
  const { authStatus } = useAuthenticator((context) => [context.route]);
  const location = useLocation();
  const navigate = useNavigate();
  let from = location.state?.from?.pathname || "/";

  const formFields = {
    signUp: {
      given_name: {
        order: 1,
      },
      family_name: { order: 2 },
      email: { order: 3 },
    },
  };
  const theme = {
    name: "Cloudscape",
    tokens: {
      fonts: {
        default: {
          variable: { value: "Open Sans, sans-serif" },
          static: { value: "Open Sans, sans-serif" },
        },
        weights: tokens.fontWeights.bold,
      },
      colors: {
        brand: {
          primary: {
            10: tokens.colors.blue["20"], // buttons & links on focus colour
            80: primaryBlue, // signin button active colour
            90: hoverBlue, // signin button hover colour
            100: primaryBlue, // field focus border colour
          },
        },
      },
      components: {
        tabs: {
          item: {
            _focus: {
              color: {
                value: primaryBlue,
              },
            },
            _hover: {
              color: {
                value: hoverBlue,
              },
            },
            _active: {
              color: {
                value: primaryBlue,
              },
            },
          },
        },
      },
    },
  };
  const components = {
    Header() {
      const { tokens } = useTheme();
      return (
        <View textAlign="center" padding={tokens.space.xxl}>
          <Image alt="Easypermits Logo" src={logo} />
        </View>
      );
    },
    // SignIn: {
    //   Header() {
    //     const { tokens } = useTheme();
    //     return (
    //       <Heading
    //         padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
    //         level={5}
    //       >
    //         Sign in to your Agent or Developer account
    //       </Heading>
    //     );
    //   },
    // },
    // SignUp: {
    //   Header() {
    //     const { tokens } = useTheme();
    //     return (
    //       <Heading
    //         padding={`${tokens.space.xl} 0 0 ${tokens.space.xl}`}
    //         level={5}
    //       >
    //         Create a new Agent or Developer account
    //       </Heading>
    //     );
    //   },
    //   FormFields() {
    //     return (
    //       <>
    //         <Authenticator.SignUp.FormFields />
    //         <SelectField
    //           name="custom:country"
    //           label="Country"
    //           descriptiveText="Select your operating country"
    //         >
    //           <option value="denmark">Denmark</option>
    //           <option value="poland">Poland</option>
    //           <option value="france">France</option>
    //         </SelectField>
    //       </>
    //     );
    //   },
    // },
  };

  useEffect(() => {
    document.title = "EasyPermits®";
    I18n.setLanguage(lang);
    i18n.changeLanguage(lang);

    if (authStatus === "authenticated") {
      if (from === "/") {
        navigate("/dashboard", { replace: true });
      } else {
        navigate(from, { replace: true }); // go to page user was previously at
      }
    }
  }, [authStatus, navigate, from]);

  return (
    <>
      {/* Amplify Login form with customizations */}
      <ThemeProvider theme={theme}>
        <Authenticator formFields={formFields} components={components} />
      </ThemeProvider>

      <div style={{ textAlign: "center", padding: 30 }}>
        {authStatus === "configuring"
          ? "Loading..."
          : authStatus === "unauthenticated" && (
              <Button
                href="/"
                variant="link"
                // onClick={() => i18n.changeLanguage(lang)}
              >
                {t("Back to Homepage")}
              </Button>
            )}
      </div>
    </>
  );
}
