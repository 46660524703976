/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-north-1",
    "aws_appsync_graphqlEndpoint": "https://ielsuy6vnzgtxb3ywpm4fpxdx4.appsync-api.eu-north-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-north-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-north-1:57cba8a4-1614-47a1-bf7a-7d9e463efea8",
    "aws_cognito_region": "eu-north-1",
    "aws_user_pools_id": "eu-north-1_zKrxolTLD",
    "aws_user_pools_web_client_id": "2jo23chnhvfvl9djm7mo1cotp2",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME",
        "PHONE_NUMBER"
    ],
    "aws_cognito_mfa_configuration": "ON",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "ezpermitsdocstorage134009-dev",
    "aws_user_files_s3_bucket_region": "eu-north-1",
    "geo": {
        "amazon_location_service": {
            "region": "eu-north-1",
            "maps": {
                "items": {
                    "ezpermitsmap-dev": {
                        "style": "VectorEsriStreets"
                    }
                },
                "default": "ezpermitsmap-dev"
            },
            "search_indices": {
                "items": [
                    "ezpermitsplaceindex-dev"
                ],
                "default": "ezpermitsplaceindex-dev"
            }
        }
    }
};


export default awsmobile;
