/*
  VARIABLES AND FUNCTIONS THAT ARE USED IN MANY DIFFERENT COMPONENTS
*/
import { useTranslation } from "react-i18next";
import {
  Link,
  SpaceBetween,
  SideNavigation,
  Popover as CloudscapePopover,
} from "@cloudscape-design/components";
import KeyboardControlKeyIcon from "@mui/icons-material/KeyboardControlKey";
import KeyboardDoubleArrowUpIcon from "@mui/icons-material/KeyboardDoubleArrowUp";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import Tooltip from "@mui/material/Tooltip";

/* ----------------------------- CSS ----------------------------- */
const primaryBlue = "#0972D3";
const hoverBlue = "#075dad";
const blueLink = "#539FE5";
const warningOrange = "#E07941";
const darkGray = "#414D5C";
const lightGray = "#e3e3e3";
const primaryGreen = "#04A50B";
const warningRed = "#D82732";
const currentDate = new Date().toISOString().slice(0, 10);

const Divider = () => {
  return (
    <hr
      style={{
        borderTop: "0.5px solid",
        borderColor: lightGray,
        marginTop: 20,
        marginBottom: 20,
      }}
    />
  );
};

const DividerThick = () => {
  return (
    <hr
      style={{
        borderTop: "1.2px solid",
        borderColor: lightGray,
        marginTop: 20,
        marginBottom: 20,
      }}
    />
  );
};

const VerticalDivider = () => {
  return (
    <hr
      style={{
        borderLeft: "1px solid",
        borderColor: lightGray,
        height: "200px",
        marginLeft: 20,
        marginRight: 20,
      }}
    />
  );
};

const AccountInfoPopover = ({ project, css }) => {
  const { t } = useTranslation();
  return (
    <CloudscapePopover
      position="top"
      dismissButton={false}
      size="medium"
      triggerType="custom"
      header={
        project?.mainAgent?.given_name + " " + project?.mainAgent?.family_name
      }
      content={
        <SpaceBetween size="s">
          <Link>{project.mainAgent?.email}</Link>
          <span>{project.mainAgent?.phone_number}</span>
          <span>
            {project.mainAgent?.role === "agents" ? "Agent" : "Developer"}
          </span>
          <span>
            {project.mainAgent?.municipality && (
              <>
                {MapCodeToLabel(project.mainAgent?.municipality)},{" "}
                {project.mainAgent?.municipality.slice(0, 2) === "DK"
                  ? t("Denmark 🇩🇰")
                  : t("Poland 🇵🇱")}
              </>
            )}
          </span>
        </SpaceBetween>
      }
    >
      <div style={css}>
        <AccountCircleIcon style={{ marginRight: 5 }} />
        <b
          style={{
            color: primaryBlue,
          }}
        >
          {project.mainAgent?.given_name + " " + project.mainAgent?.family_name}
        </b>
      </div>
    </CloudscapePopover>
  );
};

const SideNavigationContents = ({ userRole }) => {
  return !userRole ? (
    <p style={{ paddingLeft: 20, paddingTop: 20 }}>Loading...</p>
  ) : userRole === "agents" ? (
    <SideNavigation
      header={{
        // href: "#",
        text: "Menu",
      }}
      items={[
        {
          type: "link",
          text: "Dashboard",
          href: "/dashboard",
        },
        {
          type: "link",
          text: "Workflow Template",
          // href: "/setup-project-template",
          href: "#",
        },
        {
          type: "link",
          text: "QuickSight Dashboard",
          href: "/quicksight",
        },
        { type: "link", text: "Payments", href: "#" },
        { type: "divider" },
        {
          type: "section-group",
          title: "Quick Links",
          items: [
            {
              type: "link",
              text: "Community Page",
              href: "/map",
            },
            {
              type: "link",
              text: "Support",
              href: "/support",
            },
            {
              type: "link",
              text: "FAQs",
              href: "/faqs",
            },
            {
              type: "link",
              text: "Data Privacy",
              href: "/data-privacy",
            },
          ],
        },
      ]}
    />
  ) : (
    // if Developers
    <SideNavigation
      header={{
        // href: "#",
        text: "Menu",
      }}
      items={[
        {
          type: "link",
          text: "Dashboard",
          href: "/dashboard",
        },
        { type: "divider" },
        {
          type: "section-group",
          title: "Quick Links",
          items: [
            {
              type: "link",
              text: "Community Page",
              href: "/map",
            },
            {
              type: "link",
              text: "Support",
              href: "/support",
            },
            {
              type: "link",
              text: "FAQs",
              href: "/faqs",
            },
            {
              type: "link",
              text: "Data Privacy",
              href: "/data-privacy",
            },
          ],
        },
      ]}
    />
  );
};

/* ----------------------------- FUNCTIONS ----------------------------- */
const isDeadlinePassed = (deadlineDate) => {
  if (deadlineDate !== null) {
    const deadlineUTC = new Date(deadlineDate);
    const currentDateUTC = new Date();
    return deadlineUTC < currentDateUTC; // returns true if deadline has passed
  } else return false;
};

const calculatePriority = (deadline, showDeadline) => {
  let priorityText = "";
  const deadlineUTC = new Date(deadline);
  const currentDateUTC = new Date(currentDate);
  const diffTime = Math.abs(deadlineUTC - currentDateUTC);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  const value = Math.trunc(100 - (diffDays / 365) * 100) || "-"; // get numerical value in %

  if (deadline) {
    // Low priority
    if (value <= 50)
      priorityText = (
        <SpaceBetween direction="horizontal">
          <Tooltip title="Low priority">
            <KeyboardControlKeyIcon style={{ color: primaryGreen }} />
          </Tooltip>
          {showDeadline && (deadline || "-")}
        </SpaceBetween>
      );
    // Medium priority
    else if (value > 50 && value <= 80)
      priorityText = (
        <SpaceBetween direction="horizontal">
          <Tooltip title="Medium priority">
            <KeyboardDoubleArrowUpIcon style={{ color: warningOrange }} />
          </Tooltip>
          {showDeadline && (deadline || "-")}
        </SpaceBetween>
      );
    // High priority
    else
      priorityText = (
        <SpaceBetween direction="horizontal">
          <Tooltip title="High priority">
            <KeyboardDoubleArrowUpIcon style={{ color: "#D50000" }} />
          </Tooltip>
          {showDeadline && (deadline || "-")}
        </SpaceBetween>
      );
  } else priorityText = "-";

  return priorityText;
};

// Get deadline dates for popover contents
const getDeadlineDate = (project, stage, deadlineEnum) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { t } = useTranslation();
  let deadlineDate = "";
  if (project.deadlines?.items?.length) {
    // only get latest deadline by sorting by createdAt value and get the first item in the list
    let sortByDate = project.deadlines?.items
      ?.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      })
      .filter(
        (item) => item.stage === stage && item.deadlineEnum === deadlineEnum
      );
    // Check that field 'date' exists to prevent app from crashing as sometimes new projects won't have this field set
    if (sortByDate[0]?.date) deadlineDate = sortByDate[0]?.date;
  }
  return (
    deadlineDate || (
      <i style={{ color: "gray", fontSize: "0.9em" }}>
        {t("Deadline not set")}
      </i>
    )
  );
};

const getDeadlineAsNumber = (
  project,
  prevStage,
  prevDeadlineEnum,
  currentStage,
  currentDeadlineEnum
) => {
  let numericalValue = 0;
  let sortByDate = "";

  if (project?.deadlines?.items?.length) {
    // Get current stage's deadline
    sortByDate = project?.deadlines?.items
      ?.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      })
      .filter(
        (item) =>
          item.stage === currentStage &&
          item.deadlineEnum === currentDeadlineEnum
      );

    // Check that field 'date' exists to prevent app from crashing as sometimes new projects won't have this field set
    if (sortByDate[0]?.date) {
      // For the very first stage (ie. Documents Review in Pre-planning)
      // there's no previous deadline so we compare it against the current date
      if (prevStage === null && prevDeadlineEnum === null) {
        const deadlineUTC = new Date(sortByDate[0]?.date);
        const currentDateUTC = new Date(currentDate);
        const diffTime = Math.abs(deadlineUTC - currentDateUTC);
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
        numericalValue = Math.trunc(100 - (diffDays / 365) * 100); // get numerical value in %
      } else {
        // For all other stages
        // Get the progress by comparing against previous stage's deadline
        let sortByDate_prev = project?.deadlines?.items
          ?.sort((a, b) => {
            return new Date(b.createdAt) - new Date(a.createdAt);
          })
          .filter(
            (item) =>
              item.stage === prevStage && item.deadlineEnum === prevDeadlineEnum
          );
        const deadlineUTC = new Date(sortByDate[0]?.date);
        const prevDeadlineUTC = new Date(sortByDate_prev[0].date);
        const currentDateUTC = new Date();

        // If deadline is in the past then progress is full
        if (currentDateUTC > prevDeadlineUTC && currentDateUTC > deadlineUTC) {
          numericalValue = 100;
        } else if (
          // if deadline is in the future then progress is 0
          currentDateUTC < prevDeadlineUTC &&
          currentDateUTC < deadlineUTC
        ) {
          numericalValue = 0;
        } else {
          // If deadline is today's date, then calculate progress
          // by getting the difference between 2 dates, then convert the difference to numerical value over 100%
          const diffTime = Math.abs(currentDateUTC - prevDeadlineUTC);
          const diffTimeDeadlines = Math.abs(deadlineUTC - prevDeadlineUTC);
          numericalValue = Math.trunc((diffTime / diffTimeDeadlines) * 100);
        }
      }
    } else numericalValue = 0;
  }
  return numericalValue || 0;
};

const getProgressColor = (project, stage, deadlineEnum) => {
  var colour = "";
  if (project.deadlines?.items?.length) {
    // only get latest deadline by sorting by createdAt value and get the first item in the list
    let sortByDate = project.deadlines?.items
      ?.sort((a, b) => {
        return new Date(b.createdAt) - new Date(a.createdAt);
      })
      .filter(
        (item) => item.stage === stage && item.deadlineEnum === deadlineEnum
      );

    if (sortByDate[0]?.date) {
      const delayedTasksExist = project?.tasks?.items.filter(
        (task) =>
          task.stage === project.stage &&
          task.deadline &&
          !task.done &&
          isDeadlinePassed(task.deadline)
      ).length;
      const delayedDocumentsExist = project?.documents?.items.filter(
        (document) =>
          document.stage === project.stage &&
          document.deadline &&
          document.status !== "d_done" &&
          isDeadlinePassed(document.deadline)
      ).length;

      // check if stage < project stage by comparing the first letter
      if (stage[0] === project.stage[0]) {
        // show warning orange if there are either delayed tasks or documents
        colour =
          delayedTasksExist || delayedDocumentsExist
            ? warningOrange
            : primaryBlue;
      } else {
        if (stage[0] < project.stage[0])
          colour = primaryGreen; // for past stages show green (done)
        else colour = lightGray; // for future stages show blue
      }
    } else colour = "";
  }
  return colour;
};

const MapCodeToLabel = (code) => {
  let label = "";
  denmarkMunicipalityList.forEach((item) => {
    if (item.value === code) label = item.label;
  });
  return label;
};

const MapCodeToWebsite = (code) => {
  let website = "";
  denmarkMunicipalityList.forEach((item) => {
    if (item.value === code) website = item.website;
  });
  return website;
};

/* MUNICIPALITIES */
// Min and max coordinates taken from: https://elevation.city/
// Denmark municipalities reference list: https://en.wikipedia.org/wiki/List_of_municipalities_of_Denmark
const denmarkMunicipalityList = [
  // {
  //   value: "DK_101",
  //   label: "Copenhagen",
  //   website: "https://www.kk.dk",
  // },
  // {
  //   value: "DK_751",
  //   label: "Aarhus",
  //   website: "https://www.aarhus.dk",
  // },
  // {
  //   value: "DK_851",
  //   label: "Aalborg",
  // },
  // {
  //   value: "DK_461",
  //   label: "Odense",
  // },
  // {
  //   value: "DK_561",
  //   label: "Esbjerg",
  // },
  // {
  //   value: "DK_630",
  //   label: "Vejle",
  // },
  // {
  //   value: "DK_147",
  //   label: "Frederiksberg",
  // },
  // {
  //   value: "DK_730",
  //   label: "Randers",
  // },
  // {
  //   value: "DK_791",
  //   label: "Viborg",
  // },
  // {
  //   value: "DK_621",
  //   label: "Kolding",
  // },
  // {
  //   value: "DK_740",
  //   label: "Silkeborg",
  // },
  // {
  //   value: "DK_657",
  //   label: "Herning",
  // },
  // {
  //   value: "DK_615",
  //   label: "Horsens",
  // },
  // {
  //   value: "DK_265",
  //   label: "Roskilde",
  // },
  // {
  //   value: "DK_370",
  //   label: "Næstved",
  // },
  // {
  //   value: "DK_540",
  //   label: "Sønderborg",
  // },
  // {
  //   value: "DK_157",
  //   label: "Gentofte",
  // },
  // {
  //   value: "DK_316",
  //   label: "Holbæk",
  // },
  // {
  //   value: "DK_860",
  //   label: "Hjørring",
  // },
  // {
  //   value: "DK_159",
  //   label: "Gladsaxe",
  // },
  // {
  //   value: "DK_376",
  //   label: "Guldborgsund",
  // },
  // {
  //   value: "DK_217",
  //   label: "Helsingør",
  // },
  // {
  //   value: "DK_813",
  //   label: "Frederikshavn",
  // },
  // {
  //   value: "DK_580",
  //   label: "Aabenraa",
  // },
  // {
  //   value: "DK_479",
  //   label: "Svendborg",
  // },
  // {
  //   value: "DK_746",
  //   label: "Skanderborg",
  // },
  // {
  //   value: "DK_760",
  //   label: "Ringkøbing-Skjern",
  // },
  // {
  //   value: "DK_259",
  //   label: "Køge",
  // },
  // {
  //   value: "DK_661",
  //   label: "Holstebro",
  // },
  // {
  //   value: "DK_510",
  //   label: "Haderslev",
  // },
  // {
  //   value: "DK_230",
  //   label: "Rudersdal",
  // },
  // {
  //   value: "DK_173",
  //   label: "Lyngby-Taarbæk",
  // },
  // {
  //   value: "DK_430",
  //   label: "Faaborg-Midtfyn",
  // },
  // {
  //   value: "DK_167",
  //   label: "Hvidovre",
  // },
  // {
  //   value: "DK_573",
  //   label: "Varde",
  // },
  // {
  //   value: "DK_607",
  //   label: "Fredericia",
  // },
  // {
  //   value: "DK_326",
  //   label: "Kalundborg",
  // },
  // {
  //   value: "DK_219",
  //   label: "Hillerød",
  // },
  // {
  //   value: "DK_169",
  //   label: "Høje-Taastrup",
  // },
  // {
  //   value: "DK_151",
  //   label: "Ballerup",
  // },
  // {
  //   value: "DK_253",
  //   label: "Greve",
  // },
  // {
  //   value: "DK_779",
  //   label: "Skive",
  // },
  // {
  //   value: "DK_710",
  //   label: "Favrskov",
  // },
  // {
  //   value: "DK_766",
  //   label: "Hedensted",
  // },
  // {
  //   value: "DK_390",
  //   label: "Vordingborg",
  // },
  // {
  //   value: "DK_360",
  //   label: "Lolland",
  // },
  // {
  //   value: "DK_787",
  //   label: "Thisted",
  // },
  // {
  //   value: "DK_250",
  //   label: "Frederikssund",
  // },
  // {
  //   value: "DK_575",
  //   label: "Vejen",
  // },
  // {
  //   value: "DK_846",
  //   label: "Mariagerfjord",
  // },
  // {
  //   value: "DK_240",
  //   label: "Egedal",
  // },
  // {
  //   value: "DK_706",
  //   label: "Syddjurs",
  // },
  // {
  //   value: "DK_420",
  //   label: "Assens",
  // },
  // {
  //   value: "DK_400",
  //   label: "Bornholm",
  // },
  // {
  //   value: "DK_185",
  //   label: "Tårnby",
  // },
  // {
  //   value: "DK_756",
  //   label: "Ikast-Brande",
  // },
  // {
  //   value: "DK_270",
  //   label: "Gribskov",
  // },
  // {
  //   value: "DK_210",
  //   label: "Fredensborg",
  // },
  {
    value: "DK_550",
    label: "Tønder",
    website: "https://www.toender.dk",
    max_lon: 9.03,
    max_lat: 55.03,
    min_lon: 8.74,
    min_lat: 54.87,
  },
  // {
  //   value: "DK_849",
  //   label: "Jammerbugt",
  // },
  // {
  //   value: "DK_190",
  //   label: "Furesø",
  // },
  // {
  //   value: "DK_770",
  //   label: "Norddjurs",
  // },
  // {
  //   value: "DK_410",
  //   label: "Middelfart",
  // },
  {
    value: "DK_820",
    label: "Vesthimmerland",
    website: "https://vesthimmerland.dk",
    feedback: "https://vesthimmerland.viewer.dkplan.niras.dk/plan/19#/18988",
    max_lon: 9.6,
    max_lat: 57.03,
    min_lon: 9.16,
    min_lat: 56.65,
  },
  // {
  //   value: "DK_175",
  //   label: "Rødovre",
  // },
  // {
  //   value: "DK_810",
  //   label: "Brønderslev",
  // },
  // {
  //   value: "DK_153",
  //   label: "Brøndby",
  // },
  // {
  //   value: "DK_329",
  //   label: "Ringsted",
  // },
  // {
  //   value: "DK_306",
  //   label: "Odsherred",
  // },
  // {
  //   value: "DK_450",
  //   label: "Nyborg",
  // },
  // {
  //   value: "DK_260",
  //   label: "Halsnæs",
  // },
  // {
  //   value: "DK_340",
  //   label: "Sorø",
  // },
  // {
  //   value: "DK_480",
  //   label: "Nordfyn",
  // },
  // {
  //   value: "DK_840",
  //   label: "Rebild",
  // },
  // {
  //   value: "DK_165",
  //   label: "Albertslund",
  // },
  // {
  //   value: "DK_350",
  //   label: "Lejre",
  // },
  // {
  //   value: "DK_163",
  //   label: "Herlev",
  // },
  // {
  //   value: "DK_530",
  //   label: "Billund",
  // },
  // {
  //   value: "DK_223",
  //   label: "Hørsholm",
  // },
  // {
  //   value: "DK_201",
  //   label: "Allerød",
  // },
  // {
  //   value: "DK_440",
  //   label: "Kerteminde",
  // },
  // {
  //   value: "DK_671",
  //   label: "Struer",
  // },
  // { value: "DK_336", label: "Stevns" },
  // { value: "DK_727", label: "Odder" },
  // { value: "DK_161", label: "Glostrup" },
  // { value: "DK_773", label: "Morsø" },
  // { value: "DK_665", label: "Lemvig" },
  // { value: "DK_269", label: "Solrød" },
  // { value: "DK_183", label: "Ishøj" },
  // { value: "DK_187", label: "Vallensbæk" },
  // { value: "DK_155", label: "Dragør" },
  // { value: "DK_482", label: "Langeland" },
  // { value: "DK_492", label: "Ærø" },
  // { value: "DK_741", label: "Samsø" },
  // { value: "DK_563", label: "Fanø" },
  // { value: "DK_825", label: "Læsø" },
];

// Poland municipalities (gminas) reference list: https://en.wikipedia.org/wiki/List_of_Polish_gminas
const polandMunicipalityList = [
  {
    value: "PL_WARSAW",
    label: "Warsaw",
    // website: "",
    max_lon: 21.05,
    max_lat: 52.25,
    min_lon: 20.96,
    min_lat: 52.2,
  },
  {
    value: "PL_KRAKOW",
    label: "Kraków",
    // website: "",
    max_lon: 19.98,
    max_lat: 50.08,
    min_lon: 19.89,
    min_lat: 50.03,
  },
  {
    value: "PL_LODZ",
    label: "Łódź",
    // website: "",
    max_lon: 19.51,
    max_lat: 51.79,
    min_lon: 19.43,
    min_lat: 51.74,
  },
  {
    value: "PL_WROCLAW",
    label: "Wrocław",
    // website: "",
    max_lon: 17.07,
    max_lat: 51.12,
    min_lon: 16.98,
    min_lat: 51.07,
  },
  {
    value: "PL_POZNAN",
    label: "Poznań",
    // website: "",
    max_lon: 16.97,
    max_lat: 52.43,
    min_lon: 16.88,
    min_lat: 52.38,
  },
];

export {
  primaryBlue,
  blueLink,
  hoverBlue,
  darkGray,
  warningOrange,
  warningRed,
  lightGray,
  primaryGreen,
  currentDate,
  Divider,
  DividerThick,
  VerticalDivider,
  AccountInfoPopover,
  SideNavigationContents,
  MapCodeToLabel,
  MapCodeToWebsite,
  getDeadlineDate,
  getDeadlineAsNumber,
  getProgressColor,
  isDeadlinePassed,
  calculatePriority,
  denmarkMunicipalityList,
  polandMunicipalityList,
};
