import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "../../i18n";
import { CSVLink } from "react-csv";
import {
  primaryBlue,
  Divider,
  MapCodeToLabel,
  AccountInfoPopover,
} from "../../shared";
import {
  Container,
  Header,
  ExpandableSection,
  Tabs,
  Popover,
  SpaceBetween,
  Link,
  Button,
} from "@cloudscape-design/components";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

export default function Activities({
  projectDetails,
  allComments,
  userDetails,
}) {
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState("history");

  const LogsCSVHeaders = [
    { label: t("Project log"), key: "title" },
    { label: t("Date"), key: "date" },
    { label: t("Author"), key: "by" },
    { label: t("Email"), key: "email" },
  ];
  const DocumentCommentsCSVHeaders = [
    { label: t("Comment"), key: "comment" },
    { label: t("Date"), key: "date" },
    { label: t("Author"), key: "by" },
    { label: t("Version"), key: "version" },
    { label: t("Page"), key: "page" },
    { label: t("Section"), key: "section" },
  ];
  const CommentsCSVHeaders = [
    { label: t("Comment"), key: "comment" },
    { label: t("Date"), key: "date" },
    { label: t("Author"), key: "by" },
    { label: t("Email"), key: "email" },
  ];

  useEffect(() => {}, [activeTab]);
  useEffect(() => {
    if (projectDetails?.notificationMessage?.includes("comment")) {
      setActiveTab("comments");
    } else if (
      projectDetails?.notificationMessage?.includes("task") ||
      projectDetails?.notificationMessage?.includes("document")
    ) {
      setActiveTab("logs");
    }
  }, [projectDetails?.notificationMessage]);

  return (
    <Container
      fitHeight
      header={
        <Header
          variant="h2"
          actions={
            // Project Logs
            activeTab === "logs" ? (
              <Button iconAlign="left" iconName="download">
                <CSVLink
                  separator={";"}
                  style={{ textDecoration: "none", color: primaryBlue }}
                  filename={`${projectDetails?.name}-${t(
                    "Project Logs"
                  )}-${new Date().toLocaleDateString()}.csv`}
                  data={
                    projectDetails?.documents?.items
                      .filter((t) => t.d_done_at)
                      .map((t) => ({
                        ...t,
                        date: t.d_done_at,
                        b_review_at: null,
                        c_revise_at: null,
                      }))
                      ?.concat(
                        projectDetails?.documents?.items
                          .filter((t) => t.c_revise_at)
                          .map((t) => ({
                            ...t,
                            date: t.c_revise_at,
                            b_review_at: null,
                            d_done_at: null,
                          }))
                          ?.concat(
                            projectDetails?.documents?.items
                              .filter((t) => t.b_review_at)
                              .map((t) => ({
                                ...t,
                                date: t.b_review_at,
                                c_revise_at: null,
                                d_done_at: null,
                              }))
                              ?.concat(
                                projectDetails?.tasks?.items
                                  .filter((t) => t.done_at)
                                  .map((t) => ({ ...t, date: t.done_at }))
                                  ?.concat(
                                    projectDetails?.tasks?.items
                                      .map((t) => ({
                                        ...t,
                                        date: t.createdAt,
                                        done_at: null,
                                      }))
                                      ?.concat(
                                        projectDetails?.documents?.items.map(
                                          (t) => ({
                                            ...t,
                                            date: t.createdAt,
                                            b_review_at: null,
                                            c_revise_at: null,
                                            d_done_at: null,
                                          })
                                        )
                                      )
                                  )
                              )
                          )
                      )
                      ?.sort((a, b) => new Date(b.date) - new Date(a.date))
                      ?.map((task) => ({
                        title: `${t(
                          task?.title
                            ? task?.done_at
                              ? "Task Completed"
                              : "New Task Created"
                            : task?.b_review_at
                            ? "New Document Uploaded"
                            : task?.c_revise_at
                            ? "Sent Back to Edit"
                            : task?.d_done_at
                            ? "Document Marked as Complete"
                            : "New Document Created"
                        )}: ${t(task?.title || task?.name)}`,
                        date: task?.b_review_at
                          ? new Date(task?.b_review_at).toLocaleString()
                          : task?.c_revise_at
                          ? new Date(task?.c_revise_at).toLocaleString()
                          : task?.d_done_at
                          ? new Date(task?.d_done_at).toLocaleString()
                          : task?.done_at
                          ? new Date(task?.done_at).toLocaleString()
                          : new Date(task.createdAt).toLocaleString(),
                        by: task.b_review_at
                          ? `${task?.assignedTo?.given_name} ${task?.assignedTo?.family_name}`
                          : task.owners.includes(projectDetails?.mainAgent?.id)
                          ? projectDetails?.mainAgent?.given_name +
                            " " +
                            projectDetails?.mainAgent?.family_name
                          : projectDetails?.mainDeveloper?.given_name +
                            " " +
                            projectDetails?.mainDeveloper?.family_name,
                        email: task.b_review_at
                          ? task?.assignedTo?.email
                          : task.owners.includes(projectDetails?.mainAgent?.id)
                          ? projectDetails?.mainAgent?.email
                          : projectDetails?.mainDeveloper?.email,
                      })) || ""
                  }
                  headers={LogsCSVHeaders}
                >
                  {t("Download Project Logs")}
                </CSVLink>
              </Button>
            ) : (
              // Project Comments
              <SpaceBetween direction="horizontal" size="s">
                <Button iconAlign="left" iconName="download">
                  <CSVLink
                    separator={";"}
                    style={{ textDecoration: "none", color: primaryBlue }}
                    filename={`${projectDetails?.name}-${t(
                      "Comments"
                    )}-${new Date().toLocaleDateString()}.csv`}
                    data={
                      projectDetails?.comments?.items?.map((c) => ({
                        comment: c.comment,
                        date: c.createdAt,
                        by: c.persona.family_name + " " + c.persona.given_name,
                        email: c.persona.email,
                      })) || ""
                    }
                    headers={CommentsCSVHeaders}
                  >
                    {t("Download Project Comments")}
                  </CSVLink>
                </Button>
                <Button iconAlign="left" iconName="download">
                  <CSVLink
                    separator={";"}
                    style={{ textDecoration: "none", color: primaryBlue }}
                    filename={`${projectDetails?.name}-${t(
                      "Document Comments"
                    )}-${new Date().toLocaleDateString()}.csv`}
                    data={allComments}
                    headers={DocumentCommentsCSVHeaders}
                  >
                    {t("Download Document Comments")}
                  </CSVLink>
                </Button>
              </SpaceBetween>
            )
          }
        >
          {t("Activities")}
        </Header>
      }
    >
      <Tabs
        activeTabId={activeTab}
        tabs={[
          {
            label: t("History"),
            id: "history",
            content: (
              <>
                {projectDetails?.mainAgent &&
                  projectDetails?.e_approval_closed &&
                  projectDetails?.projectClosed && (
                    <div
                      style={{
                        display: "flex",
                        padding: 10,
                      }}
                    >
                      <AccountInfoPopover
                        project={projectDetails}
                        css={{
                          display: "flex",
                          cursor: "pointer",
                          paddingRight: 7,
                        }}
                      />
                      <span
                        style={{
                          fontWeight:
                            new Date(projectDetails?.e_approval_closed) >
                            new Date(
                              userDetails?.visitedProjects?.find(
                                (vp) => vp.projectId === projectDetails?.id
                              )?.visitedAt
                            )
                              ? "800"
                              : "400",
                        }}
                      >
                        {t("closed the Final Decision Stage and the Project")}.
                      </span>
                      <div style={{ marginLeft: "auto", marginRight: 0 }}>
                        {new Date(
                          projectDetails?.e_approval_closed
                        ).toLocaleString()}
                      </div>
                    </div>
                  )}
                {projectDetails?.mainAgent &&
                  projectDetails?.d_final_consultation_closed &&
                  projectDetails?.stage[0] &&
                  ["e", "f"].includes(projectDetails?.stage[0]) && (
                    <div
                      style={{
                        display: "flex",
                        padding: 10,
                      }}
                    >
                      <AccountInfoPopover
                        project={projectDetails}
                        css={{
                          display: "flex",
                          cursor: "pointer",
                          paddingRight: 7,
                        }}
                      />
                      <span
                        style={{
                          fontWeight:
                            new Date(
                              projectDetails?.d_final_consultation_closed
                            ) >
                            new Date(
                              userDetails?.visitedProjects?.find(
                                (vp) => vp.projectId === projectDetails?.id
                              )?.visitedAt
                            )
                              ? "800"
                              : "400",
                        }}
                      >
                        {t("closed the Public Consultation Stage")}.
                      </span>
                      <div style={{ marginLeft: "auto", marginRight: 0 }}>
                        {new Date(
                          projectDetails?.d_final_consultation_closed
                        ).toLocaleString()}
                      </div>
                    </div>
                  )}

                {projectDetails?.mainAgent &&
                  projectDetails?.c_planning_closed &&
                  projectDetails?.stage[0] &&
                  ["d", "e", "f"].includes(projectDetails?.stage[0]) && (
                    <div
                      style={{
                        display: "flex",
                        padding: 10,
                      }}
                    >
                      <AccountInfoPopover
                        project={projectDetails}
                        css={{
                          display: "flex",
                          cursor: "pointer",
                          paddingRight: 7,
                        }}
                      />
                      <span
                        style={{
                          fontWeight:
                            new Date(projectDetails?.c_planning_closed) >
                            new Date(
                              userDetails?.visitedProjects?.find(
                                (vp) => vp.projectId === projectDetails?.id
                              )?.visitedAt
                            )
                              ? "800"
                              : "400",
                        }}
                      >
                        {t("closed the Planning Stage")}.
                      </span>
                      <div style={{ marginLeft: "auto", marginRight: 0 }}>
                        {new Date(
                          projectDetails?.c_planning_closed
                        ).toLocaleString()}
                      </div>
                    </div>
                  )}

                {projectDetails?.mainAgent &&
                  projectDetails?.b_initial_consultation_closed &&
                  projectDetails?.stage[0] &&
                  ["c", "d", "e", "f"].includes(projectDetails?.stage[0]) && (
                    <div
                      style={{
                        display: "flex",
                        padding: 10,
                      }}
                    >
                      <AccountInfoPopover
                        project={projectDetails}
                        css={{
                          display: "flex",
                          cursor: "pointer",
                          paddingRight: 7,
                        }}
                      />
                      <span
                        style={{
                          fontWeight:
                            new Date(
                              projectDetails?.b_initial_consultation_closed
                            ) >
                            new Date(
                              userDetails?.visitedProjects?.find(
                                (vp) => vp.projectId === projectDetails?.id
                              )?.visitedAt
                            )
                              ? "800"
                              : "400",
                        }}
                      >
                        {t("closed the Initial Public Consultation Stage")}.
                      </span>
                      <div style={{ marginLeft: "auto", marginRight: 0 }}>
                        {new Date(
                          projectDetails?.b_initial_consultation_closed
                        ).toLocaleString()}
                      </div>
                    </div>
                  )}

                {projectDetails?.mainAgent &&
                  projectDetails?.a_preplanning_closed &&
                  projectDetails?.stage[0] &&
                  ["b", "c", "d", "e", "f"].includes(
                    projectDetails?.stage[0]
                  ) && (
                    <div
                      style={{
                        display: "flex",
                        padding: 10,
                      }}
                    >
                      <AccountInfoPopover
                        project={projectDetails}
                        css={{
                          display: "flex",
                          cursor: "pointer",
                          paddingRight: 7,
                        }}
                      />
                      <span
                        style={{
                          fontWeight:
                            new Date(projectDetails?.a_preplanning_closed) >
                            new Date(
                              userDetails?.visitedProjects?.find(
                                (vp) => vp.projectId === projectDetails?.id
                              )?.visitedAt
                            )
                              ? "800"
                              : "400",
                        }}
                      >
                        {t("closed the Preplanning Stage")}.
                      </span>
                      <div style={{ marginLeft: "auto", marginRight: 0 }}>
                        {new Date(
                          projectDetails?.a_preplanning_closed
                        ).toLocaleString()}
                      </div>
                    </div>
                  )}

                {projectDetails?.mainAgent && projectDetails?.startDate && (
                  <div
                    style={{
                      display: "flex",
                      padding: 10,
                    }}
                  >
                    <AccountInfoPopover
                      project={projectDetails}
                      css={{
                        display: "flex",
                        cursor: "pointer",
                        paddingRight: 7,
                      }}
                    />
                    {t("started the project")}.
                    <div style={{ marginLeft: "auto", marginRight: 0 }}>
                      {new Date(projectDetails?.createdAt).toLocaleDateString()}
                    </div>
                  </div>
                )}

                <div
                  style={{
                    display: "flex",
                    padding: 10,
                  }}
                >
                  <AccountInfoPopover
                    project={projectDetails}
                    css={{
                      display: "flex",
                      cursor: "pointer",
                      paddingRight: 7,
                    }}
                  />
                  {t(" sent a project request")}.
                  <div
                    style={{
                      marginLeft: "auto",
                      marginRight: 0,
                      whiteSpace: "nowrap",
                    }}
                  >
                    {new Date(projectDetails?.createdAt).toLocaleString()}
                  </div>
                </div>
              </>
            ),
          },
          {
            label: t("Project Logs"),
            id: "logs",
            content: (
              <>
                {projectDetails?.documents?.items
                  .filter((t) => t.d_done_at)
                  .map((t) => ({
                    ...t,
                    date: t.d_done_at,
                    b_review_at: null,
                    c_revise_at: null,
                  }))
                  ?.concat(
                    projectDetails?.documents?.items
                      .filter((t) => t.c_revise_at)
                      .map((t) => ({
                        ...t,
                        date: t.c_revise_at,
                        b_review_at: null,
                        d_done_at: null,
                      }))
                      ?.concat(
                        projectDetails?.documents?.items
                          .filter((t) => t.b_review_at)
                          .map((t) => ({
                            ...t,
                            date: t.b_review_at,
                            c_revise_at: null,
                            d_done_at: null,
                          }))
                          ?.concat(
                            projectDetails?.tasks?.items
                              .filter((t) => t.done_at)
                              .map((t) => ({ ...t, date: t.done_at }))
                              ?.concat(
                                projectDetails?.tasks?.items
                                  .map((t) => ({
                                    ...t,
                                    date: t.createdAt,
                                    done_at: null,
                                  }))
                                  ?.concat(
                                    projectDetails?.documents?.items.map(
                                      (t) => ({
                                        ...t,
                                        date: t.createdAt,
                                        b_review_at: null,
                                        c_revise_at: null,
                                        d_done_at: null,
                                      })
                                    )
                                  )
                              )
                          )
                      )
                  )
                  ?.sort((a, b) => new Date(b.date) - new Date(a.date))
                  ?.map((task) => (
                    <div
                      key={task.id}
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                        padding: 10,
                      }}
                    >
                      {/* Activity author */}
                      <div>
                        <Popover
                          position="top"
                          dismissButton={false}
                          size="medium"
                          triggerType="custom"
                          header={
                            task.b_review_at
                              ? task?.assignedTo?.given_name +
                                " " +
                                task?.assignedTo?.family_name
                              : task.owners.includes(
                                  projectDetails?.mainAgent?.id
                                )
                              ? projectDetails?.mainAgent?.given_name +
                                " " +
                                projectDetails?.mainAgent?.family_name
                              : projectDetails?.mainDeveloper?.given_name +
                                " " +
                                projectDetails?.mainDeveloper?.family_name
                          }
                          content={
                            <SpaceBetween size="s">
                              <Link>
                                {task.b_review_at
                                  ? task?.assignedTo?.email
                                  : task.owners.includes(
                                      projectDetails?.mainAgent?.id
                                    )
                                  ? projectDetails?.mainAgent?.email
                                  : projectDetails?.mainDeveloper?.email}
                              </Link>
                              <span>
                                {task.b_review_at
                                  ? task?.assignedTo.phone_number
                                  : task.owners.includes(
                                      projectDetails?.mainAgent?.id
                                    )
                                  ? projectDetails?.mainAgent?.phone_number
                                  : projectDetails?.mainDeveloper?.phone_number}
                              </span>
                              <span>
                                {task.b_review_at
                                  ? t(task?.assignedTo.role)
                                  : task.owners.includes(
                                      projectDetails?.mainAgent?.id
                                    )
                                  ? t(projectDetails?.mainAgent?.role)
                                  : t(projectDetails?.mainDeveloper?.role)}
                              </span>
                              <span>
                                {t("Municipality")}:{" "}
                                {task.b_review_at
                                  ? MapCodeToLabel(
                                      task?.assignedTo.municipality
                                    ) || t("None")
                                  : task.owners.includes(
                                      projectDetails?.mainAgent?.id
                                    )
                                  ? MapCodeToLabel(
                                      projectDetails?.mainAgent?.municipality
                                    ) || "None"
                                  : MapCodeToLabel(
                                      projectDetails?.mainDeveloper
                                        ?.municipality
                                    ) || "None"}
                              </span>
                            </SpaceBetween>
                          }
                        >
                          <div style={{ display: "flex", cursor: "pointer" }}>
                            <AccountCircleIcon style={{ marginRight: 5 }} />
                            <b
                              style={{
                                color: primaryBlue,
                                marginRight: 5,
                                whiteSpace: "nowrap",
                              }}
                            >
                              {task.b_review_at
                                ? `${task?.assignedTo?.given_name} ${task?.assignedTo?.family_name}`
                                : task.owners.includes(
                                    projectDetails?.mainAgent?.id
                                  )
                                ? `${projectDetails?.mainAgent?.given_name} ${projectDetails?.mainAgent?.family_name}`
                                : `${projectDetails?.mainDeveloper?.given_name} ${projectDetails?.mainDeveloper?.family_name}`}
                            </b>
                          </div>
                        </Popover>
                      </div>
                      {/* Activity name */}
                      <div
                        style={{
                          fontWeight:
                            new Date(task?.date) >
                            new Date(
                              userDetails?.visitedProjects?.find(
                                (vp) => vp.projectId === projectDetails?.id
                              )?.visitedAt
                            )
                              ? "800"
                              : "400",
                        }}
                      >{`${t(
                        task?.title
                          ? task?.done_at
                            ? "Task Completed"
                            : "New Task Created"
                          : task?.b_review_at
                          ? "New Document Uploaded"
                          : task?.c_revise_at
                          ? "Sent Back to Edit"
                          : task?.d_done_at
                          ? "Document Marked as Complete"
                          : "New Document Created"
                      )}: ${t(task?.title || task?.name)}`}</div>
                      {/* Activity timestamp */}
                      <div
                        style={{
                          whiteSpace: "nowrap",
                          marginLeft: "auto",
                          marginRight: 0,
                        }}
                      >
                        {task?.b_review_at
                          ? new Date(task?.b_review_at).toLocaleString()
                          : task?.c_revise_at
                          ? new Date(task?.c_revise_at).toLocaleString()
                          : task?.d_done_at
                          ? new Date(task?.d_done_at).toLocaleString()
                          : task?.done_at
                          ? new Date(task?.done_at).toLocaleString()
                          : new Date(task.createdAt).toLocaleString()}
                      </div>
                    </div>
                  ))}
              </>
            ),
          },
          {
            label: t("Comments"),
            id: "comments",
            content: (
              <>
                {/* -------------------- Document comments -------------------- */}
                <ExpandableSection
                  defaultExpanded
                  variant="footer"
                  headerText={t("Document Comments")}
                >
                  {projectDetails?.documents?.items?.map((document) =>
                    document?.comments?.items
                      ?.sort(
                        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                      )
                      .map((comment) => (
                        <div
                          key={comment.id}
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            padding: 10,
                          }}
                        >
                          <Popover
                            position="top"
                            dismissButton={false}
                            size="medium"
                            triggerType="custom"
                            header={
                              comment?.persona?.given_name +
                              " " +
                              comment?.persona?.family_name
                            }
                            content={
                              <SpaceBetween size="s">
                                <Link>{comment?.persona?.email}</Link>
                                <span>{comment?.persona?.phone_number}</span>
                                <span>
                                  {comment?.persona?.role === "agents"
                                    ? "Agent"
                                    : "Developer"}
                                </span>
                                <span>
                                  {t("Municipality")}:{" "}
                                  {MapCodeToLabel(
                                    comment?.persona?.municipality
                                  ) || "None"}
                                </span>
                              </SpaceBetween>
                            }
                          >
                            <div style={{ display: "flex", cursor: "pointer" }}>
                              <AccountCircleIcon style={{ marginRight: 5 }} />
                              <b style={{ color: primaryBlue, marginRight: 5 }}>
                                {comment?.persona?.given_name +
                                  " " +
                                  comment?.persona?.family_name}
                              </b>
                            </div>
                          </Popover>
                          {t("commented on the document")}{" "}
                          <i style={{ marginLeft: 5 }}>{t(document.name)}</i>:
                          <span
                            style={{
                              marginLeft: 5,
                              fontWeight:
                                new Date(comment.createdAt) >
                                new Date(
                                  userDetails?.visitedProjects?.find(
                                    (vp) => vp.projectId === projectDetails?.id
                                  )?.visitedAt
                                )
                                  ? "800"
                                  : "400",
                            }}
                          >
                            {comment.comment}
                          </span>
                          <div style={{ marginLeft: "auto", marginRight: 0 }}>
                            {new Date(comment.createdAt).toLocaleString()}
                          </div>
                        </div>
                      ))
                  )}
                </ExpandableSection>

                <Divider />

                {/* --------------------  Project comments -------------------- */}
                <ExpandableSection
                  defaultExpanded
                  variant="footer"
                  headerText={t("Project Comments")}
                >
                  {projectDetails?.comments?.items
                    ?.sort(
                      (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                    )
                    .map((comment) => (
                      <div
                        key={comment.id}
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          padding: 10,
                        }}
                      >
                        <Popover
                          position="top"
                          dismissButton={false}
                          size="medium"
                          triggerType="custom"
                          header={
                            comment?.persona?.given_name +
                            " " +
                            comment?.persona?.family_name
                          }
                          content={
                            <SpaceBetween size="s">
                              <Link>{comment?.persona?.email}</Link>
                              <span>{comment?.persona?.phone_number}</span>
                            </SpaceBetween>
                          }
                        >
                          <div style={{ display: "flex", cursor: "pointer" }}>
                            <AccountCircleIcon style={{ marginRight: 5 }} />
                            <b style={{ color: primaryBlue, marginRight: 5 }}>
                              {comment?.persona?.given_name +
                                " " +
                                comment?.persona?.family_name}
                            </b>
                          </div>
                        </Popover>
                        {t("added a comment")}:
                        <span
                          style={{
                            marginLeft: 5,
                            fontWeight:
                              new Date(comment.createdAt) >
                              new Date(
                                userDetails?.visitedProjects?.find(
                                  (vp) => vp.projectId === projectDetails?.id
                                )?.visitedAt
                              )
                                ? "800"
                                : "400",
                          }}
                        >
                          {comment.comment}
                        </span>
                        <div style={{ marginLeft: "auto", marginRight: 0 }}>
                          {new Date(comment.createdAt).toLocaleString()}
                        </div>
                      </div>
                    ))}
                </ExpandableSection>
              </>
            ),
          },
        ]}
        onChange={(e) => setActiveTab(e.detail.activeTabId)}
      />
    </Container>
  );
}
