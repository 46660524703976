import { useContext } from "react";
import { useTranslation } from "react-i18next";
import { TabletLayoutContext } from "../../AppContexts";
import CommunityNavigationBar from "./CommunityNavigationBar";
import { Divider } from "../../shared";
import {
  AppLayout,
  ContentLayout,
  Header,
  SpaceBetween,
  Container,
  Grid,
  Button,
  Box,
} from "@cloudscape-design/components";
import PermittingAgent from "../../assets/PermittingAgent.svg";
import CommunityMember from "../../assets/CommunityMember.svg";
import ProjectDeveloper from "../../assets/WindfarmDeveloper.svg";

export default function LandingPage() {
  const { t, i18n } = useTranslation();
  const { tabletLayout } = useContext(TabletLayoutContext);

  return (
    <>
      <CommunityNavigationBar />
      <AppLayout
        navigationHide={true}
        toolsHide={true}
        content={
          <ContentLayout
            header={
              <SpaceBetween size="xxl">
                <div />
                <Header variant="h1">
                  <div style={{ display: "flex" }}>
                    <span
                      style={{
                        fontSize: "1.4em",
                        marginTop: 15,
                        marginRight: 20,
                      }}
                    >
                      {t("Welcome To")}
                    </span>
                    <img
                      alt="Easypermits Logo"
                      src={
                        process.env.PUBLIC_URL + "easypermits-logo-white.svg"
                      }
                      style={{ width: "50%" }}
                    />
                  </div>
                </Header>
                <span style={{ color: "white", whiteSpace: "pre-wrap" }}>
                  {t(
                    "EasyPermits is your digital one-stop shop for the onshore wind projects permitting process.\n\nWith EasyPermits we are focused on streamlining the permitting process by improving information management, increasing transparency of the onshore wind farm permitting process and increasing collaboration between project developers, permitting agents as well as the community members.\n\nEasyPermits is a web-based tool developed through a collaboration between WindEurope, Amazon Web Services and Accenture."
                  )}
                </span>
                <div />
              </SpaceBetween>
            }
          >
            <Grid
              gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}
            >
              <Container fitHeight>
                <Box>
                  <img
                    alt="houses icon"
                    src={CommunityMember}
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "5%",
                      marginBottom: "6%",
                      width: tabletLayout ? "85%" : "60%",
                    }}
                  />
                  <Box variant="p" textAlign="center" fontWeight="bold">
                    {t("I am a Community Member")}
                  </Box>
                </Box>
                <Box>
                  {tabletLayout ? (
                    <Box variant="p" margin={{ top: "m", bottom: "m" }}>
                      {t(
                        "I live in a municipality with planned wind projects. I want to learn more about the upcoming wind projects near me. I want to stay informed and actively engage in discussions about their impact on our community."
                      )}
                    </Box>
                  ) : (
                    <div style={{ height: 100 }}>
                      <Box variant="p" margin={{ top: "m", bottom: "m" }}>
                        {t(
                          "I live in a municipality with planned wind projects. I want to learn more about the upcoming wind projects near me. I want to stay informed and actively engage in discussions about their impact on our community."
                        )}
                      </Box>
                    </div>
                  )}
                  <Box margin="xxl" textAlign="center">
                    <Button variant="primary" href="/map">
                      {t("Search Wind Projects")}
                    </Button>
                  </Box>
                </Box>
                <Divider />
                <Box variant="p">
                  {t(
                    "Explore our Interactive Map. Click on the red markers to discover about the upcoming wind projects in the permitting process in your area and beyond. Want to learn more about wind energy and the permitting process in general? Visit the Frequently Asked Questions section."
                  )}
                </Box>
              </Container>
              <Container fitHeight>
                <Box>
                  <img
                    alt="houses icon"
                    src={PermittingAgent}
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "5%",
                      marginBottom: "5%",
                      width: tabletLayout ? "70%" : "50%",
                    }}
                  />
                  <Box variant="p" textAlign="center" fontWeight="bold">
                    {t("I am a Permitting Agent")}
                  </Box>
                </Box>
                {tabletLayout ? (
                  <Box variant="p" margin={{ top: "m", bottom: "m" }}>
                    {t(
                      "I work at the municipality, process permit applications or provide my expertise. I want to use the tool for managing the permit applications from Project Developers and engage my community on upcoming planned projects."
                    )}
                  </Box>
                ) : (
                  <div style={{ height: 100 }}>
                    <Box variant="p" margin={{ top: "m", bottom: "m" }}>
                      {t(
                        "I work at the municipality, process permit applications or provide my expertise. I want to use the tool for managing the permit applications from Project Developers and engage my community on upcoming planned projects."
                      )}
                    </Box>
                  </div>
                )}
                <Box margin="xxl" textAlign="center">
                  <Button variant="primary" href={"/signin/" + i18n.language}>
                    {t("Sign In")}
                  </Button>
                </Box>
                <Divider />
                <Box variant="p">
                  {t(
                    "Sign in to access the project management capabilities of the platform tailored to your role. Collaborate with project developers and your colleagues in the municipalities and prepare for engaging communities through consultations."
                  )}
                </Box>
              </Container>
              <Container fitHeight>
                <Box>
                  <img
                    alt="houses icon"
                    src={ProjectDeveloper}
                    style={{
                      display: "block",
                      marginLeft: "auto",
                      marginRight: "auto",
                      marginTop: "5%",
                      marginBottom: "5%",
                      width: tabletLayout ? "65%" : "45%",
                    }}
                  />
                  <Box variant="p" textAlign="center" fontWeight="bold">
                    {t("I am a Project Developer")}
                  </Box>
                </Box>
                {tabletLayout ? (
                  <Box variant="p" margin={{ top: "s", bottom: "s" }}>
                    {t(
                      "I work for a company that wants to develop wind projects. I want to use the tool to submit a request for wind projects and send the necessary documentation to the permitting agents and engage the community on project activities and benefits."
                    )}
                  </Box>
                ) : (
                  <div style={{ height: 100 }}>
                    <Box variant="p" margin={{ top: "m", bottom: "m" }}>
                      {t(
                        "I work for a company that wants to develop wind projects. I want to use the tool to submit a request for wind projects and send the necessary documentation to the permitting agents and engage the community on project activities and benefits."
                      )}
                    </Box>
                  </div>
                )}
                <Box margin={tabletLayout ? "xl" : "xxl"} textAlign="center">
                  <Button variant="primary" href={"/signin/" + i18n.language}>
                    {t("Sign In")}
                  </Button>
                </Box>
                <Divider />
                <Box variant="p">
                  {t(
                    "Sign in to access the project management capabilities of the platform tailored to your role. Collaborate with permitting agents and your colleagues and prepare for engaging communities through consultations."
                  )}
                </Box>
              </Container>
            </Grid>
          </ContentLayout>
        }
      />
    </>
  );
}
