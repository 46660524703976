import { useState, useEffect, useContext } from "react";
import { UserContext, TabletLayoutContext } from "../../AppContexts";
import { ProjectDetailsContext } from "./ProjectDashboard";
import { API, graphqlOperation } from "aws-amplify";
import { useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import "../../i18n";
import {
  primaryGreen,
  primaryBlue,
  warningOrange,
  Divider,
  MapCodeToLabel,
  AccountInfoPopover,
  denmarkMunicipalityList,
} from "../../shared";
import * as mutations from "../../graphql/mutations";
import {
  Container,
  Header,
  ColumnLayout,
  Link,
  Modal,
  FormField,
  Button,
  Textarea,
  Box,
  Select,
  Grid,
  Input,
  RadioGroup,
  StatusIndicator,
  SpaceBetween,
  Multiselect,
} from "@cloudscape-design/components";
import { Tooltip, IconButton } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import VerifiedIcon from "@mui/icons-material/Verified";
import DeleteIcon from "@mui/icons-material/Delete";
// AWS Geo Maps
import { MapView } from "@aws-amplify/ui-react-geo";
import "@aws-amplify/ui-react-geo/styles.css";
import { Marker, NavigationControl } from "react-map-gl";

export default function Summary(props) {
  const { id } = useParams(); // get project Id in URL parameter
  const { t } = useTranslation();
  const [comment, setComment] = useState("");
  const [fullDetailsModal, showFullDetailsModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const { userDetails } = useContext(UserContext);
  const { projectDetails } = useContext(ProjectDetailsContext);
  const [loadingCircle, showLoadingCircle] = useState(false);
  const [loadingCircleComments, showLoadingCircleComments] = useState(false); // for comments only

  // Form fields
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [households, setHouseholds] = useState(0);
  const [latitude, setLatitude] = useState(0);
  const [longitude, setLongitude] = useState(0);
  const [size, setSize] = useState(0);
  const [capacity, setCapacity] = useState(0);
  const [turbinesNum, setTurbinesNum] = useState(0);
  const [height, setHeight] = useState(0);
  const [diameter, setDiameter] = useState(0);
  const [preAgreement, setPreAgreement] = useState(true);
  const [relationNeighbours, setRelationNeighbors] = useState("");
  const [additionalInfo, setAdditionalInfo] = useState("");
  const [benefits, setBenefits] = useState("");
  const [municipality, setMunicipality] = useState([]);
  const [type, setType] = useState({});
  const [organisation, setOrganisation] = useState("");

  const denmark_coordinates = {
    min_lat: 54.85,
    max_lat: 57.84,
    min_lon: 8,
    max_lon: 12.6,
  };

  const updateProject = async () => {
    const formInputs = {
      id: id,
      name: name,
      description: description,
      projectType: type.value,
      latitude: latitude,
      longitude: longitude,
      size: size,
      capacity: capacity,
      turbines: turbinesNum,
      height: height,
      diameter: diameter,
      provides_households: households,
      municipalities: municipality.map((item) => item.value),
      adminGroups: municipality.map((item) => item.value),
      preAgreementLandowners: preAgreement,
      relationNeighbours: relationNeighbours,
      additionalInfo: additionalInfo,
      benefits,
      mainDeveloperId: userDetails.id,
      summaryEditable: false,
      notificationMessage: "project_summary_editable_false_verified_false",
      contact_organization: organisation,
    };
    await API.graphql(
      graphqlOperation(mutations.updateProject, {
        input: formInputs,
      })
    )
      .then((res) => {})
      .catch((error) => console.error(error.message));
  };

  const updateSummaryStatus = async (summaryEditable, summaryVerified) => {
    const formInputs = {
      id: id,
      summaryEditable,
      summaryVerified,
      notificationMessage: `project_summary_editable_${summaryEditable}_verified_${summaryVerified}`,
    };
    await API.graphql(
      graphqlOperation(mutations.updateProject, {
        input: formInputs,
      })
    )
      .then((res) => {})
      .catch((error) => console.error(error.message));
  };

  const submitComment = async () => {
    API.graphql(
      graphqlOperation(mutations.updateProject, {
        input: {
          id: projectDetails?.id,
          nested_update_at: new Date().toISOString(),
          notificationMessage: "project_comment",
        },
      })
    );
    await API.graphql(
      graphqlOperation(mutations.createProjectComment, {
        input: {
          comment: comment,
          projectId: projectDetails?.id,
          personaId: userDetails.id,
          owner: userDetails.id,
        },
      })
    )
      .then(async (res) => {
        showLoadingCircle(true);
        showLoadingCircleComments(true);
        await props.getProjectDetails();
        showLoadingCircle(false);
      })
      .catch((error) => console.error(error));
  };

  const [deleteCommentModal, showDeleteCommentModal] = useState(false);
  const [commentToDelete, setCommentToDelete] = useState(null);

  const deleteComment = async (comment) => {
    await API.graphql(
      graphqlOperation(mutations.deleteProjectComment, {
        input: {
          id: comment.id,
        },
      })
    )
      .then(async (res) => {
        showLoadingCircle(true);
        await props.getProjectDetails();
        showLoadingCircle(false);
      })
      .catch((error) => console.error(error));
  };

  const validateValue = (value, name) => {
    if (value) {
      // Validate coordinate values based on municipality
      if (name === "latitude") {
        if (
          value <
          (municipality?.length > 0
            ? Math.min(
                ...municipality?.map(
                  (mu) =>
                    denmarkMunicipalityList.find((m) => m.value === mu.value)
                      ?.min_lat
                )
              )
            : denmark_coordinates.min_lat)
        )
          return "Value is too small";
        if (
          value >
          (municipality?.length > 0
            ? Math.max(
                ...municipality?.map(
                  (mu) =>
                    denmarkMunicipalityList.find((m) => m.value === mu.value)
                      ?.max_lat
                )
              )
            : denmark_coordinates.max_lat)
        )
          return "Value is too large";
      } else {
        if (
          value <
          (municipality?.length > 0
            ? Math.min(
                ...municipality?.map(
                  (mu) =>
                    denmarkMunicipalityList.find((m) => m.value === mu.value)
                      ?.min_lon
                )
              )
            : denmark_coordinates.min_lon)
        )
          return "Value is too small";
        if (
          value >
          (municipality?.length > 0
            ? Math.max(
                ...municipality?.map(
                  (mu) =>
                    denmarkMunicipalityList.find((m) => m.value === mu.value)
                      ?.max_lon
                )
              )
            : denmark_coordinates.max_lon)
        )
          return "Value is too large";
      }
    } else return;
  };

  const validateBenefitsInput = (string) => {
    // check if message contains a url
    if (
      new RegExp(
        "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?"
      ).test(string)
    ) {
      // ensure URL starts with HTTP/HTTPS (https://stackoverflow.com/questions/3809401/what-is-a-good-regular-expression-to-match-a-url)
      const urlRegExp =
        /https?:\/\/?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)/g;
      if (string)
        if (!string.match(urlRegExp)) return "Link must start with http(s)://";
        else return;
    }
  };

  const initializePropsValues = () => {
    setName(projectDetails?.name);
    setBenefits(projectDetails?.benefits);
    setDescription(projectDetails?.description);
    setHouseholds(projectDetails?.provides_households);
    setLatitude(projectDetails?.latitude);
    setLongitude(projectDetails?.longitude);
    setSize(projectDetails?.size);
    setCapacity(projectDetails?.capacity);
    setTurbinesNum(projectDetails?.turbines);
    setHeight(projectDetails?.height);
    setDiameter(projectDetails?.diameter);
    setPreAgreement(projectDetails?.preAgreementLandowners);
    setRelationNeighbors(projectDetails?.relationNeighbours);
    setAdditionalInfo(projectDetails?.additionalInfo);
    setMunicipality(
      projectDetails?.municipalities?.map((item) => ({
        value: item,
        label: MapCodeToLabel(item),
      }))
    );
    setType({
      value: projectDetails?.projectType,
      label: projectDetails?.projectType === "wind" ? "Wind Farm" : "Solar",
    });
    setOrganisation(projectDetails?.contact_organization);
  };

  const ProjectCommentsCSVHeaders = [
    { label: t("Comment"), key: "comment" },
    { label: t("Date"), key: "date" },
    { label: t("Author"), key: "by" },
  ];

  useEffect(() => {
    initializePropsValues();
  }, [projectDetails]);

  return (
    <Container
      fitHeight
      header={
        <SpaceBetween direction="horizontal" size="xs">
          <Header variant="h2">{t("Project Summary")}</Header>
          {projectDetails?.summaryVerified ? (
            <div style={{ marginTop: 5, marginLeft: 3 }}>
              <Tooltip title="Summary verified">
                <VerifiedIcon style={{ color: primaryGreen }} />
              </Tooltip>
            </div>
          ) : projectDetails?.summaryEditable ? (
            <h3 style={{ marginTop: 7, color: primaryBlue }}>
              {t("In Revision")}
            </h3>
          ) : (
            <h3 style={{ marginTop: 7, color: warningOrange }}>
              {t("To Be Reviewed")}
            </h3>
          )}
        </SpaceBetween>
      }
      footer={
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <Link
            onFollow={() => {
              showFullDetailsModal(true);
            }}
          >
            {t("View all details")}
          </Link>
        </div>
      }
    >
      <div style={{ paddingTop: 20 }}>
        <ProjectSummary projectDetails={projectDetails} t={t} />
        {userDetails.id === projectDetails?.mainAgent &&
          projectDetails?.summaryEditable && (
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={async () => {
                  await updateSummaryStatus(true, false);
                }}
              >
                {t("Send Back to Edit")}
              </Button>
            </SpaceBetween>
          )}
      </div>

      {/* -------------- Full project details popup -------------- */}
      <Modal
        size="max"
        closeAriaLabel="Close modal"
        header={editMode ? t("Edit Project") : t("Project Details")}
        onDismiss={() => showFullDetailsModal(false)}
        visible={fullDetailsModal}
        footer={
          <>
            {userDetails.id === projectDetails?.mainAgent?.id && (
              <Box float="right">
                <SpaceBetween direction="horizontal" size="s">
                  {!projectDetails?.summaryEditable && (
                    <Button
                      float="right"
                      onClick={async () => {
                        showLoadingCircle(true);
                        await updateSummaryStatus(true, false);
                        await props.getProjectDetails();
                        showLoadingCircle(false);
                      }}
                    >
                      {loadingCircle
                        ? t("Please wait") + "..."
                        : t("Send Back to Edit")}
                    </Button>
                  )}
                  {!projectDetails?.summaryEditable &&
                    !projectDetails?.summaryVerified && (
                      <Button
                        float="right"
                        variant="primary"
                        disabled={loadingCircle}
                        onClick={async () => {
                          showLoadingCircle(true);
                          await updateSummaryStatus(false, true);
                          await props.getProjectDetails();
                          showLoadingCircle(false);
                        }}
                      >
                        {loadingCircle ? t("Please wait") + "..." : t("Verify")}
                      </Button>
                    )}
                </SpaceBetween>
              </Box>
            )}
            <Button
              float="right"
              variant="primary"
              disabled={loadingCircle}
              onClick={async () => {
                showLoadingCircle(true);
                await updateSummaryStatus(false, true);
                await props.getProjectDetails();
                showLoadingCircle(false);
              }}
            >
              <CSVLink
                separator={";"}
                style={{ textDecoration: "none", color: "white" }}
                filename={`${projectDetails?.name}-${t(
                  "Comments"
                )}-${new Date().toLocaleDateString()}.csv`}
                data={
                  projectDetails?.comments?.items?.map((c) => ({
                    comment: c.comment,
                    date: c.createdAt,
                    by: `${c.persona.family_name}, ${c.persona.given_name} <${c.persona.email}>`,
                  })) || ""
                }
                headers={ProjectCommentsCSVHeaders}
              >
                {t("Download Comments")}
              </CSVLink>
            </Button>
            {userDetails.role === "developers" &&
              projectDetails?.summaryEditable && (
                <>
                  {
                    // Show loading circle when user clicks Save Changes
                    loadingCircle && (
                      <Box float="left">
                        <CircularProgress size={20} />
                        <span style={{ paddingLeft: 10 }}>
                          <b>{t("Saving changes")}...</b>
                        </span>
                      </Box>
                    )
                  }
                  <Box float="right">
                    {editMode ? (
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button
                          variant="link"
                          onClick={() => {
                            setEditMode(false);
                          }}
                        >
                          {t("Cancel")}
                        </Button>
                        <Button
                          variant="primary"
                          disabled={
                            loadingCircle ||
                            validateValue(latitude, "latitude") ||
                            validateValue(longitude, "longitude")
                          }
                          onClick={async () => {
                            showLoadingCircle(true);
                            await updateProject();
                            await props.getProjectDetails();
                            showLoadingCircle(false);
                            setEditMode(false);
                          }}
                        >
                          {loadingCircle
                            ? t("Please wait") + "..."
                            : t("Save Changes")}
                        </Button>
                      </SpaceBetween>
                    ) : (
                      <Button
                        variant="primary"
                        onClick={() => setEditMode(true)}
                      >
                        {t("Edit Project")}
                      </Button>
                    )}
                  </Box>
                </>
              )}
          </>
        }
      >
        {
          // Edit Mode
          editMode ? (
            <SpaceBetween direction="vertical" size="xl">
              <div style={{ height: 10 }} />
              <ColumnLayout columns={3}>
                <div>
                  <FormField
                    stretch
                    label={t("Organisation")}
                    description={t("Name of the Organisation")}
                  >
                    <Input
                      placeholder="Organisation"
                      value={organisation}
                      onChange={(event) => setOrganisation(event.detail.value)}
                    />
                  </FormField>
                </div>
                <div>
                  <FormField
                    label={t("Project name")}
                    description={t("Name of the project")}
                  >
                    <Input
                      type="text"
                      value={name}
                      onChange={(event) => setName(event.detail.value)}
                    />
                  </FormField>
                </div>
                <div>
                  <FormField
                    label={t("Project type")}
                    description={t("Specify the type of the development")}
                  >
                    <Select
                      selectedAriaLabel="Project type selected"
                      selectedOption={type}
                      onChange={({ detail }) => setType(detail.selectedOption)}
                      options={[
                        {
                          value: "wind",
                          label: "Wind Farm",
                        },
                        {
                          value: "solar",
                          label: "Solar",
                        },
                      ]}
                    />
                  </FormField>
                </div>
              </ColumnLayout>

              <FormField
                stretch
                label={t("Project Description")}
                description={t(
                  "Detailed description of the proposed wind farm, basic information, company description, etc."
                )}
              >
                <Textarea
                  rows={2}
                  ariaLabel="project-overview"
                  placeholder="Enter description here..."
                  value={description}
                  onChange={({ detail }) => {
                    setDescription(detail.value);
                  }}
                />
              </FormField>
              <ColumnLayout columns={3}>
                <div>
                  <FormField
                    label={t("Municipality")}
                    description="Muncipality of the project"
                  >
                    <Multiselect
                      selectedAriaLabel="Project municipality selected"
                      selectedOptions={municipality}
                      onChange={({ detail }) =>
                        setMunicipality(detail.selectedOptions)
                      }
                      options={denmarkMunicipalityList}
                    />
                  </FormField>
                </div>
                <div>
                  <FormField
                    label={t("Location - Latitude")}
                    description={t(
                      "Latitude location of the project on the map"
                    )}
                    constraintText={`${t("Value must be between")} ${
                      municipality?.length > 0
                        ? Math.min(
                            ...municipality?.map(
                              (mu) =>
                                denmarkMunicipalityList.find(
                                  (m) => m.value === mu.value
                                )?.min_lat
                            )
                          )
                        : denmark_coordinates.min_lat
                    } ${t("and")} ${
                      municipality?.length > 0
                        ? Math.max(
                            ...municipality?.map(
                              (mu) =>
                                denmarkMunicipalityList.find(
                                  (m) => m.value === mu.value
                                )?.max_lat
                            )
                          )
                        : denmark_coordinates.max_lat
                    }`}
                    errorText={validateValue(latitude, "latitude")}
                  >
                    <Input
                      type="number"
                      value={latitude}
                      onChange={(event) => setLatitude(event.detail.value)}
                    />
                  </FormField>
                </div>
                <div>
                  <FormField
                    label={t("Location - Longitude")}
                    description={t(
                      "Longitude location of the project on the map"
                    )}
                    constraintText={`${t("Value must be between")} ${
                      municipality?.length > 0
                        ? Math.min(
                            ...municipality?.map(
                              (mu) =>
                                denmarkMunicipalityList.find(
                                  (m) => m.value === mu.value
                                )?.min_lon
                            )
                          )
                        : denmark_coordinates.min_lon
                    } ${t("and")} ${
                      municipality?.length > 0
                        ? Math.max(
                            ...municipality?.map(
                              (mu) =>
                                denmarkMunicipalityList.find(
                                  (m) => m.value === mu.value
                                )?.max_lon
                            )
                          )
                        : denmark_coordinates.max_lon
                    }`}
                    errorText={validateValue(longitude, "longitude")}
                  >
                    <Input
                      type="number"
                      value={longitude}
                      onChange={(event) => setLongitude(event.detail.value)}
                    />
                  </FormField>
                </div>
              </ColumnLayout>
              <ColumnLayout columns={3}>
                <div>
                  <FormField
                    label={t("Number of Turbines")}
                    description={t("Total number of turbines")}
                  >
                    <Input
                      value={turbinesNum}
                      onChange={(event) => setTurbinesNum(event.detail.value)}
                    />
                  </FormField>
                </div>
                <div>
                  <FormField
                    label={t("Expected Tip Height")}
                    description={t("Height of the wind turbines in meters")}
                  >
                    <Input
                      value={height}
                      onChange={(event) => setHeight(event.detail.value)}
                    />
                  </FormField>
                </div>
                <div>
                  <FormField
                    label={t("Expected rotor diameter")}
                    description={t("Rotor diameter of the wind turbines")}
                  >
                    <Input
                      value={diameter}
                      onChange={(event) => setDiameter(event.detail.value)}
                    />
                  </FormField>
                </div>
              </ColumnLayout>

              <ColumnLayout columns={3}>
                <div>
                  <FormField
                    label={t("Overall capacity")}
                    description={t("Expected overall capacity")}
                  >
                    <Input
                      value={capacity}
                      onChange={(event) => setCapacity(event.detail.value)}
                    />
                  </FormField>
                </div>
                <div>
                  <FormField
                    label={t("Households")}
                    description={t(
                      "Potential number of households it supplies energy to"
                    )}
                  >
                    <Input
                      placeholder="0"
                      type="number"
                      value={households}
                      onChange={(event) => setHouseholds(event.detail.value)}
                    />
                  </FormField>
                </div>
                <div>
                  <FormField
                    label={t("Size of project")}
                    description={t("hectares")}
                  >
                    <Input
                      type="number"
                      value={size}
                      onChange={(event) => setSize(event.detail.value)}
                    />
                  </FormField>
                </div>
              </ColumnLayout>

              <Divider />
              <ColumnLayout columns={2}>
                <div>
                  <FormField
                    label={t("Is there a pre-agreement with owners?")}
                    description={t("Do you have a written document?")}
                  >
                    <RadioGroup
                      onChange={({ detail }) => setPreAgreement(detail.value)}
                      value={preAgreement}
                      items={[
                        { value: true, label: t("Yes") },
                        { value: false, label: t("No") },
                      ]}
                    />
                  </FormField>
                </div>
                <div>
                  <FormField
                    label={
                      <span>
                        {t("Relation to neighbors")} <i>- {t("optional")}</i>
                      </span>
                    }
                    description=""
                  >
                    <Input
                      value={relationNeighbours}
                      onChange={(event) =>
                        setRelationNeighbors(event.detail.value)
                      }
                    />
                  </FormField>
                </div>
              </ColumnLayout>
              <FormField
                stretch
                label={
                  <span>
                    {t("Benefits for the Community")} <i>({t("optional")})</i>
                  </span>
                }
                description={t(
                  "Provide information regarding community benefits. You can also add a link to your project website. Link must start with http(s)://"
                )}
                constraintText={t(
                  "Recommendation to also add the community testimonial video on your project website."
                )}
                errorText={validateBenefitsInput(benefits)}
              >
                <Textarea
                  rows={2}
                  ariaLabel="project-benefits"
                  placeholder={t("Enter information here...")}
                  onChange={({ detail }) => setBenefits(detail.value)}
                  value={benefits}
                />
              </FormField>
              <FormField
                stretch
                label={t("Additional information")}
                description={t(
                  "Please provide any additional technical information relevant to the project eg. design, configuration, known issues, nature, etc."
                )}
              >
                <Textarea
                  rows={2}
                  ariaLabel="project-overview"
                  placeholder={t("Enter information here...")}
                  onChange={({ detail }) => setAdditionalInfo(detail.value)}
                  value={additionalInfo}
                />
              </FormField>
            </SpaceBetween>
          ) : (
            // View Mode: show project details and comments section
            <div style={{ marginTop: 30 }}>
              <ProjectFullDetails projectDetails={projectDetails} t={t} />
              <br />
              <Divider />
              <h3>{t("Comments")}</h3>
              <div style={{ height: 20 }} />
              {!projectDetails?.comments?.items.length ? (
                <p style={{ color: "gray" }}>{t("No comments available")}</p>
              ) : (
                projectDetails?.comments?.items.map((comment) => (
                  <div
                    key={comment.id}
                    style={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div style={{ float: "left" }}>
                      <div style={{ display: "flex" }}>
                        <AccountCircleIcon style={{ marginRight: 5 }} />
                        <b>
                          {comment.persona?.given_name +
                            " " +
                            comment.persona?.family_name}
                        </b>
                        <i style={{ paddingLeft: 10, color: "gray" }}>
                          on {new Date(comment.createdAt).toString()}
                        </i>
                      </div>
                      <p>{comment.comment}</p>
                      <div style={{ height: 30 }} />
                    </div>
                    <div style={{ float: "right" }}>
                      {comment?.personaId === userDetails?.id && (
                        <IconButton
                          aria-label={t("Delete comment")}
                          size="small"
                          onClick={() => {
                            setCommentToDelete(comment);
                            showDeleteCommentModal(true);
                          }}
                        >
                          <DeleteIcon
                            sx={{
                              fontSize: 20,
                              color: "gray",
                            }}
                          />
                        </IconButton>
                      )}
                    </div>
                  </div>
                ))
              )}
              <br />
              <FormField stretch label={t("Add a comment")}>
                <Textarea
                  rows={2}
                  ariaLabel="project comments"
                  placeholder={t("Enter comments here...")}
                  onChange={({ detail }) => setComment(detail.value)}
                  value={comment}
                />
              </FormField>
              <br />
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <SpaceBetween direction="horizontal" size="s">
                  {loadingCircleComments && (
                    <StatusIndicator type="loading">
                      {t("Saving changes")}
                    </StatusIndicator>
                  )}
                  <Button
                    variant="primary"
                    disabled={!comment || loadingCircle}
                    onClick={async () => {
                      await submitComment();
                      setComment("");
                      showLoadingCircleComments(false);
                    }}
                  >
                    {t("Add comment")}
                  </Button>
                </SpaceBetween>
              </div>
            </div>
          )
        }
      </Modal>

      {/* ------ Delete comment confirmation popup ------ */}
      <Modal
        onDismiss={() => showDeleteCommentModal(false)}
        visible={deleteCommentModal}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button variant="link">{t("Cancel")}</Button>
              <Button
                variant="primary"
                onClick={(e) => {
                  e.preventDefault();
                  deleteComment(commentToDelete);
                  showDeleteCommentModal(false);
                }}
              >
                {t("Delete comment")}
              </Button>
            </SpaceBetween>
          </Box>
        }
        header={t("Confirm action")}
      >
        {t("Are you sure you want to delete?")}
      </Modal>
    </Container>
  );
}

const ProjectSummary = ({ projectDetails, t }) => {
  return (
    <>
      <SpaceBetween direction="horizontal" size="xxl">
        <div>
          <b>{t("Organisation")}</b>
          <p>{projectDetails?.contact_organization}</p>
        </div>
        <div>
          <b>{t("Project Overview")}</b>
          <p>{projectDetails?.description}</p>
        </div>
      </SpaceBetween>
      <br />
      <ColumnLayout columns={4}>
        <div>
          <b>{t("Permit Process Start Date")}</b>
          <br />
          {projectDetails?.startDate || (
            <i style={{ color: "gray" }}>{t("Date not set yet")}</i>
          )}
          <br />
          <br />
          <b>{t("Overall Capacity")}</b>
          <br />
          {projectDetails?.capacity} MW
        </div>
        <div>
          <b>{t("Permit Process End Date")}</b>
          <br />
          {projectDetails?.endDate || (
            <i style={{ color: "gray" }}>{t("Date not set yet")}</i>
          )}
          <br />
          <br />
          <b>{t("Number of Turbines")}</b>
          <br />
          {projectDetails?.turbines}
        </div>
        <div>
          <b>{t("Permitting Agent")}</b>
          <br />
          {projectDetails?.mainAgent ? (
            <AccountInfoPopover
              project={projectDetails}
              css={{
                display: "flex",
                cursor: "pointer",
                paddingRight: 7,
              }}
            />
          ) : (
            <i style={{ color: "gray" }}>{t("Not yet assigned")}</i>
          )}
          <br />
          <br />
          <b>{t("Expected Height")}</b>
          <br />
          {projectDetails?.height} meters
        </div>
        <div>
          <b>{t("Project Developer")}</b>
          <br />
          {projectDetails?.mainDeveloper ? (
            <AccountInfoPopover
              project={projectDetails}
              css={{
                display: "flex",
                cursor: "pointer",
                paddingRight: 7,
              }}
            />
          ) : (
            <i style={{ color: "gray" }}>{t("Not yet assigned")}</i>
          )}
          <br />
          <br />
          <b>{t("Expected Rotor Diameter")}</b>
          <br />
          {projectDetails?.diameter} meters
        </div>
      </ColumnLayout>
    </>
  );
};

const ProjectFullDetails = ({ projectDetails, t }) => {
  const { tabletLayout } = useContext(TabletLayoutContext);
  const validateBenefitsInput = (string) => {
    // check if message contains a url
    if (
      new RegExp(
        "([a-zA-Z0-9]+://)?([a-zA-Z0-9_]+:[a-zA-Z0-9_]+@)?([a-zA-Z0-9.-]+\\.[A-Za-z]{2,4})(:[0-9]+)?(/.*)?"
      ).test(string)
    ) {
      const urlRegExp =
        /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/g;
      const url = string.match(urlRegExp); // extract the url from the message
      const text = string.replace(urlRegExp, ""); // remove the url text so we can display the url as Link instead

      return (
        <>
          {text}
          <Link
            href={url[0]}
            target="_blank"
            rel="noreferrer"
            sx={{
              marginLeft: 1,
              textDecoration: "underline",
              color: "white",
            }}
          >
            {url}
          </Link>
        </>
      );
    } else return string;
  };

  useEffect(() => {}, [projectDetails]);

  return (
    <>
      <ColumnLayout columns={4}>
        <div>
          <b>{t("Permit Process Start Date")}</b>
          <br />
          {projectDetails?.startDate || (
            <i style={{ color: "gray" }}>{t("Date not set yet")}</i>
          )}
        </div>
        <div>
          <b>{t("Permit Process End Date")}</b>
          <br />
          {projectDetails?.endDate || (
            <i style={{ color: "gray" }}>{t("Date not set yet")}</i>
          )}
        </div>
        <div>
          <b>{t("Permitting Agent")}</b>
          <br />

          {projectDetails?.mainAgent ? (
            <AccountInfoPopover
              project={projectDetails}
              css={{
                display: "flex",
                cursor: "pointer",
                paddingRight: 7,
              }}
            />
          ) : (
            <i style={{ color: "gray" }}>{t("Not yet assigned")}</i>
          )}
        </div>
        <div>
          <b>{t("Project Developer")}</b>
          <br />
          {projectDetails?.mainDeveloper ? (
            <AccountInfoPopover
              project={projectDetails}
              css={{
                display: "flex",
                cursor: "pointer",
                paddingRight: 7,
              }}
            />
          ) : (
            "-"
          )}
        </div>
      </ColumnLayout>
      <Divider />
      <b>{t("Project Overview")}</b>
      <p>{projectDetails?.description}</p>
      {
        // Tablet view
        tabletLayout ? (
          <>
            <Grid
              gridDefinition={[
                { colspan: 3 },
                { colspan: 3 },
                { colspan: 3 },
                { colspan: 3 },
              ]}
            >
              <div>
                <b>{t("Project Name")}</b>
                <br />
                {projectDetails?.name || "-"}
                <br />
                <br />
                <b>{t("Project Type")}</b>
                <br />
                {projectDetails?.projectType === "wind"
                  ? "Wind Farm"
                  : "Solar" || "-"}
                <br />
                <br />
                <b>{t("Overall Capacity")}</b>
                <br />
                {projectDetails?.capacity} MW
                <br />
                <br />
                <b>{t("Number of Turbines")}</b>
                <br />
                {projectDetails?.turbines}
              </div>
              <div>
                <b>{t("Location - Latitude")}</b>
                <br />
                {projectDetails?.latitude}
                <br />
                <br />
                <b>{t("Location - Longitude")}</b>
                <br />
                {projectDetails?.longitude}
                <br />
                <br />
                <b>{t("Municipality")}</b>
                <br />
                {projectDetails?.municipalities?.map((item, index) => (
                  <div key={item}>
                    {MapCodeToLabel(item)}
                    {
                      // add in a separation comma if there's more than 2 municipalities
                      projectDetails?.municipalities?.length >= 2 && ", "
                    }
                  </div>
                )) || "-"}
                <br />
                <b>{t("Size of project")}</b>
                <br />
                {projectDetails?.size} {t("hectares")}
              </div>
              <div>
                <b>{t("Maximum tip height of the wind turbines")}</b>
                <br />
                {projectDetails?.height} {t("meters")}
                <br />
                <br />
                <b>{t("Pre-agreement with Owners")}</b>
                <br />
                {projectDetails?.preAgreementLandowners?.toString() === "true"
                  ? "Yes"
                  : "No"}
                <br />
                <br />
                <b>{t("Relation to Neighbors")}</b>
                <br />
                {projectDetails?.relationNeighbours || "None"}
                <br />
                <br />
                <b>{t("Expected Rotor Diameter")}</b>
                <br />
                {projectDetails?.diameter} {t("meters")}
              </div>
              <div>
                <div>
                  <b>
                    {t("Potential number of households it supplies energy to")}
                  </b>
                  <p>{projectDetails?.provides_households || "None"}</p>
                </div>
                <div>
                  <b>{t("Benefits for the Community")}</b>
                  <p>
                    {validateBenefitsInput(projectDetails?.benefits) || "None"}
                  </p>
                </div>
                <div>
                  <b>{t("Additional technical details")}</b>
                  <p>{projectDetails?.additionalInfo || "None"}</p>
                </div>
              </div>
            </Grid>
            <Box margin={{ top: "l" }}>
              {projectDetails?.latitude && projectDetails?.longitude && (
                <MapView
                  style={{ height: 300, width: "fit" }}
                  initialViewState={{
                    latitude: projectDetails?.latitude,
                    longitude: projectDetails?.longitude,
                    zoom: 5,
                  }}
                >
                  <NavigationControl
                    position="top-right"
                    showZoom
                    visualizePitch
                  />
                  <Marker
                    color="red"
                    latitude={projectDetails?.latitude}
                    longitude={projectDetails?.longitude}
                  />
                </MapView>
              )}
            </Box>
          </>
        ) : (
          // Desktop view
          <Grid gridDefinition={[{ colspan: 9 }, { colspan: 3 }]}>
            {/* ---------------- Left hand side info ---------------- */}
            <div>
              <Grid
                gridDefinition={[
                  { colspan: 2 },
                  { colspan: 2 },
                  { colspan: 3 },
                  { colspan: 4 },
                ]}
              >
                <div>
                  <b>{t("Project Name")}</b>
                  <br />
                  {projectDetails?.name || "-"}
                  <br />
                  <br />
                  <b>{t("Project Type")}</b>
                  <br />
                  {projectDetails?.projectType === "wind"
                    ? "Wind Farm"
                    : "Solar" || "-"}
                  <br />
                  <br />
                  <b>{t("Overall Capacity")}</b>
                  <br />
                  {projectDetails?.capacity} MW
                  <br />
                  <br />
                  <b>{t("Number of Turbines")}</b>
                  <br />
                  {projectDetails?.turbines}
                </div>
                <div>
                  <b>{t("Location - Latitude")}</b>
                  <br />
                  {projectDetails?.latitude}
                  <br />
                  <br />
                  <b>{t("Location - Longitude")}</b>
                  <br />
                  {projectDetails?.longitude}
                  <br />
                  <br />
                  <b>{t("Municipality")}</b>
                  <br />
                  {projectDetails?.municipalities?.map((item, index) => (
                    <div key={item}>
                      {MapCodeToLabel(item)}
                      {
                        // add in a separation comma if there's more than 2 municipalities
                        projectDetails?.municipalities?.length >= 2 && ", "
                      }
                    </div>
                  )) || "-"}
                  <br />
                  <b>{t("Size of project")}</b>
                  <br />
                  {projectDetails?.size} {t("hectares")}
                </div>
                <div>
                  <b>{t("Maximum tip height of the wind turbines")}</b>
                  <br />
                  {projectDetails?.height} {t("meters")}
                  <br />
                  <br />
                  <b>{t("Pre-agreement with Owners")}</b>
                  <br />
                  {projectDetails?.preAgreementLandowners?.toString() === "true"
                    ? "Yes"
                    : "No"}
                  <br />
                  <br />
                  <b>{t("Relation to Neighbors")}</b>
                  <br />
                  {projectDetails?.relationNeighbours || "None"}
                  <br />
                  <br />
                  <b>{t("Expected Rotor Diameter")}</b>
                  <br />
                  {projectDetails?.diameter} {t("meters")}
                </div>
                <div>
                  <div>
                    <b>
                      {t(
                        "Potential number of households it supplies energy to"
                      )}
                    </b>
                    <p>{projectDetails?.provides_households || "None"}</p>
                  </div>
                  <div>
                    <b>{t("Benefits for the Community")}</b>
                    <p>
                      {validateBenefitsInput(projectDetails?.benefits) ||
                        "None"}
                    </p>
                  </div>
                  <div>
                    <b>{t("Additional technical details")}</b>
                    <p>{projectDetails?.additionalInfo || "None"}</p>
                  </div>
                </div>
              </Grid>
            </div>

            {/* ---------------- Right hand side Map ---------------- */}
            <div>
              {projectDetails?.latitude && projectDetails?.longitude && (
                <MapView
                  style={{ height: 350, width: "fit" }}
                  initialViewState={{
                    latitude: projectDetails?.latitude,
                    longitude: projectDetails?.longitude,
                    zoom: 5,
                  }}
                  // fit the map to the full width on render without having to resize browser window
                  // (https://stackoverflow.com/questions/71006426/react-map-gl-map-is-not-fullscreen-until-resizing-window)
                  // onRender={(e) => e.target.resize()}
                  // onViewportChange={(viewport) => setViewState(viewport)}
                >
                  <NavigationControl
                    position="top-right"
                    showZoom
                    visualizePitch
                  />
                  <Marker
                    color="red"
                    latitude={projectDetails?.latitude}
                    longitude={projectDetails?.longitude}
                  />
                </MapView>
              )}
            </div>
          </Grid>
        )
      }
    </>
  );
};
