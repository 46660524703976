import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

// Internationalisation (i18n)
// https://locize.com/blog/react-i18next/

export default i18n
  // detect user language
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: false,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      /* ------------------------ ENGLISH ------------------------ */
      en: {
        translation: {
          // types:
          wind: "Windfarm",
          solar: "Solar",
          // stages:
          a_preplanning: "Pre-Planning",
          b_initial_consultation: "Initial Public Consultation",
          c_planning: "Planning Process",
          d_final_consultation: "Public Consultation",
          e_approval: "Final Decision",
          // deadlines:
          documents_handover: "Documents Review",
          public_consultation: "Public Consultation",
          city_council: "City Council Meeting",
          // roles
          agents: "Permitting Agent",
          developers: "Project Developer",
          a_preplanning_closed: "Pre-Planning Stage is Closed",
          b_initial_consultation_closed:
            "Initial Public Consultation is Closed",
          c_planning_closed: "Planning Process is Closed",
          d_final_consultation_closed: "Public Consultation Stage is Closed",
          e_approval_closed: "Final Decision is Closed",
          task_done: "New Task Completion",
          b_review: "New Document Uploaded",
          c_revision: "Document Needs Revision",
          d_done: "New Document Completion",
          document_comment: "New Document Comment",
          project_comment: "New Project Comment",
          project_summary_editable_false_verified_true:
            "Project Summary is Verified",
          project_summary_editable_true_verified_false:
            "Project Summary Needs Revision",
          project_summary_editable_false_verified_false:
            "Project Summary Needs Review",
          // Custom Doc Names:
          "Invitation and Project Presentation for Public Consultation":
            "Invitation Public Consultation",
        },
      },
      /* ------------------------ POLISH ------------------------ */
      pl: {
        translation: {
          Denmark: "Dania",
          Poland: "Polska",
          at: "na",
          // deadlines:
          Deadline: "Termin",
          documents_handover: "Przegląd dokumentów",
          public_consultation: "Konsultacje społeczne",
          city_council: "Posiedzenie Rady Miejskiej",
          "Export Deadlines": "Eksportuj kamienie milowe",
          "Project Deadlines": "Terminy projektu",
          // community:
          "About us": "O nas",
          "Welcome To": "Witamy na",
          "Welcome to EasyPermits": "Witamy w EasyPermits",
          "To get your questions answered about the project":
            "Aby uzyskać odpowiedzi na pytania dotyczące projektu",
          "Welcome!": "Witaj!",
          "welcome!": "witaj!",
          "I am a Community Member": "Jestem członkiem społeczności",
          "I am a Permitting Agent": "Jesftem agentem ds. zezwoleń",
          "I am a Project Developer": "Jestem deweloperem projektu",
          "Are you a Permitting Agent? \n Sign-in to use full features of our platform":
            "Jesteś agentem ds. zezwoleń? \n Zaloguj się, aby korzystać z pełnych funkcji naszej platformy",
          "Are you a Developer? \n Sign-in to use full features of our platform":
            "Jesteś deweloperem? \n Zaloguj się, aby korzystać z pełnych funkcji naszej platformy",
          "Explore our interactive map to discover wind farms in your area and beyond. Click on markers to find details about existing and proposed projects. Your engagement matters, and here, you can stay informed about the wind energy initiatives shaping your community.":
            "Odkryj interaktywną mapę, aby dowiedzieć się o istniejących i planowanych farmach wiatrowych w Twojej okolicy i poza nią. Kliknij na znaczniki, aby znaleźć szczegóły dotyczące projektów istniejących i planowanych. Twoje zaangażowanie ma znaczenie, a tutaj możesz być na bieżąco z inicjatywami dotyczącymi energii wiatrowej, które kształtują Twoją społeczność.",
          "If you're a permitting agent or wind farm developer, log in to access powerful tools and resources tailored to your role. Streamline the permitting process, collaborate with stakeholders, and contribute to sustainable wind energy solutions.":
            "Jeśli jesteś agentem ds. zezwoleń lub deweloperem farm wiatrowych, zaloguj się, aby uzyskać dostęp do potężnych narzędzi i zasobów dostosowanych do Twojej roli. Ułatw proces uzyskiwania zezwoleń, współpracuj z interesariuszami i przyczyniaj się do zrównoważonych rozwiązań w dziedzinie energii wiatrowej.",
          "Search Wind Projects": "Szukaj projektów wiatrowych",
          "Search Wind Projects on the Map":
            "Szukaj projektów wiatrowych na mapie",
          "EasyPermits is your digital one-stop shop for the onshore wind projects permitting process.\n\nWith EasyPermits we are focused on streamlining the permitting process by improving information management, increasing transparency of the onshore wind farm permitting process and increasing collaboration between project developers, permitting agents as well as the community members.\n\nEasyPermits is a web-based tool developed through a collaboration between WindEurope, Amazon Web Services and Accenture.":
            "EasyPermits to cyfrowy punkt kompleksowej obsługi procesu wydawania pozwoleń na lądowe projekty wiatrowe.\n\nDzięki EasyPermits koncentrujemy się na usprawnieniu procesu wydawania pozwoleń poprzez poprawę zarządzania informacjami, zwiększenie przejrzystości procesu wydawania pozwoleń na lądowe farmy wiatrowe oraz zacieśnienie współpracy między deweloperami projektów, podmiotami wydającymi pozwolenia, a także członkami społeczności.\n\nEasyPermits to narzędzie internetowe opracowane we współpracy WindEurope, Amazon Web Services i Accenture.",
          "I live in a municipality with planned wind projects. I want to learn more about the upcoming wind projects near me. I want to stay informed and actively engage in discussions about their impact on our community.":
            "Mieszkam w gminie, w której planowane są projekty wiatrowe. Chcę dowiedzieć się więcej o nadchodzących projektach wiatrowych w mojej okolicy. Chcę być informowany i aktywnie uczestniczyć w dyskusjach na temat ich wpływu na naszą społeczność.",
          "I work at the municipality, process permit applications or provide my expertise. I want to use the tool for managing the permit applications from Project Developers and engage my community on upcoming planned projects.":
            "Pracuję w urzędzie gminy, przetwarzam wnioski o zezwolenia lub udzielam swojej wiedzy specjalistycznej. Chcę używać narzędzia do zarządzania wnioskami o zezwolenia od deweloperów projektów i angażować moją społeczność w nadchodzące planowane projekty.",
          "I work for a company that wants to develop wind projects. I want to use the tool to submit a request for wind projects and send the necessary documentation to the permitting agents and engage the community on project activities and benefits.":
            "Pracuję dla firmy, która chce rozwijać projekty wiatrowe. Chcę użyć narzędzia do złożenia wniosku o projekty wiatrowe i przesłać niezbędną dokumentację do agentów ds. zezwoleń oraz zaangażować społeczność w działania projektu i korzyści z niego wynikające.",
          // Footer contents
          "Explore our Interactive Map. Click on the red markers to discover about the upcoming wind projects in the permitting process in your area and beyond. Want to learn more about wind energy and the permitting process in general? Visit the Frequently Asked Questions section.":
            "Poznaj naszą interaktywną mapę. Kliknij czerwone znaczniki, aby dowiedzieć się o nadchodzących projektach wiatrowych będących w trakcie procesu wydawania pozwoleń w Twojej okolicy i poza nią. Chcesz dowiedzieć się więcej o energii wiatrowej i ogólnie o procesie wydawania pozwoleń? Odwiedź sekcję Często Zadawane Pytania.",
          "Sign in to access the project management capabilities of the platform tailored to your role. Collaborate with project developers and your colleagues in the municipalities and prepare for engaging communities through consultations.":
            "Zaloguj się, aby uzyskać dostęp do funkcji zarządzania projektami platformy dostosowanych do Twojej roli. Współpracuj z twórcami projektów i współpracownikami w gminach i przygotuj się do angażowania społeczności poprzez konsultacje.",
          "Sign in to access the project management capabilities of the platform tailored to your role. Collaborate with permitting agents and your colleagues and prepare for engaging communities through consultations.":
            "Zaloguj się, aby uzyskać dostęp do funkcji zarządzania projektami platformy dostosowanych do Twojej roli. Współpracuj z agentami wydającymi pozwolenia i współpracownikami oraz przygotuj się do angażowania społeczności poprzez konsultacje.",
          // Community: project timeline hover description
          "Project Developer submits new project request and municipality has initial considerations about the project and determines a timeline for the planning work.":
            "Deweloper projektu składa nową prośbę o projekt, a gmina ma początkowe rozważania na temat projektu i ustala harmonogram prac planistycznych.",
          "A period of typically two weeks in which citizens are engaged in the project by the municipality. Citizens are informed of the project and the upcoming planning process.":
            "Okres zwykle dwóch tygodni, podczas którego mieszkańcy zaangażowani są w projekt przez gminę. Mieszkańcy są informowani o projekcie i nadchodzącym procesie planowania.",
          "Environmental Report and Environmental Impact Assessment Report are prepared by the Project Developer and the Permitting Agent.":
            "Raport środowiskowy i raport oceny wpływu na środowisko są przygotowywane przez dewelopera projektu i agenta ds. zezwoleń.",
          "Municipality publishes proposal for municipal planning and associated EIA report. A period of at least eight weeks starts in which citizens and other stakeholders are engaged in the project by the municipality.":
            "Gmina publikuje propozycję planowania komunalnego i związany z nią raport EIA. Rozpoczyna się okres trwający co najmniej osiem tygodni, podczas którego mieszkańcy i inni interesariusze są zaangażowani w projekt przez gminę.",
          "The municipality decides on the adoption of the plan proposal.":
            "Gmina decyduje o przyjęciu propozycji planu.",
          // Notifications:
          a_preplanning_closed: "Faza rozpoczęcia została zamknięta.",
          b_initial_consultation_closed:
            "Pierwsza konsultacja publiczna została zakończona.",
          c_planning_closed: "Proces planowania został zakończony.",
          d_final_consultation_closed:
            "Faza ostatniej konsultacji publicznej została zakończona.",
          e_approval_closed: "Ostateczna decyzja została zakończona.",
          task_done: "Zadanie zakończone.",
          b_review: "Nowy dokument został przesłany",
          c_revision: "Dokument wymaga rewizji",
          d_done: "Nowy dokument został oznaczony jako zakończony.",
          document_comment: "Nowy komentarz do dokumentu.",
          project_comment: "Nowy komentarz do projektu.",
          project_summary_editable_false_verified_true:
            "Opis projektu został zweryfikowany.",
          project_summary_editable_true_verified_false:
            "Opis projektu wymaga rewizji.",
          project_summary_editable_false_verified_false:
            "Opis projektu wymaga przeglądu.",
          "closed the Public Consultation Stage":
            "zakończył fazę konsultacji publicznych",
          "closed the Final Decision Stage and the Project":
            "zakończył fazę ostatecznej decyzji i projekt",
          "closed the Planning Stage": "zakończył fazę planowania",
          "closed the Initial Public Consultation Stage":
            "zakończył fazę początkowej konsultacji publicznej",
          "closed the Preplanning Stage": "zakończył fazę rozpoczęcia",
          "Document Marked as Complete": "Dokument oznaczony jako zakończony",
          "Task Completed": "Zadanie zakończone",
          "Sent Back to Edit": "Dokument przesłany do edycji",
          "New Document Uploaded": "Nowy dokument przesłany",
          "Project Details": "Szczegóły projektu",
          "Edit Project": "Edytuj projekt",
          "Project Description": "Opis projektu",
          "Detailed description of the proposed wind farm, basic information, company description, etc.":
            "Szczegółowy opis proponowanej farmy wiatrowej, podstawowe informacje, opis firmy, itp.",
          "Pre agreement with owners": "Wstępna umowa z właścicielami",
          "Muncipality of the project": "Gmina projektu",
          "Rotor diameter of the wind turbines in meters":
            "Średnica wirnika wiatraków w metrach",
          "Height of the wind turbines in meters":
            "Wysokość wiatraków w metrach",
          "Start date not set yet":
            "Data rozpoczęcia nie jest jeszcze ustalona",
          "Start date set": "Określona data rozpoczęcia",
          "Not happened yet": "Jeszcze się nie wydarzyło",
          "Not yet set": "Jeszcze nie ustawiony",
          "Assign Project to Me": "Przydziel projekt do mnie",
          "Next Document to Submit": "Następny dokument do przesłania",
          // Community Page:
          Home: "Strona główna",
          Dashboard: "Deska rozdzielcza",
          Community: "Społeczność",
          "Search on this page to find more information on projects in the permitting process in the neighbourhood":
            "Szukaj na tej stronie, aby znaleźć więcej informacji na temat projektów w procesie uzyskiwania zezwoleń w okolicy",
          "Wind Farms in Permitting Process":
            "Farmy wiatrowe w procesie uzyskiwania zezwoleń",
          "In Permit Process": "W trakcie",
          "Appeal Phase": "Faza Apelacyjna",
          "Appeal Raised": "Zgłoszono apelację",
          Planned: "Zaplanowany",
          "Pins on the map": "Szpile na mapie",
          "Show/hide": "Pokaż/ukryj",
          "The projects are still in the process of approval, which does not guarantee their full implementation.":
            "Projekty są wciąż w procesie zatwierdzania, co nie gwarantuje ich pełnej realizacji.",
          Municipality: "Gmina",
          "Sign in": "Zaloguj się",
          "Sign In": "Zaloguj Się",
          "Sign out": "Wyloguj się",
          Support: "Wsparcie",
          "User Pages": "Strony użytkowników",
          "Data Privacy": "Ochrona danych",
          "Frequently Asked Questions": "Najczęściej zadawane pytania",
          "Quick Links": "Szybkie linki",
          // Instructions
          "How to use this page?": "Jak korzystać z tej strony?",
          "Search for an address or postal code (including country) to see projects in the permitting process.":
            "Wyszukaj adres, kod pocztowy lub kraj (włączając kraj), aby zobaczyć projekty w procesie uzyskiwania zezwoleń.",
          "The red markers indicate projects in the permitting process.":
            "Czerwone znaczniki wskazują projekty w procesie uzyskiwania zezwoleń.",
          "Click on the red marker to see more details of that project in the permitting process.":
            "Kliknij na czerwony znacznik, aby zobaczyć więcej szczegółów dotyczących tego projektu w procesie uzyskiwania zezwoleń.",
          "Using the map search feature":
            "Korzystanie z funkcji wyszukiwania na mapie",
          "Enter an address, postcode or country in the search bar.":
            "Wprowadź adres, kod pocztowy lub kraj w pasku wyszukiwania.",
          "Select your result in the drop-down list. The map will automatically fly and zoom into that location.":
            "Wybierz swój wynik z listy rozwijanej. Mapa automatycznie przemieści się i powiększy do wybranego miejsca.",
          "You should still see the selected result under the search bar. Click on it again. The map should then automatically zoom out.":
            "Powinieneś nadal widzieć wybrany wynik pod polem wyszukiwania. Kliknij go ponownie. Mapa powinna automatycznie oddalić się.",
          "If the map does not zoom out far enough, please use the +/- navigation tool at the bottom left corner or mouse scroll up/down to control the zoom":
            "Jeśli mapa nie oddala się wystarczająco, użyj narzędzia nawigacyjnego +/- w lewym dolnym rogu lub przewiń myszą w górę/dół, aby kontrolować zoom",
          Permit: "Zezwolenie",
          "Start Date": "Data rozpoczęcia",
          "Download Comments": "Pobierz komentarze",
          Comments: "Komentarze",
          "Remove start date": "Usuń datę rozpoczęcia",
          "Set Project Start Date": "Ustaw datę rozpoczęcia projektu",
          "End Date": "Data zakończenia",
          "Permit Start Date": "Data rozpoczęcia procesu uzyskiwania zezwoleń",
          "Permit End Date":
            "Przewidywana data zakończenia procesu uzyskiwania zezwoleń",
          "End date not set yet": "Data zakończenia jeszcze nieustalona",
          "Re-open project": "Ponowne otwarcie projektu",
          // Export:
          Property: "Właściwość",
          Value: "Wartość",
          Unit: "Jednostka",
          // Project:
          Country: "Kraj",
          "Country of the project": "Kraj projektu",
          wind: "Elektrownia wiatrowa",
          solar: "Energia słoneczna",
          meters: "metrów",
          hectares: "hektarów",
          "It will supply": "Dostarczy energię dla",
          households: "gospodarstw domowych",
          Households: "Gospodarstwa domowe",
          "Average number of households to supply energy to":
            "Średnia liczba gospodarstw domowych, dla których dostarczana jest energia",
          Details: "Szczegóły",
          "Overall Capacity": "Łączna moc",
          "Capacity in megawatts (MW)": "Wydajność w megawatach (MW)",
          "Please select a country first": "Najpierw wybierz kraj",
          "Expected Tip Height": "Oczekiwana wysokość końcowa łopat wirnika",
          "Expected Rotor Diameter": "Oczekiwana średnica wirnika",
          Size: "Rozmiar",
          Generalities: "Ogólne informacje",
          "All the above information is provided by the project developer":
            "Wszystkie powyższe informacje są dostarczane przez dewelopera projektu",
          "Hover over each stage to see more details":
            "Najedź kursorem na każdy etap, aby zobaczyć więcej szczegółów",
          "Project Overview": "Przegląd projektu",
          "More information on municipality webpage":
            "Więcej informacji na stronie internetowej gminy",
          "You can add your feedback on the project here":
            "Możesz dodać swoją opinię na temat projektu tutaj",
          // Project setup
          "Our app is designed to streamline and simplify the permitting process for wind farm developers like you. As you begin, please proceed by submitting the Project Setup Form, where you can provide essential information about your project application.":
            "Nasza aplikacja została zaprojektowana, aby usprawnić i uprościć proces wydawania pozwoleń dla deweloperów farm wiatrowych takich jak Ty. Na początku prosimy o przesłanie formularza konfiguracji projektu, w którym można podać niezbędne informacje na temat wniosku dotyczącego projektu.",
          "This form will help you communicate all the basic, important details required by the permitting agent. Our goal is to ensure a seamless and efficient process, facilitating clear communication and collaboration between you and the permitting authorities.":
            "Ten formularz pomoże Ci przekazać wszystkie podstawowe, ważne szczegóły wymagane przez agenta wydającego pozwolenie. Naszym celem jest zapewnienie płynnego i wydajnego procesu, ułatwiając jasną komunikację i współpracę pomiędzy Tobą a organami wydającymi pozwolenia.",
          "Thank you for choosing EasyPermits. Let's work together to create a greener and sustainable future!":
            "Dziękujemy za wybranie EasyPermits. Pracujmy razem, aby stworzyć bardziej ekologiczną i zrównoważoną przyszłość!",
          "Maximum tip height of the wind turbines":
            "Maksymalna wysokość końcowa wiatraków",
          "Expected height": "Oczekiwana wysokość",
          "Expected Height": "Oczekiwana wysokość",
          "Developer Website": "Strona dewelopera",
          // FAQs:
          Accessed: "Dostępny",
          "Available at": "Dostępny pod adresem",
          // - Categories:
          "General Questions": "Pytania ogólne",
          "Permitting Process": "Proces uzyskiwania zezwoleń",
          "Reference List": "Lista referencyjna",
          // - Questions:
          "What is a wind farm permitting process?":
            "Czym jest proces uzyskiwania zezwoleń na farmę wiatrową?",
          "The wind farm permitting process is the series of regulatory steps and approvals required before a project can be constructed and operated. This process varies by according to local laws and can involve several municipal agencies and public review (European Commission, n.d.a).":
            "Proces uzyskiwania pozwoleń na farmę wiatrową to seria kroków regulacyjnych i zatwierdzeń wymaganych przed rozpoczęciem budowy i eksploatacją projektu. Proces ten różni się w zależności od lokalnych przepisów i może angażować kilka agencji miejskich oraz podlegać publicznej ocenie (European Commission, n.d.a).",
          "What permits does a wind farm need?":
            "Jakie są typowe zezwolenia wymagane dla projektu?",
          "The type of permits varies based on the size and complexity of the wind farm. Some common permits include: environmental permits, land-use permits, zoning permits, building permits, and electrical interconnection permits (European Commission, n.d.a).":
            "Rodzaj pozwoleń różni się w zależności od wielkości i złożoności farmy wiatrowej. Niektóre typowe pozwolenia obejmują: pozwolenia środowiskowe, pozwolenia na użytkowanie gruntów, pozwolenia na zagospodarowanie przestrzenne, pozwolenia na budowę i pozwolenia na wzajemne połączenia elektryczne (Komisja Europejska, n.d.a).",
          "Who is responsible for obtaining these permits?":
            "Kto jest odpowiedzialny za uzyskanie tych zezwoleń?",
          "The wind farm developer, aka. project developer or just developer, is typically responsible for obtaining the necessary permits, but may work with consultants or legal counsel to navigate the permitting process (European Commission, n.d.b).":
            "Deweloper farmy wiatrowej, znany również jako deweloper projektu lub po prostu deweloper, jest zazwyczaj odpowiedzialny za uzyskanie niezbędnych pozwoleń, ale może współpracować z konsultantami lub radcą prawnym w celu poruszania się po procesie wydawania pozwoleń (Komisja Europejska, n.d.b).",
          "How long does the wind farm permitting process typically take?":
            "Jak długo zazwyczaj trwa proces uzyskiwania pozwoleń na budowę farmy wiatrowej?",
          "The length of the permitting process can vary widely depending on the jurisdiction and complexity of the project. It can range from several months to several years. EU law requires public authorities to complete the process within two years for new wind farms, and within one year for repowered wind farms (when the turbines in an existing wind farm are being replaced with newer ones) (WindEurope, 2023).":
            "Czas trwania procesu uzyskiwania pozwoleń może się znacznie różnić w zależności od jurysdykcji i złożoności projektu. Może on trwać od kilku miesięcy do kilku lat. Prawo UE wymaga, aby organy publiczne zakończyły proces w ciągu dwóch lat w przypadku nowych farm wiatrowych i w ciągu jednego roku w przypadku farm wiatrowych o zmienionej mocy (gdy turbiny w istniejącej farmie wiatrowej są zastępowane nowszymi) (WindEurope, 2023).",
          "How can I stay informed about the wind farm permitting process?":
            "Jak mogę być na bieżąco z procesem wydawania pozwoleń dla farm wiatrowych?",
          "Many jurisdictions have public consultation periods and hearings as part of the permitting process. You can find the contact details of the agency in charge of the project you're interested on the project page of the municipality website.":
            "Wiele jurysdykcji organizuje konsultacje publiczne i przesłuchania w ramach procesu wydawania pozwoleń. Dane kontaktowe agencji odpowiedzialnej za dany projekt można znaleźć na stronie projektu w witrynie internetowej gminy.",
          "What happens after a project is permitted?":
            "Co dzieje się po uzyskaniu pozwolenia na realizację projektu?",
          "Once a project is permitted, construction can begin. The developer will also need to obtain additional permits and approvals as construction progresses, such as building inspections and connections to the transmission networks (Danish Energy Agency, 2022).":
            "Po uzyskaniu zezwolenia na projekt, można rozpocząć budowę. Deweloper będzie również musiał uzyskać dodatkowe zezwolenia i zatwierdzenia w miarę postępu budowy, takie jak inspekcje budowlane i połączenia z siecią przesyłową (Danish Energy Agency, 2022).",
          "Why is it so difficult to get permits for wind farms in Europe?":
            "Dlaczego tak trudno uzyskać zezwolenia na nowe i modernizowane farmy wiatrowe w Europie?",
          "Complex rules and slow procedures are the main reasons why it’s difficult to get permits for wind farms. Wind farms face several spatial planning constraints are emerging, such as minimum distance to housing, height restrictions and exclusion zones around radar installations, or in specific areas. Additionally, numerous administrative authorities are involved, including at national, regional and municipal level (WindEurope, 2020).":
            "Złożone przepisy i powolne procedury to główne powody, dla których trudno jest uzyskać pozwolenia na budowę farm wiatrowych. Farmy wiatrowe napotykają na szereg ograniczeń związanych z planowaniem przestrzennym, takich jak minimalna odległość od zabudowań mieszkalnych, ograniczenia wysokości i strefy wykluczenia wokół instalacji radarowych lub na określonych obszarach. Ponadto zaangażowane są liczne organy administracyjne, w tym na szczeblu krajowym, regionalnym i gminnym (WindEurope, 2020).",
          "What requirements do wind farm developers account for in the permitting process?":
            "Jakie wymagania uwzględniają deweloperzy projektów w procesie uzyskiwania zezwoleń?",
          "Project developers need to account for several requirements when they consider building a wind farm on a specific site: wind speeds, grid connection supporting infrastructure such as roads, impact of the wind farm on surrounding natural ecosystems and communities, the right turbine technology for the site etc. (Domaingue, J.-C. and Jain, P., 2022).":
            "Deweloperzy projektów muszą uwzględnić kilka kwestii, gdy rozważają budowę na konkretnej lokalizacji, takie jak prędkości wiatru, połączenia z siecią i infrastrukturę wspierającą (np. drogi), wpływ na otaczające ekosystemy naturalne oraz społeczności, odpowiednią technologię turbin wiatrowych dla danej lokalizacji itp. (Domaingue, J.-C. and Jain, P., 2022).",
          "How can developers seek participation from local residents and other stakeholders during the permitting process?":
            "Jak deweloperzy mogą pozyskać uczestnictwo mieszkańców i innych interesariuszy podczas procesu uzyskiwania zezwoleń?",
          "The wind industry follows three guiding principles as a model of best practice for good community engagement:":
            "Branża wiatrowa kieruje się trzema zasadami przewodnimi jako modelem najlepszych praktyk w zakresie dobrej współpracy ze społecznością lokalną:",
          "early, transparent and comprehensive information and communication;":
            "wczesne, przejrzyste i kompleksowe informowanie i komunikacja;",
          "direct engagement of key local actors and activation of the local economy; and":
            "bezpośrednie angażowanie kluczowych aktorów lokalnych i aktywizacja lokalnej gospodarki; oraz",
          "highlighting how communities will benefit from the investments being made. These principles apply at every stage of a project, from site selection and pre-application, planning, construction to operation and decommissioning. (WindEurope, 2020)":
            "podkreślanie, w jaki sposób społeczności skorzystają z dokonywanych inwestycji. Te zasady mają zastosowanie na każdym etapie projektu, od wyboru lokalizacji i wniosku przedrealizacyjnego, poprzez planowanie, budowę, eksploatację po likwidację. (WindEurope, 2020)",
          "How is EasyPermits simplifying the permit application process?":
            "Jak EasyPermits upraszcza proces składania wniosków o zezwolenie?",
          "The EasyPermits application makes it possible for permitting authorities, wind farm developers and community members to access all the information about a project in an easy, centralised way. The application provides permitting agents with a dedicated dashboard giving them a concise snapshot of each permit application, encompassing critical tasks, notifications, project summaries, associated activities, and productivity KPIs.":
            "Aplikacja EasyPermits posiada dedykowany pulpit, który umożliwia szybkie przeglądanie konkretnej aplikacji o zezwolenie dla upoważnionego agenta. Obejmuje to holistyczny przegląd kluczowych zadań, powiadomień, przeglądu projektu, zadań powiązanych oraz wskaźników KPI dotyczących produktywności.",
          // Data Privacy popup
          "Please note that the personal data in this system cannot be used for other purposes than the purposes of this tool/system. If you plan to do so, seek advice from your legal department regarding required steps.":
            "Proszę pamiętać, że dane osobowe w tym systemie nie mogą być wykorzystywane do innych celów niż cele tego narzędzia/systemu. Jeśli planujesz to zrobić, zasięgnij porady swojego działu prawnego w sprawie wymaganych kroków.",
          "WindEurope can process my personal data in accordance with this":
            "WindEurope może przetwarzać moje dane osobowe zgodnie z tym",
          "I agree": "Zgadzam się",
          "I do not agree": "Nie zgadzam się",
          "For more information, please see":
            "Aby uzyskać więcej informacji, proszę zobaczyć",
          "Please note that the personal data in this system cannot be used for other purposes than the purposes of this tool/system. If you plan to do so, seek advice from your legal department regarding required steps.":
            "Należy pamiętać, że dane osobowe w tym systemie nie mogą być wykorzystywane do innych celów niż cele tego narzędzia/systemu. Jeśli planujesz to zrobić, zasięgnij porady działu prawnego w zakresie wymaganych kroków.",
          // Dashboard:
          "No projects found": "Nie znaleziono żadnych projektów",
          "Overdue Tasks": "Zaległe zadania",
          "Welcome Back": "Witamy z powrotem",
          Productivity: "Produktywność",
          Notifications: "Powiadomienia",
          "No new notifications": "Brak nowych powiadomień",
          "Take a look at your achievements": "Zobacz swoje osiągnięcia",
          "Ongoing projects": "Trwające projekty",
          "Delayed projects": "Opóźnione projekty",
          "Your open tasks": "Twoje otwarte zadania",
          "Overdue tasks overall": "Ogólna liczba zaległych zadań",
          "Saving changes": "Zapisywanie zmian",
          "Please wait": "Proszę czekać",
          "Fetching data": "Pobieranie danych",
          "My Tasks": "Moje zadania",
          "This Week": "W tym tygodniu",
          "Next Week": "W przyszłym tygodniu",
          Overall: "Ogólnie",
          "Task Name": "Nazwa zadania",
          "Project Name": "Nazwa projektu",
          "Project Phase": "Faza projektu",
          "Due Date": "Termin",
          "Days to Finish": "Dni do ukończenia",
          Delayed: "Opóźnione",
          "Loading resources": "Ładowanie zasobów",
          Loading: "Ładowanie",
          "No resources": "Brak zasobów",
          "No resources to display": "Brak dostępnych zasobów do wyświetlenia",
          "Deadline not set": "Termin nie został jeszcze ustalony",
          "Pre-planning": "Przygotowania",
          "Documents Review": "Przegląd dokumentów",
          "City Council Meeting": "Spotkanie Rady Miejskiej",
          "Initial Public Consultation": "Pierwsza konsultacja publiczna",
          "Public Consultation": "Konsultacja publiczna",
          "Planning Process": "Proces planowania",
          "Final Public Consultation": "Ostateczna konsultacja publiczna",
          "Final Approval": "Ostateczna zgoda",
          Appeal: "Apelacja",
          "Start date will be set once all the documents and have been reviewed":
            "Data rozpoczęcia zostanie ustalona po przeglądzie wszystkich dokumentów",
          "Project will start on": "Projekt rozpocznie się",
          "You are in the Pre-Planning phase": "Jesteś w fazie przygotowań",
          Mon: "Pon.",
          Tue: "Wt.",
          Wed: "Śr.",
          Thu: "Czw.",
          Fri: "Pt.",
          Sat: "Sob.",
          Sun: "Niedz.",
          Oct: "Paź",
          May: "Maj",
          "My Projects": "Moje projekty",
          "In Revision": "W Rewizji",
          "To Be Reviewed": "Do Przejrzenia",
          "All Projects in Municipality": "Wszystkie projekty w gminie",
          "Unassigned Projects": "Projekty bez przypisania",
          "Are you sure you want to close this project?":
            "Jesteś pewny, że chcesz zamknąć projekt?",
          "Closed Projects": "Zamknięte projekty",
          "Report Appeal": "Zgłoś apelację",
          "Are you sure you want to report appeal this project?":
            "Jesteś pewny, że chcesz zgłosić apelację w sprawie tego projektu?",
          "You have 4 weeks to report an appeal. After this, the button  will be disabled.":
            "Masz 4 tygodnie na zgłoszenie odwołania. Po tym czasie przycisk zostanie wyłączony.",
          "Share Appeal Outcome": "Podziel się wynikiem apelacji",
          "Download everything": "Pobierz wszystko",
          "Download all project files": "Pobierz wszystkie pliki projektu",
          "Please choose what you would like to download":
            "Proszę wybrać, co chciałbyś pobrać",
          "All project comments": "Wszystkie komentarze projektu",
          "All document comments": "Wszystkie komentarze dokumentów",
          "All document attachments (all versions)":
            "Wszystkie załączniki dokumentów (wszystkie wersje)",
          "No attachments found": "Nie znaleziono załączników",
          // Project Dashboard:
          "Updating assignee. Please do not close or refresh this page":
            "Aktualizacja cesjonariusza. Nie zamykaj ani nie odświeżaj tej strony",
          "Appeal is overruled": "Apelacja została odrzucona",
          "Appeal is sustained": "Apelacja jest utrzymana",
          "This will also cancel the project": "To również anuluje projekt",
          "Community page": "Strona publiczna",
          "Project website": "Strona internetowa projektu",
          "Edit website": "Edytuj stronę internetową",
          "Add project website": "Dodaj stronę internetową projektu",
          "added a comment": "dodał komentarz",
          "commented on the document": "skomentował dokument",
          "Take a look at your progress": "Zobacz swój postęp",
          "Overview of the project including timeline and key highlights":
            "Przegląd projektu, w tym harmonogram i kluczowe punkty",
          "Created on": "Utworzony na",
          by: "przez",
          Update: "Aktualizacja",
          Set: "Ustaw",
          "Open tasks": "Otwarte zadania",
          Tasks: "Zadania",
          Documents: "Dokumenty",
          Document: "Dokument",
          "Owner as role": "Właściciel jako rola",
          "Delete version": "Usuń wersję",
          "in delay": "z opóźnieniem",
          Collaborators: "Współpracownicy",
          "Once you have reviewed all the documents and the Summary, you can set the start date for the project":
            "Po przejrzeniu wszystkich dokumentów i streszczenia możesz ustawić datę rozpoczęcia projektu",
          "Once the Permitting Agent have reviewed and validated all the documents and Summary, they will set a start date for the project":
            "Gdy agent ds. pozwoleń przejrzy i zweryfikuje wszystkie dokumenty i streszczenie, ustawi datę rozpoczęcia projektu",
          "Update deadlines": "Aktualizuj terminy",
          Cancel: "Anuluj",
          "Save Changes": "Zapisz zmiany",
          "Final Appeal": "Ostateczna apelacja",
          "Edit Milestones Deadlines": "Edytuj terminy kamieni milowych",
          "Current stage": "Aktualny etap",
          "Last stage": "Ostatni etap",
          "Manage team": "Zarządzaj zespołem",
          "Manage Team": "Zarządzaj zespołem",
          "Update additional collaborators for your project":
            "Aktualizuj dodatkowych współpracowników dla swojego projektu",
          "Start Over": "Rozpocznij od nowa",
          "Close Stage": "Zamknij etap",
          "Skip Stage": "Etap pomijania",
          "Export Summary": "Eksportuj podsumowanie",
          "Assigned to": "Przydzielone do",
          "Project Dashboard": "Dashboard projektu",
          "No open documents": "Brak otwartych dokumentów",
          "Related Documents": "Powiązane dokumenty",
          "Related documents": "Powiązane dokumenty",
          "Choose documents": "Wybierz dokumenty",
          "Not complete": "Niekompletny",
          document: "dokument",
          documents: "dokumenty",
          "All done!": "Wszystko gotowe!",
          "No open tasks to display": "Brak otwartych zadań do wyświetlenia",
          "open documents": "otwarte dokumenty",
          "Add New Task": "Dodaj nowe zadanie",
          "Re-open Task": "Ponownie otwórz zadanie",
          Description: "Opis",
          Stage: "Etap",
          "Due date": "Termin wykonania",
          "Edit Task": "Edytuj zadanie",
          "Edit task": "Edytuj zadanie",
          "Mark as complete": "Oznacz jako zakończone",
          "Linked Documents": "Powiązane dokumenty",
          Priority: "Priorytet",
          "View all tasks": "Zobacz wszystkie zadania",
          "View all documents": "Zobacz wszystkie dokumenty",
          "Ready for upload": "Gotowe do przesłania",
          "Sent for review": "Wysłane do przeglądu",
          "Revision needed": "Wymagana rewizja",
          Completed: "Zakończono",
          // Activities
          Activities: "Aktywności",
          History: "Historia",
          "Project Logs": "Logi projektu",
          "Project Request": "Żądanie projektu",
          "sent a project request": "wysłał prośbę o projekt",
          "started the project": "rozpoczął projekt",
          "Project Comments": "Komentarze do projektu",
          "Document Comments": "Komentarze do dokumentów",
          "Download History": "Pobierz historię",
          "Download Project Logs": "Pobierz logi projektu",
          "Download Project Comments": "Pobierz komentarze projektowe",
          Maybe: "Być może",
          "Close Project": "Zamknij projekt",
          "Close project": "Zamknij projekt",
          "Project Stopped": "Projekt zatrzymany",
          "Project Completed": "Projekt zakończony",
          "Mandatory Documents & Tasks": "Obowiązkowe dokumenty i zadania",
          "Optional Documents & Tasks": "Opcjonalne dokumenty i zadania",
          "All tasks have been completed":
            "Wszystkie zadania zostały zakończone",
          "There are still open mandatory documents":
            "Pozostało jeszcze kilka otwartych dokumentów obowiązkowych",
          "There are still open mandatory tasks":
            "Pozostało jeszcze kilka otwartych zadań obowiązkowych",
          "There are still open optional documents":
            "Wciąż dostępne są opcjonalne dokumenty",
          "There are still open optional tasks":
            "Wciąż dostępne są zadania opcjonalne",
          "All items in the checklist must be completed before you can close this stage":
            "Wszystkie elementy listy kontrolnej muszą zostać ukończone przed zamknięciem tego etapu",
          "You still have open optional documents and tasks":
            "Nadal masz otwarte opcjonalne dokumenty i zadania",
          "Once you close this stage, all relevant information will be published to the Community page":
            "Po zamknięciu tej fazy wszystkie istotne informacje zostaną opublikowane na stronie społecznościowej",
          Confirm: "Potwierdź",
          "All mandatory documents are uploaded and reviewed":
            "Wszystkie wymagane dokumenty zostały przesłane i przeanalizowane",
          "Are you sure you want to close this stage?":
            "Czy na pewno chcesz zamknąć tę fazę?",
          "Are you sure you want to skip this stage?":
            "Czy na pewno chcesz pominąć ten etap?",
          "Before proceeding to the next stage, please ensure the following checklist is completed:":
            "Przed przejściem do kolejnego etapu upewnij się, że została wykonana poniższa lista kontrolna:",
          "City Council Meeting Outcome": "Wynik posiedzenia Rady Miasta",
          "Please provide the outcome of the City Council meeting for this stage":
            "Proszę podać wynik posiedzenia Rady Miasta dla tej fazy",
          "Please provide the outcome of the City Council meeting for the last stage":
            "Proszę podać wynik posiedzenia Rady Miasta dla ostatniej fazy",
          "You can close this stage and proceed to the next one":
            "Możesz zamknąć tę fazę i przejść do kolejnej",
          "You can close the last stage and close the project":
            "Możesz zamknąć ostatnią fazę i zamknąć projekt",
          "Further adjustments required. You may need to stay in this stage, re-open and modify the documents accordingly":
            "Wymagane są dalsze dostosowania. Możliwe, że musisz pozostać w tej fazie, ponownie otworzyć i zmodyfikować dokumenty zgodnie z tymi zmianami",
          "The project needs to be stopped and closed at this stage":
            "Projekt musi zostać zatrzymany i zamknięty na tym etapie",
          "Share Outcome": "Podziel się wynikiem",
          "View all details": "Zobacz wszystkie szczegóły",
          "Project Summary": "Podsumowanie projektu",
          "Permit Process Start Date": "Data rozpoczęcia procesu zezwolenia",
          Date: "Data",
          Author: "Autor",
          Comment: "Komentarz",
          "Permit Process End Date": "Data zakończenia procesu zezwolenia",
          "Permitting Agent": "Agent ds. zezwoleń",
          "Project Developer": "Deweloper projektu",
          "Number of Turbines": "Liczba turbin",
          "Size of project": "Wielkość projektu",
          "Relation to Neighbors": "Stosunek do sąsiadów",
          "Pre-agreement with Owners": "Przedumowa z właścicielami",
          "Location - Latitude": "Lokalizacja - szerokość geograficzna",
          "Location - Longitude": "Lokalizacja - długość geograficzna",
          "Project Type": "Typ projektu",
          "Additional technical details": "Dodatkowe szczegóły techniczne",
          "Potential number of households it supplies energy to":
            "Potencjalna liczba gospodarstw domowych, którym dostarcza energię",
          "Date not set yet": "Data jeszcze nie jest ustalona",
          "All Project Documents": "Wszystkie dokumenty projektu",
          "All Project Tasks": "Wszystkie zadania projektu",
          "Project Documents": "Dokumenty projektu",
          "Project Tasks": "Zadania projektowe",
          "Add New Document": "Dodaj nowy dokument",
          "No comments available": "Brak dostępnych komentarzy",
          "No documents available": "Brak dostępnych dokumentów",
          "No versions available": "Brak dostępnych wersji",
          "No tasks available": "Brak dostępnych zadania",
          "Add a comment": "Dodaj komentarz",
          "Enter comments here...": "Wprowadź komentarze tutaj...",
          "Add comment": "Dodaj komentarz",
          "Delete comment": "Usuń komentarz",
          "Confirm action": "Potwierdź czynność",
          "Are you sure you want to delete?": "Czy na pewno chcesz usunąć?",
          "Send Back to Edit": "Wyślij z powrotem do edycji",
          Verify: "Zweryfikuj",
          "Not yet assigned": "Jeszcze nie przydzielono",
          Verified: "Zweryfikowany",
          "Setup Workflow": "Skonfiguruj pracowity",
          // Workflow:
          "Permitting Workflow Setup": "Konfiguracja pracowity dla zezwoleń",
          "Set up an individual workflow as standardized template for your own work":
            "Skonfiguruj indywidualny pracowity jako standardową szablon do własnej pracy",
          "Select Template": "Wybierz szablon",
          "Create my own template": "Utwórz swój własny szablon",
          "Coming soon": "Wkrótce dostępne",
          "Next: Set Required Documents":
            "Następnie: Skonfiguruj wymagane dokumenty",
          "Required Documents List": "Lista wymaganych dokumentów",
          "We set the documents of the request that are important to you. Use the checklist icon on the left to design your individual workflow.":
            "Skonfigurowaliśmy dokumenty wniosku, które są dla Ciebie ważne. Użyj ikony listy kontrolnej po lewej stronie, aby zaprojektować indywidualny pracowity.",
          "Back to Templates": "Powrót do szablonów",
          "Next: Set Tasks": "Następnie: Skonfiguruj zadania",
          "Set Tasks": "Skonfiguruj zadania",
          "We set the tasks of the request that are important to you. Use the checklist icon on the left to design your individual workflow.":
            "Skonfigurowaliśmy zadania wniosku, które są dla Ciebie ważne. Użyj ikony listy kontrolnej po lewej stronie, aby zaprojektować indywidualny pracowity.",
          "No stage selected": "Nie wybrano etapu",
          "Back to Documents": "Powrót do dokumentów",
          "Next: Set Milestones Deadlines":
            "Następnie: Skonfiguruj terminy kamieni milowych",
          "Set Milestone Deadlines": "Skonfiguruj terminy kamieni milowych",
          "We have set you an approximate duration for the different periods, which you can change if you wish.":
            "Ustaliliśmy przybliżony czas trwania różnych okresów, który możesz zmienić, jeśli chcesz.",
          "Set deadlines": "Ustaw terminy",
          "Back to Tasks": "Powrót do zadań",
          "Next: Set Collaborators": "Następnie: Skonfiguruj współpracowników",
          "Set Collaborators": "Skonfiguruj współpracowników",
          "As the concluding stage, you have the option to assign collaborators to specific roles within the project. This assignment can be adjusted at a later point, and new team members may also be added if needed.":
            "Jako etap końcowy masz możliwość przypisania współpracowników do konkretnych ról w projekcie. To przypisanie można dostosować w późniejszym terminie, a także można dodać nowych członków zespołu, jeśli to konieczne.",
          "Choose developer(s)": "Wybierz dewelopera/lub deweloperów",
          "Select developer(s)": "Wybierz dewelopera/lub deweloperów",
          "Choose agents(s)": "Wybierz agenta/lub agentów",
          "Select agents(s)": "Wybierz agenta/lub agentów",
          "SME Reviewer": "Recenzent ds. MŚP",
          "Choose SME(s)": "Wybierz MŚP(y)",
          "Select SME(s)": "Wybierz MŚP(y)",
          "Back to Milestones deadlines": "Powrót do terminów kamieni milowych",
          "Value must be between": "Wartość musi być pomiędzy",
          and: "a",
          "Select municipalities": "Wybierz gminy",
          "Are you sure you want to submit?": "Czy na pewno chcesz przesłać?",
          "A notification will be sent to a Permitting Agent with your project submission request. You can get in touch with the Permitting Agent once an Agent is assigned on the project.":
            "Powiadomienie zostanie wysłane do Agenta ds. Pozwoleń wraz z Twoim wnioskiem o zgłoszenie projektu. Możesz skontaktować się z Agentem ds. Pozwoleń, gdy zostanie przypisany do projektu.",
          "Note: All the additional attachments and supporting documents can be conveniently added on the next page after submitting this form.":
            "Uwaga: Wszystkie dodatkowe załączniki i dokumenty wspierające można wygodnie dodać na następnej stronie po złożeniu tego formularza.",
          "Confirm Submission": "Potwierdź Wysłanie",
          // Project Setup:
          Organisation: "Organizacja",
          "Benefits for the Community": "Korzyści dla Społeczności",
          "Provide information regarding community benefits. You can also add a link to your project website. Link must start with http(s)://":
            "Podaj informacje dotyczące korzyści dla społeczności. Możesz także dodać link do strony internetowej projektu. Link musi zaczynać się od http(s)://",
          "Recommendation to also add the community testimonial video on your project website.":
            "Rekomendacja dodania również wideo z opiniami społeczności na stronie internetowej projektu.",
          "Name of the organisation": "Nazwa organizacji",
          "Submit New Project": "Prześlij Nowy Projekt",
          "Project name": "Nazwa projektu",
          "Name of the project": "Nazwa projektu",
          "Latitude location of the project on the map":
            "Szerokość geograficzna położenia projektu na mapie",
          "Longitude location of the project on the map":
            "Długość geograficzna położenia projektu na mapie",
          "Overall capacity": "Całkowita pojemność",
          "Municipality of the project": "Gmina projektu",
          "Go Back a Stage": "Wróć do Etapu",
          "Size of project in hectares": "Rozmiar projektu w hektarach",
          "Detailed description of the proposed project, basic information, company description, etc.":
            "Szczegółowy opis proponowanego projektu, podstawowe informacje, opis firmy, itp.",
          "Please provide essential information required for the permitting process. By completing this form, you'll offer a comprehensive overview of your proposed project. We'll gather vital details such as project location, project type, and community engagement plans. This initial step will pave the way for a smooth and efficient permitting process.":
            "Proszę podać niezbędne informacje wymagane w procesie uzyskiwania pozwoleń. Wypełniając ten formularz, zapewnisz kompleksowy przegląd swojego proponowanego projektu. Zbierzemy istotne szczegóły, takie jak lokalizacja projektu, rodzaj projektu i plany zaangażowania społeczności. Ten początkowy krok ułatwi płynny i efektywny proces uzyskiwania pozwoleń.",
          "Specify the type of the project": "Określ rodzaj projektu",
          "Total number of turbines": "Całkowita liczba turbin",
          "Expected rotor diameter": "Oczekiwana średnica wirnika",
          "Enter description here...": "Wprowadź opis tutaj...",
          "Is there a pre-agreement with owners?":
            "Czy istnieje wcześniejsza umowa z właścicielami?",
          "Information about community engagement and the current relationship to neighbors":
            "Informacje o zaangażowaniu społeczności i aktualnym stosunku do sąsiadów",
          "Enter information here...": "Wprowadź informacje tutaj...",
          "Relation to neighbors": "Stosunek do sąsiadów",
          "Do you have a written document?":
            "Czy posiadasz dokument piśmienny?",
          "Additional information": "Dodatkowe informacje",
          "Currently viewing": "Obecnie przeglądasz",
          Yes: "Tak",
          No: "Nie",
          "Please provide any additional technical information relevant to the project e.g. design, configuration, known issues, nature, etc.":
            "Proszę podać dodatkowe informacje techniczne związane z projektem, np. projekt, konfigurację, znane problemy, charakterystykę itp.",
          "Enter additional info here...":
            "Wprowadź dodatkowe informacje tutaj...",
          "Note: All the additional attachments and supporting documents can be conveniently added on the next page after submitting this form to ensure a seamless submission process.":
            "Uwaga: Wszystkie dodatkowe załączniki i dokumenty wspierające można wygodnie dodać na następnej stronie po złożeniu tego formularza, aby zapewnić płynny proces składania.",
          "Fill out the below form and submit the form to send it to the Permitting Agent":
            "Wypełnij poniższy formularz i prześlij go, aby wysłać go do Agenta ds. Pozwoleń",
          "Project Setup Request": "Żądanie Konfiguracji Projektu",
          // Stages:
          a_preplanning: "Przygotowania wstępne",
          b_initial_consultation: "Pierwsza Konsultacja",
          c_planning: "Planowanie",
          d_final_consultation: "Ostateczna Konsultacja",
          e_approval: "Zatwierdzenie",
          // roles
          agents: "Agenci",
          developers: "Deweloperzy",
          // Workflow:
          "Use predefined template": "Użyj predefiniowanej szablonu",
          // Documents:
          Version: "Wersja",
          "Set a deadline for your document": "Ustaw termin na dokument",
          "Related Tasks": "Powiązane Zadania",
          "New Task Created": "Utworzone Nowe Zadanie",
          "New Document Created": "Utworzono Nowy Dokument",
          "Submit Outcome of the Appeal phase": "Prześlij Wynik Fazy Apelacji",
          "Download Document Comments": "Pobierz Komentarze do Dokumentu",
          Section: "Sekcja",
          Page: "Strona",
          "Document Name": "Nazwa Dokumentu",
          "Set a name for your document": "Ustaw nazwę dla swojego dokumentu",
          "Please compress all additional documents into a ZIP file":
            "Proszę skompresować wszystkie dodatkowe dokumenty do pliku ZIP",
          "The geo file in the right format":
            "Plik geograficzny w odpowiednim formacie",
          "The geo file require to be in the right format":
            "Plik geograficzny musi być w odpowiednim formacie",
          "You can send the project summary back to edit or verify it":
            "Możesz ponownie przesłać podsumowanie projektu, aby je edytować lub zweryfikować",
          "Set a stage for the document": "Ustaw etap dla dokumentu",
          "Geo file in the right format, project presentation less than 20 pages, and additional documents in a ZIP format":
            "Plik geograficzny w odpowiednim formacie, prezentacja projektu mniejsza niż 20 stron i dodatkowe dokumenty w formacie ZIP",
          "Select a user to assign your document to":
            "Wybierz użytkownika, do którego chcesz przypisać swój dokument",
          "None assigned": "Nie przypisano",
          "None selected": "Nic nie wybrano",
          "Not related to any tasks": "Niepowiązane z żadnymi zadaniami",
          "Document is Mandatory": "Dokument jest obowiązkowy",
          "Task is Mandatory": "Zadanie jest obowiązkowe",
          "Publish document to the Community page when stage ends":
            "Opublikuj dokument na stronie Społeczności po zakończeniu etapu",
          "Select task(s) that belong to this document":
            "Wybierz zadania przypisane do tego dokumentu",
          "Choose tasks": "Wybierz zadania",
          "Linked Tasks": "Powiązane zadania",
          "Edit Document": "Edytuj dokument",
          "Re-Open Document": "Ponownie otwórz dokument",
          "Please do not exceed 20 pages": "Proszę nie przekraczać 20 stron",
          Actions: "Działania",
          Status: "Status",
          "Drop files to upload": "Przeciągnij pliki, aby przesłać",
          "Upload files": "Prześlij pliki",
          tasks: "zadania",
          task: "zadanie",
          "Linked to": "Powiązane z",
          "Version History": "Historia wersji",
          Versions: "Wersje",
          Chapter: "Rozdział",
          "Enter a name here...": "Wpisz tutaj nazwę...",
          "Enter a comment here...": "Wprowadź komentarz tutaj...",
          optional: "opcjonalne",
          Optional: "Opcjonalne",
          "Page Number": "Numer strony",
          "Download All Comments": "Pobierz wszystkie komentarze",
          "Download All Attachments": "Pobierz wszystkie załączniki",
          // - a
          "The Geo File":
            "Plik geo (.TAB, .MIF, .SHP, .DWG, .GML, .KML eller .DXF)",
          "Project Presentation": "Prezentacja projektu",
          "Additional Documents": "Dodatkowe dokumenty",
          // - b
          "Invitation and Project Presentation for Initial Public Consultation":
            "Zaproszenie i prezentacja projektu na wstępnej konsultacji publicznej",
          "Outcome of the City Council Meeting at the End of the Final Decision Phase":
            "Wynik posiedzenia Rady Miasta na zakończenie ostatniego etapu decyzyjnego",
          "Outcome of the City Council Meeting at the End of Initial Public Consultation":
            "Wynik posiedzenia Rady Miasta na zakończenie wstępnej konsultacji publicznej",
          "Full List of Comments Initial Public Consultation":
            "Pełna lista komentarzy z wstępnej konsultacji publicznej",
          "Input for Initial Public Consultation (Slides)":
            "Dane wejściowe do wstępnej konsultacji publicznej (slajdy)",
          "Resumé Initial Public Consultation":
            "Podsumowanie wstępnej konsultacji publicznej",
          "Agenda for City Council Meeting After Initial Public Consultation":
            "Porządek obrad Rady Miasta po wstępnej konsultacji publicznej",
          "Agenda for Initial Public Consultation":
            "Porządek obrad wstępnej konsultacji publicznej",
          // - c
          "Screening for Environmental Impact Assessment":
            "Badanie przesiewowe dla oceny wpływu na środowisko",
          "Environmental Impact Assessment Scope":
            "Zakres oceny wpływu na środowisko",
          "Environmental Impact Report": "Raport oceny wpływu na środowisko",
          "Environmental Report": "Raport środowiskowy",
          "Report on Investigation of Bats, Birds and Nature":
            "Raport z badania nietoperzy, ptaków i przyrody",
          "Report on Shadow Pollution": "Raport o zanieczyszczeniach cieniem",
          "Report on Noise Pollution": "Raport o zanieczyszczeniach hałasem",
          Visualisations: "Wizualizacje",
          "Planning Document": "Dokument planistyczny",
          "Zoning Permit / Change of Land Use":
            "Zezwolenie na zmianę przeznaczenia terenu",
          "Agenda for City Council Meeting in Planning Process":
            "Porządek obrad Rady Miasta w procesie planowania",
          "Outcome of the City Council Meeting at the End of Planning Process":
            "Wynik posiedzenia Rady Miasta na zakończenie procesu planowania",
          // - d:
          "Agenda for Public Consultation":
            "Porządek obrad konsultacji publicznej",
          "Input for Public Consultation (Slides)":
            "Dane wejściowe do konsultacji publicznej (slajdy)",
          "Invitation and Project Presentation for Public Consultation":
            "Zaproszenie i prezentacja projektu na konsultacjach publicznych",
          "Invitation Public Consultation":
            "Zaproszenie na konsultacje publiczne",
          "Full List of Comments Public Consultation":
            "Pełna lista komentarzy z konsultacji publicznych",
          "Resumé Public Consultation": "Podsumowanie konsultacji publicznych",
          "§25 Permission Draft": "Projekt decyzji o zezwoleniu §25",
          "Agenda for City Council Meeting After Public Consultation":
            "Porządek obrad Rady Miasta po konsultacjach publicznych",
          "Outcome of the City Council Meeting at the End of Public Consultation":
            "Wynik posiedzenia Rady Miasta na zakończenie konsultacji publicznych",
          // - e:
          "Issue §25 Permission":
            "Wydanie ostatecznej decyzji o zezwoleniu §25",
          "Building Permission (Building Department)":
            "Zezwolenie na budowę (Wydział Budownictwa)",
          // Tasks:
          // - a
          "Upload the Geo File":
            "Prześlij plik geo (.TAB, .MIF, .SHP, .DWG, .GML, .KML eller .DXF)",
          "Upload the Project Presentation": "Prześlij prezentację projektu",
          "Upload the Additional Documents": "Prześlij dodatkowe dokumenty",
          "Review Project Summary": "Przegląd podsumowania projektu",
          "Review All Documents": "Przegląd wszystkich dokumentów",
          // - b:
          "Invitation to Public Consultation and Presentation":
            "Zaproszenie na konsultacje publiczne i prezentacja",
          "Prepare Initial Public Consultation Meeting":
            "Przygotuj spotkanie wstępnej konsultacji publicznej",
          "Create Slides for Public Consultation":
            "Utwórz slajdy do konsultacji publicznych",
          "Prepare Full List of Comments": "Przygotuj pełną listę komentarzy",
          "Upload Comments to Municipality Website":
            "Prześlij komentarze na stronę internetową gminy",
          "Summarize and Respond to Public Consultation":
            "Podsumuj i odpowiedz na konsultacje publiczne",
          "Upload Resumé to Municipality Website":
            "Prześlij podsumowanie na stronę internetową gminy",
          "Create Agenda for City Council Meeting":
            "Utwórz porządek obrad na posiedzenie Rady Miasta",
          "Submit Outcome of the City Council Meeting":
            "Prześlij wynik posiedzenia Rady Miasta",
          // - c
          "Execute Screening": "Przeprowadź badanie przesiewowe",
          "Define Scope of Environmental Impact":
            "Zdefiniuj zakres oceny wpływu na środowisko",
          "Define Scope of Environmental Assessment":
            "Zdefiniuj zakres oceny wpływu na środowisko",
          "Create Environmental Impact Report":
            "Utwórz raport oceny wpływu na środowisko",
          "Submit Document for Report on Investigation of Bats, Birds and Nature":
            "Prześlij dokument dotyczący badania nietoperzy, ptaków i przyrody",
          "Submit Document for Report on Shadow Pollution":
            "Prześlij dokument dotyczący zanieczyszczenia cieniem",
          "Submit Document for Report on Noise Pollution":
            "Prześlij dokument dotyczący zanieczyszczenia hałasem",
          "Submit Document for Visualisations":
            "Prześlij dokument z wizualizacjami",
          "Submit Planning Document": "Prześlij dokument planistyczny",
          "Submit Zoning Permit / Change of Land Use":
            "Prześlij zezwolenie na zmianę przeznaczenia terenu",
          "Review Specific Documents": "Przeglądaj konkretne dokumenty",
          // - d
          "Include a Link to the Relevant Documents for the Public Consultation":
            "Dołącz link do odpowiednich dokumentów do konsultacji publicznej",
          "Prepare Public Consultation": "Przygotuj konsultacje publiczne",
          "Prepare Public Consultation Meeting":
            "Przygotuj spotkanie konsultacyjne z udziałem społeczeństwa",
          "Create Slide Show for Public Consultation":
            "Utwórz pokaz slajdów dla konsultacji publicznych",
          "Upload to Municipality Website":
            "Prześlij na stronę internetową gminy",
          "Start Draft for §25 Permission":
            "Rozpocznij projekt decyzji o zezwoleniu §25",
          // - e
          "Make Final Changes to Documents":
            "Wprowadź ostateczne zmiany w dokumentach",
          "Upload to National Planning Database":
            "Prześlij do krajowej bazy danych planowania",
          "Submit Issue §25 Permission":
            "Prześlij wydanie ostatecznej decyzji o zezwoleniu §25",
          "Submit Outcome of the Appeal Phase":
            "Prześlij wynik fazy odwoławczej",
          /* Data privacy */
          "WindEurope Data Privacy Statement for EasyPermits®":
            "Oświadczenie o ochronie danych osobowych WindEurope dla EasyPermits®",
          General: "Ogólne",
          "This Privacy Statement (“Privacy Statement”) explains how and for what purpose WindEurope will process certain personal data belonging to the authorized users of this tool (”Tool”) when they access and use it":
            "Niniejsze oświadczenie o ochronie prywatności („Oświadczenie o prywatności”) wyjaśnia, w jaki sposób i w jakim celu WindEurope będzie przetwarzać określone dane osobowe należące do upoważnionych użytkowników tego narzędzia („Narzędzie”), gdy uzyskają do niego dostęp i będą z niego korzystać",
          "Why are you interacting with WindEurope in the context of EasyPermits®":
            "Dlaczego kontaktujesz się z WindEurope w kontekście EasyPermits®",
          "WindEurope provides access to this Tool to an entity, organization, or business you are affiliated with (“Your Organization”)":
            "WindEurope zapewnia dostęp do tego Narzędzia podmiotowi, organizacji lub firmie, z którą jesteś powiązany/powiązana („Twoja Organizacja”)",
          "You have provided EasyPermits® and therewith WindEurope with your contact details and were designated as an authorized user of the Tool. If you have any doubts or queries relating to why your personal data has been shared with WindEurope or you have been selected to access and use the Tool, you should contact Your Organization first":
            "Dostarczyłeś/Dostarczyłaś EasyPermits®, a tym samym WindEurope swoje dane kontaktowe i zostałeś/zostałaś wyznaczony/wyznaczona jako upoważniony użytkownik Narzędzia. Jeśli masz jakiekolwiek wątpliwości lub pytania dotyczące tego, dlaczego Twoje dane osobowe zostały udostępnione WindEurope lub dlaczego zostałeś/zostałaś wybrany/wybrana do dostępu i korzystania z Narzędzia, powinieneś/powinnaś najpierw skontaktować się z Twoją Organizacją",
          "Legal Framework": "Ramy prawne",
          "This privacy policy is subject to privacy legislation, i.e.":
            "Niniejsza polityka prywatności podlega przepisom o ochronie prywatności, tj.",
          "the Belgian Data Protection Act of 8th December 1992 on the protection of privacy in relation to the processing of personal data (as amended) (the “Privacy Act”); and/or":
            "belgijskiej ustawie o ochronie prywatności z dnia 8 grudnia 1992 r. w sprawie ochrony prywatności w związku z przetwarzaniem danych osobowych (z późniejszymi zmianami) („Ustawa o ochronie prywatności”); i/lub",
          "Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons regarding the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation) (“GDPR”); and/or":
            "Rozporządzeniu (UE) 2016/679 Parlamentu Europejskiego i Rady z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o ochronie danych) („RODO”); i/lub",
          "All other applicable legislation regarding the protection of privacy and the processing of personal data":
            "Wszystkim innym obowiązującym ustawodawstwie dotyczącym ochrony prywatności i przetwarzania danych osobowych",
          Purpose: "Cel",
          "WindEurope can process personal data in accordance with the Privacy Legislation and this privacy policy to":
            "WindEurope może przetwarzać dane osobowe zgodnie z Przepisami o ochronie prywatności i niniejszą polityką prywatności w celu",
          "Account data / login credentials": "Dane konta / dane logowania",
          "Creating your account in the Tool":
            "Utworzenie Twojego konta w Narzędziu",
          "Enabling you to access and use the Tool":
            "Umożliwienie Ci dostępu i korzystania z Narzędzia",
          "Exercising or defending WindEurope's rights in the context of legal claims/disputes":
            "Realizacja lub obrona praw WindEurope w kontekście roszczeń/sporów prawnych",
          "Legal basis and Applicability": "Podstawa prawna i zastosowanie",
          "WindEurope has the following legal grounds for the processing of personal data":
            "WindEurope ma następujące podstawy prawne do przetwarzania danych osobowych",
          "your free, specific, informed and unambiguous consent to WindEurope, its partners (e.g., subcontractors, partners or contracting parties) to process your personal data. You can give your consent by any means, such as, but not limited to mail, email, an online or offline consent form, a membership form; and/or":
            "Twoja dobrowolna, konkretna, świadoma i jednoznaczna zgoda na przetwarzanie Twoich danych osobowych przez WindEurope, jego partnerów (np. podwykonawców, partnerów lub strony umów) w celu przetwarzania Twoich danych osobowych. Możesz wyrazić zgodę w dowolny sposób, taki jak, ale nie tylko, poczta, e-mail, formularz zgody online lub offline, formularz członkostwa; i/lub",
          "a legal obligation; and/ or": "obowiązek prawny; i/lub",
          "a legitimate interest of WindEurope":
            "uzasadniony interes WindEurope",
          "Providing your personal data to WindEurope, implies that you":
            "Podanie Twoich danych osobowych WindEurope oznacza, że",
          "declare that you are fully informed about this privacy policy and that you fully accept this privacy policy without any reservation; and":
            "oświadczasz, że jesteś w pełni poinformowany/poinformowana o niniejszej polityce prywatności i w pełni ją akceptujesz bez żadnych zastrzeżeń; oraz",
          "give your free, specific, informed and unambiguous consent to WindEurope to process or have processed your personal data in accordance with this privacy policy and the Privacy Legislation":
            "wyrażasz dobrowolną, konkretną, świadomą i jednoznaczną zgodę na przetwarzanie Twoich danych osobowych przez WindEurope zgodnie z niniejszą polityką prywatności i Przepisami o ochronie prywatności",
          "Consequently, you expressly grant WindEurope permission to use your personal data to accomplish the Purpose set out under article 3":
            "W konsekwencji, wyraźnie udzielasz WindEurope pozwolenia na wykorzystanie Twoich danych osobowych w celu realizacji Celu określonego w artykule 3",
          "Personal Data": "Dane osobowe",
          "When you use and access the Tool, the personal data WindEurope can process are":
            "Korzystając i uzyskując dostęp do Narzędzia, WindEurope może przetwarzać następujące dane osobowe",
          "Account data / Login credentials: first name, name, business E-mail, (business) phone number":
            "Dane konta / dane logowania: imię, nazwisko, adres e-mail firmowy, (firmowy) numer telefonu",
          "With agreeing to this privacy policy, you guarantee that the personal data you have provided, are complete and correct":
            "Zgadzając się na niniejszą politykę prywatności, gwarantujesz, że dostarczone przez Ciebie dane osobowe są kompletne i poprawne",
          "Transfer of personal data to third parties":
            "Przekazywanie danych osobowych stronom trzecim",
          "WindEurope guarantees it does not transfer your personal data to third parties, unless":
            "WindEurope gwarantuje, że nie przekazuje Twoich danych osobowych stronom trzecim, chyba że",
          "There is a legal obligation to transfer the personal data":
            "Istnieje prawny obowiązek przekazania danych osobowych",
          "To its partners (e.g., subcontractors, partners or contracting parties) for the achievement of the Purpose":
            "Swoim partnerom (np. podwykonawcom, partnerom lub stronom umów) w celu realizacji Celu",
          "WindEurope has a legitimate interest in doing so":
            "WindEurope ma uzasadniony interes w tym zakresie",
          "Any third-party service providers and professional advisors to whom your personal data is disclosed, are expected and required to protect the confidentiality and security of your personal data and may only use your personal data in compliance with applicable data privacy laws":
            "Od wszelkich dostawców usług stron trzecich i doradców zawodowych, którym ujawniane są Twoje dane osobowe, oczekuje się i wymaga się, aby chronili poufność i bezpieczeństwo Twoich danych osobowych oraz aby mogli korzystać z Twoich danych osobowych wyłącznie zgodnie z obowiązującymi przepisami o ochronie danych",
          "Storage period": "Okres przechowywania",
          "WindEurope stores personal data for as long as is necessary to achieve the Purpose":
            "WindEurope przechowuje dane osobowe tak długo, jak jest to konieczne do osiągnięcia Celu",
          "Your rights": "Twoje prawa",
          "The Privacy Legislation provides you with a number of rights in relation to your personal data. You have the right, free of charge":
            "Przepisy o ochronie prywatności przyznają Ci szereg praw w odniesieniu do Twoich danych osobowych. Masz prawo, bezpłatnie",
          "to have access to and receive a copy of your personal data":
            "uzyskać dostęp do swoich danych osobowych i otrzymać ich kopię",
          "to have your personal data erased in case":
            "żądać usunięcia Twoich danych osobowych w przypadku gdy",
          "your personal data are no longer necessary to achieve the Purpose":
            "Twoje dane osobowe nie są już niezbędne do osiągnięcia Celu",
          "you withdraw your consent and there is no other legal ground for the processing of the personal data":
            "wycofasz swoją zgodę, a nie ma innej podstawy prawnej przetwarzania danych osobowych",
          "you object to the processing of the personal data and there is no other legal ground for the processing of the personal data":
            "wniesiesz sprzeciw wobec przetwarzania danych osobowych, a nie ma innej podstawy prawnej przetwarzania danych osobowych",
          "the personal data have been unlawfully processed":
            "dane osobowe były przetwarzane niezgodnie z prawem",
          "there is a legal obligation to erase the personal data":
            "istnieje prawny obowiązek usunięcia danych osobowych",
          "to have the processing of your personal data restricted":
            "żądać ograniczenia przetwarzania Twoich danych osobowych",
          "to have your personal data transferred to a third party":
            "żądać przekazania Twoich danych osobowych stronie trzeciej",
          "to withdraw your consent pursuant to which WindEurope is allowed to process your personal data":
            "wycofać zgodę, na podstawie której WindEurope jest uprawniony do przetwarzania Twoich danych osobowych",
          "to lodge a complaint with the Commission for the Protection of Privacy (“Privacy Commission”) if you are of the opinion that the processing of your personal data breaches the Privacy Legislation":
            "wnieść skargę do Komisji Ochrony Prywatności („Komisja Ochrony Prywatności”), jeżeli uważasz, że przetwarzanie Twoich danych osobowych narusza Przepisy o ochronie prywatności",
          "In case you want to exercise the above rights, and provided you prove your identity, you can send a written, dated and signed request by mail to":
            "W przypadku chęci skorzystania z powyższych praw i pod warunkiem udowodnienia swojej tożsamości, możesz wysłać pisemny, datowany i podpisany wniosek pocztą do",
          "Eline Post, Director for Human Resource (HR) and Legal, Rue Belliard 40, 1040 Brussels, Belgium":
            "Eline Post, Dyrektor ds. Zasobów Ludzkich (HR) i Prawnych, Rue Belliard 40, 1040 Bruksela, Belgia",
          "or by email to": "lub e-mailem na adres",
          Security: "Bezpieczeństwo",
          "WindEurope commits to take (or have) all reasonable measures (taken) to its best ability to safeguard the protection of personal data through technical safety measures and an appropriate safety policy for its employees. The personal data are stored with WindEurope, or, if applicable, with the processor of the personal data on servers located in Stockholm, Sweden. You acknowledge and accept that the transfer and storage of personal data is never without risk and consequently, the damage that you may suffer from the unlawful use of your personal data by third parties can never be collected from WindEurope":
            "WindEurope zobowiązuje się podjąć (lub zapewnić podjęcie) wszelkich rozsądnych środków (podjętych) w celu zabezpieczenia ochrony danych osobowych poprzez techniczne środki bezpieczeństwa i odpowiednią politykę bezpieczeństwa dla swoich pracowników. Dane osobowe są przechowywane w WindEurope lub, w stosownych przypadkach, u podmiotu przetwarzającego dane osobowe na serwerach zlokalizowanych w Sztokholmie, w Szwecji. Przyjmujesz do wiadomości i akceptujesz, że przekazywanie i przechowywanie danych osobowych nigdy nie jest pozbawione ryzyka, w związku z czym szkoda, którą możesz ponieść w wyniku bezprawnego wykorzystania Twoich danych osobowych przez osoby trzecie, nigdy nie może być dochodzona od WindEurope",
          Liability: "Odpowiedzialność",
          "WindEurope can solely be held accountable for damages which directly result from the processing of your personal data due to a fault or negligence of WindEurope. In any event, WindEurope cannot be held liable: (i) in circumstances of force majeure and/or (ii) for any indirect or consequential damage and/or (iii) for damages that result from errors, faults or negligence by you or third parties. WindEurope's liability cannot exceed 1,000 euro (thousand euro) for breaches regarding this privacy policy and/or Privacy Legislation":
            "WindEurope może zostać pociągnięty do odpowiedzialności wyłącznie za szkody, które bezpośrednio wynikają z przetwarzania Twoich danych osobowych z powodu błędu lub zaniedbania WindEurope. WindEurope nie może być w żadnym wypadku pociągnięty do odpowiedzialności: (i) w sytuacjach siły wyższej i/lub (ii) za jakiekolwiek szkody pośrednie lub wynikowe oraz (iii) za szkody wynikające z błędów, wad lub zaniedbań Twoich lub osób trzecich. Odpowiedzialność WindEurope nie może przekroczyć 1 000 euro (tysiąca euro) za naruszenia dotyczące niniejszej polityki prywatności i/lub Przepisów o ochronie prywatności",
          Severability: "Postanowienia niezależne",
          "If any provision in this privacy policy is deemed to be unlawful or unenforceable, that provision shall be amended by WindEurope and yourself insofar as necessary to make it lawful or enforceable, while retaining the meaning of WindEurope and yourself with respect to that provision as much as possible":
            "Jeżeli jakiekolwiek postanowienie niniejszej polityki prywatności zostanie uznane za niezgodne z prawem lub niewykonalne, postanowienie to zostanie zmienione przez WindEurope i Ciebie w zakresie niezbędnym do jego zgodności z prawem lub wykonalności, przy zachowaniu w jak największym stopniu pierwotnego znaczenia nadanego temu postanowieniu przez WindEurope i Ciebie",
          "Whenever possible, the provisions of this privacy policy shall be interpreted in such a manner as to be valid and enforceable under the applicable law. However, if one or more provisions of this privacy policy are found to be invalid, illegal or unenforceable, in whole or in part, the remainder of that provision and of this privacy policy shall remain in full force and effect as if such invalid, illegal or unenforceable provision had never been contained herein":
            "W miarę możliwości postanowienia niniejszej polityki prywatności powinny być interpretowane w sposób pozwalający na ich ważność i wykonalność na mocy obowiązującego prawa. Jednakże, jeżeli jedno lub więcej postanowień niniejszej polityki prywatności zostanie uznane za nieważne, niezgodne z prawem lub niewykonalne, w całości lub w części, pozostała część tego postanowienia i niniejszej polityki prywatności pozostanie w pełnej mocy i skuteczności, tak jak gdyby takie nieważne, niezgodne z prawem lub niewykonalne postanowienie nigdy nie stanowiło części niniejszej polityki",
          "Applicable law and Competent court": "Prawo właściwe i właściwy sąd",
          "You agree that all disputes between you and WindEurope regarding personal data and privacy issues, are exclusively subject to Belgian law, excluding any conflict of law principles":
            "Zgadzasz się, że wszelkie spory między Tobą a WindEurope dotyczące danych osobowych i kwestii związanych z prywatnością podlegają wyłącznie prawu belgijskiemu, z wyłączeniem wszelkich norm kolizyjnych",
          "Every dispute regarding personal data and privacy issues belongs to the exclusive jurisdiction of the Dutch speaking courts of Brussels, Belgium, excluding any other court":
            "Wszelkie spory dotyczące danych osobowych i kwestii związanych z prywatnością należą do wyłącznej jurysdykcji sądów niderlandzkojęzycznych w Brukseli, Belgia, z wyłączeniem wszelkich innych sądów",
          // Access denied and 404 Not found
          "Access Denied": "Dostęp zabroniony",
          "Sorry, you do not have permission to access this page.":
            "Przepraszam, nie masz uprawnień dostępu do tej strony.",
          "Back to Homepage": "Powrót do strony głównej",
          "404 Not Found": "404 Nie znaleziono",
          "Oops! The page you're trying to access does not exist.":
            "Ups! Strona, do której próbujesz uzyskać dostęp nie istnieje.",
        },
      },
      /* ------------------------ DANISH ------------------------ */
      da: {
        translation: {
          Denmark: "Danmark",
          Poland: "Polen",
          at: "ved",
          // deadlines:
          documents_handover: "Gennemgang af dokumenter",
          public_consultation: "Offentlig høring",
          city_council: "Politisk Behandling",
          "Export Deadlines": "Eksporter milepæle",
          "Project Deadlines": "Projektets milepæle",
          // community:
          "About us": "Om os",
          "Welcome To": "Velkommen Til",
          "Welcome to EasyPermits": "Velkommen til EasyPermits",
          "To get your questions answered about the project":
            "Få svar på dine spørgsmål om projektet",
          "Welcome!": "Velkommen!",
          "welcome!": "velkommen!",
          "I am a Community Member": "Jeg er medlem af lokalsamfundet",
          "I am a Permitting Agent": "Jeg er myndighed (public authority)",
          "I am a Project Developer": "Jeg er projektudvikler",
          "Are you a Permitting Agent? \n Sign-in to use full features of our platform":
            "Er du en myndighed? \n Log ind for at bruge alle funktioner på vores platform",
          "Are you a Developer? \n Sign-in to use full features of our platform":
            "Er du projektudvikler? \n Log ind for at bruge alle funktioner på vores platform",
          "Explore our interactive map to discover wind farms in your area and beyond. Click on markers to find details about existing and proposed projects. Your engagement matters, and here, you can stay informed about the wind energy initiatives shaping your community.":
            "Udforsk vores interaktive kort for at se mere om vindmølleparker i både dit lokalområde samt resten af Danmark. Klik på markører for at se detaljer om både eksisterende og foreslåede projekter. Dit engagement betyder noget, og her kan du holde dig orienteret om de vindmølleprojekter, der berører dig.",
          "If you're a permitting agent or wind farm developer, log in to access powerful tools and resources tailored to your role. Streamline the permitting process, collaborate with stakeholders, and contribute to sustainable wind energy solutions.":
            "Hvis du er myndighed eller vindmølleparkudvikler, skal du logge ind for at få adgang til kraftfulde værktøjer og ressourcer, der er skræddersyet til din rolle. Strømlin godkendelsesprocessen, samarbejd med interessenter og bidrag til bæredygtige vindenergiløsninger.",
          "Search Wind Projects": "Søg Vindprojekter",
          "Search Wind Projects on the Map":
            "Søg efter vindprojekter på kortet",
          "EasyPermits is your digital one-stop shop for the onshore wind projects permitting process.\n\nWith EasyPermits we are focused on streamlining the permitting process by improving information management, increasing transparency of the onshore wind farm permitting process and increasing collaboration between project developers, permitting agents as well as the community members.\n\nEasyPermits is a web-based tool developed through a collaboration between WindEurope, Amazon Web Services and Accenture.":
            "EasyPermits er din digitale one-stop-shop til tilladelsesprocessen for onshore-vindprojekter.\n\nMed EasyPermits fokuserer vi på at strømline tilladelsesprocessen ved at forbedre informationsstyringen, øge gennemsigtigheden i tilladelsesprocessen for onshore-vindparker og øge samarbejdet mellem projektudviklere, tilladelsesagenter samt medlemmer af lokalsamfundet.\n\nEasyPermits er et webbaseret værktøj, der er udviklet i et samarbejde mellem WindEurope, Amazon Web Services og Accenture.",
          "I live in a municipality with planned wind projects. I want to learn more about the upcoming wind projects near me. I want to stay informed and actively engage in discussions about their impact on our community.":
            "Jeg bor i en kommune med planlagte vindprojekter. Jeg vil gerne vide mere om de kommende vindmølleprojekter i nærheden af mig. Jeg ønsker at holde mig informeret og deltage aktivt i diskussioner om deres indflydelse på vores samfund.",
          "I work at the municipality, process permit applications or provide my expertise. I want to use the tool for managing the permit applications from Project Developers and engage my community on upcoming planned projects.":
            "Jeg arbejder hos kommunen og behandler ansøgninger om tilladelser eller stiller min ekspertise til rådighed. Jeg vil gerne bruge værktøjet til at administrere projektansøgninger fra projektudviklere og engagere mit lokalsamfund i kommende planlagte projekter.",
          "I work for a company that wants to develop wind projects. I want to use the tool to submit a request for wind projects and send the necessary documentation to the permitting agents and engage the community on project activities and benefits.":
            "Jeg arbejder for en virksomhed, der ønsker at udvikle vindprojekter. Jeg vil bruge værktøjet til at indsende en anmodning om vindprojekter og sende den nødvendige dokumentation til tilladelsesmyndighederne og engagere lokalsamfundet i projektets aktiviteter og fordele.",
          // Footer contents
          "Explore our Interactive Map. Click on the red markers to discover about the upcoming wind projects in the permitting process in your area and beyond. Want to learn more about wind energy and the permitting process in general? Visit the Frequently Asked Questions section.":
            "Udforsk vores interaktive kort. Klik på de røde markører for at finde ud af mere om de kommende vindprojekter i dit område og andre steder. Vil du vide mere om vindenergi og godkendelsesprocessen generelt? Besøg sektionen Ofte stillede spørgsmål.",
          "Sign in to access the project management capabilities of the platform tailored to your role. Collaborate with project developers and your colleagues in the municipalities and prepare for engaging communities through consultations.":
            "Log ind for at få adgang til platformens projektstyringsfunktioner, der er skræddersyet til din rolle. Samarbejd med tilladelsesagenter og dine kolleger, og forbered dig på at engagere lokalsamfund gennem høringer.",
          "Sign in to access the project management capabilities of the platform tailored to your role. Collaborate with permitting agents and your colleagues and prepare for engaging communities through consultations.":
            "Log ind for at få adgang til platformens projektstyringsfunktioner, der er skræddersyet til din rolle. Samarbejd med tilladelsesagenter og dine kolleger, og forbered dig på at engagere lokalsamfund gennem høringer.",
          // Community: project timeline hover description
          "Project Developer submits new project request and municipality has initial considerations about the project and determines a timeline for the planning work.":
            "Projektudvikleren indsender en ny projektanmodning, og kommunen har indledende overvejelser om projektet og fastlægger en tidsplan for planlægningsarbejdet.",
          "A period of typically two weeks in which citizens are engaged in the project by the municipality. Citizens are informed of the project and the upcoming planning process.":
            "En periode på typisk to uger, hvor borgerne engageres i projektet af kommunen. Borgerne informeres om projektet og den kommende planlægningsproces.",
          "Environmental Report and Environmental Impact Assessment Report are prepared by the Project Developer and the Permitting Agent.":
            "Miljørapporten og miljøkonsekvensvurderingsrapporten udarbejdes af projektudvikleren og den planansvarlige myndighed.",
          "Municipality publishes proposal for municipal planning and associated EIA report. A period of at least eight weeks starts in which citizens and other stakeholders are engaged in the project by the municipality.":
            "Kommunen offentliggør forslag til plangrundlag og tilhørende miljøvurdering. Der afholdes minimum otte ugers høring, hvor borgere og andre interessenter engageres i projektet af kommunen.",
          "The municipality decides on the adoption of the plan proposal.":
            "Kommunalbestyrelsen(City council) træffer beslutning om vedtagelse af planforslaget.",
          // Notifications:
          a_preplanning_closed: "Opstarts fase er lukket.",
          b_initial_consultation_closed:
            "Den første offentlige høring er afsluttet.",
          c_planning_closed: "Planlægningsprocessen er afsluttet.",
          d_final_consultation_closed:
            "Den offentlige høringsfase er afsluttet.",
          e_approval_closed: "Endelig beslutning er afsluttet.",
          task_done: "Ny opgave fuldført.",
          b_review: "Nyt dokument uploadet",
          c_revision: "Dokument skal revideres",
          d_done: "Nyt dokument markeret som afsluttet.",
          document_comment: "Ny dokumentkommentar.",
          project_comment: "Ny projektkommentar.",
          project_summary_editable_false_verified_true:
            "Projektoversigt er bekræftet.",
          project_summary_editable_true_verified_false:
            "Projektoversigt skal revideres.",
          project_summary_editable_false_verified_false:
            "Projektoversigt skal gennemgås.",
          "closed the Public Consultation Stage":
            "afsluttede forplanlægningsfasen",
          "closed the Final Decision Stage and the Project":
            "afsluttede den endelige beslutningsfase og projektet",
          "closed the Planning Stage": "afsluttede planlægningsfasen",
          "closed the Initial Public Consultation Stage":
            "afsluttede den indledende offentlige høring",
          "closed the Preplanning Stage": "afsluttede forplanlægningsfasen",
          "Document Marked as Complete": "Dokument markeret som afsluttet",
          "Task Completed": "Opgave afsluttet",
          "Sent Back to Edit": "Dokument sendes tilbage til redigering",
          "New Document Uploaded": "Nyt dokument uploadet",
          "Project Details": "Projektdetaljer",
          "Edit Project": "Rediger projekt",
          "Project Description": "Projekt Beskrivelse",
          "Detailed description of the proposed wind farm, basic information, company description, etc.":
            "Detaljeret beskrivelse af den foreslåede vindmøllepark, grundlæggende oplysninger, virksomhedsbeskrivelse osv.",
          "Pre agreement with owners": "Forudgående aftale med ejere",
          "Muncipality of the project": "Projektets kommune",
          "Rotor diameter of the wind turbines in meters":
            "Rotordiameter af vindmøllerne i meter",
          "Height of the wind turbines in meters":
            "Maksimal tiphøjde på vindmøllerne i meter",
          "Start date not set yet": "Startdatoen er ikke fastsat endnu",
          "Start date set": "Startdato defineret",
          "Not happened yet": "Ikke sket endnu",
          "Assign Project to Me": "Tildel projekt til mig",
          "Next Document to Submit": "Næste dokument der skal indsendes",
          // Community Page:
          Home: "Hjem",
          Dashboard: "Instrumentbræt",
          Community: "Fællesskab",
          "Search on this page to find more information on projects in the permitting process in the neighbourhood":
            "Søg her for at finde information om projekter under igangværende tilladelsesproces​",
          "Wind Farms in Permitting Process":
            "Vindmølleparker under tilladelsesproces",
          "In Permit Process": "I tilladelsesproces",
          "Appeal Phase": "Ankefase",
          "Appeal Raised": "Appel rejst",
          Planned: "Planlagt",
          "Pins on the map": "Knappenåle på kortet",
          "Show/hide": "Vis/skjul",
          "The projects are still in the process of approval, which does not guarantee their full implementation.":
            "Projekterne er stadig i godkendelsesprocessen, hvilket ikke garanterer deres fulde gennemførelse.",
          Municipality: "Kommune",
          "Sign in": "Log ind",
          "Sign In": "Log Ind",
          "Sign out": "Log ud",
          Support: "Støtte",
          "User Pages": "Brugersider",
          "Data Privacy": "Databeskyttelse",
          "Frequently Asked Questions": "Ofte stillede spørgsmål",
          "Quick Links": "Hurtige links",
          // Instructions
          "How to use this page?": "Sådan bruger du denne side?",
          "Search for an address or postal code (including country) to see projects in the permitting process.":
            "Søg på adreesse, postnummer eller land, for at se projekter i tilladelsprocessen.",
          "The red markers indicate projects in the permitting process.":
            "De røde markører viser lokationen for projekter i tilladelsesprocessen.",
          "Click on the red marker to see more details of that project in the permitting process.":
            "Klik på de røde markører for at se detaljerne om vindmølleparkerne.",
          "Using the map search feature": "Brug af kortsøgningsfunktionen",
          "Enter an address, postcode or country in the search bar.":
            "Indtast en adresse, et postnummer eller et land i søgefeltet.",
          "Select your result in the drop-down list. The map will automatically fly and zoom into that location.":
            "Vælg dit resultat i rullelisten. Kortet vil automatisk zoome ind på det sted.",
          "You should still see the selected result under the seach bar. Click on it again. The map should then automatically zoom out.":
            "Du bør stadig se det valgte resultat under søgelinjen. Klik på det igen. Kortet skulle så automatisk zoome ud.",
          "If the map does not zoom out far enough, please use the +/- navigation tool at the bottom left corner or mouse scroll up/down to control the zoom":
            "Hvis kortet ikke zoomer langt nok ud, kan du bruge navigationsværktøjet +/- i nederste venstre hjørne eller rulle op/ned med musen for at zoome ind og ud",
          Permit: "Tilladelse",
          "Start Date": "Startdato",
          "Download Comments": "Download kommentarer",
          Comments: "Kommentarer",
          "Remove start date": "Fjern startdato",
          "Set Project Start Date": "Indstil projektstartdato",
          "End Date": "Slutdato",
          "Permit Start Date": "Startdato for tilladelsesproces",
          "Permit End Date": "Estimeret slutdato for tilladelsesproces",
          "End date not set yet": "Slutdato endnu ikke fastsat",
          "Re-open project": "Genåbne projekt",
          // Export:
          Property: "Egenskabe",
          Value: "Værdi",
          Unit: "Enhed",
          // Project:
          Country: "Land",
          "Country of the project": "Projektets land",
          wind: "Vindmøllepark",
          solar: "Solenergi",
          meters: "meter",
          hectares: "Hektar",
          "It will supply": "Dette vil give energi til",
          households: "husstande",
          Households: "Husstande",
          "Average number of households to supply energy to":
            "Gennemsnitligt antal husstande, den leverer energi til",
          Details: "Detaljer",
          "Overall Capacity": "Samlet kapacitet",
          "Capacity in megawatts (MW)": "Kapacitet i megawatt (MW)",
          "Please select a country first": "Vælg venligst et land først",
          "Expected Tip Height": "Maksimal tiphøjde på vindmøllerne",
          "Expected Rotor Diameter": "Forventet rotordiameter",
          Size: "Størrelse",
          Generalities: "Generelle forhold",
          "All the above information is provided by the project developer":
            "Alle ovenstående oplysninger er leveret af projektudvikleren",
          "Hover over each stage to see more details":
            "Hold musen over hvert trin for at se flere detaljer",
          "Project Overview": "Oversigt over projektet",
          "More information on municipality webpage":
            "Mere information på kommunens hjemmeside",
          "You can add your feedback on the project here":
            "Du kan give din feedback på projektet her",
          // Project setup
          "Maximum tip height of the wind turbines":
            "Vindmøllernes maksimale højde",
          "Expected height": "Forventet højde",
          "Expected Height": "Forventet højde",
          "Developer Website": "Udvikler hjemmeside",
          // FAQs:
          "Available at": "tilgængelig på",
          Accessed: "Tilgået",
          // - Categories:
          "General Questions": "Generelle spørgsmål",
          "Permitting Process": "Tilladelses Proces",
          "Reference List": "Referenceliste",
          // - Questions:
          "What is a wind farm permitting process?":
            "Hvad er en vindmølleparks tilladelsesproces?",
          "The wind farm permitting process is the series of regulatory steps and approvals required before a project can be constructed and operated. This process varies by according to local laws and can involve several municipal agencies and public review (European Commission, n.d.a).":
            "Processen for tilladelse til vindmølleparker er rækken af regulatoriske skridt og godkendelser, der kræves, før et projekt kan opføres og drives. Denne proces varierer alt efter lokale love og kan involvere flere kommunale instanser og offentlig gennemgang (European Commission, n.d.a).",
          "What permits does a wind farm need?":
            "Hvilke almindelige tilladelser kræves til et projekt?",
          "The type of permits varies based on the size and complexity of the wind farm. Some common permits include: environmental permits, land-use permits, zoning permits, building permits, and electrical interconnection permits (European Commission, n.d.a).":
            "Typen af tilladelser varierer alt efter vindmølleparkens størrelse og kompleksitet. Nogle almindelige tilladelser omfatter: miljøtilladelser, tilladelser til arealanvendelse, zonetilladelser, byggetilladelser og tilladelser til elektrisk sammenkobling (European Commission, n.d.a).",
          "Who is responsible for obtaining these permits?":
            "Hvem er ansvarlig for at indhente disse tilladelser?",
          "The wind farm developer, aka. project developer or just developer, is typically responsible for obtaining the necessary permits, but may work with consultants or legal counsel to navigate the permitting process (European Commission, n.d.b).":
            "Udvikleren af vindmølleparken, også kaldet projektudvikleren eller bare udvikleren, er typisk ansvarlig for at indhente de nødvendige tilladelser, men kan arbejde sammen med konsulenter eller juridiske rådgivere for at navigere i tilladelsesprocessen (European Commission, n.d.b).",
          "How long does the wind farm permitting process typically take?":
            "Hvor lang tid tager tilladelsesprocessen for en vindmøllepark typisk?",
          "The length of the permitting process can vary widely depending on the jurisdiction and complexity of the project. It can range from several months to several years. EU law requires public authorities to complete the process within two years for new wind farms, and within one year for repowered wind farms (when the turbines in an existing wind farm are being replaced with newer ones) (WindEurope, 2023).":
            "Længden af tilladelsesprocessen kan variere meget afhængigt af jurisdiktion og projektets kompleksitet. Den kan variere fra flere måneder til flere år. EU-lovgivningen kræver, at offentlige myndigheder afslutter processen inden for to år for nye vindmølleparker og inden for et år for re-powered vindmølleparker (når møllerne i en eksisterende vindmøllepark udskiftes med nyere møller) (WindEurope, 2023).",
          "How can I stay informed about the wind farm permitting process?":
            "Hvordan kan jeg holde mig informeret om tilladelsesprocessen for vindmølleparker?",
          "Many jurisdictions have public consultation periods and hearings as part of the permitting process. You can find the contact details of the agency in charge of the project you're interested on the project page of the municipality website.":
            "Mange jurisdiktioner har offentlige høringsperioder og høringer som en del af tilladelsesprocessen. Du kan finde kontaktoplysningerne på den myndighed, der har ansvaret for det projekt, du er interesseret i, på projektsiden på kommunens hjemmeside.",
          "What happens after a project is permitted?":
            "Hvad sker der, når et projekt har fået tilladelse?",
          "Once a project is permitted, construction can begin. The developer will also need to obtain additional permits and approvals as construction progresses, such as building inspections and connections to the transmission networks (Danish Energy Agency, 2022).":
            "Når et projekt har fået tilladelse, kan byggeriet begynde. Bygherren skal også indhente yderligere tilladelser og godkendelser, efterhånden som byggeriet skrider frem, f.eks. bygningsinspektioner og forbindelser til transmissionsnettene (Energistyrelsen, 2022).",
          "Why is it so difficult to get permits for wind farms in Europe?":
            "Hvorfor er det så svært at få tilladelser til vindmølleparker i Europa?",
          "Complex rules and slow procedures are the main reasons why it's difficult to get permits for wind farms. Wind farms face several spatial planning constraints, such as minimum distance to housing, height restrictions and exclusion zones around radar installations, or in specific areas. Additionally, numerous administrative authorities are involved, including at national, regional and municipal level (WindEurope, 2020).":
            "Der er primært to årsager til dette, herunder komplekse regler og langsomme procedurer. Der er flere begrænsninger i den fysiske planlægning, såsom minimumsafstand til boliger, højdebegrænsninger og eksklusionszoner omkring radarinstallationer eller i Natura 2000-områder. Derudover er mange administrative myndigheder involveret, herunder på nationalt, regionalt og kommunalt niveau (WindEurope, 2020).",
          "What requirements do wind farm developers account for in the permitting process?":
            "Hvilke krav tager projektudviklere højde for i tilladelsesprocessen?",
          "Project developers need to account for several requirements when they consider building a wind farm on a specific site: wind speeds, grid connection supporting infrastructure such as roads, impact of the wind farm on surrounding natural ecosystems and communities, the right turbine technology for the site etc. (Domaingue, J.-C. and Jain, P., 2022).":
            "Projektudviklere skal tage højde for flere forhold, når de overvejer at bygge på et bestemt sted, såsom vindhastigheder, netforbindelser og understøttende infrastruktur (f.eks. veje), indvirkning på de omkringliggende naturlige økosystemer og samfund, den rigtige vindmølleteknologi til stedet etc. (Domaingue, J.-C. and Jain, P., 2022).",
          "How can developers seek participation from local residents and other stakeholders during the permitting process?":
            "Hvordan kan bygherrer søge engagement fra lokale beboere og andre interessenter under tilladelsesprocessen?",
          "The wind industry follows three guiding principles as a model of best practice for good community engagement:":
            "Vindindustrien følger tre ledende principper som en model for bedste praksis for god lokalsamfundsengagement:",
          "early, transparent and comprehensive information and communication;":
            "tidlig, gennemsigtig og omfattende information og kommunikation;",
          "direct engagement of key local actors and activation of the local economy; and":
            "direkte engagement af nøgleaktører lokalt og aktivering af den lokale økonomi; og",
          "highlighting how communities will benefit from the investments being made. These principles apply at every stage of a project, from site selection and pre-application, planning, construction to operation and decommissioning. (WindEurope, 2020)":
            "fremhævelse af hvordan lokalsamfundene vil drage fordel af de investeringer, der foretages. Disse principper gælder i alle faser af et projekt, fra valg af placering og foransøgning, planlægning, opførelse til drift og nedlukning. (WindEurope, 2020)",
          "How is EasyPermits simplifying the permit application process?":
            "Hvordan forenkler EasyPermits ansøgningsprocessen for tilladelser?",
          "The EasyPermits application makes it possible for permitting authorities, wind farm developers and community members to access all the information about a project in an easy, centralised way. The application provides permitting agents with a dedicated dashboard giving them a concise snapshot of each permit application, encompassing critical tasks, notifications, project summaries, associated activities, and productivity KPIs.":
            "EasyPermits-applikationen gør det muligt for tilladelsesmyndigheder, vindmølleparkudviklere og medlemmer af lokalsamfundet at få adgang til alle oplysninger om et projekt på en nem, centraliseret måde. Applikationen giver tilladelsesagenter et dedikeret dashboard, der giver dem et kortfattet øjebliksbillede af hver tilladelsesansøgning, der omfatter kritiske opgaver, meddelelser, projektresuméer, tilknyttede aktiviteter og produktivitets-KPI'er.",
          "How does EasyPermits benefit project developers and community members?":
            "Hvordan kan sagsbehandlere bruge EasyPermits til løbende tilladelser?",
          "EasyPermits enables permitting agents to facilitate transparency regarding the project process to developers, and the project's potential impact to the local community.":
            "EasyPermits gør det muligt for tilladelsesmyndighederne at skabe gennemsigtighed i projektprocessen for udviklere og projektets potentielle indvirkning på lokalsamfundet.",
          // Data Privacy popup
          "Please note that the personal data in this system cannot be used for other purposes than the purposes of this tool/system. If you plan to do so, seek advice from your legal department regarding required steps.":
            "Vær opmærksom på, at de personlige oplysninger i dette system ikke kan bruges til andre formål end formålene med dette værktøj/system. Hvis du planlægger at gøre det, bør du søge rådgivning fra din juridiske afdeling om de nødvendige skridt.",
          "WindEurope can process my personal data in accordance with this":
            "WindEurope kan behandle mine personlige oplysninger i overensstemmelse med dette",
          "I agree": "Jeg er enig",
          "I do not agree": "Jeg er ikke enig",
          "For more information, please see":
            "For mere information, se venligst",
          // Dashboard:
          "No projects found": "Ingen projekter fundet",
          "Overdue Tasks": "Forfaldne opgaver",
          "Welcome Back": "Velkommen tilbage",
          Productivity: "Produktivitet",
          Notifications: "Notifikationer",
          "No new notifications": "Ingen nye notifikationer",
          "Take a look at your achievements": "Tag et kig på dine præstationer",
          "Ongoing projects": "Igangværende projekter",
          "Delayed projects": "Forsinkede projekter",
          "Your open tasks": "Dine åbne opgaver",
          "Overdue tasks overall": "Forfaldne opgaver generelt",
          "Saving changes": "Gemmer ændringer",
          "Please wait": "Vent venligst",
          "Fetching data": "Indlæsning af data",
          "My Tasks": "Mine opgaver",
          "This Week": "Denne uge",
          "Next Week": "Næste uge",
          Overall: "Samlet set",
          "Task Name": "Opgavenavn",
          "Project Name": "Projekt navn",
          "Project Phase": "Projektfase",
          "Due Date": "Afleveringsdato",
          "Days to Finish": "Dage til slut",
          Delayed: "Forsinket",
          "Loading resources": "Indlæser ressourcer",
          Loading: "Indlæser",
          "No resources": "Ingen ressourcer",
          "No resources to display": "Ingen ressourcer at vise",
          "Deadline not set": "Frist endnu ikke fastsat",
          "Pre-planning": "Opstartsfase",
          "Documents Review": "Gennemgang af dokumenter",
          "City Council Meeting": "Politisk Behandling",
          "Initial Public Consultation": "Idéhøring",
          "Public Consultation": "Offentlig høring",
          "Planning Process": "Planlægningsproces",
          "Final Public Consultation": "Afsluttende offentlig høring",
          "Final Approval": "Endelige vedtagelse",
          Appeal: "klage",
          "Start date will be set once all the documents and have been reviewed":
            "Startdatoen vil blive fastsat, når alle dokumenter er blevet gennemgået",
          "Project will start on": "Projektet starter den",
          "You are in the Pre-Planning phase": "Du er i opstartsfasen",
          Mon: "Mandag",
          Tue: "Tirsdag",
          Wed: "Onsdag",
          Thu: "Torsdag",
          Fri: "Fredag",
          Sat: "Lørdag",
          Sun: "Søndag",
          Oct: "Okt",
          May: "Maj",
          "My Projects": "Mine projekter",
          "To Be Reviewed": "Skal Gennemgås",
          "All Projects in Municipality": "Alle projekter i kommunen",
          "Unassigned Projects": "Utildelte projekter",
          "Are you sure you want to close this project?":
            "Er du sikker på, at du vil afslutte projektet?",
          "Closed Projects": "afslutte projekter",
          "Report Appeal": "Rapport klage",
          "Are you sure you want to report appeal this project?":
            "Er du sikker på, at du vil rapportere om dette projekt?",
          "You have 4 weeks to report an appeal. After this, the button  will be disabled.":
            "Du har 4 uger til at rapportere en appel. Herefter vil knappen være deaktiveret.",
          "Share Appeal Outcome": "Resultat af aktieappel",
          "Download everything": "Download alt",
          "Download all project files": "Download alle projektfiler",
          "Please choose what you would like to download":
            "Vælg venligst, hvad du vil downloade",
          "All project comments": "Alle projektkommentarer",
          "All document comments": "Alle dokumentkommentarer",
          "All document attachments (all versions)":
            "Alle vedhæftede dokumenter (alle versioner)",
          "No attachments found": "Ingen vedhæftninger fundet",
          // Project Dashboard:
          "Updating assignee. Please do not close or refresh this page":
            "Opdatering af modtager. Undlad venligst at lukke eller opdatere denne side",
          "Appeal is overruled": "Klagen er afvist",
          "Appeal is sustained": "Klagen er imødekommet",
          "This will also cancel the project":
            "Dette vil også annullere projektet",
          "Community page": "Gå til offentlig side",
          "Project website": "Gå til projektets websted",
          "Edit website": "Rediger websted",
          "Add project website": "Tilføj projektets hjemmeside",
          "added a comment": "tilføjet en kommentar",
          "commented on the document": "kommenterede dokumentet",
          "Take a look at your progress": "Tag et kig på dine fremskridt",
          "In Revision": "Bliver Revideret",
          "Overview of the project including timeline and key highlights":
            "Oversigt over projektet inklusive tidslinje og vigtige milepæle",
          "Created on": "Oprettet den",
          by: "af",
          Update: "Opdater",
          Set: "Sæt",
          "Open tasks": "Åbne opgaver",
          Tasks: "Opgaver",
          Documents: "Dokumenter",
          Document: "Dokument",
          "Owner as role": "Ejer som rolle",
          "Delete version": "Fjerne Version",
          "in delay": "med forsinkelse",
          Collaborators: "Samarbejdspartnere",
          "Once you have reviewed all the documents and the Summary, you can set the start date for the project":
            "Når du har gennemgået alle dokumenter og resuméet, kan du fastsætte startdatoen for projektet",
          "Once the Permitting Agent have reviewed and validated all the documents and Summary, they will set a start date for the project":
            "Når myndigheden har gennemgået og valideret alle dokumenter og resumé, vil de sætte en startdato for projektet",
          "Update deadlines": "Opdater frister",
          Cancel: "Anullér",
          "Save Changes": "Gem ændringer",
          "Final Appeal": "Endelig appel",
          "Edit Milestones Deadlines": "Rediger frister for milepæle",
          "Current stage": "Nuværende fase",
          "Last stage": "Sidste etape",
          "Manage team": "Administrer team",
          "Manage Team": "Administrer team",
          "Update additional collaborators for your project":
            "Tilføj flere samarbejdspartnere til dit projekt",
          "Start Over": "Gå en fase tilbage",
          "Close Stage": "Afslut fasen",
          "Skip Stage": "Spring scenen over",
          "Export Summary": "Eksporter oversigt",
          "Assigned to": "Tildelt",
          "Project Dashboard": "Projekt Dashboard",
          "No open documents": "Ingen åbne dokumenter",
          "Related Documents": "Relaterede dokumenter",
          "Related documents": "Relaterede dokumenter",
          "Choose documents": "Vælg dokumenter",
          "Not complete": "ikke færdigt",
          document: "dokument",
          documents: "dokumenter",
          "All done!": "Helt færdig!",
          "No open tasks to display": "Ingen åbne opgaver at vise",
          "open documents": "åbne dokumenter",
          "Add New Task": "Tilføj ny opgave",
          "Re-open Task": "Genåbn opgave",
          Description: "Beskrivelse",
          Stage: "Fase",
          "Due date": "Afleveringsdato",
          "Edit Task": "Rediger opgave",
          "Edit task": "Rediger opgave",
          "Mark as complete": "Markér som fuldført",
          "Linked Documents": "Tilknyttede dokumenter",
          Priority: "Prioritet",
          "View all tasks": "Se alle opgaver",
          "View all documents": "Se alle dokumenter",
          "Ready for upload": "Klar til upload",
          "Sent for review": "Sendt til gennemgang",
          "Revision needed": "Revision nødvendig",
          Completed: "Afsluttet",
          // Activities
          Activities: "Aktiviteter",
          History: "Historie",
          "Project Logs": "Projektlog",
          "Project Request": "Projektanmodning",
          "sent a project request": "sendt en projektanmodning",
          "started the project": "startede projektet",
          "Project Comments": "Projektkommentarer",
          "Document Comments": "Dokumentkommentarer",
          "Download History": "Download historie",
          "Download Project Logs": "Download projektlog",
          "Download Project Comments": "Download projektkommentarer",
          Maybe: "Måske",
          "Close Project": "Luk projekt",
          "Close project": "Luk projekt",
          "Project Stopped": "Projekt stoppet",
          "Project Completed": "Projekt afsluttet",
          "Mandatory Documents & Tasks": "Obligatoriske dokumenter og opgaver",
          "Optional Documents & Tasks": "Valgfrie dokumenter og opgaver",
          "All tasks have been completed": "Alle opgaver er udført",
          "There are still open mandatory documents":
            "Der er stadig et par obligatoriske dokumenter",
          "There are still open mandatory tasks":
            "Der er stadig et par obligatoriske opgaver",
          "There are still open optional documents":
            "Der er stadig åbne valgfri dokumenter",
          "There are still open optional tasks":
            "Der er stadig åbne valgfrie opgaver",
          "All items in the checklist must be completed before you can close this stage":
            "Alle punkter på tjeklisten skal være udfyldt, før du kan afslutte denne fase",
          "You still have open optional documents and tasks":
            "Du har stadig åbne valgfrie dokumenter og opgaver",
          "Once you close this stage, all relevant information will be published to the Community page":
            "Når du lukker afslutter fase, vil alle relevante oplysninger blive offentliggjort på den offentlige side",
          Confirm: "Bekræfte",
          "All mandatory documents are uploaded and reviewed":
            "Alle nødvendige dokumenter uploades og gennemgås",
          "Are you sure you want to close this stage?":
            "Er du sikker på, at du vil afslutte fasen?",
          "Are you sure you want to skip this stage?":
            "Er du sikker på, at du vil springe denne fase over?",
          "Before proceeding to the next stage, please ensure the following checklist is completed:":
            "Før du fortsætter til næste trin, skal du sikre dig, at følgende tjekliste er udfyldt:",
          "City Council Meeting Outcome": "Resultat af byrådsmøde",
          "Please provide the outcome of the City Council meeting for this stage":
            "Angiv venligst resultatet af byrådsmødet for denne fase",
          "Please provide the outcome of the City Council meeting for the last stage":
            "Angiv venligst resultatet af byrådsmødet for sidste etape",
          "You can close this stage and proceed to the next one":
            "Du kan afslutte denne fase og fortsætte til den næste",
          "You can close the last stage and close the project":
            "Du kan afslutte den sidste fase og afslutte projektet",
          "Further adjustments required. You may need to stay in this stage, re-open and modify the documents accordingly":
            "Yderligere justeringer påkrævet. Du skal muligvis forblive i denne fase, genåbne og ændre dokumenterne i overensstemmelse hermed",
          "The project needs to be stopped and closed at this stage":
            "Projektet skal stoppes og afsluttes på nuværende tidspunkt",
          "Share Outcome": "Del Resultat",
          "View all details": "Se alle detaljer",
          "Project Summary": "Projektoversigt",
          "Permit Process Start Date": "Tilladelsesproces startdato",
          Date: "Dato",
          Author: "Ophavsmand",
          Comment: "Kommentar",
          "Permit Process End Date": "Tilladelsesproces slutdato",
          "Permitting Agent": "Tilladelses agent",
          "Project Developer": "Projektudvikler",
          "Number of Turbines": "Antal turbiner",
          "Size of project": "Størrelse på projekt",
          "Relation to Neighbors": "Relation til naboer",
          "Pre-agreement with Owners": "Forhåndsaftale med ejere",
          "Location - Latitude": "Placering - Breddegrad",
          "Location - Longitude": "Placering - Længdegrad",
          "Project Type": "Projekttype",
          "Additional technical details": "Yderligere tekniske detaljer",
          "Potential number of households it supplies energy to":
            "Potentielt antal husholdninger, der forsynes med energi",
          "Date not set yet": "Dato endnu ikke fastsat",
          "All Project Documents": "Alle projektdokumenter",
          "All Project Tasks": "Alle projektopgaver",
          "Project Documents": "Projektdokumenter",
          "Project Tasks": "Projekt opgaver",
          "Add New Document": "Tilføj nyt dokument",
          "No comments available": "Ingen tilgængelige kommentarer",
          "No documents available": "Ingen tilgængelige dokumenter",
          "No tasks available": "Ingen tilgængelige opgaver",
          "No versions available": "Ingen versioner tilgængelige",
          "Add a comment": "Tilføj en kommentar",
          "Enter comments here...": "Skriv kommentarer her...",
          "Add comment": "Tilføj kommentar",
          "Delete comment": "Fjern kommentar",
          "Confirm action": "Bekræft handling",
          "Are you sure you want to delete?":
            "Er du sikker på, at du vil slette?",
          "Send Back to Edit": "Send tilbage til redigering",
          Verify: "Bekræft",
          "Not yet assigned": "Endnu ikke tildelt",
          Verified: "Verificeret",
          "Setup Workflow": "Opsætning af arbejdsgang",
          // Workflow:
          "Permitting Workflow Setup": "Tilladelse af Workflow Setup",
          "Set up an individual workflow as standardised template for your own work":
            "Opsæt en individuel arbejdsgang som standardiseret skabelon til dit eget arbejde",
          "Select Template": "Vælg Skabelon",
          "Create my own template": "Lav min egen skabelon",
          "Coming soon": "Kommer snart",
          "Next: Set Required Documents": "Næste: Indstil påkrævede dokumenter",
          "Required Documents List": "Liste over nødvendige dokumenter",
          "We set the documents of the request that are important to you. Use the checklist icon on the left to design your individual workflow.":
            "Vi angiver de dokumenter for anmodningen, der er vigtige for dig. Brug tjeklisteikonet til venstre til at designe din individuelle arbejdsgang.",
          "Back to Templates": "Tilbage til skabeloner",
          "Next: Set Tasks": "Næste: Indstil opgaver",
          "Set Tasks": "Indstil opgaver",
          "We set the tasks of the request that are important to you. Use the checklist icon on the left to design your individual workflow.":
            "Vi indstiller de opgaver for anmodningen, der er vigtige for dig. Brug tjeklisteikonet til venstre til at designe dit individuelle arbejdsflow.",
          "No stage selected": "Ingen fase valgt",
          "Back to Documents": "Tilbage til Dokumenter",
          "Next: Set Milestones Deadlines":
            "Næste: Indstil frister for milepæle",
          "Set Milestone Deadlines": "Indstil frister for milepæle",
          "We have set you an approximate duration for the different periods, which you can change if you wish.":
            "Vi har sat dig en omtrentlig varighed for de forskellige perioder, som du kan ændre, hvis du ønsker det.",
          "Set deadlines": "Sæt frister",
          "Back to Tasks": "Tilbage til Opgaver",
          "Next: Set Collaborators": "Næste: Indstil samarbejdspartnere",
          "Set Collaborators": "Indstil samarbejdspartnere",
          "As the concluding stage, you have the option to assign collaborators to specific roles within the project. This assignment can be adjusted at a later point, and new team members may also be added if needed.":
            "Som den afsluttende fase har du mulighed for at tildele specifikke roller til samarbejdspartnere inden for projektet. Disse roller kan justeres på et senere tidspunkt, og nye teammedlemmer kan også tilføjes, hvis det er nødvendigt.",
          "Choose developer(s)": "Vælg udvikler(e)",
          "Select developer(s)": "Vælg udvikler(e)",
          "Choose agents(s)": "Vælg agent(er)",
          "Select agents(s)": "Vælg agent(er)",
          "SME Reviewer": "Fagekspert anmelder",
          "Choose SME(s)": "Vælg Fagekspert(er)",
          "Select SME(s)": "Vælg Fagekspert(er)",
          "Back to Milestones deadlines": "Tilbage til frister for milepæle",
          "Value must be between": "Værdien skal være mellem",
          and: "og",
          "Select municipalities": "Vælg kommuner",
          "Are you sure you want to submit?":
            "Er du sikker på, at du vil indsende?",
          "A notification will be sent to a Permitting Agent with your project submission request. You can get in touch with the Permitting Agent once an Agent is assigned on the project.":
            "En meddelelse vil blive sendt til en myndighed med din anmodning om projektindsendelse. Du kan komme i kontakt med en sagsbehandler, når en sagsbehandler er tilknyttet projektet.",
          "Note: All the additional attachments and supporting documents can be conveniently added on the next page after submitting this form.":
            "Bemærk: Alle yderligere vedhæftede filer og understøttende dokumenter kan nemt tilføjes på næste side efter indsendelse af denne formular.",
          "Confirm Submission": "Bekræft Indsendelse",
          // Project Setup:
          "It seems like it's your first time here.":
            "Det lader til at det er første gang du er her.",
          "Our app is designed to streamline and simplify the permitting process for wind farm developers like you. As you begin, please proceed by submitting the Project Setup Form, where you can provide essential information about your project application.":
            "Vores application er designet til at strømline og forenkle tilladelsesprocessen for vindpark udviklere som dig. Til at begynde med bedes du udfylde projektopsætnings formularen, hvor du kan udfylde essentiel information om lige netop din projekt ansøgning.",
          "This form will help you communicate all the basic, important details required by the permitting agent. Our goal is to ensure a seamless and efficient process, facilitating clear communication and collaboration between you and the permitting authorities.":
            "Denne formular vil hjælpe dig med at kommunikere alle de basale, vigtige detaljer som er nødvendige for tilladelsesagenten. Vores mål er at sikre en klar og efficient proces, samt facilitere klar kommunikation og samarbejde mellem dig og den givne tilladelsesmyndighed.",
          "Thank you for choosing EasyPermits. Let's work together to create a greener and sustainable future!":
            "Tak fordi du har valgt EasyPermits. Lad os sammen skabe en grønnere og mere bæredygtig fremtid!",
          Organisation: "Organisation",
          "Benefits for the Community": "Fordele for Samfundet",
          "Provide information regarding community benefits. You can also add a link to your project website. Link must start with http(s)://":
            "Giv oplysninger om fordele for lokalsamfundet. Du kan også tilføje et link til dit projekts hjemmeside. Linket skal starte med http(s)://",
          "Recommendation to also add the community testimonial video on your project website.":
            "Anbefaling om også at tilføje en video med en udtalelse fra lokalsamfundet på jeres projekthjemmeside.",
          "Name of the organisation": "Organisationens navn",
          "Submit New Project": "Opret nyt projekt",
          "Project name": "Projekt navn",
          "Name of the project": "Projektets navn",
          "Latitude location of the project on the map":
            "Projektets breddegrad på kortet",
          "Longitude location of the project on the map":
            "Projektets længdegrad på kortet",
          "Overall capacity": "Samlet kapacitet",
          "Municipality of the project": "Projektets kommune",
          "Go Back a Stage": "Gå et skridt tilbage",
          "Size of project in hectares": "Projektets størrelse i hektar",
          "Detailed description of the proposed project, basic information, company description, etc.":
            "Detaljeret beskrivelse af det foreslåede projekt, basisoplysninger, virksomhedsbeskrivelse mv.",
          "Please provide essential information required for the permitting process. By completing this form, you'll offer a comprehensive overview of your proposed project. We'll gather vital details such as project location, project type and community engagement plans. This initial step will pave the way for a smooth and efficient permitting process.":
            "Angiv de nødvendige oplysninger til godkendelsesprocessen. Ved at udfylde denne formular giver du et omfattende overblik over dit foreslåede projekt. Vi samler vigtige detaljer såsom projektplacering, projekttype og planer for samfundsengagement. Dette indledende trin vil bane vejen for en let og effektiv tilladelsesproces.",
          "Specify the type of the project": "Angiv projekttype",
          "Total number of turbines": "Samlet antal møller",
          "Expected rotor diameter": "Forventet rotordiameter",
          "Enter description here...": "Indtast beskrivelse her...",
          "Is there a pre-agreement with owners?":
            "Er der en forhåndsaftale med ejere?",
          "Information about community engagement and the current relationship to neighbours":
            "Information om samfundsengagement og det aktuelle forhold til naboer",
          "Enter information here...": "Indtast oplysninger her...",
          "Relation to neighbors": "Relation til naboer",
          "Do you have a written document?": "Har du et skriftligt dokument?",
          "Additional information": "Yderligere Information",
          "Currently viewing": "Ser i øjeblikket",
          Yes: "Ja",
          No: "Nej",
          no: "Ingen",
          "Please provide any additional technical information relevant to the project eg. design, configuration, known issues, nature, etc.":
            "Angiv venligst yderligere teknisk information, der er relevant for projektet, f.eks. design, konfiguration, kendte udfordringer, natur mv.",
          "Enter additional info here...":
            "Indtast yderligere information her...",
          "Note: All the additional attachments and supporting documents can be conveniently added on the next page after submitting this form to ensure a seamless submission process.":
            "Bemærk: Alle yderligere vedhæftede filer og understøttende dokumenter kan nemt tilføjes på næste side efter indsendelse af denne formular for at sikre en problemfri indsendelsesproces.",
          "Fill out the below form and submit the form to send it to the Permitting Agent":
            "Udfyld nedenstående formular og indsend formularen for at sende den til myndigheden",
          "Project Setup Request": "Anmodning om projektopsætning",
          // Stages:
          a_preplanning: "Forudgående Planlægning",
          b_initial_consultation: "Idéhøring",
          c_planning: "Planlægningsproces",
          d_final_consultation: "Offentlig Høring",
          e_approval: "Endelig Godkendelse",
          // roles
          agents: "Myndighed",
          developers: "Projektudvikler",
          // Workflow:
          "Use predefined template": "Brug en prædefineret skabelon",
          // Documents:
          Version: "Version",
          "Set a deadline for your document": "Sæt en frist for dit dokument",
          "Related Tasks": "Relaterede opgaver",
          "New Task Created": "Ny Opgave Oprettet",
          "New Document Created": "Ny Dokument Oprettet",
          "Submit Outcome of the Appeal phase":
            "Indsend resultatet af appelfasen",
          "Download Document Comments": "Download Dokumentkommentarer",
          Section: "Afsnit",
          Page: "Side",
          "Document Name": "Dokumentnavn",
          "Set a name for your document": "Navngiv dit dokument",
          "Please compress all additional documents into a ZIP file":
            "Komprimer venligst alle yderligere dokumenter til en ZIP-fil",
          "The geo file in the right format": "Geo-filen i det rigtige format",
          "The geo file require to be in the right format":
            "Geo-filen skal være i det rigtige format",
          "You can send the project summary back to edit or verify it":
            "Du kan sende projektresuméet tilbage for at redigere eller bekræfte det",
          "Set a stage for the document": "Sæt en for dokumentet",
          "Geo file in the right format, project presentation less than 20 pages, and additional documents in a ZIP format":
            "Geo-fil i det rigtige format, projektpræsentation på mindre end 20 sider og yderligere dokumenter i ZIP-format",
          "Select a user to assign your document to":
            "Vælg en bruger at tildele dit dokument til",
          "None assigned": "Ingen tildelt",
          "None selected": "Ingen valgt",
          "Not related to any tasks": "Ikke knyttet til nogen opgaver",
          "Document is Mandatory": "Dokument er obligatorisk",
          "Task is Mandatory": "Opgave er obligatorisk",
          "Publish document to the Community page when stage ends":
            "Offentliggør dokument på Fællesskabssiden, når etape slutter",
          "Select task(s) that belong to this document":
            "Vælg opgave(r), der hører til dette dokument",
          "Choose tasks": "Vælg opgaver",
          "Linked Tasks": "sammenhængende opgaver",
          "Edit Document": "Rediger dokument",
          "Re-Open Document": "Genåbn dokument",
          "Please do not exceed 20 pages": "Må ikke overstige 20 sider",
          Actions: "Handlinger",
          Status: "Status",
          "Drop files to upload": "Drop files to upload",
          "Upload files": "Upload filer",
          tasks: "opgaver",
          task: "opgave",
          "Linked to": "Knyttet til",
          "Version History": "Versionshistorik",
          Versions: "Versioner",
          Chapter: "Kapitel",
          "Enter a name here...": "Indtast et navn her...",
          "Enter a comment here...": "Skriv en kommentar her...",
          optional: "valgfri",
          Optional: "Valgfri",
          "Page Number": "Sidenummer",
          "Download All Comments": "Download Alle Kommentarer",
          "Download All Attachments": "Download Alle Vedhæftninger",
          // - a
          "The Geo File":
            "Kortfiler (.TAB, .MIF, .SHP, .DWG, .GML, .KML eller .DXF)",
          "Project Presentation": "Projekt præsentation",
          "Additional Documents": "Supplernede oplysninger",
          // - b
          "Invitation and Project Presentation for Initial Public Consultation":
            "Orientering om fordebat/ fordebatsmateriale",
          "Outcome of the City Council Meeting at the End of the Final Decision Phase":
            "Resultat af byrådsmøde ved afslutningen af den endelige beslutningsfase",
          "Outcome of the City Council Meeting at the End of Initial Public Consultation":
            "Resultat af byrådsmøde ved afslutningen af den indledende offentlige høring",
          "Full List of Comments Initial Public Consultation":
            "Opsamling høringssvar",
          "Input for Initial Public Consultation (Slides)": "Oplæg borgermøde",
          "Resumé Initial Public Consultation":
            "Resumé af høringssvar inkl. Forvaltningens bemærkninger",
          "Agenda for City Council Meeting After Initial Public Consultation":
            "Dagsordenspunkt politisk behandling",
          "Agenda for Initial Public Consultation": "Oplæg borgermøde",
          // - c
          "Screening for Environmental Impact Assessment":
            "Screening for miljøkonsekvensvurdering",
          "Environmental Impact Assessment Scope": "Afgrænsningsnotat",
          "Environmental Impact Report": "Miljøkonsekvensrapport",
          "Environmental Report": "Miljørapport",
          "Report on Investigation of Bats, Birds and Nature":
            "Undersøgelse af  påvirkning natur/ dyreliv (evt. Natura 2000 konsekvensvurdering)",
          "Report on Shadow Pollution": "Redegørelse skyggepåvirkning",
          "Report on Noise Pollution": "Støj redegørelse",
          Visualisations: "Visualiseringer",
          "Planning Document": "Lokalplan",
          "Zoning Permit / Change of Land Use": "Kommuneplantillæg",
          "Agenda for City Council Meeting in Planning Process":
            "Dagsordenspunkt politisk behandling",
          "Outcome of the City Council Meeting at the End of Planning Process":
            "Udfald af byrådsmødet ved planlægningsprocessens afslutning",
          // - d:
          "Agenda for Public Consultation": "Oplæg borgermøde",
          "Input for Public Consultation (Slides)": "Oplæg borgermøde",
          "Invitation and Project Presentation for Public Consultation":
            "Orienteringsbrev ift. Offentlig høring",
          "Invitation Public Consultation":
            "Orienteringsbrev ift. Offentlig høring",
          "Full List of Comments Public Consultation": "Opsamling høringssvar",
          "Resumé Public Consultation":
            "Resumé høringssvar inkl. Forvaltningens bemærkninger",
          "§25 Permission Draft": "Udkast §25-tilladelse",
          "Agenda for City Council Meeting After Public Consultation":
            "Dagsordenspunkt politisk behandling",
          "Outcome of the City Council Meeting at the End of Public Consultation":
            "Resultat af byrådsmøde ved afslutning af offentlig høring",
          // - e:
          "Issue §25 Permission": "Udstedelse af endelig §25-tilladelse",
          "Building Permission (Building Department)":
            "Byggetilladelse (Byggeafdeling)",
          // Tasks:
          // - a
          "Upload the Geo File":
            "Upload Kortfiler (.TAB, .MIF, .SHP, .DWG, .GML, .KML eller .DXF)",
          "Upload the Project Presentation": "Upload projekt præsentation",
          "Upload the Additional Documents":
            "Upload de Supplernede oplysninger",
          "Review Project Summary": "Gennemgå projektoversigt",
          "Review All Documents": "Gennemgå alle dokumenter",
          // - b:
          "Invitation to Public Consultation and Presentation":
            "Orientering om fordebat / fordebatsmateriale",
          "Prepare Initial Public Consultation Meeting":
            "Forbered oplæg borgermøde",
          "Create Slides for Public Consultation":
            "Opret dias til oplæg borgermøde",
          "Prepare Full List of Comments": "Forbered opsamling høringssvar",
          "Upload Comments to Municipality Website":
            "Upload opsamling høringssvar til kommunens websted",
          "Summarize and Respond to Public Consultation":
            "Opsummer og svar på borgermøde",
          "Upload Resumé to Municipality Website":
            "Upload Resumé til kommunens websted",
          "Create Agenda for City Council Meeting":
            "Opret dagsorden til politisk behandling",
          "Submit Outcome of the City Council Meeting":
            "Indsend resultat af politisk behandling",
          // - c
          "Execute Screening": "Udfør screening",
          "Define Scope of Environmental Impact":
            "Definer omfanget af afgrænsningsnotat",
          "Define Scope of Environmental Assessment":
            "Definer omfanget af miljøkonsekvensrapport",
          "Create Environmental Impact Report": "Opret miljørapport",
          "Submit Document for Report on Investigation of Bats, Birds and Nature":
            "Indsend undersøgelse af påvirkning natur/ dyreliv (evt. Natura 2000 konsekvensvurdering)",
          "Submit Document for Report on Shadow Pollution":
            "Indsend dokument til redegørelse skyggepåvirkning",
          "Submit Document for Report on Noise Pollution":
            "Indsend dokument til støj redegørelse",
          "Submit Document for Visualisations":
            "Indsend dokument til visualiseringer",
          "Submit Planning Document": "Indsend lokalplan",
          "Submit Zoning Permit / Change of Land Use":
            "Indsend kommuneplantillæg",
          "Review Specific Documents": "Gennemgå specifikke dokumenter",
          // - d
          "Include a Link to the Relevant Documents for the Public Consultation":
            "Inkluder et link til de relevante dokumenter til den offentlig høring",
          "Prepare Public Consultation": "Forbered offentlig høring",
          "Prepare Public Consultation Meeting":
            "Forbered offentligt høringsmøde",
          "Create Slide Show for Public Consultation":
            "Opret diasshow til offentlig høring",
          "Upload to Municipality Website": "Upload til kommunens websted",
          "Start Draft for §25 Permission": "Start udkast §25-tilladelse",
          // - e
          "Make Final Changes to Documents":
            "Foretag endelige ændringer i dokumenter",
          "Upload to National Planning Database":
            "Upload til Landsplandatabasen",
          "Submit Issue §25 Permission":
            "Indsend udstedelse af endelig §25-tilladelse",
          "Submit Outcome of the Appeal Phase":
            "Indsend resultatet af appelfasen",
          /* Data Privacy */
          "WindEurope Data Privacy Statement for EasyPermits®":
            "WindEuropes fortrolighedserklæring for persondata til EasyPermits®",
          General: "Generelt",
          "This Privacy Statement (“Privacy Statement”) explains how and for what purpose WindEurope will process certain personal data belonging to the authorized users of this tool (”Tool”) when they access and use it":
            "Denne fortrolighedserklæring (“fortrolighedserklæring”) forklarer, hvordan og til hvilket formål WindEurope vil behandle visse personoplysninger, der tilhører de autoriserede brugere af dette værktøj (”værktøj”), når de får adgang til og bruger det",
          "Why are you interacting with WindEurope in the context of EasyPermits®":
            "Hvorfor interagerer du med WindEurope i forbindelse med EasyPermits®",
          "WindEurope provides access to this Tool to an entity, organization, or business you are affiliated with (“Your Organization”)":
            "WindEurope giver adgang til dette værktøj til en enhed, organisation eller virksomhed, du er tilknyttet (“Din organisation”)",
          "You have provided EasyPermits® and therewith WindEurope with your contact details and were designated as an authorized user of the Tool. If you have any doubts or queries relating to why your personal data has been shared with WindEurope or you have been selected to access and use the Tool, you should contact Your Organization first":
            "Du har givet EasyPermits® og dermed WindEurope dine kontaktoplysninger og blev udpeget som autoriseret bruger af værktøjet. Hvis du har nogen tvivl eller spørgsmål om, hvorfor dine personoplysninger er blevet delt med WindEurope, eller hvorfor du er blevet udvalgt til at få adgang til og bruge værktøjet, bør du først kontakte Din organisation",
          "Legal Framework": "Juridiske rammer",
          "This privacy policy is subject to privacy legislation, i.e.":
            "Denne fortrolighedspolitik er underlagt lovgivning om databeskyttelse, dvs.",
          "the Belgian Data Protection Act of 8th December 1992 on the protection of privacy in relation to the processing of personal data (as amended) (the “Privacy Act”); and/or":
            "den belgiske databeskyttelseslov af 8. december 1992 om beskyttelse af privatlivets fred i forbindelse med behandling af personoplysninger (med ændringer) (“privatlivsloven”); og/eller",
          "Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons regarding the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation) (“GDPR”); and/or":
            "Europa-Parlamentets og Rådets forordning (EU) 2016/679 af 27. april 2016 om beskyttelse af fysiske personer i forbindelse med behandling af personoplysninger og om fri udveksling af sådanne oplysninger og om ophævelse af direktiv 95/46/EF (generel forordning om databeskyttelse) (“GDPR”); og/eller",
          "All other applicable legislation regarding the protection of privacy and the processing of personal data":
            "Al anden gældende lovgivning om beskyttelse af privatlivets fred og behandling af personoplysninger",
          Purpose: "Formål",
          "WindEurope can process personal data in accordance with the Privacy Legislation and this privacy policy to":
            "WindEurope kan behandle personoplysninger i overensstemmelse med lovgivningen om databeskyttelse og denne fortrolighedspolitik til",
          "Account data / login credentials": "Kontodata / loginoplysninger",
          "Creating your account in the Tool":
            "Oprettelse af din konto i værktøjet",
          "Enabling you to access and use the Tool":
            "Gør det muligt for dig at få adgang til og bruge værktøjet",
          "Exercising or defending WindEurope's rights in the context of legal claims/disputes":
            "Udøvelse eller forsvar af WindEuropes rettigheder i forbindelse med juridiske krav/tvister",
          "Legal basis and Applicability": "Juridisk grundlag og anvendelse",
          "WindEurope has the following legal grounds for the processing of personal data":
            "WindEurope har følgende juridiske grundlag for behandlingen af personoplysninger",
          "your free, specific, informed and unambiguous consent to WindEurope, its partners (e.g., subcontractors, partners or contracting parties) to process your personal data. You can give your consent by any means, such as, but not limited to mail, email, an online or offline consent form, a membership form; and/or":
            "dit frie, specifikke, informerede og utvetydige samtykke til, at WindEurope, dets partnere (f.eks. underleverandører, partnere eller kontraherende parter) behandler dine personoplysninger. Du kan give dit samtykke på enhver måde, såsom, men ikke begrænset til, post, e-mail, online eller offline samtykkeformular, medlemskabsformular; og/eller",
          "a legal obligation; and/ or": "en juridisk forpligtelse; og/eller",
          "a legitimate interest of WindEurope":
            "en legitim interesse for WindEurope",
          "Providing your personal data to WindEurope, implies that you":
            "Ved at give dine personoplysninger til WindEurope indebærer det, at du",
          "declare that you are fully informed about this privacy policy and that you fully accept this privacy policy without any reservation; and":
            "erklærer, at du er fuldt informeret om denne fortrolighedspolitik, og at du fuldt ud accepterer denne fortrolighedspolitik uden forbehold; og",
          "give your free, specific, informed and unambiguous consent to WindEurope to process or have processed your personal data in accordance with this privacy policy and the Privacy Legislation.":
            "giver dit frie, specifikke, informerede og utvetydige samtykke til, at WindEurope behandler eller får behandlet dine personoplysninger i overensstemmelse med denne fortrolighedspolitik og lovgivningen om databeskyttelse.",
          "Consequently, you expressly grant WindEurope permission to use your personal data to accomplish the Purpose set out under article 3":
            "Som følge heraf giver du udtrykkeligt WindEurope tilladelse til at bruge dine personoplysninger til at opfylde formålet, der er fastsat i artikel 3",
          "Personal Data": "Personoplysninger",
          "When you use and access the Tool, the personal data WindEurope can process are":
            "Når du bruger og får adgang til værktøjet, kan WindEurope behandle følgende personoplysninger",
          "Account data / Login credentials: first name, name, business E-mail, (business) phone number":
            "Kontodata / loginoplysninger: fornavn, efternavn, erhvervse-mail, (erhverv) telefonnummer",
          "With agreeing to this privacy policy, you guarantee that the personal data you have provided, are complete and correct":
            "Ved at acceptere denne fortrolighedspolitik garanterer du, at de personoplysninger, du har givet, er fuldstændige og korrekte",
          "Transfer of personal data to third parties":
            "Overførsel af personoplysninger til tredjeparter",
          "WindEurope guarantees it does not transfer your personal data to third parties, unless":
            "WindEurope garanterer, at det ikke videregiver dine personoplysninger til tredjeparter, medmindre",
          "There is a legal obligation to transfer the personal data":
            "Der er en juridisk forpligtelse til at overføre personoplysningerne",
          "To its partners (e.g., subcontractors, partners or contracting parties) for the achievement of the Purpose":
            "Til sine partnere (f.eks. underleverandører, partnere eller kontraherende parter) for at opfylde formålet",
          "WindEurope has a legitimate interest in doing so":
            "WindEurope har en legitim interesse i at gøre det",
          "Any third-party service providers and professional advisors to whom your personal data is disclosed, are expected and required to protect the confidentiality and security of your personal data and may only use your personal data in compliance with applicable data privacy laws":
            "Eventuelle tredjeparts tjenesteudbydere og professionelle rådgivere, som dine personoplysninger videregives til, forventes og skal beskytte fortroligheden og sikkerheden af dine personoplysninger og må kun bruge dine personoplysninger i overensstemmelse med gældende love om databeskyttelse",
          "Storage period": "Opbevaringsperiode",
          "WindEurope stores personal data for as long as is necessary to achieve the Purpose":
            "WindEurope opbevarer personoplysninger, så længe det er nødvendigt for at opfylde formålet",
          "Your rights": "Dine rettigheder",
          "The Privacy Legislation provides you with a number of rights in relation to your personal data. You have the right, free of charge":
            "Lovgivningen om databeskyttelse giver dig en række rettigheder i forhold til dine personoplysninger. Du har ret til gratis",
          "to have access to and receive a copy of your personal data":
            "at få adgang til og modtage en kopi af dine personoplysninger",
          "to have your personal data erased in case":
            "at få slettet dine personoplysninger, hvis",
          "your personal data are no longer necessary to achieve the Purpose":
            "dine personoplysninger ikke længere er nødvendige for at opfylde formålet",
          "you withdraw your consent and there is no other legal ground for the processing of the personal data":
            "du trækker dit samtykke tilbage, og der ikke er andet juridisk grundlag for behandlingen af personoplysningerne",
          "you object to the processing of the personal data and there is no other legal ground for the processing of the personal data":
            "du gør indsigelse mod behandlingen af personoplysningerne, og der ikke er andet juridisk grundlag for behandlingen af personoplysningerne",
          "the personal data have been unlawfully processed":
            "personoplysningerne er blevet ulovligt behandlet",
          "there is a legal obligation to erase the personal data":
            "der er en juridisk forpligtelse til at slette personoplysningerne",
          "to have the processing of your personal data restricted":
            "at få behandlingen af dine personoplysninger begrænset",
          "to have your personal data transferred to a third party":
            "at få overført dine personoplysninger til en tredjepart",
          "to withdraw your consent pursuant to which WindEurope is allowed to process your personal data":
            "til at trække dit samtykke tilbage, i henhold til hvilket WindEurope har tilladelse til at behandle dine personoplysninger",
          "to lodge a complaint with the Commission for the Protection of Privacy (“Privacy Commission”) if you are of the opinion that the processing of your personal data breaches the Privacy Legislation":
            "til at indgive en klage til Kommissionen for beskyttelse af privatlivets fred (“Privatlivskommissionen”), hvis du mener, at behandlingen af dine personoplysninger overtræder lovgivningen om databeskyttelse",
          "In case you want to exercise the above rights, and provided you prove your identity, you can send a written, dated and signed request by mail to":
            "Hvis du vil gøre brug af ovenstående rettigheder og kan bevise din identitet, kan du sende en skriftlig, dateret og underskrevet anmodning pr. post til",
          "Eline Post, Director for Human Resource (HR) and Legal, Rue Belliard 40, 1040 Brussels, Belgium":
            "Eline Post, Director for Human Resource (HR) and Legal, Rue Belliard 40, 1040 Bruxelles, Belgien",
          "or by email to": "eller pr. e-mail til",
          Security: "Sikkerhed",
          "WindEurope commits to take (or have) all reasonable measures (taken) to its best ability to safeguard the protection of personal data through technical safety measures and an appropriate safety policy for its employees. The personal data are stored with WindEurope, or, if applicable, with the processor of the personal data on servers located in Stockholm, Sweden. You acknowledge and accept that the transfer and storage of personal data is never without risk and consequently, the damage that you may suffer from the unlawful use of your personal data by third parties can never be collected from WindEurope":
            "WindEurope forpligter sig til at træffe (eller få truffet) alle rimelige foranstaltninger (truffet) efter bedste evne for at sikre beskyttelsen af personoplysninger gennem tekniske sikkerhedsforanstaltninger og en passende sikkerhedspolitik for sine medarbejdere. Personoplysninger opbevares hos WindEurope eller, hvis relevant, hos databehandleren af personoplysninger på servere, der er placeret i Stockholm, Sverige. Du anerkender og accepterer, at overførsel og opbevaring af personoplysninger aldrig er uden risiko, og at den skade, du eventuelt kan lide som følge af ulovlig brug af dine personoplysninger fra tredjeparter, aldrig kan opkræves fra WindEurope",
          Liability: "Ansvar",
          "WindEurope can solely be held accountable for damages which directly result from the processing of your personal data due to a fault or negligence of WindEurope. In any event, WindEurope cannot be held liable: (i) in circumstances of force majeure and/or (ii) for any indirect or consequential damage and/or (iii) for damages that result from errors, faults or negligence by you or third parties. WindEurope's liability cannot exceed 1,000 euro (thousand euro) for breaches regarding this privacy policy and/or Privacy Legislation":
            "WindEurope kan kun holdes ansvarlig for skader, der direkte skyldes behandlingen af dine personoplysninger på grund af fejl eller forsømmelser fra WindEuropes side. Under ingen omstændigheder kan WindEurope holdes ansvarlig for: (i) omstændigheder som skyldes force majeure og/eller (ii) eventuelle indirekte eller følgeskader og/eller (iii) skader, der skyldes fejl, mangler eller forsømmelser fra din eller tredjeparters side. WindEuropes ansvar kan ikke overstige 1.000 euro (tusinde euro) for overtrædelser vedrørende denne fortrolighedspolitik og/eller lovgivningen om databeskyttelse",
          Severability: "Delbarhed",
          "If any provision in this privacy policy is deemed to be unlawful or unenforceable, that provision shall be amended by WindEurope and yourself insofar as necessary to make it lawful or enforceable, while retaining the meaning of WindEurope and yourself with respect to that provision as much as possible":
            "Hvis en bestemmelse i denne fortrolighedspolitik anses for at være ulovlig eller ugyldig, skal denne bestemmelse ændres af WindEurope og dig selv i det omfang, det er nødvendigt for at gøre den lovlig eller håndhævelig, samtidig med at WindEuropes og din oprindelige hensigt med bestemmelsen så vidt muligt bevares",
          "Whenever possible, the provisions of this privacy policy shall be interpreted in such a manner as to be valid and enforceable under the applicable law. However, if one or more provisions of this privacy policy are found to be invalid, illegal or unenforceable, in whole or in part, the remainder of that provision and of this privacy policy shall remain in full force and effect as if such invalid, illegal or unenforceable provision had never been contained herein":
            "I videst muligt omfang skal bestemmelserne i denne privatlivspolitik fortolkes på en sådan måde, at de er gyldige og kan håndhæves i henhold til gældende ret. Hvis imidlertid en eller flere bestemmelser i denne privatlivspolitik findes ugyldige, ulovlige eller ikke kan håndhæves, helt eller delvist, forbliver resten af den pågældende bestemmelse og denne privatlivspolitik fuldt ud gældende og i kraft, som om den ugyldige, ulovlige eller ikke-håndhævelige bestemmelse aldrig havde været indeholdt heri",
          "Applicable law and Competent court":
            "Gældende lov og kompetent domstol",
          "You agree that all disputes between you and WindEurope regarding personal data and privacy issues, are exclusively subject to Belgian law, excluding any conflict of law principles":
            "Du accepterer, at alle tvister mellem dig og WindEurope vedrørende personoplysninger og privatlivsspørgsmål udelukkende er underlagt belgisk ret med udelukkelse af alle lovkonfliktprincipper",
          "Every dispute regarding personal data and privacy issues belongs to the exclusive jurisdiction of the Dutch speaking courts of Brussels, Belgium, excluding any other court":
            "Enhver tvist vedrørende personoplysninger og privatlivsspørgsmål falder under den eksklusive kompetence for de nederlandsksprogede domstole i Bruxelles, Belgien, med udelukkelse af enhver anden domstol",
          // Access denied and 404 Not found
          "Access Denied": "Adgang nægtet",
          "Sorry, you do not have permission to access this page.":
            "Beklager, du har ikke adgang til denne side.",
          "Back to Homepage": "Tilbage til startside",
          "404 Not Found": "404 Ikke fundet",
          "Oops! The page you're trying to access does not exist.":
            "Ops! Siden du prøver at få adgang til findes ikke.",
        },
      },
      /* ------------------------ FRENCH ------------------------ */
      fr: {
        translation: {
          Denmark: "Danemark",
          Poland: "Pologne",
          at: "ved",
          // Sign in
          "Sign in to your account": "",
          "Create a new account":
            "Créer un nouveau compte agent ou développeur",
          // deadlines:
          Deadline: "Date limite",
          documents_handover: "Remise de documents",
          public_consultation: "Consultation publique",
          city_council: "Conseil Municipal",
          "Export Deadlines": "Eksporter milepæle",
          "Project Deadlines": "Projektets milepæle",
          // community:
          "About us": "À propos de nous",
          "Welcome To": "Bienvenue sur",
          "Welcome to EasyPermits": "Bienvenue sur EasyPermits",
          "To get your questions answered about the project":
            "Pour obtenir des réponses à vos questions sur le projet",
          "Welcome!": "Bienvenue !",
          "welcome!": "bienvenue !",
          "I am a Community Member": "Je suis un membre de la communauté",
          "I am a Permitting Agent": "Je suis un agent d'autorisation",
          "I am a Project Developer": "Je suis un développeur de projet",
          "Are you a Permitting Agent? \n Sign-in to use full features of our platform":
            "Êtes-vous un agent d'autorisation ? \n Connectez-vous pour utiliser toutes les fonctionnalités de notre plateforme",
          "Are you a Developer? \n Sign-in to use full features of our platform":
            "Êtes-vous un développeur ? \n Connectez-vous pour utiliser toutes les fonctionnalités de notre plateforme",
          "Explore our interactive map to discover wind farms in your area and beyond. Click on markers to find details about existing and proposed projects. Your engagement matters, and here, you can stay informed about the wind energy initiatives shaping your community.":
            "Explorez notre carte interactive pour découvrir les parcs éoliens dans votre région et au-delà. Cliquez sur les marqueurs pour trouver des détails sur les projets existants et proposés. Votre engagement est important, et ici, vous pouvez rester informé des initiatives éoliennes qui façonnent votre communauté.",
          "If you're a permitting agent or wind farm developer, log in to access powerful tools and resources tailored to your role. Streamline the permitting process, collaborate with stakeholders, and contribute to sustainable wind energy solutions.":
            "If you're a permitting agent or wind farm developer, log in to access powerful tools and resources tailored to your role. Streamline the permitting process, collaborate with stakeholders, and contribute to sustainable wind energy solutions.",
          "Search Wind Projects": "Rechercher des projets éoliens",
          "Search Wind Projects on the Map":
            "Rechercher des projets éoliens sur la carte",
          "EasyPermits is your digital one-stop shop for the onshore wind projects permitting process.\n\nWith EasyPermits we are focused on streamlining the permitting process by improving information management, increasing transparency of the onshore wind farm permitting process and increasing collaboration between project developers, permitting agents as well as the community members.\n\nEasyPermits is a web-based tool developed through a collaboration between WindEurope, Amazon Web Services and Accenture.":
            "EasyPermits est votre guichet numérique unique pour le processus d'autorisation des projets éoliens terrestres.\n\nAvec EasyPermits, nous nous concentrons sur la rationalisation du processus d'autorisation en améliorant la gestion de l'information, en augmentant la transparence du processus d'autorisation des parcs éoliens terrestres et en renforçant la collaboration entre les développeurs de projets, les agents chargés de l'autorisation ainsi que les membres de la communauté.\n\nEasyPermits est un outil basé sur le web développé grâce à une collaboration entre WindEurope, Amazon Web Services et Accenture.",
          "I live in a municipality with planned wind projects. I want to learn more about the upcoming wind projects near me. I want to stay informed and actively engage in discussions about their impact on our community.":
            "J'habite dans une municipalité avec des projets éoliens prévus. Je veux en savoir plus sur les futurs projets éoliens près de chez moi. Je veux rester informé et participer activement aux discussions sur leur impact sur notre communauté.",
          "I work at the municipality, process permit applications or provide my expertise. I want to use the tool for managing the permit applications from Project Developers and engage my community on upcoming planned projects.":
            "Je travaille à la municipalité, je traite les demandes de permis ou j'apporte mon expertise. Je souhaite utiliser l'outil pour gérer les demandes de permis des promoteurs de projets et engager ma communauté dans les projets prévus.",
          "I work for a company that wants to develop wind projects. I want to use the tool to submit a request for wind projects and send the necessary documentation to the permitting agents and engage the community on project activities and benefits.":
            "Je travaille pour une entreprise qui souhaite développer des projets éoliens. Je souhaite utiliser l'outil pour soumettre une demande pour des projets éoliens et envoyer la documentation nécessaire aux agents d'autorisation et engager la communauté sur les activités et les avantages du projet.",
          // Footer contents
          "Explore our Interactive Map. Click on the red markers to discover about the upcoming wind projects in the permitting process in your area and beyond. Want to learn more about wind energy and the permitting process in general? Visit the Frequently Asked Questions section.":
            "Explorez notre carte interactive. Cliquez sur les marqueurs rouges pour en savoir plus sur les futurs projets éoliens en cours d'autorisation dans votre région et au-delà. Vous voulez en savoir plus sur l'énergie éolienne et le processus d'autorisation en général ? Visitez la section Foire aux questions.",
          "Sign in to access the project management capabilities of the platform tailored to your role. Collaborate with project developers and your colleagues in the municipalities and prepare for engaging communities through consultations.":
            "Connectez-vous pour accéder aux fonctionnalités de gestion de projet de la plateforme adaptées à votre rôle. Collaborez avec les développeurs de projets et vos collègues des municipalités et préparez-vous à mobiliser les communautés par le biais de consultations.",
          "Sign in to access the project management capabilities of the platform tailored to your role. Collaborate with permitting agents and your colleagues and prepare for engaging communities through consultations.":
            "Connectez-vous pour accéder aux fonctionnalités de gestion de projet de la plateforme adaptées à votre rôle. Collaborez avec les agents d'autorisation et vos collègues et préparez-vous à mobiliser les communautés par le biais de consultations.",
          // Community: project timeline hover description
          "Project Developer submits new project request and municipality has initial considerations about the project and determines a timeline for the planning work.":
            "Le développeur de projet soumet une nouvelle demande de projet et la municipalité a des considérations initiales sur le projet et détermine un calendrier pour le travail de planification.",
          "A period of typically two weeks in which citizens are engaged in the project by the municipality. Citizens are informed of the project and the upcoming planning process.":
            "Une période de généralement deux semaines pendant laquelle les citoyens sont impliqués dans le projet par la municipalité. Les citoyens sont informés du projet et du prochain processus de planification.",
          "Environmental Report and Environmental Impact Assessment Report are prepared by the Project Developer and the Permitting Agent.":
            "Le rapport environnemental et le rapport d'évaluation de l'impact environnemental sont préparés par le développeur de projet et l'agent d'autorisation.",
          "Municipality publishes proposal for municipal planning and associated EIA report. A period of at least eight weeks starts in which citizens and other stakeholders are engaged in the project by the municipality.":
            "La municipalité publie une proposition de planification municipale et le rapport EIE associé. Une période d'au moins huit semaines commence pendant laquelle les citoyens et autres parties prenantes sont impliqués dans le projet par la municipalité.",
          "The municipality decides on the adoption of the plan proposal.":
            "La municipalité décide de l'adoption de la proposition de plan.",
          // Notifications:
          a_preplanning_closed: "Phase de pré-planification fermée.",
          b_initial_consultation_closed:
            "La première consultation publique est terminée.",
          c_planning_closed: "Processus de planification terminé.",
          d_final_consultation_closed:
            "La phase de consultation finale est terminée.",
          e_approval_closed: "Décision finale terminée.",
          task_done: "Nouvelle tâche accomplie.",
          b_review: "Nouveau document téléchargé",
          c_revision: "Le document doit être révisé",
          d_done: "Nouveau document marqué comme terminé.",
          document_comment: "Nouveau commentaire sur le document.",
          project_comment: "Nouveau commentaire sur le projet.",
          project_summary_editable_false_verified_true:
            "Résumé du projet vérifié.",
          project_summary_editable_true_verified_false:
            "Le résumé du projet doit être révisé.",
          project_summary_editable_false_verified_false:
            "Le résumé du projet doit être examiné.",
          "closed the Public Consultation Stage":
            "a clôturé l'étape de consultation publique",
          "closed the Final Decision Stage and the Project":
            "a clôturé l'étape de décision finale et le projet",
          "closed the Planning Stage": "a clôturé l'étape de planification",
          "closed the Initial Public Consultation Stage":
            "a clôturé l'étape de consultation publique initiale",
          "closed the Preplanning Stage":
            "a clôturé l'étape de pré-planification",
          "Document Marked as Complete": "Document marqué comme terminé",
          "Task Completed": "Tâche accomplie",
          "Sent Back to Edit": "Renvoyé pour modification",
          "New Document Uploaded": "Nouveau document téléchargé",
          "Project Details": "Détails du projet",
          "Edit Project": "Modifier le projet",
          "Project Description": "Description du projet",
          "Detailed description of the proposed wind farm, basic information, company description, etc.":
            "Description détaillée de la ferme éolienne proposée, informations de base, description de l'entreprise, etc.",
          "Pre agreement with owners": "Pré-accord avec les propriétaires",
          "Muncipality of the project": "Commune du projet",
          "Rotor diameter of the wind turbines in meters":
            "Diamètre du rotor des éoliennes en mètres",
          "Height of the wind turbines in meters":
            "Hauteur des éoliennes en mètres",
          "Start date not set yet": "Date de début pas encore définie",
          "Start date set": "Date de début définie",
          "Not happened yet": "Pas encore survenu",
          "Assign Project to Me": "M'attribuer le projet",
          "Next Document to Submit": "Prochain document à soumettre",
          // Community Page:
          Home: "Accueil",
          Dashboard: "Tableau de bord",
          Community: "Communauté",
          "Search on this page to find more information on projects in the permitting process in the neighbourhood":
            "Recherchez sur cette page pour trouver plus d'informations sur les projets en cours d'autorisation dans le voisinage",
          "Wind Farms in Permitting Process":
            "Parcs éoliens en cours d'autorisation",
          "In Permit Process": "En cours d'autorisation",
          "Appeal Phase": "Phase d'appel",
          "Appeal Raised": "Appel déposé",
          Planned: "Prévu",
          "Pins on the map": "Épingles sur la carte",
          "Show/hide": "Afficher/masquer",
          "The projects are still in the process of approval, which does not guarantee their full implementation.":
            "Les projets sont encore en cours d'approbation, ce qui ne garantit pas leur mise en œuvre complète.",
          Municipality: "Municipalité",
          "Sign in": "Se connecter",
          "Sign In": "Se connecter",
          "Sign out": "Se déconnecter",
          Support: "Support",
          "User Pages": "Pages utilisateur",
          "Data Privacy": "Confidentialité des données",
          "Frequently Asked Questions": "Foire aux questions",
          "Quick Links": "Liens rapides",
          // Instructions
          "How to use this page?": "Comment utiliser cette page ?",
          "Search for an address or postal code (including country) to see projects in the permitting process.":
            "Recherchez une adresse ou un code postal (y compris le pays) pour voir les projets en cours d'autorisation.",
          "The red markers indicate projects in the permitting process.":
            "Les marqueurs rouges indiquent les projets en cours d'autorisation.",
          "Click on the red marker to see more details of that project in the permitting process.":
            "Cliquez sur le marqueur rouge pour voir plus de détails sur ce projet en cours d'autorisation.",
          "Using the map search feature":
            "Utilisation de la fonction de recherche sur la carte",
          "Enter an address, postcode or country in the search bar.":
            "Saisissez une adresse, un code postal ou un pays dans la barre de recherche.",
          "Select your result in the drop-down list. The map will automatically fly and zoom into that location.":
            "Sélectionnez votre résultat dans la liste déroulante. La carte volera et zoomera automatiquement sur cet emplacement.",
          "You should still see the selected result under the seach bar. Click on it again. The map should then automatically zoom out.":
            "Vous devriez toujours voir le résultat sélectionné sous la barre de recherche. Cliquez à nouveau dessus. La carte devrait alors automatiquement zoomer.",
          "If the map does not zoom out far enough, please use the +/- navigation tool at the bottom left corner or mouse scroll up/down to control the zoom":
            "Si la carte ne zoome pas suffisamment loin, utilisez l'outil de navigation +/- dans le coin inférieur gauche ou faites défiler la souris vers le haut/bas pour contrôler le zoom",
          Permit: "Permis",
          "Start Date": "Date de début",
          "Download Comments": "Télécharger les commentaires",
          Comments: "Commentaires",
          "Remove start date": "Supprimer la date de début",
          "Set Project Start Date": "Définir la date de début du projet",
          "End Date": "Date de fin",
          "Permit Start Date": "Date de début du permis",
          "Permit End Date": "Date de fin estimée du permis",
          "End date not set yet": "Date de fin pas encore définie",
          "Re-open project": "Rouvrir le projet",
          // Export:
          Property: "Propriété",
          Value: "Valeur",
          Unit: "Unité",
          // Project:
          Country: "Pays",
          "Country of the project": "Pays du projet",
          wind: "éolien",
          solar: "solaire",
          meters: "mètres",
          hectares: "hectares",
          "It will supply": "Il fournira de l'énergie à",
          households: "ménages",
          Households: "Ménages",
          "Average number of households to supply energy to":
            "Nombre moyen de ménages auxquels fournir de l'énergie",
          Details: "Détails",
          "Overall Capacity": "Capacité totale",
          "Capacity in megawatts (MW)": "Capacité en mégawatts (MW)",
          "Please select a country first": "Veuillez d'abord choisir un pays",
          "Expected Tip Height": "Hauteur maximale prévue",
          "Expected Rotor Diameter": "Diamètre du rotor prévu",
          Size: "Taille",
          Generalities: "Généralités",
          "All the above information is provided by the project developer":
            "Toutes les informations ci-dessus sont fournies par le développeur de projet",
          "Hover over each stage to see more details":
            "Survolez chaque étape pour voir plus de détails",
          "Project Overview": "Aperçu du projet",
          "More information on municipality webpage":
            "Plus d'informations sur le site Web de la municipalité",
          "You can add your feedback on the project here":
            "Vous pouvez ajouter vos commentaires sur le projet ici",
          // Project setup
          "Maximum tip height of the wind turbines":
            "Hauteur maximale en bout de pale des éoliennes",
          "Expected height": "Hauteur prévue",
          "Expected Height": "Hauteur prévue",
          "Developer Website": "Site web du développeur",
          // FAQs:
          "Available at": "Disponible sur",
          Accessed: "Consulté",
          // - Categories:
          "General Questions": "Questions générales",
          "Permitting Process": "Processus d'autorisation",
          "Reference List": "Liste de références",
          // - Questions:
          "What is a wind farm permitting process?":
            "Qu'est-ce qu'un processus d'autorisation pour un parc éolien?",
          "The wind farm permitting process is the series of regulatory steps and approvals required before a project can be constructed and operated. This process varies by according to local laws and can involve several municipal agencies and public review (European Commission, n.d.a).":
            "Le processus d'autorisation d'un parc éolien est la série d'étapes réglementaires et d'approbations requises avant qu'un projet puisse être construit et exploité. Ce processus varie selon les lois locales et peut impliquer plusieurs agences municipales et un examen public (European Commission, n.d.a).",
          "What permits does a wind farm need?":
            "Quels permis un parc éolien a-t-il besoin ?",
          "The type of permits varies based on the size and complexity of the wind farm. Some common permits include: environmental permits, land-use permits, zoning permits, building permits, and electrical interconnection permits (European Commission, n.d.a).":
            "Le type de permis varie selon la taille et la complexité du parc éolien. Certains permis courants comprennent : permis environnementaux, permis d'utilisation des terres, permis de zonage, permis de construire et permis de raccordement électrique (European Commission, n.d.a).",
          "Who is responsible for obtaining these permits?":
            "Qui est responsable de l'obtention de ces permis ?",
          "The wind farm developer, aka. project developer or just developer, is typically responsible for obtaining the necessary permits, but may work with consultants or legal counsel to navigate the permitting process (European Commission, n.d.b).":
            "Le développeur de parc éolien, également appelé promoteur de projet ou simplement développeur, est généralement responsable de l'obtention des permis nécessaires, mais peut travailler avec des consultants ou des conseillers juridiques pour naviguer dans le processus d'autorisation (European Commission, n.d.b).",
          "How long does the wind farm permitting process typically take?":
            "Combien de temps dure généralement le processus d'autorisation d'un parc éolien ?",
          "The length of the permitting process can vary widely depending on the jurisdiction and complexity of the project. It can range from several months to several years. EU law requires public authorities to complete the process within two years for new wind farms, and within one year for repowered wind farms (when the turbines in an existing wind farm are being replaced with newer ones) (WindEurope, 2023).":
            "La durée du processus d'autorisation peut varier considérablement selon la juridiction et la complexité du projet. Elle peut aller de quelques mois à plusieurs années. La législation de l'UE exige que les autorités publiques mènent le processus à terme en deux ans pour les nouveaux parcs éoliens, et en un an pour les parcs éoliens repowerés (lorsque les éoliennes d'un parc existant sont remplacées par de nouvelles) (WindEurope, 2023).",
          "How can I stay informed about the wind farm permitting process?":
            "Comment puis-je rester informé du processus d'autorisation d'un parc éolien ?",
          "Many jurisdictions have public consultation periods and hearings as part of the permitting process. You can find the contact details of the agency in charge of the project you're interested on the project page of the municipality website.":
            "De nombreuses juridictions prévoient des périodes de consultation publique et des audiences dans le cadre du processus d'autorisation. Vous pouvez trouver les coordonnées de l'agence en charge du projet qui vous intéresse sur la page du projet sur le site web de la municipalité.",
          "What happens after a project is permitted?":
            "Que se passe-t-il après l'autorisation d'un projet ?",
          "Once a project is permitted, construction can begin. The developer will also need to obtain additional permits and approvals as construction progresses, such as building inspections and connections to the transmission networks (Danish Energy Agency, 2022).":
            "Une fois qu'un projet est autorisé, la construction peut commencer. Le promoteur devra également obtenir des permis et des approbations supplémentaires au fur et à mesure de l'avancement de la construction, tels que des inspections de bâtiments et des raccordements aux réseaux de transport (Danish Energy Agency, 2022).",
          "Why is it so difficult to get permits for wind farms in Europe?":
            "Pourquoi est-il si difficile d'obtenir des permis pour les parcs éoliens en Europe?",
          "Complex rules and slow procedures are the main reasons why it's difficult to get permits for wind farms. Wind farms face several spatial planning constraints, such as minimum distance to housing, height restrictions and exclusion zones around radar installations, or in specific areas. Additionally, numerous administrative authorities are involved, including at national, regional and municipal level (WindEurope, 2020).":
            "Des règles complexes et des procédures lentes sont les principales raisons pour lesquelles il est difficile d'obtenir des permis pour les parcs éoliens. Les parcs éoliens sont confrontés à plusieurs contraintes en matière de planification spatiale, telles que des distances minimales par rapport aux habitations, des limites de hauteur et des zones d'exclusion autour des installations radar, ou dans des zones spécifiques. En outre, de nombreuses autorités administratives sont impliquées, notamment aux niveaux national, régional et municipal (WindEurope, 2020).",
          "What requirements do wind farm developers account for in the permitting process?":
            "Quelles exigences les développeurs de parcs éoliens prennent-ils en compte dans le processus d'autorisation?",
          "Project developers need to account for several requirements when they consider building a wind farm on a specific site: wind speeds, grid connection supporting infrastructure such as roads, impact of the wind farm on surrounding natural ecosystems and communities, the right turbine technology for the site etc. (Domaingue, J.-C. and Jain, P., 2022).":
            "Les développeurs de projets doivent tenir compte de plusieurs exigences lorsqu'ils envisagent de construire un parc éolien sur un site spécifique : vitesse du vent, infrastructure de soutien pour la connexion au réseau comme les routes, impact du parc éolien sur les écosystèmes naturels environnants et les communautés, la bonne technologie d'éolienne pour le site, etc. (Domaingue, J.-C. and Jain, P., 2022).",
          "How can developers seek participation from local residents and other stakeholders during the permitting process?":
            "Comment les développeurs peuvent-ils solliciter la participation des résidents locaux et des autres parties prenantes pendant le processus d'autorisation ?",
          "The wind industry follows three guiding principles as a model of best practice for good community engagement:":
            "L'industrie éolienne suit trois principes directeurs comme modèle des meilleures pratiques pour une bonne implication de la communauté:",
          "early, transparent and comprehensive information and communication;":
            "informations et communication précoces, transparentes et complètes;",
          "direct engagement of key local actors and activation of the local economy; and":
            "engagement direct des acteurs locaux clés et activation de l'économie locale; et",
          "highlighting how communities will benefit from the investments being made. These principles apply at every stage of a project, from site selection and pre-application, planning, construction to operation and decommissioning. (WindEurope, 2020)":
            "mise en évidence des avantages que les communautés tireront des investissements réalisés. Ces principes s'appliquent à toutes les étapes d'un projet, de la sélection du site et de la demande préalable, à la planification, la construction, l'exploitation et le démantèlement. (WindEurope, 2020)",
          "How is EasyPermits simplifying the permit application process?":
            "Comment EasyPermits simplifie-t-il le processus de demande de permis?",
          "The EasyPermits application makes it possible for permitting authorities, wind farm developers and community members to access all the information about a project in an easy, centralised way. The application provides permitting agents with a dedicated dashboard giving them a concise snapshot of each permit application, encompassing critical tasks, notifications, project summaries, associated activities, and productivity KPIs.":
            "L'application EasyPermits permet aux autorités de délivrance de permis, aux développeurs de parcs éoliens et aux membres de la communauté d'accéder facilement à toutes les informations sur un projet de manière centralisée. L'application fournit aux agents d'autorisation un tableau de bord dédié leur donnant un aperçu concis de chaque demande de permis, englobant les tâches critiques, les notifications, les résumés de projet, les activités associées et les indicateurs de performance clés en matière de productivité.",
          "How does EasyPermits benefit project developers and community members?":
            "Comment EasyPermits bénéficie-t-il aux développeurs de projets et aux membres de la communauté?",
          "EasyPermits enables permitting agents to facilitate transparency regarding the project process to developers, and the project's potential impact to the local community.":
            "EasyPermits permet aux agents d'autorisation de faciliter la transparence concernant le processus de projet pour les développeurs, et l'impact potentiel du projet sur la communauté locale.",
          // Data Privacy popup
          "Please note that the personal data in this system cannot be used for other purposes than the purposes of this tool/system. If you plan to do so, seek advice from your legal department regarding required steps.":
            "Veuillez noter que les données personnelles de ce système ne peuvent être utilisées à d'autres fins que les objectifs de cet outil/système. Si vous envisagez de le faire, demandez conseil à votre service juridique concernant les étapes requises.",
          "WindEurope can process my personal data in accordance with this":
            "WindEurope peut traiter mes données personnelles conformément à ceci",
          "I agree": "J'accepte",
          "I do not agree": "Je n'accepte pas",
          "For more information, please see":
            "Pour plus d'informations, veuillez consulter",
          // Dashboard:
          "No projects found": "Aucun projet trouvé",
          "Overdue Tasks": "Tâches en retard",
          "Welcome Back": "Bienvenue à nouveau",
          Productivity: "Productivité",
          Notifications: "Notifications",
          "No new notifications": "Aucune nouvelle notification",
          "Take a look at your achievements": "Regardez vos réalisations",
          "Ongoing projects": "Projets en cours",
          "Delayed projects": "Projets retardés",
          "Your open tasks": "Vos tâches ouvertes",
          "Overdue tasks overall": "Tâches en retard au total",
          "Saving changes": "Enregistrement des modifications",
          "Please wait": "Veuillez patienter",
          "Fetching data": "Récupération des données",
          "My Tasks": "Mes tâches",
          "This Week": "Cette semaine",
          "Next Week": "Semaine prochaine",
          Overall: "Globalement",
          "Task Name": "Nom de la tâche",
          "Project Name": "Nom du projet",
          "Project Phase": "Phase du projet",
          "Due Date": "Date d'échéance",
          "Days to Finish": "Jours restants",
          Delayed: "En retard",
          "Loading resources": "Chargement des ressources",
          Loading: "Chargement",
          "No resources": "Pas de ressources",
          "No resources to display": "Aucune ressource à afficher",
          "Deadline not set": "Date limite non définie",
          "Pre-planning": "Pré-planification",
          "Documents Review": "Revue des documents",
          "City Council Meeting": "Réunion du conseil municipal",
          "Initial Public Consultation": "Consultation publique initiale",
          "Public Consultation": "Consultation publique",
          "Planning Process": "Processus de planification",
          "Final Public Consultation": "Consultation publique finale",
          "Final Approval": "Approbation finale",
          Appeal: "Appel",
          "Start date will be set once all the documents and have been reviewed":
            "La date de début sera fixée une fois que tous les documents auront été examinés",
          "Project will start on": "Le projet commencera le",
          "You are in the Pre-Planning phase":
            "Vous êtes dans la phase de pré-planification",
          Mon: "Lun",
          Tue: "Mar",
          Wed: "Mer",
          Thu: "Jeu",
          Fri: "Ven",
          Sat: "Sam",
          Sun: "Dim",
          Oct: "Oct",
          May: "Mai",
          "My Projects": "Mes projets",
          "To Be Reviewed": "À examiner",
          "All Projects in Municipality": "Tous les projets de la municipalité",
          "Unassigned Projects": "Projets non assignés",
          "Are you sure you want to close this project?":
            "Êtes-vous sûr de vouloir fermer le projet ?",
          "Closed Projects": "Projets fermés",
          "Report Appeal": "Signaler un appel",
          "Are you sure you want to report appeal this project?":
            "Êtes-vous sûr de vouloir signaler un appel pour ce projet ?",
          "You have 4 weeks to report an appeal. After this, the button  will be disabled.":
            "Vous disposez de 4 semaines pour signaler un recours. Passé ce délai, le bouton sera désactivé.",
          "Share Appeal Outcome": "Partager l'issue de l'appel",
          "Download everything": "Tout télécharger",
          "Download all project files":
            "Télécharger tous les fichiers du projet",
          "Please choose what you would like to download":
            "Veuillez choisir ce que vous souhaitez télécharger",
          "All project comments": "Tous les commentaires sur le projet",
          "All document comments": "Tous les commentaires sur les documents",
          "All document attachments (all versions)":
            "Toutes les pièces jointes aux documents (toutes les versions)",
          "No attachments found": "Aucune pièce jointe trouvée",
          // Project Dashboard:
          "Updating assignee. Please do not close or refresh this page":
            "Mise à jour du cessionnaire. Veuillez ne pas fermer ou actualiser cette page",
          "Appeal is overruled": "Appel rejeté",
          "Appeal is sustained": "Appel accepté",
          "This will also cancel the project":
            "Cela annulera également le projet",
          "Community page": "Page publique",
          "Project website": "Site web du projet",
          "Edit website": "Modifier le site web",
          "Add project website": "Ajouter le site web du projet",
          "added a comment": "a ajouté un commentaire",
          "commented on the document": "a commenté le document",
          "Take a look at your progress": "Regardez votre progression",
          "In Revision": "En révision",
          "Overview of the project including timeline and key highlights":
            "Présentation du projet, y compris le calendrier et les moments clés",
          "Created on": "Créé le",
          by: "par",
          Update: "Mettre à jour",
          Set: "Définir",
          "Open tasks": "Tâches ouvertes",
          Tasks: "Tâches",
          Documents: "Documents",
          "Owner as role": "Propriétaire en tant que rôle",
          "Delete version": "Supprimer la version",
          "in delay": "en retard",
          Collaborators: "Collaborateurs",
          "Once you have reviewed all the documents and the Summary, you can set the start date for the project":
            "Une fois que vous avez examiné tous les documents et le résumé, vous pouvez fixer la date de début du projet.",
          "Once the Permitting Agent have reviewed and validated all the documents and Summary, they will set a start date for the project":
            "Une fois que l'agent de permis a examiné et validé tous les documents et le résumé, il fixera une date de début pour le projet",
          "Update deadlines": "Mettre à jour les délais",
          Cancel: "Annuler",
          "Save Changes": "Sauvegarder les modifications",
          "Final Appeal": "Appel final",
          "Edit Milestones Deadlines": "Modifier les échéances des étapes",
          "Current stage": "Étape actuelle",
          "Last stage": "Dernière étape",
          "Manage team": "Gérer l'équipe",
          "Manage Team": "Gérer l'équipe",
          "Update additional collaborators for your project":
            "Mettre à jour les collaborateurs supplémentaires pour votre projet",
          "Start Over": "Recommencer",
          "Close Stage": "Fermer l'étape",
          "Skip Stage": "Sauter l'étape",
          "Export Summary": "Exporter le résumé",
          "Assigned to": "Assigné à",
          "Project Dashboard": "Tableau de bord du projet",
          "No open documents": "Aucun document ouvert",
          "Related Documents": "Documents liés",
          "Related documents": "Documents liés",
          "Choose documents": "Choisir des documents",
          "Not complete": "Pas complet",
          document: "document",
          documents: "documents",
          "All done!": "Tout est fait!",
          "No open tasks to display": "Aucune tâche ouverte à afficher",
          "open documents": "documents ouverts",
          "Add New Task": "Ajouter une nouvelle tâche",
          "Re-open Task": "Rouvrir la tâche",
          Description: "Description de la tâche",
          Stage: "Étape",
          "Due date": "Date d'échéance",
          "Edit Task": "Modifier la tâche",
          "Edit task": "Modifier la tâche",
          "Mark as complete": "Marquer comme terminé",
          "Linked Documents": "Documents liés",
          Priority: "Priorité",
          "View all tasks": "Voir toutes les tâches",
          "View all documents": "Voir tous les documents",
          "Ready for upload": "Prêt à être téléchargé",
          "Sent for review": "Envoyé pour révision",
          "Revision needed": "Révision nécessaire",
          Completed: "Terminé",
          // Activities
          Activities: "Activités",
          History: "Histoire",
          "Project Logs": "Journal de bord du projet",
          "Project Request": "Demande de projet",
          "sent a project request": "a envoyé une demande de projet",
          "started the project": "a démarré le projet",
          "Project Comments": "Commentaires sur le projet",
          "Document Comments": "Commentaires sur le document",
          "Download History": "Télécharger l'historique",
          "Download Project Logs": "Télécharger les journaux du projet",
          "Download Project Comments": "Télécharger les commentaires du projet",
          Maybe: "Peut-être",
          "Close Project": "Fermer le projet",
          "Close project": "Fermer le projet",
          "Project Stopped": "Projet arrêté",
          "Project Completed": "Projet terminé",
          "Mandatory Documents & Tasks": "Documents et tâches obligatoires",
          "Optional Documents & Tasks": "Documents et tâches facultatifs",
          "All tasks have been completed":
            "Toutes les tâches ont été accomplies",
          "There are still open mandatory documents":
            "Il reste des documents obligatoires à accomplir",
          "There are still open mandatory tasks":
            "Il reste des tâches obligatoires à accomplir",
          "There are still open optional documents":
            "Il reste des documents optionnels à accomplir",
          "There are still open optional tasks":
            "Il reste des tâches optionnelles à accomplir",
          "All items in the checklist must be completed before you can close this stage":
            "Tous les éléments de la liste de contrôle doivent être complétés avant de pouvoir clôturer cette étape",
          "You still have open optional documents and tasks":
            "Vous avez encore des documents et des tâches optionnels ouverts",
          "Once you close this stage, all relevant information will be published to the Community page":
            "Une fois cette étape terminée, toutes les informations pertinentes seront publiées sur la page de la Communauté",
          Confirm: "Confirmer",
          "All mandatory documents are uploaded and reviewed":
            "Tous les documents requis ont été téléchargés et examinés",
          "Are you sure you want to close this stage?":
            "Etes-vous sûr de vouloir clôturer l'étape?",
          "Are you sure you want to skip this stage?":
            "Êtes-vous sûr de vouloir sauter l'étape?",
          "Before proceeding to the next stage, please ensure the following checklist is completed:":
            "Avant de passer à l'étape suivante, veuillez vous assurer que la liste de contrôle suivante a été complétée:",
          "City Council Meeting Outcome":
            "Résultat de la réunion du conseil municipal",
          "Please provide the outcome of the City Council meeting for this stage":
            "Veuillez indiquer le résultat de la réunion du conseil municipal pour cette étape",
          "Please provide the outcome of the City Council meeting for the last stage":
            "Veuillez fournir les résultats de la réunion du Conseil municipal pour la dernière étape",
          "You can close this stage and proceed to the next one":
            "Vous pouvez clôturer cette étape et passer à la suivante",
          "You can close the last stage and close the project":
            "Vous pouvez clore la dernière étape et clôturer le projet",
          "Further adjustments required. You may need to stay in this stage, re-open and modify the documents accordingly":
            "D'autres ajustements sont nécessaires. Il se peut que vous deviez rester à ce stade, rouvrir et modifier les documents en conséquence",
          "The project needs to be stopped and closed at this stage":
            "Le projet doit être arrêté et clôturé à ce stade",
          "Share Outcome": "Partager le résultat",
          "View all details": "Afficher tous les détails",
          "Project Summary": "Résumé du projet",
          "Permit Process Start Date":
            "Date de début de la procédure d'autorisation",
          Date: "Date",
          Author: "Auteur",
          Comment: "Commentaire",
          "Permit Process End Date":
            "Date de fin de la procédure d'autorisation",
          "Permitting Agent": "Agent chargé de l'octroi des permis",
          "Project Developer": "Développeur de projet",
          "Number of Turbines": "Nombre de turbines",
          "Size of project": "Taille du projet",
          "Relation to Neighbors": "Relation avec les voisins",
          "Pre-agreement with Owners":
            "Accord préalable avec les propriétaires",
          "Location - Latitude": "Emplacement - Latitude",
          "Location - Longitude": "Emplacement - Longitude",
          "Project Type": "Type de projet",
          "Additional technical details": "Détails techniques supplémentaires",
          "Potential number of households it supplies energy to":
            "Nombre potentiel de ménages approvisionnés en énergie",
          "Date not set yet": "Date non encore fixée",
          "All Project Documents": "Tous les documents du projet",
          "All Project Tasks": "Toutes les tâches du projet",
          "Project Documents": "Documents du projet",
          "Project Tasks": "Tâches du projet",
          "Add New Document": "Ajouter un nouveau document",
          "No comments available": "Pas de commentaires disponibles",
          "No documents available": "Aucun document disponible",
          "No tasks available": "Pas de tâches disponibles",
          "No versions available": "Aucune version disponible",
          "Add a comment": "Ajouter un commentaire",
          "Enter comments here...": "Saisir un commentaire ici...",
          "Add comment": "Ajouter un commentaire",
          "Delete comment": "Supprimer le commentaire",
          "Confirm action": "Confirmer l'action",
          "Are you sure you want to delete?":
            "Êtes-vous sûr de vouloir supprimer?",
          "Send Back to Edit": "Renvoyer à l'édition",
          Verify: "Vérifier",
          "Not yet assigned": "Pas encore attribué",
          Verified: "Vérifié",
          "Setup Workflow": "Configurer le flux de travail",
          // Workflow:
          "Permitting Workflow Setup":
            "Permettre la configuration d'un flux de travail",
          "Set up an individual workflow as standardised template for your own work":
            "Configurer un flux de travail individuel comme modèle standardisé pour votre propre travail",
          "Select Template": "Sélectionner un modèle",
          "Create my own template": "Créer mon propre modèle",
          "Coming soon": "Prochainement",
          "Next: Set Required Documents":
            "Suivant: Définir les documents requis",
          "Required Documents List": "Liste des documents requis",
          "We set the documents of the request that are important to you. Use the checklist icon on the left to design your individual workflow.":
            "Nous définissons les documents de la demande qui sont importants pour vous. Utilisez l'icône de la liste de contrôle à gauche pour concevoir votre propre flux de travail.",
          "Back to Templates": "Retour aux modèles",
          "Next: Set Tasks": "Suivant: Définir les tâches",
          "Set Tasks": "Définir les tâches",
          "We set the tasks of the request that are important to you. Use the checklist icon on the left to design your individual workflow.":
            "Nous définissons les tâches de la demande qui sont importantes pour vous. Utilisez l'icône de la liste de contrôle à gauche pour concevoir votre propre flux de travail.",
          "No stage selected": "Aucune étape sélectionnée",
          "Back to Documents": "Retour aux documents",
          "Next: Set Milestones Deadlines":
            "Suivant: Définir les échéances des étapes",
          "Set Milestone Deadlines": "Fixer les échéances des étapes",
          "We have set you an approximate duration for the different periods, which you can change if you wish.":
            "Nous vous avons fixé une durée approximative pour les différentes périodes, que vous pouvez modifier si vous le souhaitez.",
          "Set deadlines": "Fixer des délais",
          "Back to Tasks": "Retour aux tâches",
          "Set Collaborators": "Définir les collaborateurs",
          "Next: Set Collaborators": "Suivant: Définir les collaborateurs",
          "As the concluding stage, you have the option to assign collaborators to specific roles within the project. This assignment can be adjusted at a later point, and new team members may also be updated if needed.":
            "En conclusion, vous avez la possibilité d'affecter des collaborateurs à des rôles spécifiques au sein du projet. Cette affectation peut être modifiée ultérieurement, et de nouveaux membres de l'équipe peuvent également être ajoutés si nécessaire.",
          "Choose developer(s)": "Choisir le(s) développeur(s)",
          "Select developer(s)": "Sélectionnez le(s) développeur(s)",
          "Choose agents(s)": "Choisir le(s) agent(s)",
          "Select agents(s)": "Sélectionner le(s) agent(s)",
          "SME Reviewer": "Réviseur PME",
          "Choose SME(s)": "Choisir les PME",
          "Select SME(s)": "Sélectionner les PME",
          "Back to Milestones deadlines": "Retour aux échéances des étapes",
          "Value must be between": "La valeur doit être comprise entre",
          and: "et",
          "Select municipalities": "Sélectionnez les municipalités",
          "Are you sure you want to submit?":
            "Êtes-vous sûr de vouloir soumettre votre projet?",
          "A notification will be sent to a Permitting Agent with your project submission request. You can get in touch with the Permitting Agent once an Agent is assigned on the project.":
            "Une notification sera envoyée à un agent chargé de l'octroi des permis avec votre demande de soumission de projet. Vous pourrez prendre contact avec l'agent chargé des autorisations une fois qu'un agent aura été désigné pour le projet.",
          "Note: All the additional attachments and supporting documents can be conveniently added on the next page after submitting this form.":
            "Note : Toutes les pièces jointes et les documents justificatifs peuvent être ajoutés à la page suivante après avoir soumis ce formulaire.",
          "Confirm Submission": "confirmer la soumission",
          // Project Setup:
          "It seems like it's your first time here.":
            "On dirait que c'est la première fois que vous venez ici.",
          "Our app is designed to streamline and simplify the permitting process for wind farm developers like you. As you begin, please proceed by submitting the Project Setup Form, where you can provide essential information about your project application.":
            "Notre application est conçue pour rationaliser et simplifier le processus d'autorisation pour les développeurs de parcs éoliens comme vous. Pour commencer, veuillez soumettre le formulaire de configuration du projet, dans lequel vous pouvez fournir des informations essentielles sur votre demande de projet.",
          "This form will help you communicate all the basic, important details required by the permitting agent. Our goal is to ensure a seamless and efficient process, facilitating clear communication and collaboration between you and the permitting authorities.":
            "Ce formulaire vous aidera à communiquer tous les détails de base importants requis par l'agent chargé de la délivrance des permis. Notre objectif est de garantir un processus transparent et efficace, en facilitant une communication et une collaboration claires entre vous et les autorités chargées de délivrer les permis.",
          "Thank you for choosing EasyPermits. Let's work together to create a greener and sustainable future!":
            "Merci d'avoir choisi EasyPermits. Travaillons ensemble pour créer un avenir plus vert et durable!",
          Organisation: "Organisation",
          "Benefits for the Community": "Avantages pour la communauté",
          "Provide information regarding community benefits. You can also add a link to your project website. Link must start with http(s)://":
            "Fournissez des informations concernant les avantages pour la communauté. Vous pouvez également ajouter un lien vers le site web de votre projet. Le lien doit commencer par http(s)://",
          "Recommendation to also add the community testimonial video on your project website.":
            "Recommandation d'ajouter également la vidéo de témoignage de la communauté sur le site web de votre projet.",
          "Name of the organisation": "Nom de l'organisation",
          "Submit New Project": "Soumettre un nouveau projet",
          "Project name": "Nom du projet",
          "Name of the project": "Nom du projet",
          "Latitude location of the project on the map":
            "Latitude de l'emplacement du projet sur la carte",
          "Longitude location of the project on the map":
            "Longitude de l'emplacement du projet sur la carte",
          "Overall capacity": "Capacité globale",
          "Municipality of the project": "Municipalité du projet",
          "Go Back a Stage": "Retourner à l'étape précédente",
          "Size of project in hectares": "Taille du projet en hectares",
          "Detailed description of the proposed project, basic information, company description, etc.":
            "Description détaillée du projet proposé, informations de base, description de l'entreprise, etc.",
          "Please provide essential information required for the permitting process. By completing this form, you'll offer a comprehensive overview of your proposed project. We'll gather vital details such as project location, project type and community engagement plans. This initial step will pave the way for a smooth and efficient permitting process.":
            "Veuillez fournir les informations essentielles requises pour la procédure d'autorisation. En remplissant ce formulaire, vous nous donnerez une vue d'ensemble du projet que vous proposez. Nous recueillerons des informations essentielles telles que l'emplacement du projet, le type de projet et les plans de participation de la communauté. Cette première étape ouvrira la voie à une procédure d'autorisation efficace et sans heurts.",
          "Specify the type of the project": "Précisez le type de projet",
          "Total number of turbines": "Nombre total de turbines",
          "Expected rotor diameter": "Diamètre prévu du rotor",
          "Enter description here...": "Entrez la description ici...",
          "Is there a pre-agreement with owners?":
            "Y a-t-il un accord préalable avec les propriétaires?",
          "Information about community engagement and the current relationship to neighbours":
            "Informations sur l'engagement communautaire et les relations actuelles avec les voisins.",
          "Enter information here...": "Saisir les informations ici...",
          "Relation to neighbors": "Relation avec les voisins",
          "Do you have a written document?":
            "Disposez-vous d'un document écrit?",
          "Additional information": "Informations complémentaires",
          "Currently viewing": "Actuellement en cours de visualisation",
          Yes: "Oui",
          No: "Non",
          no: "non",
          "Please provide any additional technical information relevant to the project eg. design, configuration, known issues, nature, etc.":
            "Veuillez fournir toute information technique supplémentaire relative au projet, par exemple la conception, la configuration, les problèmes connus, la nature, etc.",
          "Enter additional info here...":
            "Entrez des informations supplémentaires ici...",
          "Note: All the additional attachments and supporting documents can be conveniently added on the next page after submitting this form to ensure a seamless submission process.":
            "Note : Toutes les pièces jointes et les documents justificatifs peuvent être ajoutés à la page suivante après avoir soumis ce formulaire afin de garantir un processus de soumission sans faille.",
          "Fill out the below form and submit the form to send it to the Permitting Agent":
            "Remplissez le formulaire ci-dessous et soumettez-le pour l'envoyer à l'agent chargé de la délivrance des permis.",
          "Project Setup Request": "Demande de montage de projet",
          // Stages:
          a_preplanning: "a_préplanification",
          b_initial_consultation: "b_consultation initiale",
          c_planning: "c_planification",
          d_final_consultation: "d_consultation_finale",
          e_approval: "e_approbation",
          // roles
          agents: "agents",
          developers: "développeurs",
          // Workflow:.
          "Use predefined template": "Utiliser un modèle prédéfini",
          // Documents:
          Version: "Version",
          "Set a deadline for your document":
            "Fixer une date limite pour votre document",
          "Related Tasks": "Tâches associées",
          "New Task Created": "Nouvelle tâche créée",
          "New Document Created": "Nouveau document créé",
          "Submit Outcome of the Appeal phase":
            "Soumettre le résultat de la phase d'appel",
          "Download Document Comments":
            "Télécharger les commentaires du document",
          Section: "Section",
          Page: "Page",
          "Document Name": "Nom du document",
          "Set a name for your document": "Donnez un nom à votre document",
          "Please compress all additional documents into a ZIP file":
            "Veuillez compresser tous les documents supplémentaires dans un fichier ZIP",
          "The geo file in the right format":
            "Le fichier géo dans le bon format",
          "The geo file require to be in the right format":
            "Le fichier géo doit être dans le bon format",
          "You can send the project summary back to edit or verify it":
            "Vous pouvez renvoyer le résumé du projet pour le modifier ou le vérifier.",
          "Set a stage for the document": "Définir une étape pour le document",
          "Geo file in the right format, project presentation less than 20 pages, and additional documents in a ZIP format":
            "Fichier géo au bon format, présentation du projet de moins de 20 pages, et documents complémentaires au format ZIP",
          "Select a user to assign your document to":
            "Sélectionnez un utilisateur à qui attribuer votre document",
          "None assigned": "Aucun n'est assigné",
          "None selected": "Aucun n'a été sélectionné",
          "Not related to any tasks": "Ne concerne aucune tâche",
          "Document is Mandatory": "Le document est obligatoire",
          "Task is Mandatory": "La tâche est obligatoire",
          "Publish document to the Community page when stage ends":
            "Publier le document sur la page Communauté lorsque l'étape se termine",
          "Select task(s) that belong to this document":
            "Sélectionner la ou les tâches qui appartiennent à ce document",
          "Choose tasks": "Choisir des tâches",
          "Linked Tasks": "Tâches liées",
          "Edit Document": "Editer le document",
          "Re-Open Document": "Rouvrir le document",
          "Please do not exceed 20 pages": "Veuillez ne pas dépasser 20 pages",
          Actions: "Actions",
          Status: "Statut",
          "Drop files to upload": "Déposer des fichiers à télécharger",
          "Upload files": "Télécharger les fichiers",
          tasks: "tâches",
          task: "tâche",
          "Linked to": "Lié à",
          "Version History": "Historique des versions",
          Versions: "Versions",
          Chapter: "Chapitre",
          "Enter a name here...": "Entrez un nom ici...",
          "Enter a comment here...": "Entrez un commentaire ici...",
          optional: "facultatif",
          Optional: "Facultatif",
          "Page Number": "Numéro de page",
          "Download All Comments": "Télécharger tous les commentaires",
          "Download All Attachments": "Télécharger toutes les pièces jointes",
          // - a
          "The Geo File":
            "Le fichier Géo (.TAB, .MIF, .SHP, .DWG, .GML, .KML eller .DXF)",
          "Project Presentation": "Présentation du projet",
          "Additional Documents": "Documents complémentaires",
          // - b
          "Invitation and Project Presentation for Initial Public Consultation":
            "Invitation et présentation du projet pour la consultation publique initiale",
          "Outcome of the City Council Meeting at the End of the Final Decision Phase":
            "Résultat de la réunion du Conseil municipal à l'issue de la phase de décision finale",
          "Outcome of the City Council Meeting at the End of Initial Public Consultation":
            "Résultat de la réunion du conseil municipal à l'issue de la consultation publique initiale",
          "Full List of Comments Initial Public Consultation":
            "Liste complète des commentaires de la consultation publique initiale",
          "Input for Initial Public Consultation (Slides)":
            "Contribution à la consultation publique initiale (diapositives)",
          "Resumé Initial Public Consultation":
            "Résumé de la consultation publique initiale",
          "Agenda for City Council Meeting After Initial Public Consultation":
            "Ordre du jour de la réunion du conseil municipal après la consultation publique initiale",
          "Agenda for Initial Public Consultation":
            "Ordre du jour de la consultation publique initiale",
          // - c
          "Screening for Environmental Impact Assessment":
            "Examen préalable à l'évaluation des incidences sur l'environnement",
          "Environmental Impact Assessment Scope":
            "Champ d'application de l'évaluation des incidences sur l'environnement",
          "Environmental Impact Report":
            "Rapport sur les incidences environnementales",
          "Environmental Report": "Rapport environnemental",
          "Report on Investigation of Bats, Birds and Nature":
            "Rapport d'enquête sur les chauves-souris, les oiseaux et la nature",
          "Report on Shadow Pollution": "Rapport sur la pollution par l'ombre",
          "Report on Noise Pollution": "Rapport sur la pollution sonore",
          Visualisations: "Visualisations",
          "Planning Document": "Document d'urbanisme",
          "Zoning Permit / Change of Land Use":
            "Permis de zonage / changement d'utilisation du sol",
          "Agenda for City Council Meeting in Planning Process":
            "Ordre du jour de la réunion du conseil municipal au cours de la procédure de planification",
          "Outcome of the City Council Meeting at the End of Planning Process":
            "Résultat de la réunion du conseil municipal à la fin du processus de planification",
          // - d:
          "Agenda for Public Consultation":
            "Ordre du jour de la consultation publique",
          "Input for Public Consultation (Slides)":
            "Contribution à la consultation publique (diapositives)",
          "Invitation and Project Presentation for Public Consultation":
            "Invitation et présentation du projet pour la consultation publique",
          "Invitation Public Consultation":
            "Invitation à la consultation publique",
          "Full List of Comments Public Consultation":
            "Liste complète des commentaires pour la consultation publique",
          "Resumé Public Consultation": "Résumé de la consultation publique",
          "§25 Permission Draft": "Projet d'autorisation §25",
          "Agenda for City Council Meeting After Public Consultation":
            "Ordre du jour de la réunion du conseil municipal après la consultation publique",
          "Outcome of the City Council Meeting at the End of Public Consultation":
            "Résultat de la réunion du conseil municipal à l'issue de la consultation publique",
          // - e:
          "Issue §25 Permission": "Délivrer l'autorisation §25",
          "Building Permission (Building Department)":
            "Permis de construire (service de la construction)",
          // Tasks:
          // - a
          "Upload the Geo File":
            "Télécharger le fichier Géo (.TAB, .MIF, .SHP, .DWG, .GML, .KML eller .DXF)",
          "Upload the Project Presentation":
            "Télécharger la présentation du projet",
          "Upload the Additional Documents":
            "Télécharger les documents supplémentaires",
          "Review Project Summary": "Examiner le résumé du projet",
          "Review All Documents": "Examiner tous les documents",
          // - b
          "Invitation to Public Consultation and Presentation":
            "Zaproszenie na konsultacje publiczne i prezentacja",
          "Prepare Initial Public Consultation Meeting":
            "Préparer la réunion initiale de consultation publique ",
          "Create Slides for Public Consultation":
            "Créer des diapositives pour la consultation publique",
          "Prepare Full List of Comments":
            "Préparer la liste complète des commentaires",
          "Upload Comments to Municipality Website":
            "Télécharger les commentaires sur le site Web de la municipalité",
          "Summarize and Respond to Public Consultation":
            "Résumer et répondre à la consultation publique",
          "Upload Resumé to Municipality Website":
            "Télécharger le résumé sur le site Web de la municipalité",
          "Create Agenda for City Council Meeting":
            "Créer un ordre du jour pour la réunion du conseil municipal",
          "Submit Outcome of the City Council Meeting":
            "Soumettre le résultat de la réunion du conseil municipal",
          // - c
          "Execute Screening": "Effectuer un dépistage",
          "Define Scope of Environmental Impact":
            "Définir la portée de l'impact environnemental",
          "Define Scope of Environmental Assessment":
            "Définir la portée de l'évaluation environnementale",
          "Create Environmental Impact Report":
            "Créer un rapport d'impact environnemental",
          "Submit Document for Report on Investigation of Bats, Birds and Nature":
            "Soumettre le document pour le rapport sur l'étude des chauves-souris, des oiseaux et de la nature",
          "Submit Document for Report on Shadow Pollution":
            "Soumettre le document pour le rapport sur la pollution par l'ombre",
          "Submit Document for Report on Noise Pollution":
            "Soumettre le document pour le rapport sur la pollution sonore",
          "Submit Document for Visualisations":
            "Soumettre le document pour les visualisations",
          "Submit Planning Document": "Soumettre le document d'urbanisme",
          "Submit Zoning Permit / Change of Land Use":
            "Soumettre le permis de construire / changement d'affectation des sols",
          "Review Specific Documents": "Examiner des documents spécifiques",
          "Include a Link to the Relevant Documents for the Public Consultation":
            "Inclure un lien vers les documents pertinents pour la consultation publique",
          "Prepare Public Consultation": "Préparer la consultation publique",
          "Prepare Public Consultation Meeting":
            "Préparer la réunion de consultation publique",
          "Create Slide Show for Public Consultation":
            "Créer un diaporama pour la consultation publique",
          "Upload to Municipality Website":
            "Téléverser sur le site web de la municipalité",
          "Start Draft for §25 Permission":
            "Commencer l'ébauche pour l'autorisation §25",
          "Make Final Changes to Documents":
            "Apporter les modifications finales aux documents",
          "Upload to National Planning Database":
            "Téléverser dans la base de données nationale d'urbanisme",
          "Submit Issue §25 Permission":
            "Soumettre la délivrance de l'autorisation définitive §25",
          "Submit Outcome of the Appeal Phase":
            "Soumettre le résultat de la phase d'appel",
          // Data Privacy
          "WindEurope Data Privacy Statement for EasyPermits®":
            "Déclaration de confidentialité des données de WindEurope pour EasyPermits®",
          General: "Général",
          "This Privacy Statement (“Privacy Statement”) explains how and for what purpose WindEurope will process certain personal data belonging to the authorized users of this tool (”Tool”) when they access and use it":
            "Cette déclaration de confidentialité (« Déclaration de confidentialité ») explique comment et pour quelle raison WindEurope traitera certaines données personnelles appartenant aux utilisateurs autorisés de cet outil (« Outils ») lorsqu'ils y accèdent et l'utilisent",
          "Why are you interacting with WindEurope in the context of EasyPermits®":
            "Pourquoi interagissez-vous avec WindEurope dans le cadre d'EasyPermits®",
          "WindEurope provides access to this Tool to an entity, organization, or business you are affiliated with (“Your Organization”)":
            "WindEurope donne accès à cet outil à une entité, organisation ou entreprise à laquelle vous êtes affilié (« Votre organisation »)",
          "You have provided EasyPermits® and therewith WindEurope with your contact details and were designated as an authorized user of the Tool. If you have any doubts or queries relating to why your personal data has been shared with WindEurope or you have been selected to access and use the Tool, you should contact Your Organization first":
            "Vous avez fourni à EasyPermits® et par là même à WindEurope vos coordonnées et avez été désigné comme utilisateur autorisé de l'outil. Si vous avez le moindre doute ou question concernant la raison pour laquelle vos données personnelles ont été partagées avec WindEurope ou pour laquelle vous avez été sélectionné pour accéder à l'outil et l'utiliser, vous devez d'abord contacter votre organisation",
          "Legal Framework": "Cadre juridique",
          "This privacy policy is subject to privacy legislation, i.e.":
            "Cette politique de confidentialité est soumise à la législation sur la vie privée, c'est-à-dire",
          "the Belgian Data Protection Act of 8th December 1992 on the protection of privacy in relation to the processing of personal data (as amended) (the “Privacy Act”); and/or":
            "la loi belge du 8 décembre 1992 sur la protection de la vie privée en matière de traitement de données à caractère personnel (telle que modifiée) (la « Loi sur la vie privée ») ; et/ou",
          "Regulation (EU) 2016/679 of the European Parliament and of the Council of 27 April 2016 on the protection of natural persons regarding the processing of personal data and on the free movement of such data, and repealing Directive 95/46/EC (General Data Protection Regulation) (“GDPR”); and/or":
            "le règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données, et abrogeant la directive 95/46/CE (règlement général sur la protection des données) (« RGPD ») ; et/ou",
          "All other applicable legislation regarding the protection of privacy and the processing of personal data":
            "Toute autre législation applicable en matière de protection de la vie privée et de traitement des données à caractère personnel",
          Purpose: "Objectif",
          "WindEurope can process personal data in accordance with the Privacy Legislation and this privacy policy to":
            "WindEurope peut traiter les données à caractère personnel conformément à la législation sur la vie privée et à la présente politique de confidentialité aux fins de",
          "Account data / login credentials":
            "Données de compte / identifiants de connexion",
          "Creating your account in the Tool":
            "Créer votre compte dans l'outil",
          "Enabling you to access and use the Tool":
            "Vous permettre d'accéder à l'outil et de l'utiliser",
          "Exercising or defending WindEurope's rights in the context of legal claims/disputes":
            "Exercer ou défendre les droits de WindEurope dans le cadre de réclamations/litiges juridiques",
          "Legal basis and Applicability": "Base juridique et applicabilité",
          "WindEurope has the following legal grounds for the processing of personal data":
            "WindEurope a les bases juridiques suivantes pour le traitement des données à caractère personnel",
          "your free, specific, informed and unambiguous consent to WindEurope, its partners (e.g., subcontractors, partners or contracting parties) to process your personal data. You can give your consent by any means, such as, but not limited to mail, email, an online or offline consent form, a membership form; and/or":
            "votre consentement libre, spécifique, éclairé et univoque à WindEurope, ses partenaires (par ex. sous-traitants, partenaires ou cocontractants) pour traiter vos données à caractère personnel. Vous pouvez donner votre consentement par tout moyen, tel que, mais sans s'y limiter, courrier, e-mail, formulaire de consentement en ligne ou hors ligne, formulaire d'adhésion ; et/ou",
          "a legal obligation; and/ or": "une obligation légale ; et/ou",
          "a legitimate interest of WindEurope":
            "un intérêt légitime de WindEurope",
          "Providing your personal data to WindEurope, implies that you":
            "En fournissant vos données personnelles à WindEurope, vous",
          "declare that you are fully informed about this privacy policy and that you fully accept this privacy policy without any reservation; and":
            "déclarez être pleinement informé de cette politique de confidentialité et l'accepter pleinement sans aucune réserve ; et",
          "give your free, specific, informed and unambiguous consent to WindEurope to process or have processed your personal data in accordance with this privacy policy and the Privacy Legislation":
            "donnez votre consentement libre, spécifique, éclairé et univoque à WindEurope pour traiter ou faire traiter vos données à caractère personnel conformément à cette politique de confidentialité et à la législation sur la vie privée",
          "Consequently, you expressly grant WindEurope permission to use your personal data to accomplish the Purpose set out under article 3":
            "En conséquence, vous accordez expressément à WindEurope l'autorisation d'utiliser vos données personnelles pour accomplir l'Objectif énoncé à l'article 3",
          "Personal Data": "Données personnelles",
          "When you use and access the Tool, the personal data WindEurope can process are":
            "Lorsque vous utilisez l'outil et y accédez, les données personnelles que WindEurope peut traiter sont",
          "Account data / Login credentials: first name, name, business E-mail, (business) phone number":
            "Données de compte / Identifiants de connexion : prénom, nom, e-mail professionnel, numéro de téléphone professionnel",
          "With agreeing to this privacy policy, you guarantee that the personal data you have provided, are complete and correct":
            "En acceptant cette politique de confidentialité, vous garantissez que les données personnelles que vous avez fournies sont complètes et exactes",
          "Transfer of personal data to third parties":
            "Transfert de données personnelles à des tiers",
          "WindEurope guarantees it does not transfer your personal data to third parties, unless":
            "WindEurope garantit qu'il ne transfère pas vos données personnelles à des tiers, sauf",
          "There is a legal obligation to transfer the personal data":
            "Il existe une obligation légale de transférer les données personnelles",
          "To its partners (e.g., subcontractors, partners or contracting parties) for the achievement of the Purpose":
            "À ses partenaires (par ex. sous-traitants, partenaires ou cocontractants) pour réaliser l'Objectif",
          "WindEurope has a legitimate interest in doing so":
            "WindEurope a un intérêt légitime à le faire",
          "Any third-party service providers and professional advisors to whom your personal data is disclosed, are expected and required to protect the confidentiality and security of your personal data and may only use your personal data in compliance with applicable data privacy laws":
            "Tous les prestataires de services tiers et conseillers professionnels auxquels vos données personnelles sont divulguées sont tenus de protéger la confidentialité et la sécurité de vos données personnelles et ne peuvent utiliser vos données personnelles qu'en conformité avec les lois applicables en matière de protection des données",
          "Storage period": "Période de conservation",
          "WindEurope stores personal data for as long as is necessary to achieve the Purpose":
            "WindEurope conserve les données personnelles aussi longtemps que nécessaire pour réaliser l'Objectif",
          "Your rights": "Vos droits",
          "The Privacy Legislation provides you with a number of rights in relation to your personal data. You have the right, free of charge":
            "La législation sur la vie privée vous confère un certain nombre de droits concernant vos données à caractère personnel. Vous avez le droit, gratuitement",
          "to have access to and receive a copy of your personal data":
            "d'accéder à vos données et d'en recevoir une copie",
          "to have your personal data erased in case":
            "de faire effacer vos données personnelles dans le cas où",
          "your personal data are no longer necessary to achieve the Purpose":
            "vos données personnelles ne sont plus nécessaires pour réaliser l'Objectif",
          "you withdraw your consent and there is no other legal ground for the processing of the personal data":
            "vous retirez votre consentement et qu'il n'existe aucun autre fondement juridique au traitement des données personnelles",
          "you object to the processing of the personal data and there is no other legal ground for the processing of the personal data":
            "vous vous opposez au traitement des données personnelles et qu'il n'existe aucun autre fondement juridique au traitement des données personnelles",
          "the personal data have been unlawfully processed":
            "les données personnelles ont été traitées illégalement",
          "there is a legal obligation to erase the personal data":
            "il existe une obligation légale d'effacer les données personnelles",
          "to have the processing of your personal data restricted":
            "de faire restreindre le traitement de vos données personnelles",
          "to have your personal data transferred to a third party":
            "de faire transférer vos données personnelles à un tiers",
          "to withdraw your consent pursuant to which WindEurope is allowed to process your personal data":
            "de retirer votre consentement en vertu duquel WindEurope est autorisé à traiter vos données personnelles",
          "to lodge a complaint with the Commission for the Protection of Privacy (“Privacy Commission”) if you are of the opinion that the processing of your personal data breaches the Privacy Legislation":
            "de déposer une plainte auprès de la Commission de la protection de la vie privée (« Commission de la protection de la vie privée ») si vous estimez que le traitement de vos données personnelles enfreint la législation sur la vie privée",
          "In case you want to exercise the above rights, and provided you prove your identity, you can send a written, dated and signed request by mail to":
            "Si vous souhaitez exercer les droits ci-dessus et sous réserve que vous prouviez votre identité, vous pouvez envoyer une demande écrite, datée et signée par courrier à",
          "Eline Post, Director for Human Resource (HR) and Legal, Rue Belliard 40, 1040 Brussels, Belgium":
            "Eline Post, Directeur des ressources humaines (RH) et juridiques, Rue Belliard 40, 1040 Bruxelles, Belgique",
          "or by email to": "ou par e-mail à",
          Security: "Sécurité",
          "WindEurope commits to take (or have) all reasonable measures (taken) to its best ability to safeguard the protection of personal data through technical safety measures and an appropriate safety policy for its employees. The personal data are stored with WindEurope, or, if applicable, with the processor of the personal data on servers located in Stockholm, Sweden. You acknowledge and accept that the transfer and storage of personal data is never without risk and consequently, the damage that you may suffer from the unlawful use of your personal data by third parties can never be collected from WindEurope":
            "WindEurope s'engage à prendre (ou à faire prendre) toutes les mesures raisonnables en son pouvoir pour garantir la protection des données à caractère personnel grâce à des mesures de sécurité techniques et à une politique de sécurité appropriée pour ses employés. Les données à caractère personnel sont stockées chez WindEurope ou, le cas échéant, chez le sous-traitant des données à caractère personnel sur des serveurs situés à Stockholm, en Suède. Vous reconnaissez et acceptez que le transfert et le stockage de données à caractère personnel ne sont jamais sans risque et, par conséquent, les dommages que vous pourriez subir du fait de l'utilisation illicite de vos données à caractère personnel par des tiers ne pourront jamais être récupérés auprès de WindEurope",
          Liability: "Responsabilité",
          "WindEurope can solely be held accountable for damages which directly result from the processing of your personal data due to a fault or negligence of WindEurope. In any event, WindEurope cannot be held liable: (i) in circumstances of force majeure and/or (ii) for any indirect or consequential damage and/or (iii) for damages that result from errors, faults or negligence by you or third parties. WindEurope's liability cannot exceed 1,000 euro (thousand euro) for breaches regarding this privacy policy and/or Privacy Legislation":
            "WindEurope ne peut être tenu responsable que des dommages résultant directement du traitement de vos données à caractère personnel en raison d'une faute ou d'une négligence de WindEurope. En tout état de cause, WindEurope ne peut être tenu responsable : (i) en cas de force majeure et/ou (ii) de tout dommage indirect ou consécutif et/ou (iii) de dommages résultant d'erreurs, de fautes ou de négligences de votre part ou de celles de tiers. La responsabilité de WindEurope ne peut excéder 1 000 euros (mille euros) pour violation de la présente politique de confidentialité et/ou de la législation sur la protection de la vie privée",
          Severability: "Divisibilité",
          "If any provision in this privacy policy is deemed to be unlawful or unenforceable, that provision shall be amended by WindEurope and yourself insofar as necessary to make it lawful or enforceable, while retaining the meaning of WindEurope and yourself with respect to that provision as much as possible":
            "Si une disposition de la présente politique de confidentialité est jugée illégale ou inapplicable, cette disposition sera modifiée par WindEurope et vous-même dans la mesure nécessaire pour la rendre légale ou applicable, tout en conservant autant que possible la signification que WindEurope et vous-même lui avez donnée",
          "Whenever possible, the provisions of this privacy policy shall be interpreted in such a manner as to be valid and enforceable under the applicable law. However, if one or more provisions of this privacy policy are found to be invalid, illegal or unenforceable, in whole or in part, the remainder of that provision and of this privacy policy shall remain in full force and effect as if such invalid, illegal or unenforceable provision had never been contained herein":
            "Dans la mesure du possible, les dispositions de la présente politique de confidentialité doivent être interprétées de manière à être valables et applicables en vertu de la loi applicable. Toutefois, si une ou plusieurs dispositions de la présente politique de confidentialité sont jugées invalides, illégales ou inapplicables, en tout ou en partie, le reste de cette disposition et de la présente politique demeurera pleinement en vigueur et applicable comme si une telle disposition invalide, illégale ou inapplicable n'avait jamais été incluse dans les présentes",
          "Applicable law and Competent court":
            "Droit applicable et tribunal compétent",
          "You agree that all disputes between you and WindEurope regarding personal data and privacy issues, are exclusively subject to Belgian law, excluding any conflict of law principles":
            "Vous acceptez que tout litige entre vous et WindEurope concernant les données à caractère personnel et la confidentialité soit exclusivement soumis au droit belge, à l'exclusion de tout principe de conflit de lois",
          "Every dispute regarding personal data and privacy issues belongs to the exclusive jurisdiction of the Dutch speaking courts of Brussels, Belgium, excluding any other court":
            "Tout litige concernant les données à caractère personnel et la confidentialité relève de la compétence exclusive des tribunaux néerlandophones de Bruxelles, Belgique, à l'exclusion de tout autre tribunal",
          // Access denied and 404 Not found
          "Access Denied": "Accès refusé",
          "Sorry, you do not have permission to access this page.":
            "Désolé, vous n'êtes pas autorisé à accéder à cette page.",
          "Back to Homepage": "Retour à la page d'accueil",
          "404 Not Found": "404 Page non trouvée",
          "Oops! The page you're trying to access does not exist.":
            "Oups! La page que vous essayez d'accéder n'existe pas.",
        },
      },
    },
  });
