/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createOrganisation = /* GraphQL */ `
  mutation CreateOrganisation(
    $input: CreateOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    createOrganisation(input: $input, condition: $condition) {
      id
      domain
      role
      municipality
      personnel {
        items {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        nextToken
      }
      projects {
        items {
          id
          organisationId
          projectId
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          admins
          subscribers
        }
        nextToken
      }
      name
      createdAt
      updatedAt
    }
  }
`;
export const updateOrganisation = /* GraphQL */ `
  mutation UpdateOrganisation(
    $input: UpdateOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    updateOrganisation(input: $input, condition: $condition) {
      id
      domain
      role
      municipality
      personnel {
        items {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        nextToken
      }
      projects {
        items {
          id
          organisationId
          projectId
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          admins
          subscribers
        }
        nextToken
      }
      name
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrganisation = /* GraphQL */ `
  mutation DeleteOrganisation(
    $input: DeleteOrganisationInput!
    $condition: ModelOrganisationConditionInput
  ) {
    deleteOrganisation(input: $input, condition: $condition) {
      id
      domain
      role
      municipality
      personnel {
        items {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        nextToken
      }
      projects {
        items {
          id
          organisationId
          projectId
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          admins
          subscribers
        }
        nextToken
      }
      name
      createdAt
      updatedAt
    }
  }
`;
export const createGDPR = /* GraphQL */ `
  mutation CreateGDPR(
    $input: CreateGDPRInput!
    $condition: ModelGDPRConditionInput
  ) {
    createGDPR(input: $input, condition: $condition) {
      id
      personaId
      given_name
      family_name
      email
      phone_number
      organisation
      municipality
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateGDPR = /* GraphQL */ `
  mutation UpdateGDPR(
    $input: UpdateGDPRInput!
    $condition: ModelGDPRConditionInput
  ) {
    updateGDPR(input: $input, condition: $condition) {
      id
      personaId
      given_name
      family_name
      email
      phone_number
      organisation
      municipality
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteGDPR = /* GraphQL */ `
  mutation DeleteGDPR(
    $input: DeleteGDPRInput!
    $condition: ModelGDPRConditionInput
  ) {
    deleteGDPR(input: $input, condition: $condition) {
      id
      personaId
      given_name
      family_name
      email
      phone_number
      organisation
      municipality
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createPersona = /* GraphQL */ `
  mutation CreatePersona(
    $input: CreatePersonaInput!
    $condition: ModelPersonaConditionInput
  ) {
    createPersona(input: $input, condition: $condition) {
      id
      owner
      productivityExpanded
      tasksExpanded
      given_name
      family_name
      email
      phone_number
      role
      domain
      dateOfPrivacyConsent
      municipality
      assignedTasks {
        items {
          id
          deadline
          title
          description
          mandatory
          owners
          ownerGroups
          subscribers
          subscriberGroups
          stage
          done
          done_at
          documents {
            nextToken
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      assignedDocuments {
        items {
          id
          name
          description
          deadline
          owners
          ownerGroups
          subscribers
          subscriberGroups
          mandatory
          stage
          publishable
          status
          a_upload_at
          b_review_at
          c_revise_at
          d_done_at
          comments {
            nextToken
          }
          tasks {
            nextToken
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          reviewerId
          reviewedBy {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      reviewingDocuments {
        items {
          id
          name
          description
          deadline
          owners
          ownerGroups
          subscribers
          subscriberGroups
          mandatory
          stage
          publishable
          status
          a_upload_at
          b_review_at
          c_revise_at
          d_done_at
          comments {
            nextToken
          }
          tasks {
            nextToken
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          reviewerId
          reviewedBy {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      organisation {
        id
        domain
        role
        municipality
        personnel {
          items {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          nextToken
        }
        projects {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        name
        createdAt
        updatedAt
      }
      projectComments {
        items {
          owner
          subscribers
          subscriberGroups
          comment
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          personaId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      documentComments {
        items {
          owner
          subscribers
          subscriberGroups
          section
          page
          version
          comment
          documentId
          document {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          personaId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      mainAgentProjects {
        items {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      mainDeveloperProjects {
        items {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      agentProjects {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      smeProjects {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      developerProject {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      visitedProjects {
        projectId
        visitedAt
      }
      createdAt
      updatedAt
      organisationPersonnelId
    }
  }
`;
export const updatePersona = /* GraphQL */ `
  mutation UpdatePersona(
    $input: UpdatePersonaInput!
    $condition: ModelPersonaConditionInput
  ) {
    updatePersona(input: $input, condition: $condition) {
      id
      owner
      productivityExpanded
      tasksExpanded
      given_name
      family_name
      email
      phone_number
      role
      domain
      dateOfPrivacyConsent
      municipality
      assignedTasks {
        items {
          id
          deadline
          title
          description
          mandatory
          owners
          ownerGroups
          subscribers
          subscriberGroups
          stage
          done
          done_at
          documents {
            nextToken
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      assignedDocuments {
        items {
          id
          name
          description
          deadline
          owners
          ownerGroups
          subscribers
          subscriberGroups
          mandatory
          stage
          publishable
          status
          a_upload_at
          b_review_at
          c_revise_at
          d_done_at
          comments {
            nextToken
          }
          tasks {
            nextToken
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          reviewerId
          reviewedBy {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      reviewingDocuments {
        items {
          id
          name
          description
          deadline
          owners
          ownerGroups
          subscribers
          subscriberGroups
          mandatory
          stage
          publishable
          status
          a_upload_at
          b_review_at
          c_revise_at
          d_done_at
          comments {
            nextToken
          }
          tasks {
            nextToken
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          reviewerId
          reviewedBy {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      organisation {
        id
        domain
        role
        municipality
        personnel {
          items {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          nextToken
        }
        projects {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        name
        createdAt
        updatedAt
      }
      projectComments {
        items {
          owner
          subscribers
          subscriberGroups
          comment
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          personaId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      documentComments {
        items {
          owner
          subscribers
          subscriberGroups
          section
          page
          version
          comment
          documentId
          document {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          personaId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      mainAgentProjects {
        items {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      mainDeveloperProjects {
        items {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      agentProjects {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      smeProjects {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      developerProject {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      visitedProjects {
        projectId
        visitedAt
      }
      createdAt
      updatedAt
      organisationPersonnelId
    }
  }
`;
export const deletePersona = /* GraphQL */ `
  mutation DeletePersona(
    $input: DeletePersonaInput!
    $condition: ModelPersonaConditionInput
  ) {
    deletePersona(input: $input, condition: $condition) {
      id
      owner
      productivityExpanded
      tasksExpanded
      given_name
      family_name
      email
      phone_number
      role
      domain
      dateOfPrivacyConsent
      municipality
      assignedTasks {
        items {
          id
          deadline
          title
          description
          mandatory
          owners
          ownerGroups
          subscribers
          subscriberGroups
          stage
          done
          done_at
          documents {
            nextToken
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      assignedDocuments {
        items {
          id
          name
          description
          deadline
          owners
          ownerGroups
          subscribers
          subscriberGroups
          mandatory
          stage
          publishable
          status
          a_upload_at
          b_review_at
          c_revise_at
          d_done_at
          comments {
            nextToken
          }
          tasks {
            nextToken
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          reviewerId
          reviewedBy {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      reviewingDocuments {
        items {
          id
          name
          description
          deadline
          owners
          ownerGroups
          subscribers
          subscriberGroups
          mandatory
          stage
          publishable
          status
          a_upload_at
          b_review_at
          c_revise_at
          d_done_at
          comments {
            nextToken
          }
          tasks {
            nextToken
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          reviewerId
          reviewedBy {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      organisation {
        id
        domain
        role
        municipality
        personnel {
          items {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          nextToken
        }
        projects {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        name
        createdAt
        updatedAt
      }
      projectComments {
        items {
          owner
          subscribers
          subscriberGroups
          comment
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          personaId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      documentComments {
        items {
          owner
          subscribers
          subscriberGroups
          section
          page
          version
          comment
          documentId
          document {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          personaId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      mainAgentProjects {
        items {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      mainDeveloperProjects {
        items {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      agentProjects {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      smeProjects {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      developerProject {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      visitedProjects {
        projectId
        visitedAt
      }
      createdAt
      updatedAt
      organisationPersonnelId
    }
  }
`;
export const createProjectDeadline = /* GraphQL */ `
  mutation CreateProjectDeadline(
    $input: CreateProjectDeadlineInput!
    $condition: ModelProjectDeadlineConditionInput
  ) {
    createProjectDeadline(input: $input, condition: $condition) {
      id
      date
      owner
      deadlineEnum
      stage
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      projectDeadlinesId
    }
  }
`;
export const updateProjectDeadline = /* GraphQL */ `
  mutation UpdateProjectDeadline(
    $input: UpdateProjectDeadlineInput!
    $condition: ModelProjectDeadlineConditionInput
  ) {
    updateProjectDeadline(input: $input, condition: $condition) {
      id
      date
      owner
      deadlineEnum
      stage
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      projectDeadlinesId
    }
  }
`;
export const deleteProjectDeadline = /* GraphQL */ `
  mutation DeleteProjectDeadline(
    $input: DeleteProjectDeadlineInput!
    $condition: ModelProjectDeadlineConditionInput
  ) {
    deleteProjectDeadline(input: $input, condition: $condition) {
      id
      date
      owner
      deadlineEnum
      stage
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      projectDeadlinesId
    }
  }
`;
export const createProject = /* GraphQL */ `
  mutation CreateProject(
    $input: CreateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    createProject(input: $input, condition: $condition) {
      id
      workflowSet
      isInRevision
      summaryVerified
      summaryEditable
      projectClosed
      admins
      subscribers
      adminGroups
      subscriberGroups
      name
      description
      longitude
      latitude
      contact_organization
      contact_person
      contact_email
      contact_phone
      contact_link
      feedback_link
      additionalInfo
      provides_households
      projectType
      a_preplanning_closed
      b_initial_consultation_closed
      c_planning_closed
      d_final_consultation_closed
      e_approval_closed
      nested_update_at
      notificationMessage
      isInRevision_at
      projectClosed_at
      workflowSet_at
      size
      capacity
      turbines
      height
      diameter
      startDate
      endDate
      municipalities
      preAgreementLandowners
      benefits
      relationNeighbours
      stage
      deadlines {
        items {
          id
          date
          owner
          deadlineEnum
          stage
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          projectDeadlinesId
        }
        nextToken
      }
      comments {
        items {
          owner
          subscribers
          subscriberGroups
          comment
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          personaId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          name
          description
          deadline
          owners
          ownerGroups
          subscribers
          subscriberGroups
          mandatory
          stage
          publishable
          status
          a_upload_at
          b_review_at
          c_revise_at
          d_done_at
          comments {
            nextToken
          }
          tasks {
            nextToken
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          reviewerId
          reviewedBy {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      tasks {
        items {
          id
          deadline
          title
          description
          mandatory
          owners
          ownerGroups
          subscribers
          subscriberGroups
          stage
          done
          done_at
          documents {
            nextToken
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      mainAgentId
      mainAgent {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      mainDeveloperId
      mainDeveloper {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      smes {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      developers {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      agents {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      organisations {
        items {
          id
          organisationId
          projectId
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          admins
          subscribers
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateProject = /* GraphQL */ `
  mutation UpdateProject(
    $input: UpdateProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    updateProject(input: $input, condition: $condition) {
      id
      workflowSet
      isInRevision
      summaryVerified
      summaryEditable
      projectClosed
      admins
      subscribers
      adminGroups
      subscriberGroups
      name
      description
      longitude
      latitude
      contact_organization
      contact_person
      contact_email
      contact_phone
      contact_link
      feedback_link
      additionalInfo
      provides_households
      projectType
      a_preplanning_closed
      b_initial_consultation_closed
      c_planning_closed
      d_final_consultation_closed
      e_approval_closed
      nested_update_at
      notificationMessage
      isInRevision_at
      projectClosed_at
      workflowSet_at
      size
      capacity
      turbines
      height
      diameter
      startDate
      endDate
      municipalities
      preAgreementLandowners
      benefits
      relationNeighbours
      stage
      deadlines {
        items {
          id
          date
          owner
          deadlineEnum
          stage
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          projectDeadlinesId
        }
        nextToken
      }
      comments {
        items {
          owner
          subscribers
          subscriberGroups
          comment
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          personaId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          name
          description
          deadline
          owners
          ownerGroups
          subscribers
          subscriberGroups
          mandatory
          stage
          publishable
          status
          a_upload_at
          b_review_at
          c_revise_at
          d_done_at
          comments {
            nextToken
          }
          tasks {
            nextToken
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          reviewerId
          reviewedBy {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      tasks {
        items {
          id
          deadline
          title
          description
          mandatory
          owners
          ownerGroups
          subscribers
          subscriberGroups
          stage
          done
          done_at
          documents {
            nextToken
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      mainAgentId
      mainAgent {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      mainDeveloperId
      mainDeveloper {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      smes {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      developers {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      agents {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      organisations {
        items {
          id
          organisationId
          projectId
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          admins
          subscribers
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteProject = /* GraphQL */ `
  mutation DeleteProject(
    $input: DeleteProjectInput!
    $condition: ModelProjectConditionInput
  ) {
    deleteProject(input: $input, condition: $condition) {
      id
      workflowSet
      isInRevision
      summaryVerified
      summaryEditable
      projectClosed
      admins
      subscribers
      adminGroups
      subscriberGroups
      name
      description
      longitude
      latitude
      contact_organization
      contact_person
      contact_email
      contact_phone
      contact_link
      feedback_link
      additionalInfo
      provides_households
      projectType
      a_preplanning_closed
      b_initial_consultation_closed
      c_planning_closed
      d_final_consultation_closed
      e_approval_closed
      nested_update_at
      notificationMessage
      isInRevision_at
      projectClosed_at
      workflowSet_at
      size
      capacity
      turbines
      height
      diameter
      startDate
      endDate
      municipalities
      preAgreementLandowners
      benefits
      relationNeighbours
      stage
      deadlines {
        items {
          id
          date
          owner
          deadlineEnum
          stage
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          projectDeadlinesId
        }
        nextToken
      }
      comments {
        items {
          owner
          subscribers
          subscriberGroups
          comment
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          personaId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          name
          description
          deadline
          owners
          ownerGroups
          subscribers
          subscriberGroups
          mandatory
          stage
          publishable
          status
          a_upload_at
          b_review_at
          c_revise_at
          d_done_at
          comments {
            nextToken
          }
          tasks {
            nextToken
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          reviewerId
          reviewedBy {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      tasks {
        items {
          id
          deadline
          title
          description
          mandatory
          owners
          ownerGroups
          subscribers
          subscriberGroups
          stage
          done
          done_at
          documents {
            nextToken
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      mainAgentId
      mainAgent {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      mainDeveloperId
      mainDeveloper {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      smes {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      developers {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      agents {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      organisations {
        items {
          id
          organisationId
          projectId
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          admins
          subscribers
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createProjectComment = /* GraphQL */ `
  mutation CreateProjectComment(
    $input: CreateProjectCommentInput!
    $condition: ModelProjectCommentConditionInput
  ) {
    createProjectComment(input: $input, condition: $condition) {
      owner
      subscribers
      subscriberGroups
      comment
      projectId
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      personaId
      persona {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateProjectComment = /* GraphQL */ `
  mutation UpdateProjectComment(
    $input: UpdateProjectCommentInput!
    $condition: ModelProjectCommentConditionInput
  ) {
    updateProjectComment(input: $input, condition: $condition) {
      owner
      subscribers
      subscriberGroups
      comment
      projectId
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      personaId
      persona {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteProjectComment = /* GraphQL */ `
  mutation DeleteProjectComment(
    $input: DeleteProjectCommentInput!
    $condition: ModelProjectCommentConditionInput
  ) {
    deleteProjectComment(input: $input, condition: $condition) {
      owner
      subscribers
      subscriberGroups
      comment
      projectId
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      personaId
      persona {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const createDocument = /* GraphQL */ `
  mutation CreateDocument(
    $input: CreateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    createDocument(input: $input, condition: $condition) {
      id
      name
      description
      deadline
      owners
      ownerGroups
      subscribers
      subscriberGroups
      mandatory
      stage
      publishable
      status
      a_upload_at
      b_review_at
      c_revise_at
      d_done_at
      comments {
        items {
          owner
          subscribers
          subscriberGroups
          section
          page
          version
          comment
          documentId
          document {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          personaId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      tasks {
        items {
          id
          documentId
          taskId
          document {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          task {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owners
          subscribers
        }
        nextToken
      }
      assigneeId
      assignedTo {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      reviewerId
      reviewedBy {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      projectId
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDocument = /* GraphQL */ `
  mutation UpdateDocument(
    $input: UpdateDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    updateDocument(input: $input, condition: $condition) {
      id
      name
      description
      deadline
      owners
      ownerGroups
      subscribers
      subscriberGroups
      mandatory
      stage
      publishable
      status
      a_upload_at
      b_review_at
      c_revise_at
      d_done_at
      comments {
        items {
          owner
          subscribers
          subscriberGroups
          section
          page
          version
          comment
          documentId
          document {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          personaId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      tasks {
        items {
          id
          documentId
          taskId
          document {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          task {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owners
          subscribers
        }
        nextToken
      }
      assigneeId
      assignedTo {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      reviewerId
      reviewedBy {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      projectId
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDocument = /* GraphQL */ `
  mutation DeleteDocument(
    $input: DeleteDocumentInput!
    $condition: ModelDocumentConditionInput
  ) {
    deleteDocument(input: $input, condition: $condition) {
      id
      name
      description
      deadline
      owners
      ownerGroups
      subscribers
      subscriberGroups
      mandatory
      stage
      publishable
      status
      a_upload_at
      b_review_at
      c_revise_at
      d_done_at
      comments {
        items {
          owner
          subscribers
          subscriberGroups
          section
          page
          version
          comment
          documentId
          document {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          personaId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      tasks {
        items {
          id
          documentId
          taskId
          document {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          task {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owners
          subscribers
        }
        nextToken
      }
      assigneeId
      assignedTo {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      reviewerId
      reviewedBy {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      projectId
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDocumentComment = /* GraphQL */ `
  mutation CreateDocumentComment(
    $input: CreateDocumentCommentInput!
    $condition: ModelDocumentCommentConditionInput
  ) {
    createDocumentComment(input: $input, condition: $condition) {
      owner
      subscribers
      subscriberGroups
      section
      page
      version
      comment
      documentId
      document {
        id
        name
        description
        deadline
        owners
        ownerGroups
        subscribers
        subscriberGroups
        mandatory
        stage
        publishable
        status
        a_upload_at
        b_review_at
        c_revise_at
        d_done_at
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            documentId
            taskId
            createdAt
            updatedAt
            owners
            subscribers
          }
          nextToken
        }
        assigneeId
        assignedTo {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        reviewerId
        reviewedBy {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        projectId
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      personaId
      persona {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const updateDocumentComment = /* GraphQL */ `
  mutation UpdateDocumentComment(
    $input: UpdateDocumentCommentInput!
    $condition: ModelDocumentCommentConditionInput
  ) {
    updateDocumentComment(input: $input, condition: $condition) {
      owner
      subscribers
      subscriberGroups
      section
      page
      version
      comment
      documentId
      document {
        id
        name
        description
        deadline
        owners
        ownerGroups
        subscribers
        subscriberGroups
        mandatory
        stage
        publishable
        status
        a_upload_at
        b_review_at
        c_revise_at
        d_done_at
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            documentId
            taskId
            createdAt
            updatedAt
            owners
            subscribers
          }
          nextToken
        }
        assigneeId
        assignedTo {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        reviewerId
        reviewedBy {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        projectId
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      personaId
      persona {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const deleteDocumentComment = /* GraphQL */ `
  mutation DeleteDocumentComment(
    $input: DeleteDocumentCommentInput!
    $condition: ModelDocumentCommentConditionInput
  ) {
    deleteDocumentComment(input: $input, condition: $condition) {
      owner
      subscribers
      subscriberGroups
      section
      page
      version
      comment
      documentId
      document {
        id
        name
        description
        deadline
        owners
        ownerGroups
        subscribers
        subscriberGroups
        mandatory
        stage
        publishable
        status
        a_upload_at
        b_review_at
        c_revise_at
        d_done_at
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            documentId
            taskId
            createdAt
            updatedAt
            owners
            subscribers
          }
          nextToken
        }
        assigneeId
        assignedTo {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        reviewerId
        reviewedBy {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        projectId
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      personaId
      persona {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const createTask = /* GraphQL */ `
  mutation CreateTask(
    $input: CreateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    createTask(input: $input, condition: $condition) {
      id
      deadline
      title
      description
      mandatory
      owners
      ownerGroups
      subscribers
      subscriberGroups
      stage
      done
      done_at
      documents {
        items {
          id
          documentId
          taskId
          document {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          task {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owners
          subscribers
        }
        nextToken
      }
      projectId
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      assigneeId
      assignedTo {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTask = /* GraphQL */ `
  mutation UpdateTask(
    $input: UpdateTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    updateTask(input: $input, condition: $condition) {
      id
      deadline
      title
      description
      mandatory
      owners
      ownerGroups
      subscribers
      subscriberGroups
      stage
      done
      done_at
      documents {
        items {
          id
          documentId
          taskId
          document {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          task {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owners
          subscribers
        }
        nextToken
      }
      projectId
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      assigneeId
      assignedTo {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTask = /* GraphQL */ `
  mutation DeleteTask(
    $input: DeleteTaskInput!
    $condition: ModelTaskConditionInput
  ) {
    deleteTask(input: $input, condition: $condition) {
      id
      deadline
      title
      description
      mandatory
      owners
      ownerGroups
      subscribers
      subscriberGroups
      stage
      done
      done_at
      documents {
        items {
          id
          documentId
          taskId
          document {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          task {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owners
          subscribers
        }
        nextToken
      }
      projectId
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      assigneeId
      assignedTo {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTaskTemplates = /* GraphQL */ `
  mutation CreateTaskTemplates(
    $input: CreateTaskTemplatesInput!
    $condition: ModelTaskTemplatesConditionInput
  ) {
    createTaskTemplates(input: $input, condition: $condition) {
      id
      taskTitle
      mandatory
      taskDescription
      relevantDocuments
      assignedTo
      stage
      municipality
      createdAt
      updatedAt
    }
  }
`;
export const updateTaskTemplates = /* GraphQL */ `
  mutation UpdateTaskTemplates(
    $input: UpdateTaskTemplatesInput!
    $condition: ModelTaskTemplatesConditionInput
  ) {
    updateTaskTemplates(input: $input, condition: $condition) {
      id
      taskTitle
      mandatory
      taskDescription
      relevantDocuments
      assignedTo
      stage
      municipality
      createdAt
      updatedAt
    }
  }
`;
export const deleteTaskTemplates = /* GraphQL */ `
  mutation DeleteTaskTemplates(
    $input: DeleteTaskTemplatesInput!
    $condition: ModelTaskTemplatesConditionInput
  ) {
    deleteTaskTemplates(input: $input, condition: $condition) {
      id
      taskTitle
      mandatory
      taskDescription
      relevantDocuments
      assignedTo
      stage
      municipality
      createdAt
      updatedAt
    }
  }
`;
export const createDocumentTemplates = /* GraphQL */ `
  mutation CreateDocumentTemplates(
    $input: CreateDocumentTemplatesInput!
    $condition: ModelDocumentTemplatesConditionInput
  ) {
    createDocumentTemplates(input: $input, condition: $condition) {
      id
      documentSlug
      documentName
      documentDescription
      documentMandatory
      assignedTo
      publishable
      stage
      municipality
      createdAt
      updatedAt
    }
  }
`;
export const updateDocumentTemplates = /* GraphQL */ `
  mutation UpdateDocumentTemplates(
    $input: UpdateDocumentTemplatesInput!
    $condition: ModelDocumentTemplatesConditionInput
  ) {
    updateDocumentTemplates(input: $input, condition: $condition) {
      id
      documentSlug
      documentName
      documentDescription
      documentMandatory
      assignedTo
      publishable
      stage
      municipality
      createdAt
      updatedAt
    }
  }
`;
export const deleteDocumentTemplates = /* GraphQL */ `
  mutation DeleteDocumentTemplates(
    $input: DeleteDocumentTemplatesInput!
    $condition: ModelDocumentTemplatesConditionInput
  ) {
    deleteDocumentTemplates(input: $input, condition: $condition) {
      id
      documentSlug
      documentName
      documentDescription
      documentMandatory
      assignedTo
      publishable
      stage
      municipality
      createdAt
      updatedAt
    }
  }
`;
export const createOrganisationProjects = /* GraphQL */ `
  mutation CreateOrganisationProjects(
    $input: CreateOrganisationProjectsInput!
    $condition: ModelOrganisationProjectsConditionInput
  ) {
    createOrganisationProjects(input: $input, condition: $condition) {
      id
      organisationId
      projectId
      organisation {
        id
        domain
        role
        municipality
        personnel {
          items {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          nextToken
        }
        projects {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        name
        createdAt
        updatedAt
      }
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      admins
      subscribers
    }
  }
`;
export const updateOrganisationProjects = /* GraphQL */ `
  mutation UpdateOrganisationProjects(
    $input: UpdateOrganisationProjectsInput!
    $condition: ModelOrganisationProjectsConditionInput
  ) {
    updateOrganisationProjects(input: $input, condition: $condition) {
      id
      organisationId
      projectId
      organisation {
        id
        domain
        role
        municipality
        personnel {
          items {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          nextToken
        }
        projects {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        name
        createdAt
        updatedAt
      }
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      admins
      subscribers
    }
  }
`;
export const deleteOrganisationProjects = /* GraphQL */ `
  mutation DeleteOrganisationProjects(
    $input: DeleteOrganisationProjectsInput!
    $condition: ModelOrganisationProjectsConditionInput
  ) {
    deleteOrganisationProjects(input: $input, condition: $condition) {
      id
      organisationId
      projectId
      organisation {
        id
        domain
        role
        municipality
        personnel {
          items {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          nextToken
        }
        projects {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        name
        createdAt
        updatedAt
      }
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      admins
      subscribers
    }
  }
`;
export const createProjectAgents = /* GraphQL */ `
  mutation CreateProjectAgents(
    $input: CreateProjectAgentsInput!
    $condition: ModelProjectAgentsConditionInput
  ) {
    createProjectAgents(input: $input, condition: $condition) {
      id
      personaId
      projectId
      persona {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
      admins
      subscribers
    }
  }
`;
export const updateProjectAgents = /* GraphQL */ `
  mutation UpdateProjectAgents(
    $input: UpdateProjectAgentsInput!
    $condition: ModelProjectAgentsConditionInput
  ) {
    updateProjectAgents(input: $input, condition: $condition) {
      id
      personaId
      projectId
      persona {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
      admins
      subscribers
    }
  }
`;
export const deleteProjectAgents = /* GraphQL */ `
  mutation DeleteProjectAgents(
    $input: DeleteProjectAgentsInput!
    $condition: ModelProjectAgentsConditionInput
  ) {
    deleteProjectAgents(input: $input, condition: $condition) {
      id
      personaId
      projectId
      persona {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
      admins
      subscribers
    }
  }
`;
export const createProjectSmes = /* GraphQL */ `
  mutation CreateProjectSmes(
    $input: CreateProjectSmesInput!
    $condition: ModelProjectSmesConditionInput
  ) {
    createProjectSmes(input: $input, condition: $condition) {
      id
      personaId
      projectId
      persona {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
      admins
      subscribers
    }
  }
`;
export const updateProjectSmes = /* GraphQL */ `
  mutation UpdateProjectSmes(
    $input: UpdateProjectSmesInput!
    $condition: ModelProjectSmesConditionInput
  ) {
    updateProjectSmes(input: $input, condition: $condition) {
      id
      personaId
      projectId
      persona {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
      admins
      subscribers
    }
  }
`;
export const deleteProjectSmes = /* GraphQL */ `
  mutation DeleteProjectSmes(
    $input: DeleteProjectSmesInput!
    $condition: ModelProjectSmesConditionInput
  ) {
    deleteProjectSmes(input: $input, condition: $condition) {
      id
      personaId
      projectId
      persona {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
      admins
      subscribers
    }
  }
`;
export const createProjectDevelopers = /* GraphQL */ `
  mutation CreateProjectDevelopers(
    $input: CreateProjectDevelopersInput!
    $condition: ModelProjectDevelopersConditionInput
  ) {
    createProjectDevelopers(input: $input, condition: $condition) {
      id
      personaId
      projectId
      persona {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
      admins
      subscribers
    }
  }
`;
export const updateProjectDevelopers = /* GraphQL */ `
  mutation UpdateProjectDevelopers(
    $input: UpdateProjectDevelopersInput!
    $condition: ModelProjectDevelopersConditionInput
  ) {
    updateProjectDevelopers(input: $input, condition: $condition) {
      id
      personaId
      projectId
      persona {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
      admins
      subscribers
    }
  }
`;
export const deleteProjectDevelopers = /* GraphQL */ `
  mutation DeleteProjectDevelopers(
    $input: DeleteProjectDevelopersInput!
    $condition: ModelProjectDevelopersConditionInput
  ) {
    deleteProjectDevelopers(input: $input, condition: $condition) {
      id
      personaId
      projectId
      persona {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
      admins
      subscribers
    }
  }
`;
export const createDocumentTasks = /* GraphQL */ `
  mutation CreateDocumentTasks(
    $input: CreateDocumentTasksInput!
    $condition: ModelDocumentTasksConditionInput
  ) {
    createDocumentTasks(input: $input, condition: $condition) {
      id
      documentId
      taskId
      document {
        id
        name
        description
        deadline
        owners
        ownerGroups
        subscribers
        subscriberGroups
        mandatory
        stage
        publishable
        status
        a_upload_at
        b_review_at
        c_revise_at
        d_done_at
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            documentId
            taskId
            createdAt
            updatedAt
            owners
            subscribers
          }
          nextToken
        }
        assigneeId
        assignedTo {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        reviewerId
        reviewedBy {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        projectId
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      task {
        id
        deadline
        title
        description
        mandatory
        owners
        ownerGroups
        subscribers
        subscriberGroups
        stage
        done
        done_at
        documents {
          items {
            id
            documentId
            taskId
            createdAt
            updatedAt
            owners
            subscribers
          }
          nextToken
        }
        projectId
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        assigneeId
        assignedTo {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owners
      subscribers
    }
  }
`;
export const updateDocumentTasks = /* GraphQL */ `
  mutation UpdateDocumentTasks(
    $input: UpdateDocumentTasksInput!
    $condition: ModelDocumentTasksConditionInput
  ) {
    updateDocumentTasks(input: $input, condition: $condition) {
      id
      documentId
      taskId
      document {
        id
        name
        description
        deadline
        owners
        ownerGroups
        subscribers
        subscriberGroups
        mandatory
        stage
        publishable
        status
        a_upload_at
        b_review_at
        c_revise_at
        d_done_at
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            documentId
            taskId
            createdAt
            updatedAt
            owners
            subscribers
          }
          nextToken
        }
        assigneeId
        assignedTo {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        reviewerId
        reviewedBy {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        projectId
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      task {
        id
        deadline
        title
        description
        mandatory
        owners
        ownerGroups
        subscribers
        subscriberGroups
        stage
        done
        done_at
        documents {
          items {
            id
            documentId
            taskId
            createdAt
            updatedAt
            owners
            subscribers
          }
          nextToken
        }
        projectId
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        assigneeId
        assignedTo {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owners
      subscribers
    }
  }
`;
export const deleteDocumentTasks = /* GraphQL */ `
  mutation DeleteDocumentTasks(
    $input: DeleteDocumentTasksInput!
    $condition: ModelDocumentTasksConditionInput
  ) {
    deleteDocumentTasks(input: $input, condition: $condition) {
      id
      documentId
      taskId
      document {
        id
        name
        description
        deadline
        owners
        ownerGroups
        subscribers
        subscriberGroups
        mandatory
        stage
        publishable
        status
        a_upload_at
        b_review_at
        c_revise_at
        d_done_at
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            documentId
            taskId
            createdAt
            updatedAt
            owners
            subscribers
          }
          nextToken
        }
        assigneeId
        assignedTo {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        reviewerId
        reviewedBy {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        projectId
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      task {
        id
        deadline
        title
        description
        mandatory
        owners
        ownerGroups
        subscribers
        subscriberGroups
        stage
        done
        done_at
        documents {
          items {
            id
            documentId
            taskId
            createdAt
            updatedAt
            owners
            subscribers
          }
          nextToken
        }
        projectId
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        assigneeId
        assignedTo {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owners
      subscribers
    }
  }
`;
