import { useLocation, Navigate } from "react-router-dom";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";

export default function PrivateRoute({ children }) {
  const { i18n } = useTranslation();
  const location = useLocation();
  const { route } = useAuthenticator((context) => [context.route]);

  if (route === "authenticated") {
    return children;
  } else {
    return (
      <Navigate
        to={"/signin/" + i18n.language}
        state={{ from: location }}
        replace
      />
    );
  }
}

// source https://ui.docs.amplify.aws/react/guides/auth-protected#amplify-id-9
