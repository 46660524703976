import { useState, useContext } from "react";
import "../i18n";
import { useTranslation } from "react-i18next";
import { UserContext } from "../AppContexts";
import { Auth } from "aws-amplify";
import { Authenticator } from "@aws-amplify/ui-react";
import { Navigate } from "react-router-dom";
import { MapCodeToLabel } from "../shared";
import { TopNavigation, SpaceBetween } from "@cloudscape-design/components";
import Logo from "../assets/easypermits-logo.svg";

export default function NavigationBar() {
  const { t, i18n } = useTranslation();
  const { userDetails } = useContext(UserContext);
  const [selectedLang, setSelectedLang] = useState("");

  const mapLanguageCode = {
    en: "English",
    da: "Danish (Dansk)",
    pl: "Polish (Polski)",
    fr: "French (Français)",
  };

  return (
    <Authenticator>
      <TopNavigation
        identity={{
          href: "/",
          // title: "EasyPermits",
          logo: {
            alt: "easypermits logo",
            src: Logo,
          },
        }}
        utilities={[
          {
            type: "menu-dropdown",
            text: mapLanguageCode[i18n.resolvedLanguage],
            items: [
              {
                id: "en",
                text: (
                  <span style={{ fontWeight: selectedLang === "en" && "bold" }}>
                    English
                  </span>
                ),
              },
              {
                id: "da",
                text: (
                  <span style={{ fontWeight: selectedLang === "da" && "bold" }}>
                    Danish (Dansk)
                  </span>
                ),
              },
              {
                id: "pl",
                text: (
                  <span style={{ fontWeight: selectedLang === "pl" && "bold" }}>
                    Polish (Polski)
                  </span>
                ),
              },
              {
                id: "fr",
                text: (
                  <span style={{ fontWeight: selectedLang === "fr" && "bold" }}>
                    French (Français)
                  </span>
                ),
              },
            ],
            onItemClick: (event) => {
              i18n.changeLanguage(event.detail.id);
              setSelectedLang(event.detail.id);
            },
          },
          {
            type: "menu-dropdown",
            text: `${userDetails.given_name} ${userDetails.family_name}`,
            description: (
              <>
                <b>{userDetails.email}</b>
                <div style={{ color: "#ccc", marginTop: 7 }}>
                  <SpaceBetween size="xs">
                    <span>{userDetails.phone_number}</span>
                    <span>
                      {userDetails.role === "agents" ? "Agent" : "Developer"}
                    </span>
                    {userDetails.municipality && (
                      <span>
                        {MapCodeToLabel(userDetails.municipality)},{" "}
                        {userDetails.municipality.slice(0, 2) === "DK"
                          ? t("Denmark 🇩🇰")
                          : t("Poland 🇵🇱")}
                      </span>
                    )}
                  </SpaceBetween>
                </div>
              </>
            ),
            iconName: "user-profile",
            items: [
              // {
              //   id: "user-pages",
              //   text: t("User Pages"),
              //   items: [
              //     {
              //       id: "dasboard",
              //       text: t("Dashboard"),
              //       href: "/dashboard",
              //       external: false,
              //     },
              //     {
              //       id: "community",
              //       text: t("Community"),
              //       href: "/map",
              //       external: false,
              //     },
              //   ],
              // },
              // {
              //   id: "quick-links",
              //   text: t("Quick Links"),
              //   items: [
              //     {
              //       id: "support",
              //       text: t("Support"),
              //       href: "/support",
              //       external: true,
              //       externalIconAriaLabel: " (opens in new tab)",
              //     },
              //     {
              //       id: "faqs",
              //       text: t("FAQs"),
              //       href: "/faqs",
              //       external: true,
              //       externalIconAriaLabel: " (opens in new tab)",
              //     },
              //     {
              //       id: "dataPrivacy",
              //       text: t("Data Privacy"),
              //       href: "/data-privacy",
              //       external: true,
              //       externalIconAriaLabel: " (opens in new tab)",
              //     },
              //   ],
              // },
              { id: "signout", text: t("Sign out") },
            ],
            onItemClick: (event) => {
              if (event.detail.id === "signout") {
                Auth.signOut({ global: true });
                <Navigate to={"/signin/" + i18n.language} />;
              }
            },
          },
        ]}
        i18nStrings={{
          searchIconAriaLabel: "Search",
          searchDismissIconAriaLabel: "Close search",
          overflowMenuTriggerText: "More",
          overflowMenuTitleText: "All",
          overflowMenuBackIconAriaLabel: "Back",
          overflowMenuDismissIconAriaLabel: "Close menu",
        }}
      />
    </Authenticator>
  );
}
