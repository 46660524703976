import { useState, useContext, useEffect } from "react";
import { Authenticator } from "@aws-amplify/ui-react";
import { UserContext, MenuOpenContext } from "../../AppContexts";
import DataPrivacyPopup from "../../DataPrivacyPopup";
import { useParams } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { useTranslation } from "react-i18next";
import * as queries from "../../graphql/queries";
import * as optimizedQueries from "../../graphql/optimizedQueries";
import * as mutations from "../../graphql/mutations";
import {
  warningOrange,
  primaryGreen,
  primaryBlue,
  Divider,
  DividerThick,
  isDeadlinePassed,
  calculatePriority,
  MapCodeToLabel,
  SideNavigationContents,
} from "../../shared";
import {
  AppLayout,
  ContentLayout,
  Header,
  SpaceBetween,
  BreadcrumbGroup,
  Grid,
  Icon,
  Modal,
  Link,
  ExpandableSection,
  Box,
  Button,
  Container,
  FormField,
  Select,
  Input,
  DatePicker,
  Multiselect,
  StatusIndicator,
  Popover,
  Checkbox,
} from "@cloudscape-design/components";
import NavigationBar from "../NavigationBar";
import { Tooltip, IconButton } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import WarningIcon from "@mui/icons-material/Warning";
import EditIcon from "@mui/icons-material/Edit";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import PendingIcon from "@mui/icons-material/Pending";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CircularProgress from "@mui/material/CircularProgress";

export default function ExpandedDocuments() {
  const { id } = useParams(); // projectId in URL parameter
  const { t } = useTranslation();
  const { userDetails } = useContext(UserContext);
  const { isMenuOpen, setMenuOpen } = useContext(MenuOpenContext);
  var [projectDetails, setProjectDetails] = useState({});
  const [preplanningTasks, setPreplanningTasks] = useState([]);
  const [initialConsultationTasks, setInitialConsultationTasks] = useState([]);
  const [planningProcessTasks, setPlanningProcessTasks] = useState([]);
  const [finalConsultationTasks, setFinalConsultationTasks] = useState([]);
  const [approvalTasks, setApprovalTasks] = useState([]);
  const [loadingCircle, showLoadingCircle] = useState(false);

  // Create new task modal
  const [newTaskModal, showNewTaskModal] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [taskName, setTaskName] = useState("");
  const [deadline, setDeadline] = useState("");
  const [mandatory, setMandatory] = useState(false);
  const [stage, setStage] = useState({
    value: "",
    label: "",
  });
  const [assignee, setAssignee] = useState({
    label: "",
    value: "",
  });
  const [relatedDocuments, setRelatedDocuments] = useState([]);
  const assigneeOptions = projectDetails?.agents?.items
    ? [
        ...projectDetails?.agents?.items?.map((r) => r.persona),
        ...projectDetails?.developers?.items.map((r) => r.persona),
        ...projectDetails?.smes?.items.map((r) => r.persona),
        projectDetails?.mainAgent,
        projectDetails?.mainDeveloper,
      ]
    : [];

  const getProjectDetails = async () => {
    const res = await API.graphql(
      graphqlOperation(optimizedQueries.getProject, {
        id: id,
      })
    );
    if (res.data.getProject) {
      setProjectDetails((projectDetails = res.data.getProject));

      // Save tasks according to their stage
      setPreplanningTasks(
        projectDetails.tasks?.items.filter(
          (task) => task.stage === "a_preplanning"
        )
      );
      setInitialConsultationTasks(
        projectDetails.tasks?.items.filter(
          (task) => task.stage === "b_initial_consultation"
        )
      );
      setPlanningProcessTasks(
        projectDetails.tasks?.items.filter(
          (task) => task.stage === "c_planning"
        )
      );
      setFinalConsultationTasks(
        projectDetails.tasks?.items.filter(
          (task) => task.stage === "d_final_consultation"
        )
      );
      setApprovalTasks(
        projectDetails.tasks?.items.filter(
          (task) => task.stage === "e_approval"
        )
      );
    }
  };

  const createTask = async () => {
    const variables = {
      title: taskName,
      description: null,
      deadline: deadline,
      stage: stage.value,
      done: false,
      assigneeId: assignee.value,
      projectId: projectDetails.id,
    };

    await API.graphql(
      graphqlOperation(mutations.createTask, { input: variables })
    )
      .then(async (res) => {
        console.log("Successfully created new task!");
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    document.title = "EasyPermits®";
    getProjectDetails();
    // get all project documents
    if (projectDetails.documents?.items)
      setDocuments(projectDetails?.documents?.items);
  }, []);

  return (
    <Authenticator>
      {/* Data Privacy disclaimer popup  */}
      {userDetails.dateOfPrivacyConsent === null && <DataPrivacyPopup />}
      <NavigationBar />
      <AppLayout
        navigationOpen={isMenuOpen}
        navigation={<SideNavigationContents userRole={userDetails.role} />}
        onNavigationChange={(event) => setMenuOpen(event.detail.open)}
        toolsHide={true}
        content={
          <ContentLayout
            header={
              <SpaceBetween size="xl">
                <div />
                <BreadcrumbGroup
                  ariaLabel="Breadcrumbs"
                  items={[
                    { text: t("Dashboard"), href: "/dashboard" },
                    {
                      text: t("Project Dashboard"),
                      href: "/project/" + id,
                    },
                    {
                      text: t("Project Tasks"),
                      href: "#",
                    },
                  ]}
                />
                <Header
                  variant="h1"
                  description={
                    t("Created on") +
                    " " +
                    new Date(projectDetails?.createdAt).toDateString() +
                    " " +
                    t("by") +
                    " " +
                    projectDetails?.mainDeveloper?.given_name +
                    " " +
                    projectDetails?.mainDeveloper?.family_name
                  }
                >
                  {projectDetails?.name +
                    " " +
                    t("by") +
                    " " +
                    projectDetails?.mainDeveloper?.domain}
                </Header>
                <div />
              </SpaceBetween>
            }
          >
            <Container
              fitHeight
              header={
                <Header
                  variant="h2"
                  actions={
                    !projectDetails?.name ? (
                      <>
                        <CircularProgress size={20} />
                        <span style={{ paddingLeft: 10 }}>
                          <b>{t("Fetching data")}...</b>
                        </span>
                      </>
                    ) : (
                      // Only Main Agents and other assigned agents can add new documents
                      (userDetails.id === projectDetails?.mainAgentId ||
                        projectDetails?.agents?.items
                          .map((item) => item?.persona?.id)
                          .includes(userDetails.id)) && (
                        <Button
                          variant="primary"
                          iconAlign="left"
                          iconName="add-plus"
                          onClick={() => {
                            showNewTaskModal(true);
                          }}
                        >
                          {t("Add New Task")}
                        </Button>
                      )
                    )
                  }
                >
                  {t("All Project Tasks")}
                </Header>
              }
            >
              <div style={{ height: 30 }} />
              <SpaceBetween size="m">
                <DisplayTasksByStage
                  title={t("Pre-planning")}
                  projectDetails={projectDetails}
                  _tasks={preplanningTasks}
                  assigneeOptions={assigneeOptions}
                  defaultExpanded={projectDetails.stage === "a_preplanning"}
                  getProjectDetails={getProjectDetails}
                />
                <DisplayTasksByStage
                  title={t("Initial Public Consultation")}
                  projectDetails={projectDetails}
                  _tasks={initialConsultationTasks}
                  assigneeOptions={assigneeOptions}
                  defaultExpanded={
                    projectDetails.stage === "b_initial_consultation"
                  }
                  getProjectDetails={getProjectDetails}
                />
                <DisplayTasksByStage
                  title={t("Planning Process")}
                  projectDetails={projectDetails}
                  _tasks={planningProcessTasks}
                  assigneeOptions={assigneeOptions}
                  defaultExpanded={projectDetails.stage === "c_planning"}
                  getProjectDetails={getProjectDetails}
                />
                <DisplayTasksByStage
                  title={t("Final Public Consultation")}
                  projectDetails={projectDetails}
                  _tasks={finalConsultationTasks}
                  assigneeOptions={assigneeOptions}
                  defaultExpanded={
                    projectDetails.stage === "d_final_consultation"
                  }
                  getProjectDetails={getProjectDetails}
                />
                <DisplayTasksByStage
                  title={t("Final Approval")}
                  projectDetails={projectDetails}
                  _tasks={approvalTasks}
                  assigneeOptions={assigneeOptions}
                  defaultExpanded={projectDetails.stage === "e_approval"}
                  getProjectDetails={getProjectDetails}
                />
              </SpaceBetween>
            </Container>

            <Modal
              onDismiss={() => showNewTaskModal(false)}
              visible={newTaskModal}
              closeAriaLabel="Close modal"
              header={t("Add New Task")}
              footer={
                <>
                  {
                    // Show loading circle when user clicks Save Changes
                    loadingCircle && (
                      <Box float="left">
                        <StatusIndicator type="loading">
                          {t("Saving changes")}...
                        </StatusIndicator>
                      </Box>
                    )
                  }
                  <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        variant="link"
                        onClick={() => showNewTaskModal(false)}
                      >
                        {t("Cancel")}
                      </Button>
                      <Button
                        variant="primary"
                        onClick={async () => {
                          createTask();
                          showLoadingCircle(true);
                          await getProjectDetails();
                          showLoadingCircle(false);
                          showNewTaskModal(false);
                        }}
                        disabled={
                          taskName === "" || stage.value === "" || loadingCircle
                        }
                      >
                        {t("Add New Task")}
                      </Button>
                    </SpaceBetween>
                  </Box>
                </>
              }
            >
              <SpaceBetween direction="vertical" size="m">
                <FormField label={t("Task Name")}>
                  <Input
                    type="text"
                    placeholder={t("Enter a name here...")}
                    value={taskName}
                    onChange={(event) => setTaskName(event.detail.value)}
                  />
                </FormField>
                <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                  <FormField label={t("Stage")}>
                    <Select
                      selectedAriaLabel="Stage selected"
                      selectedOption={stage}
                      onChange={({ detail }) => setStage(detail.selectedOption)}
                      options={[
                        { label: t("Pre-planning"), value: "a_preplanning" },
                        {
                          label: t("Initial Public Consultation"),
                          value: "b_initial_consultation",
                        },
                        { label: t("Planning Process"), value: "c_planning" },
                        {
                          label: t("Final Consultation"),
                          value: "d_final_consultation",
                        },
                        { label: t("Final Approval"), value: "e_approval" },
                      ]}
                    />
                  </FormField>
                  <FormField label={t("Due date")} constraintText="YYYY/MM/DD">
                    <DatePicker
                      onChange={({ detail }) => setDeadline(detail.value)}
                      value={deadline || "-"}
                      openCalendarAriaLabel={(selectedDate) =>
                        `selected date is ${selectedDate}`
                      }
                      placeholder="YYYY/MM/DD"
                      nextMonthAriaLabel="Next month"
                      previousMonthAriaLabel="Previous month"
                      todayAriaLabel="Today"
                    />
                  </FormField>
                  <Checkbox
                    onChange={({ detail }) => setMandatory(detail.checked)}
                    checked={mandatory}
                  >
                    {t("Task is Mandatory")}
                  </Checkbox>
                </Grid>
                <FormField label={t("Assigned to")}>
                  <Select
                    selectedAriaLabel="Assignee selected"
                    selectedOption={assignee}
                    onChange={({ detail }) =>
                      setAssignee(detail.selectedOption)
                    }
                    options={assigneeOptions?.map((assignee) => ({
                      value: assignee?.id,
                      label: `${assignee?.given_name} ${assignee?.family_name}`,
                    }))}
                  />
                </FormField>
                <FormField label={t("Related documents")}>
                  <Multiselect
                    placeholder={t("Choose documents")}
                    selectedAriaLabel="related documents"
                    selectedOptions={relatedDocuments}
                    onChange={({ detail }) =>
                      setRelatedDocuments(detail.selectedOptions)
                    }
                    options={documents
                      ?.filter((d) => d.stage === stage.value)
                      .map((document) => ({
                        value: document?.id,
                        label: t(document?.name),
                      }))}
                  />
                </FormField>
              </SpaceBetween>
            </Modal>
          </ContentLayout>
        }
      />
    </Authenticator>
  );
}

const DisplayTasksByStage = ({
  title,
  projectDetails,
  _tasks,
  assigneeOptions,
  defaultExpanded,
  getProjectDetails,
}) => {
  const { userDetails } = useContext(UserContext);
  const { t } = useTranslation();
  var [selectedTaskId, setSelectedTaskId] = useState("");
  const [savingText, showSavingText] = useState(false);
  const [documents, setDocuments] = useState([]);
  const [editTaskModal, showEditTaskModal] = useState(false);
  const [markCompleteConfirmModal, showMarkCompleteConfirmModal] =
    useState(false);
  const [taskName, setTaskName] = useState("");
  const [deadline, setDeadline] = useState("");
  const [mandatory, setMandatory] = useState(false);
  const [loadingCircle, showLoadingCircle] = useState(false);
  const [sortingField, setSortingField] = useState("deadline");
  const [sortReverse, setSortReverse] = useState(false);
  const [assignee, setAssignee] = useState({
    label: "None assigned",
    value: "",
  });
  const [stage, setStage] = useState({
    value: "",
    label: "None selected",
  });
  var [relatedDocuments, setRelatedDocuments] = useState([
    {
      value: "",
      label: "Not related to any tasks",
    },
  ]);

  let tasks = _tasks.sort((_a, _b) => {
    let a;
    let b;
    if (!_a[sortingField] && sortingField !== "done") {
      return 1;
    } else {
      a = _a[sortingField];
    }
    if (!_b[sortingField] && sortingField !== "done") {
      return -1;
    } else {
      b = _b[sortingField];
    }
    if (b.toString() > a.toString()) {
      return sortReverse ? -1 : 1;
    } else {
      return sortReverse ? 1 : -1;
    }
  });

  const updateTask = async (taskId) => {
    API.graphql(
      graphqlOperation(mutations.updateProject, {
        input: {
          id: projectDetails.id,
          admins: [
            ...projectDetails?.admins?.filter((a) => a !== assignee.value),
            assignee.value,
          ],
        },
      })
    );
    const variables = {
      id: selectedTaskId,
      title: taskName,
      description: null,
      deadline: deadline,
      stage: stage.value,
      mandatory,
      assigneeId: assignee.value,
      owners: [
        ...projectDetails?.tasks?.items
          ?.find((t) => t.id === taskId)
          ?.owners?.filter((a) => a !== assignee?.value),
        assignee?.value,
      ],
      projectId: projectDetails.id,
    };

    await API.graphql(
      graphqlOperation(mutations.updateTask, {
        input: variables,
      })
    )
      .then(async (res) => {
        const currentRelatedDocuments =
          res.data.updateTask.documents?.items?.map(
            (relationship) => relationship.document.id
          );
        const targetRelatedDocuments = relatedDocuments.map(
          (document) => document.value
        );
        const documentsToUnrelate = currentRelatedDocuments.filter(
          (did) => !targetRelatedDocuments.includes(did)
        );
        const documentsToRelate = targetRelatedDocuments.filter(
          (did) => !currentRelatedDocuments.includes(did)
        );
        for (const documentId of documentsToRelate) {
          await API.graphql(
            graphqlOperation(mutations.createDocumentTasks, {
              input: {
                documentId,
                taskId: selectedTaskId,
              },
            })
          );
        }
        const relationshipsToEnd = res.data.updateTask.documents?.items?.filter(
          (relationship) =>
            documentsToUnrelate.includes(relationship.document.id)
        );
        for (const relationshipToEnd of relationshipsToEnd) {
          await API.graphql(
            graphqlOperation(mutations.deleteDocumentTasks, {
              input: { id: relationshipToEnd.id },
            })
          );
        }
      })
      .catch((error) => console.error(error));
  };

  const updateTaskStatus = async (documentId, status) => {
    const now = new Date().toISOString();
    const notification_input = {
      id: projectDetails.id,
      notificationMessage: status ? "task_done" : null,
    };
    if (status) notification_input["nested_update_at"] = now;
    API.graphql(
      graphqlOperation(mutations.updateProject, {
        input: notification_input,
      })
    );
    await API.graphql(
      graphqlOperation(mutations.updateTask, {
        input: {
          id: documentId,
          done: status,
          done_at: status ? now : null,
        },
      })
    )
      .then(async (res) => {
        await getProjectDetails();
      })
      .catch((error) => console.error(error));
  };

  const getCurrentTaskDetails = async (taskId) => {
    setSelectedTaskId((selectedTaskId = taskId));
    await API.graphql(
      graphqlOperation(queries.getTask, {
        id: taskId,
      })
    )
      .then((res) => {
        let task = res.data.getTask;
        if (task) {
          setTaskName(task.title);
          setDeadline(task.deadline);
          setMandatory(task.mandatory);
          setStage({
            value: task.stage,
            label: t(task.stage),
          });
          const myRelatedDocuments = task.documents.items?.map((document) => ({
            value: document.documentId,
            label: t(document.document.name),
          }));
          setRelatedDocuments(myRelatedDocuments);
          setAssignee({
            value: task.assignedTo?.id,
            label:
              // If names are undefined, show as "None selected"
              task.assignedTo?.given_name &&
              task.assignedTo?.family_name !== undefined
                ? task.assignedTo?.given_name +
                  " " +
                  task.assignedTo?.family_name
                : "None selected",
          });
        }
      })
      .catch((error) => console.error(error));
  };

  useEffect(() => {
    // get all project documents
    if (projectDetails.documents?.items)
      setDocuments(projectDetails.documents?.items);
  }, [tasks]);

  return (
    <ExpandableSection
      defaultExpanded={defaultExpanded}
      variant="container"
      headerText={title}
    >
      {!tasks?.length ? (
        <i style={{ color: "gray" }}>No tasks available.</i>
      ) : (
        <>
          <Box float="right">
            {savingText && (
              <StatusIndicator type="loading">
                {t("Saving changes")}...
              </StatusIndicator>
            )}
          </Box>
          <br />
          <br />
          <Grid
            gridDefinition={[
              { colspan: 3 },
              { colspan: 3 },
              { colspan: 2 },
              { colspan: 2 },
              { colspan: 1 },
              { colspan: 1 },
            ]}
          >
            <div>
              <b>{t("Task Name")}</b>
            </div>
            <div>
              <b>{t("Linked Documents")}</b>
            </div>
            <div
              style={{
                display: "flex",
                cursor: "pointer",
              }}
            >
              <b
                style={{ marginRight: 7 }}
                onClick={() =>
                  sortingField === "deadline"
                    ? setSortReverse(!sortReverse)
                    : setSortingField("deadline")
                }
              >
                {t("Due Date")}
              </b>
              {/* <Icon
                name="upload-download"
                variant={sortingField === "deadline" ? "link" : "subtle"}
              /> */}
            </div>
            <div
              style={{
                display: "flex",
                cursor: "pointer",
              }}
            >
              <b
                style={{ marginRight: 7 }}
                onClick={() =>
                  sortingField === "assigneeId"
                    ? setSortReverse(!sortReverse)
                    : setSortingField("assigneeId")
                }
              >
                {t("Assigned to")}
              </b>
              {/* <Icon
                name="upload-download"
                variant={sortingField === "assigneeId" ? "link" : "subtle"}
              /> */}
            </div>
            <div
              style={{
                display: "flex",
                cursor: "pointer",
              }}
            >
              <b
                style={{ marginRight: 7 }}
                onClick={() =>
                  sortingField === "done"
                    ? setSortReverse(!sortReverse)
                    : setSortingField("done")
                }
              >
                {t("Status")}
              </b>
              {/* <Icon
                name="upload-download"
                variant={sortingField === "done" ? "link" : "subtle"}
              /> */}
            </div>

            <div>
              <b>{t("Actions")}</b>
            </div>
          </Grid>
          <DividerThick />

          {!tasks ? (
            <p>{t("Loading")}...</p>
          ) : (
            <>
              {tasks.map((task) => (
                <div key={task?.id}>
                  <Grid
                    gridDefinition={[
                      { colspan: 3 },
                      { colspan: 3 },
                      { colspan: 2 },
                      { colspan: 2 },
                      { colspan: 1 },
                      { colspan: 1 },
                    ]}
                  >
                    <div style={{ display: "flex" }}>
                      {task?.done ? (
                        <CheckCircleIcon
                          style={{
                            color: primaryGreen,
                            marginRight: 5,
                          }}
                        />
                      ) : (
                        <PendingIcon
                          style={{ color: warningOrange, marginRight: 5 }}
                        />
                      )}
                      <b>
                        {t(task?.title)} {task?.mandatory && "*"}
                      </b>
                    </div>
                    <div>
                      {t("Linked to")} <b>{task?.documents?.items.length}</b>{" "}
                      {task?.documents?.items.length > 1
                        ? t("documents")
                        : t("document")}
                      {task?.documents?.items.map((item, index) => (
                        <li style={{ paddingTop: 5, fontSize: "0.8em" }}>
                          {t(item.document.name)}
                        </li>
                      ))}
                    </div>
                    <div>
                      <span
                        style={{
                          fontWeight:
                            isDeadlinePassed(task?.deadline) &&
                            !task?.done &&
                            "bold",
                          color:
                            isDeadlinePassed(task?.deadline) &&
                            !task?.done &&
                            warningOrange,
                        }}
                      >
                        {isDeadlinePassed(task?.deadline) && !task?.done ? (
                          <Tooltip title="Due date has passed">
                            <WarningIcon
                              sx={{ fontSize: 20, marginRight: 0.5 }}
                            />
                            <b>{task?.deadline || "-"}</b>
                          </Tooltip>
                        ) : (
                          <>
                            {calculatePriority(tasks?.deadline, true) === "-"
                              ? task?.deadline || "-"
                              : calculatePriority(tasks?.deadline, true)}
                          </>
                        )}
                        <br />
                      </span>
                    </div>
                    <div>
                      {task?.assignedTo?.given_name !== undefined &&
                        task?.assignedTo?.family_name !== undefined && (
                          <Popover
                            position="top"
                            size="medium"
                            triggerType="custom"
                            header={
                              task?.assignedTo?.given_name +
                              " " +
                              task?.assignedTo?.family_name
                            }
                            content={
                              <SpaceBetween size="s">
                                <Link>{task?.assignedTo?.email}</Link>
                                <span>{task?.assignedTo?.phone_number}</span>
                                <span>
                                  {task?.assignedTo?.role === "agents"
                                    ? "Agent"
                                    : "Developer"}
                                </span>
                                <span>
                                  {task?.assignedTo?.municipality && (
                                    <>
                                      {MapCodeToLabel(
                                        task?.assignedTo?.municipality
                                      )}
                                      ,{" "}
                                      {task?.assignedTo?.municipality.slice(
                                        0,
                                        2
                                      ) === "DK"
                                        ? t("Denmark 🇩🇰")
                                        : t("Poland 🇵🇱")}
                                    </>
                                  )}
                                </span>
                              </SpaceBetween>
                            }
                          >
                            <div style={{ display: "flex", cursor: "pointer" }}>
                              <AccountCircleIcon style={{ marginRight: 5 }} />
                              <b
                                style={{
                                  color: primaryBlue,
                                }}
                              >
                                {task?.assignedTo?.given_name +
                                  " " +
                                  task?.assignedTo?.family_name}
                              </b>
                            </div>
                          </Popover>
                        )}
                    </div>
                    <div
                      style={{
                        color: "white",
                        width: 80,
                        height: task?.done ? 30 : 50,
                        fontSize: 12,
                        borderRadius: 5,
                        padding: 4,
                        background: task?.done ? primaryGreen : warningOrange,
                        textAlign: "center",
                      }}
                    >
                      {task?.done ? t("Completed") : t("Not complete")}
                    </div>
                    <div>
                      <SpaceBetween direction="horizontal">
                        {(projectDetails.mainAgentId === userDetails.id ||
                          task?.assigneeId === userDetails.id) &&
                          !task?.done &&
                          !projectDetails.projectClosed &&
                          !savingText && (
                            <>
                              <Tooltip title={t("Edit task")}>
                                <IconButton
                                  aria-label={t("Edit task")}
                                  onClick={() => {
                                    getCurrentTaskDetails(task?.id);
                                    showEditTaskModal(true);
                                  }}
                                >
                                  <EditIcon style={{ color: primaryBlue }} />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                        {(task?.assigneeId === userDetails.id ||
                          projectDetails.mainAgentId === userDetails.id) &&
                          !task?.done &&
                          !projectDetails.projectClosed &&
                          !savingText && (
                            <>
                              <Tooltip title={t("Mark as complete")}>
                                <IconButton
                                  aria-label={t("Mark as complete")}
                                  onClick={() =>
                                    showMarkCompleteConfirmModal(true)
                                  }
                                >
                                  <CheckCircleIcon
                                    style={{ color: primaryBlue }}
                                  />
                                </IconButton>
                              </Tooltip>
                              {/* -------------- MARK AS COMPLETE CONFIRM MODAL -------------- */}
                              <Modal
                                header={t("Are you sure?")}
                                onDismiss={() =>
                                  showMarkCompleteConfirmModal(false)
                                }
                                visible={markCompleteConfirmModal}
                                footer={
                                  <Box float="right">
                                    <SpaceBetween
                                      direction="horizontal"
                                      size="xs"
                                    >
                                      <Button
                                        variant="link"
                                        onClick={() => {
                                          showMarkCompleteConfirmModal(false);
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                      <Button
                                        variant="primary"
                                        onClick={async () => {
                                          showSavingText(true);
                                          await updateTaskStatus(
                                            task?.id,
                                            true
                                          );
                                          showSavingText(false);
                                          showMarkCompleteConfirmModal(false);
                                        }}
                                      >
                                        {t("Mark as complete")}
                                      </Button>
                                    </SpaceBetween>
                                  </Box>
                                }
                              >
                                {t(
                                  "Please only mark as complete if you have reviewed it and have no further modification requests at this stage."
                                )}
                              </Modal>
                            </>
                          )}
                        {(task?.assigneeId === userDetails.id ||
                          projectDetails.mainAgentId === userDetails.id) &&
                          task?.done &&
                          !projectDetails.projectClosed &&
                          !savingText && (
                            <>
                              <Tooltip title={t("Re-open Task")}>
                                <IconButton
                                  aria-label="Re-open Task"
                                  onClick={async () => {
                                    showSavingText(true);
                                    await updateTaskStatus(task?.id, false);
                                    showSavingText(false);
                                  }}
                                >
                                  <AddCircleIcon
                                    style={{ color: warningOrange }}
                                  />
                                </IconButton>
                              </Tooltip>
                            </>
                          )}
                      </SpaceBetween>
                    </div>
                  </Grid>
                  <Divider />
                </div>
              ))}

              {/* ----------------  EDIT TASK MODAL ----------------*/}
              <Modal
                onDismiss={() => showEditTaskModal(false)}
                visible={editTaskModal}
                closeAriaLabel="Close modal"
                header={t("Edit Task")}
                footer={
                  <>
                    {
                      // Show fetching data status every time modal opens
                      !taskName && (
                        <Box float="left">
                          <StatusIndicator type="loading">
                            {t("Fetching data")}...
                          </StatusIndicator>
                        </Box>
                      )
                    }
                    {
                      // Show loading circle when user clicks Save Changes
                      loadingCircle && (
                        <Box float="left">
                          <StatusIndicator type="loading">
                            {t("Saving changes")}...
                          </StatusIndicator>
                        </Box>
                      )
                    }
                    <Box float="right">
                      <SpaceBetween direction="horizontal" size="xs">
                        <Button
                          variant="link"
                          onClick={() => showEditTaskModal(false)}
                        >
                          {t("Cancel")}
                        </Button>
                        <Button
                          variant="primary"
                          disabled={
                            taskName === "" ||
                            stage.value === "" ||
                            loadingCircle
                          }
                          onClick={async () => {
                            showLoadingCircle(true);
                            await updateTask(selectedTaskId);
                            await getProjectDetails();
                            showLoadingCircle(false);
                            showEditTaskModal(false);
                          }}
                        >
                          {loadingCircle
                            ? t("Please wait") + "..."
                            : t("Save Changes")}
                        </Button>
                      </SpaceBetween>
                    </Box>
                  </>
                }
              >
                <SpaceBetween direction="vertical" size="m">
                  <FormField label={t("Task Name")} description="">
                    <Input
                      placeholder={t("Enter a name here...")}
                      value={t(taskName)}
                      onChange={(event) => setTaskName(event.detail.value)}
                    />
                  </FormField>
                  <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                    <FormField label={t("Stage")} description="">
                      <Select
                        selectedAriaLabel="task stage selected"
                        selectedOption={stage}
                        onChange={({ detail }) =>
                          setStage(detail.selectedOption)
                        }
                        options={[
                          {
                            value: "a_preplanning",
                            label: t("Pre-planning"),
                          },
                          {
                            value: "b_initial_consultation",
                            label: t("Initial Public Consultation"),
                          },
                          {
                            value: "c_planning",
                            label: t("Planning Process"),
                          },
                          {
                            value: "d_final_consultation",
                            label: t("Final Consultation"),
                          },
                          {
                            value: "e_approval",
                            label: t("Final Approval"),
                          },
                        ]}
                      />
                    </FormField>
                    <FormField
                      label={t("Due date")}
                      description=""
                      constraintText="YYYY/MM/DD"
                    >
                      <DatePicker
                        onChange={({ detail }) => setDeadline(detail.value)}
                        value={deadline || ""}
                        openCalendarAriaLabel={(selectedDate) =>
                          `selected date is ${selectedDate}`
                        }
                        placeholder="YYYY/MM/DD"
                        nextMonthAriaLabel="Next month"
                        previousMonthAriaLabel="Previous month"
                        todayAriaLabel="Today"
                      />
                    </FormField>
                    <Checkbox
                      onChange={({ detail }) => setMandatory(detail.checked)}
                      checked={mandatory}
                    >
                      {t("Task is Mandatory")}
                    </Checkbox>
                  </Grid>
                  <FormField label={t("Assigned to")} description="">
                    <Select
                      selectedAriaLabel="Assignee selected"
                      selectedOption={assignee}
                      onChange={({ detail }) =>
                        setAssignee(detail.selectedOption)
                      }
                      options={assigneeOptions.map((assignee) => ({
                        value: assignee?.id,
                        label: `${assignee?.given_name} ${assignee?.family_name}`,
                      }))}
                    />
                  </FormField>
                  <FormField label={t("Related Documents")} description="">
                    <Multiselect
                      placeholder={t("Choose documents")}
                      selectedAriaLabel="related documents selected"
                      selectedOptions={relatedDocuments}
                      onChange={({ detail }) => {
                        setRelatedDocuments(
                          (relatedDocuments = detail.selectedOptions)
                        );
                      }}
                      options={documents
                        .filter((d) => d.stage === stage.value)
                        .map((document) => ({
                          value: document.id,
                          label: t(document.name),
                        }))}
                    />
                  </FormField>
                </SpaceBetween>
              </Modal>
            </>
          )}
        </>
      )}
    </ExpandableSection>
  );
};
