/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getOrganisation = /* GraphQL */ `
  query GetOrganisation($id: ID!) {
    getOrganisation(id: $id) {
      id
      domain
      role
      municipality
      personnel {
        items {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        nextToken
      }
      projects {
        items {
          id
          organisationId
          projectId
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          admins
          subscribers
        }
        nextToken
      }
      name
      createdAt
      updatedAt
    }
  }
`;
export const listOrganisations = /* GraphQL */ `
  query ListOrganisations(
    $filter: ModelOrganisationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganisations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        domain
        role
        municipality
        personnel {
          items {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          nextToken
        }
        projects {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGDPR = /* GraphQL */ `
  query GetGDPR($id: ID!) {
    getGDPR(id: $id) {
      id
      personaId
      given_name
      family_name
      email
      phone_number
      organisation
      municipality
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listGDPRS = /* GraphQL */ `
  query ListGDPRS(
    $filter: ModelGDPRFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGDPRS(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        personaId
        given_name
        family_name
        email
        phone_number
        organisation
        municipality
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getPersona = /* GraphQL */ `
  query GetPersona($id: ID!) {
    getPersona(id: $id) {
      id
      owner
      productivityExpanded
      tasksExpanded
      given_name
      family_name
      email
      phone_number
      role
      domain
      dateOfPrivacyConsent
      municipality
      assignedTasks {
        items {
          id
          deadline
          title
          description
          mandatory
          owners
          ownerGroups
          subscribers
          subscriberGroups
          stage
          done
          done_at
          documents {
            nextToken
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      assignedDocuments {
        items {
          id
          name
          description
          deadline
          owners
          ownerGroups
          subscribers
          subscriberGroups
          mandatory
          stage
          publishable
          status
          a_upload_at
          b_review_at
          c_revise_at
          d_done_at
          comments {
            nextToken
          }
          tasks {
            nextToken
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          reviewerId
          reviewedBy {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      reviewingDocuments {
        items {
          id
          name
          description
          deadline
          owners
          ownerGroups
          subscribers
          subscriberGroups
          mandatory
          stage
          publishable
          status
          a_upload_at
          b_review_at
          c_revise_at
          d_done_at
          comments {
            nextToken
          }
          tasks {
            nextToken
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          reviewerId
          reviewedBy {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      organisation {
        id
        domain
        role
        municipality
        personnel {
          items {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          nextToken
        }
        projects {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        name
        createdAt
        updatedAt
      }
      projectComments {
        items {
          owner
          subscribers
          subscriberGroups
          comment
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          personaId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      documentComments {
        items {
          owner
          subscribers
          subscriberGroups
          section
          page
          version
          comment
          documentId
          document {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          personaId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      mainAgentProjects {
        items {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      mainDeveloperProjects {
        items {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      agentProjects {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      smeProjects {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      developerProject {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      visitedProjects {
        projectId
        visitedAt
      }
      createdAt
      updatedAt
      organisationPersonnelId
    }
  }
`;
export const listPersonas = /* GraphQL */ `
  query ListPersonas(
    $filter: ModelPersonaFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPersonas(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      nextToken
    }
  }
`;
export const getProjectDeadline = /* GraphQL */ `
  query GetProjectDeadline($id: ID!) {
    getProjectDeadline(id: $id) {
      id
      date
      owner
      deadlineEnum
      stage
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      projectDeadlinesId
    }
  }
`;
export const listProjectDeadlines = /* GraphQL */ `
  query ListProjectDeadlines(
    $filter: ModelProjectDeadlineFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectDeadlines(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        date
        owner
        deadlineEnum
        stage
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        projectDeadlinesId
      }
      nextToken
    }
  }
`;
export const getProject = /* GraphQL */ `
  query GetProject($id: ID!) {
    getProject(id: $id) {
      id
      workflowSet
      isInRevision
      summaryVerified
      summaryEditable
      projectClosed
      admins
      subscribers
      adminGroups
      subscriberGroups
      name
      description
      longitude
      latitude
      contact_organization
      contact_person
      contact_email
      contact_phone
      contact_link
      feedback_link
      additionalInfo
      provides_households
      projectType
      a_preplanning_closed
      b_initial_consultation_closed
      c_planning_closed
      d_final_consultation_closed
      e_approval_closed
      nested_update_at
      notificationMessage
      isInRevision_at
      projectClosed_at
      workflowSet_at
      size
      capacity
      turbines
      height
      diameter
      startDate
      endDate
      municipalities
      preAgreementLandowners
      benefits
      relationNeighbours
      stage
      deadlines {
        items {
          id
          date
          owner
          deadlineEnum
          stage
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          projectDeadlinesId
        }
        nextToken
      }
      comments {
        items {
          owner
          subscribers
          subscriberGroups
          comment
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          personaId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      documents {
        items {
          id
          name
          description
          deadline
          owners
          ownerGroups
          subscribers
          subscriberGroups
          mandatory
          stage
          publishable
          status
          a_upload_at
          b_review_at
          c_revise_at
          d_done_at
          comments {
            nextToken
          }
          tasks {
            nextToken
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          reviewerId
          reviewedBy {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      tasks {
        items {
          id
          deadline
          title
          description
          mandatory
          owners
          ownerGroups
          subscribers
          subscriberGroups
          stage
          done
          done_at
          documents {
            nextToken
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      mainAgentId
      mainAgent {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      mainDeveloperId
      mainDeveloper {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      smes {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      developers {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      agents {
        items {
          id
          personaId
          projectId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owner
          admins
          subscribers
        }
        nextToken
      }
      organisations {
        items {
          id
          organisationId
          projectId
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          admins
          subscribers
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listProjects = /* GraphQL */ `
  query ListProjects(
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjects(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const projectsByMainAgentIdAndName = /* GraphQL */ `
  query ProjectsByMainAgentIdAndName(
    $mainAgentId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectsByMainAgentIdAndName(
      mainAgentId: $mainAgentId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const projectsByMainDeveloperIdAndName = /* GraphQL */ `
  query ProjectsByMainDeveloperIdAndName(
    $mainDeveloperId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectsByMainDeveloperIdAndName(
      mainDeveloperId: $mainDeveloperId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getProjectComment = /* GraphQL */ `
  query GetProjectComment($id: ID!) {
    getProjectComment(id: $id) {
      owner
      subscribers
      subscriberGroups
      comment
      projectId
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      personaId
      persona {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const listProjectComments = /* GraphQL */ `
  query ListProjectComments(
    $filter: ModelProjectCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        owner
        subscribers
        subscriberGroups
        comment
        projectId
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        personaId
        persona {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const projectCommentsByProjectIdAndComment = /* GraphQL */ `
  query ProjectCommentsByProjectIdAndComment(
    $projectId: ID!
    $comment: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectCommentsByProjectIdAndComment(
      projectId: $projectId
      comment: $comment
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        owner
        subscribers
        subscriberGroups
        comment
        projectId
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        personaId
        persona {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const projectCommentsByPersonaIdAndComment = /* GraphQL */ `
  query ProjectCommentsByPersonaIdAndComment(
    $personaId: ID!
    $comment: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProjectCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectCommentsByPersonaIdAndComment(
      personaId: $personaId
      comment: $comment
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        owner
        subscribers
        subscriberGroups
        comment
        projectId
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        personaId
        persona {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDocument = /* GraphQL */ `
  query GetDocument($id: ID!) {
    getDocument(id: $id) {
      id
      name
      description
      deadline
      owners
      ownerGroups
      subscribers
      subscriberGroups
      mandatory
      stage
      publishable
      status
      a_upload_at
      b_review_at
      c_revise_at
      d_done_at
      comments {
        items {
          owner
          subscribers
          subscriberGroups
          section
          page
          version
          comment
          documentId
          document {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          personaId
          persona {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      tasks {
        items {
          id
          documentId
          taskId
          document {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          task {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owners
          subscribers
        }
        nextToken
      }
      assigneeId
      assignedTo {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      reviewerId
      reviewedBy {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      projectId
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDocuments = /* GraphQL */ `
  query ListDocuments(
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocuments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        deadline
        owners
        ownerGroups
        subscribers
        subscriberGroups
        mandatory
        stage
        publishable
        status
        a_upload_at
        b_review_at
        c_revise_at
        d_done_at
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            documentId
            taskId
            createdAt
            updatedAt
            owners
            subscribers
          }
          nextToken
        }
        assigneeId
        assignedTo {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        reviewerId
        reviewedBy {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        projectId
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const documentsByAssigneeIdAndName = /* GraphQL */ `
  query DocumentsByAssigneeIdAndName(
    $assigneeId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentsByAssigneeIdAndName(
      assigneeId: $assigneeId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        deadline
        owners
        ownerGroups
        subscribers
        subscriberGroups
        mandatory
        stage
        publishable
        status
        a_upload_at
        b_review_at
        c_revise_at
        d_done_at
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            documentId
            taskId
            createdAt
            updatedAt
            owners
            subscribers
          }
          nextToken
        }
        assigneeId
        assignedTo {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        reviewerId
        reviewedBy {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        projectId
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const documentsByReviewerIdAndName = /* GraphQL */ `
  query DocumentsByReviewerIdAndName(
    $reviewerId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentsByReviewerIdAndName(
      reviewerId: $reviewerId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        deadline
        owners
        ownerGroups
        subscribers
        subscriberGroups
        mandatory
        stage
        publishable
        status
        a_upload_at
        b_review_at
        c_revise_at
        d_done_at
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            documentId
            taskId
            createdAt
            updatedAt
            owners
            subscribers
          }
          nextToken
        }
        assigneeId
        assignedTo {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        reviewerId
        reviewedBy {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        projectId
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const documentsByProjectIdAndName = /* GraphQL */ `
  query DocumentsByProjectIdAndName(
    $projectId: ID!
    $name: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentsByProjectIdAndName(
      projectId: $projectId
      name: $name
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        deadline
        owners
        ownerGroups
        subscribers
        subscriberGroups
        mandatory
        stage
        publishable
        status
        a_upload_at
        b_review_at
        c_revise_at
        d_done_at
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            documentId
            taskId
            createdAt
            updatedAt
            owners
            subscribers
          }
          nextToken
        }
        assigneeId
        assignedTo {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        reviewerId
        reviewedBy {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        projectId
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDocumentComment = /* GraphQL */ `
  query GetDocumentComment($id: ID!) {
    getDocumentComment(id: $id) {
      owner
      subscribers
      subscriberGroups
      section
      page
      version
      comment
      documentId
      document {
        id
        name
        description
        deadline
        owners
        ownerGroups
        subscribers
        subscriberGroups
        mandatory
        stage
        publishable
        status
        a_upload_at
        b_review_at
        c_revise_at
        d_done_at
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            documentId
            taskId
            createdAt
            updatedAt
            owners
            subscribers
          }
          nextToken
        }
        assigneeId
        assignedTo {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        reviewerId
        reviewedBy {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        projectId
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      personaId
      persona {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      id
      createdAt
      updatedAt
    }
  }
`;
export const listDocumentComments = /* GraphQL */ `
  query ListDocumentComments(
    $filter: ModelDocumentCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentComments(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        owner
        subscribers
        subscriberGroups
        section
        page
        version
        comment
        documentId
        document {
          id
          name
          description
          deadline
          owners
          ownerGroups
          subscribers
          subscriberGroups
          mandatory
          stage
          publishable
          status
          a_upload_at
          b_review_at
          c_revise_at
          d_done_at
          comments {
            nextToken
          }
          tasks {
            nextToken
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          reviewerId
          reviewedBy {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        personaId
        persona {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const documentCommentsByDocumentIdAndComment = /* GraphQL */ `
  query DocumentCommentsByDocumentIdAndComment(
    $documentId: ID!
    $comment: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentCommentsByDocumentIdAndComment(
      documentId: $documentId
      comment: $comment
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        owner
        subscribers
        subscriberGroups
        section
        page
        version
        comment
        documentId
        document {
          id
          name
          description
          deadline
          owners
          ownerGroups
          subscribers
          subscriberGroups
          mandatory
          stage
          publishable
          status
          a_upload_at
          b_review_at
          c_revise_at
          d_done_at
          comments {
            nextToken
          }
          tasks {
            nextToken
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          reviewerId
          reviewedBy {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        personaId
        persona {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const documentCommentsByPersonaIdAndComment = /* GraphQL */ `
  query DocumentCommentsByPersonaIdAndComment(
    $personaId: ID!
    $comment: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentCommentsByPersonaIdAndComment(
      personaId: $personaId
      comment: $comment
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        owner
        subscribers
        subscriberGroups
        section
        page
        version
        comment
        documentId
        document {
          id
          name
          description
          deadline
          owners
          ownerGroups
          subscribers
          subscriberGroups
          mandatory
          stage
          publishable
          status
          a_upload_at
          b_review_at
          c_revise_at
          d_done_at
          comments {
            nextToken
          }
          tasks {
            nextToken
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          reviewerId
          reviewedBy {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        personaId
        persona {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      deadline
      title
      description
      mandatory
      owners
      ownerGroups
      subscribers
      subscriberGroups
      stage
      done
      done_at
      documents {
        items {
          id
          documentId
          taskId
          document {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          task {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
          owners
          subscribers
        }
        nextToken
      }
      projectId
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      assigneeId
      assignedTo {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        deadline
        title
        description
        mandatory
        owners
        ownerGroups
        subscribers
        subscriberGroups
        stage
        done
        done_at
        documents {
          items {
            id
            documentId
            taskId
            createdAt
            updatedAt
            owners
            subscribers
          }
          nextToken
        }
        projectId
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        assigneeId
        assignedTo {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tasksByProjectIdAndTitle = /* GraphQL */ `
  query TasksByProjectIdAndTitle(
    $projectId: ID!
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tasksByProjectIdAndTitle(
      projectId: $projectId
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deadline
        title
        description
        mandatory
        owners
        ownerGroups
        subscribers
        subscriberGroups
        stage
        done
        done_at
        documents {
          items {
            id
            documentId
            taskId
            createdAt
            updatedAt
            owners
            subscribers
          }
          nextToken
        }
        projectId
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        assigneeId
        assignedTo {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const tasksByAssigneeIdAndTitle = /* GraphQL */ `
  query TasksByAssigneeIdAndTitle(
    $assigneeId: ID!
    $title: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    tasksByAssigneeIdAndTitle(
      assigneeId: $assigneeId
      title: $title
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        deadline
        title
        description
        mandatory
        owners
        ownerGroups
        subscribers
        subscriberGroups
        stage
        done
        done_at
        documents {
          items {
            id
            documentId
            taskId
            createdAt
            updatedAt
            owners
            subscribers
          }
          nextToken
        }
        projectId
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        assigneeId
        assignedTo {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTaskTemplates = /* GraphQL */ `
  query GetTaskTemplates($id: ID!) {
    getTaskTemplates(id: $id) {
      id
      taskTitle
      mandatory
      taskDescription
      relevantDocuments
      assignedTo
      stage
      municipality
      createdAt
      updatedAt
    }
  }
`;
export const listTaskTemplates = /* GraphQL */ `
  query ListTaskTemplates(
    $filter: ModelTaskTemplatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskTemplates(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskTitle
        mandatory
        taskDescription
        relevantDocuments
        assignedTo
        stage
        municipality
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDocumentTemplates = /* GraphQL */ `
  query GetDocumentTemplates($id: ID!) {
    getDocumentTemplates(id: $id) {
      id
      documentSlug
      documentName
      documentDescription
      documentMandatory
      assignedTo
      publishable
      stage
      municipality
      createdAt
      updatedAt
    }
  }
`;
export const listDocumentTemplates = /* GraphQL */ `
  query ListDocumentTemplates(
    $filter: ModelDocumentTemplatesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentTemplates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        documentSlug
        documentName
        documentDescription
        documentMandatory
        assignedTo
        publishable
        stage
        municipality
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrganisationProjects = /* GraphQL */ `
  query GetOrganisationProjects($id: ID!) {
    getOrganisationProjects(id: $id) {
      id
      organisationId
      projectId
      organisation {
        id
        domain
        role
        municipality
        personnel {
          items {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          nextToken
        }
        projects {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        name
        createdAt
        updatedAt
      }
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      admins
      subscribers
    }
  }
`;
export const listOrganisationProjects = /* GraphQL */ `
  query ListOrganisationProjects(
    $filter: ModelOrganisationProjectsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrganisationProjects(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationId
        projectId
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        admins
        subscribers
      }
      nextToken
    }
  }
`;
export const organisationProjectsByOrganisationId = /* GraphQL */ `
  query OrganisationProjectsByOrganisationId(
    $organisationId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganisationProjectsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organisationProjectsByOrganisationId(
      organisationId: $organisationId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationId
        projectId
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        admins
        subscribers
      }
      nextToken
    }
  }
`;
export const organisationProjectsByProjectId = /* GraphQL */ `
  query OrganisationProjectsByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOrganisationProjectsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    organisationProjectsByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        organisationId
        projectId
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        admins
        subscribers
      }
      nextToken
    }
  }
`;
export const getProjectAgents = /* GraphQL */ `
  query GetProjectAgents($id: ID!) {
    getProjectAgents(id: $id) {
      id
      personaId
      projectId
      persona {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
      admins
      subscribers
    }
  }
`;
export const listProjectAgents = /* GraphQL */ `
  query ListProjectAgents(
    $filter: ModelProjectAgentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectAgents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        personaId
        projectId
        persona {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        owner
        admins
        subscribers
      }
      nextToken
    }
  }
`;
export const projectAgentsByPersonaId = /* GraphQL */ `
  query ProjectAgentsByPersonaId(
    $personaId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectAgentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectAgentsByPersonaId(
      personaId: $personaId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personaId
        projectId
        persona {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        owner
        admins
        subscribers
      }
      nextToken
    }
  }
`;
export const projectAgentsByProjectId = /* GraphQL */ `
  query ProjectAgentsByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectAgentsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectAgentsByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personaId
        projectId
        persona {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        owner
        admins
        subscribers
      }
      nextToken
    }
  }
`;
export const getProjectSmes = /* GraphQL */ `
  query GetProjectSmes($id: ID!) {
    getProjectSmes(id: $id) {
      id
      personaId
      projectId
      persona {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
      admins
      subscribers
    }
  }
`;
export const listProjectSmes = /* GraphQL */ `
  query ListProjectSmes(
    $filter: ModelProjectSmesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectSmes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        personaId
        projectId
        persona {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        owner
        admins
        subscribers
      }
      nextToken
    }
  }
`;
export const projectSmesByPersonaId = /* GraphQL */ `
  query ProjectSmesByPersonaId(
    $personaId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectSmesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectSmesByPersonaId(
      personaId: $personaId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personaId
        projectId
        persona {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        owner
        admins
        subscribers
      }
      nextToken
    }
  }
`;
export const projectSmesByProjectId = /* GraphQL */ `
  query ProjectSmesByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectSmesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectSmesByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personaId
        projectId
        persona {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        owner
        admins
        subscribers
      }
      nextToken
    }
  }
`;
export const getProjectDevelopers = /* GraphQL */ `
  query GetProjectDevelopers($id: ID!) {
    getProjectDevelopers(id: $id) {
      id
      personaId
      projectId
      persona {
        id
        owner
        productivityExpanded
        tasksExpanded
        given_name
        family_name
        email
        phone_number
        role
        domain
        dateOfPrivacyConsent
        municipality
        assignedTasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        assignedDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        reviewingDocuments {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        organisation {
          id
          domain
          role
          municipality
          personnel {
            nextToken
          }
          projects {
            nextToken
          }
          name
          createdAt
          updatedAt
        }
        projectComments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documentComments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainDeveloperProjects {
          items {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          nextToken
        }
        agentProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        smeProjects {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developerProject {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        visitedProjects {
          projectId
          visitedAt
        }
        createdAt
        updatedAt
        organisationPersonnelId
      }
      project {
        id
        workflowSet
        isInRevision
        summaryVerified
        summaryEditable
        projectClosed
        admins
        subscribers
        adminGroups
        subscriberGroups
        name
        description
        longitude
        latitude
        contact_organization
        contact_person
        contact_email
        contact_phone
        contact_link
        feedback_link
        additionalInfo
        provides_households
        projectType
        a_preplanning_closed
        b_initial_consultation_closed
        c_planning_closed
        d_final_consultation_closed
        e_approval_closed
        nested_update_at
        notificationMessage
        isInRevision_at
        projectClosed_at
        workflowSet_at
        size
        capacity
        turbines
        height
        diameter
        startDate
        endDate
        municipalities
        preAgreementLandowners
        benefits
        relationNeighbours
        stage
        deadlines {
          items {
            id
            date
            owner
            deadlineEnum
            stage
            createdAt
            updatedAt
            projectDeadlinesId
          }
          nextToken
        }
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            comment
            projectId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        documents {
          items {
            id
            name
            description
            deadline
            owners
            ownerGroups
            subscribers
            subscriberGroups
            mandatory
            stage
            publishable
            status
            a_upload_at
            b_review_at
            c_revise_at
            d_done_at
            assigneeId
            reviewerId
            projectId
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            deadline
            title
            description
            mandatory
            owners
            ownerGroups
            subscribers
            subscriberGroups
            stage
            done
            done_at
            projectId
            assigneeId
            createdAt
            updatedAt
          }
          nextToken
        }
        mainAgentId
        mainAgent {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        mainDeveloperId
        mainDeveloper {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        smes {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        developers {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        agents {
          items {
            id
            personaId
            projectId
            createdAt
            updatedAt
            owner
            admins
            subscribers
          }
          nextToken
        }
        organisations {
          items {
            id
            organisationId
            projectId
            createdAt
            updatedAt
            admins
            subscribers
          }
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owner
      admins
      subscribers
    }
  }
`;
export const listProjectDevelopers = /* GraphQL */ `
  query ListProjectDevelopers(
    $filter: ModelProjectDevelopersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProjectDevelopers(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personaId
        projectId
        persona {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        owner
        admins
        subscribers
      }
      nextToken
    }
  }
`;
export const projectDevelopersByPersonaId = /* GraphQL */ `
  query ProjectDevelopersByPersonaId(
    $personaId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectDevelopersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectDevelopersByPersonaId(
      personaId: $personaId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personaId
        projectId
        persona {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        owner
        admins
        subscribers
      }
      nextToken
    }
  }
`;
export const projectDevelopersByProjectId = /* GraphQL */ `
  query ProjectDevelopersByProjectId(
    $projectId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelProjectDevelopersFilterInput
    $limit: Int
    $nextToken: String
  ) {
    projectDevelopersByProjectId(
      projectId: $projectId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personaId
        projectId
        persona {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        owner
        admins
        subscribers
      }
      nextToken
    }
  }
`;
export const getDocumentTasks = /* GraphQL */ `
  query GetDocumentTasks($id: ID!) {
    getDocumentTasks(id: $id) {
      id
      documentId
      taskId
      document {
        id
        name
        description
        deadline
        owners
        ownerGroups
        subscribers
        subscriberGroups
        mandatory
        stage
        publishable
        status
        a_upload_at
        b_review_at
        c_revise_at
        d_done_at
        comments {
          items {
            owner
            subscribers
            subscriberGroups
            section
            page
            version
            comment
            documentId
            personaId
            id
            createdAt
            updatedAt
          }
          nextToken
        }
        tasks {
          items {
            id
            documentId
            taskId
            createdAt
            updatedAt
            owners
            subscribers
          }
          nextToken
        }
        assigneeId
        assignedTo {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        reviewerId
        reviewedBy {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        projectId
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      task {
        id
        deadline
        title
        description
        mandatory
        owners
        ownerGroups
        subscribers
        subscriberGroups
        stage
        done
        done_at
        documents {
          items {
            id
            documentId
            taskId
            createdAt
            updatedAt
            owners
            subscribers
          }
          nextToken
        }
        projectId
        project {
          id
          workflowSet
          isInRevision
          summaryVerified
          summaryEditable
          projectClosed
          admins
          subscribers
          adminGroups
          subscriberGroups
          name
          description
          longitude
          latitude
          contact_organization
          contact_person
          contact_email
          contact_phone
          contact_link
          feedback_link
          additionalInfo
          provides_households
          projectType
          a_preplanning_closed
          b_initial_consultation_closed
          c_planning_closed
          d_final_consultation_closed
          e_approval_closed
          nested_update_at
          notificationMessage
          isInRevision_at
          projectClosed_at
          workflowSet_at
          size
          capacity
          turbines
          height
          diameter
          startDate
          endDate
          municipalities
          preAgreementLandowners
          benefits
          relationNeighbours
          stage
          deadlines {
            nextToken
          }
          comments {
            nextToken
          }
          documents {
            nextToken
          }
          tasks {
            nextToken
          }
          mainAgentId
          mainAgent {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          mainDeveloperId
          mainDeveloper {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          smes {
            nextToken
          }
          developers {
            nextToken
          }
          agents {
            nextToken
          }
          organisations {
            nextToken
          }
          createdAt
          updatedAt
        }
        assigneeId
        assignedTo {
          id
          owner
          productivityExpanded
          tasksExpanded
          given_name
          family_name
          email
          phone_number
          role
          domain
          dateOfPrivacyConsent
          municipality
          assignedTasks {
            nextToken
          }
          assignedDocuments {
            nextToken
          }
          reviewingDocuments {
            nextToken
          }
          organisation {
            id
            domain
            role
            municipality
            name
            createdAt
            updatedAt
          }
          projectComments {
            nextToken
          }
          documentComments {
            nextToken
          }
          mainAgentProjects {
            nextToken
          }
          mainDeveloperProjects {
            nextToken
          }
          agentProjects {
            nextToken
          }
          smeProjects {
            nextToken
          }
          developerProject {
            nextToken
          }
          visitedProjects {
            projectId
            visitedAt
          }
          createdAt
          updatedAt
          organisationPersonnelId
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
      owners
      subscribers
    }
  }
`;
export const listDocumentTasks = /* GraphQL */ `
  query ListDocumentTasks(
    $filter: ModelDocumentTasksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDocumentTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        documentId
        taskId
        document {
          id
          name
          description
          deadline
          owners
          ownerGroups
          subscribers
          subscriberGroups
          mandatory
          stage
          publishable
          status
          a_upload_at
          b_review_at
          c_revise_at
          d_done_at
          comments {
            nextToken
          }
          tasks {
            nextToken
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          reviewerId
          reviewedBy {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        task {
          id
          deadline
          title
          description
          mandatory
          owners
          ownerGroups
          subscribers
          subscriberGroups
          stage
          done
          done_at
          documents {
            nextToken
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        owners
        subscribers
      }
      nextToken
    }
  }
`;
export const documentTasksByDocumentId = /* GraphQL */ `
  query DocumentTasksByDocumentId(
    $documentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentTasksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentTasksByDocumentId(
      documentId: $documentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        documentId
        taskId
        document {
          id
          name
          description
          deadline
          owners
          ownerGroups
          subscribers
          subscriberGroups
          mandatory
          stage
          publishable
          status
          a_upload_at
          b_review_at
          c_revise_at
          d_done_at
          comments {
            nextToken
          }
          tasks {
            nextToken
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          reviewerId
          reviewedBy {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        task {
          id
          deadline
          title
          description
          mandatory
          owners
          ownerGroups
          subscribers
          subscriberGroups
          stage
          done
          done_at
          documents {
            nextToken
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        owners
        subscribers
      }
      nextToken
    }
  }
`;
export const documentTasksByTaskId = /* GraphQL */ `
  query DocumentTasksByTaskId(
    $taskId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDocumentTasksFilterInput
    $limit: Int
    $nextToken: String
  ) {
    documentTasksByTaskId(
      taskId: $taskId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        documentId
        taskId
        document {
          id
          name
          description
          deadline
          owners
          ownerGroups
          subscribers
          subscriberGroups
          mandatory
          stage
          publishable
          status
          a_upload_at
          b_review_at
          c_revise_at
          d_done_at
          comments {
            nextToken
          }
          tasks {
            nextToken
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          reviewerId
          reviewedBy {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          createdAt
          updatedAt
        }
        task {
          id
          deadline
          title
          description
          mandatory
          owners
          ownerGroups
          subscribers
          subscriberGroups
          stage
          done
          done_at
          documents {
            nextToken
          }
          projectId
          project {
            id
            workflowSet
            isInRevision
            summaryVerified
            summaryEditable
            projectClosed
            admins
            subscribers
            adminGroups
            subscriberGroups
            name
            description
            longitude
            latitude
            contact_organization
            contact_person
            contact_email
            contact_phone
            contact_link
            feedback_link
            additionalInfo
            provides_households
            projectType
            a_preplanning_closed
            b_initial_consultation_closed
            c_planning_closed
            d_final_consultation_closed
            e_approval_closed
            nested_update_at
            notificationMessage
            isInRevision_at
            projectClosed_at
            workflowSet_at
            size
            capacity
            turbines
            height
            diameter
            startDate
            endDate
            municipalities
            preAgreementLandowners
            benefits
            relationNeighbours
            stage
            mainAgentId
            mainDeveloperId
            createdAt
            updatedAt
          }
          assigneeId
          assignedTo {
            id
            owner
            productivityExpanded
            tasksExpanded
            given_name
            family_name
            email
            phone_number
            role
            domain
            dateOfPrivacyConsent
            municipality
            createdAt
            updatedAt
            organisationPersonnelId
          }
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
        owners
        subscribers
      }
      nextToken
    }
  }
`;
