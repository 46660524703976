import { useEffect, useContext } from "react";
import { useAuthenticator } from "@aws-amplify/ui-react";
import { useTranslation } from "react-i18next";
import { UserContext, MenuOpenContext } from "../../AppContexts";
import "../../i18n";
import {
  AppLayout,
  ContentLayout,
  Header,
  SpaceBetween,
  Container,
  Box,
  ExpandableSection,
  BreadcrumbGroup,
  TextContent,
} from "@cloudscape-design/components";
import { SideNavigationContents } from "../../shared";
import CommunityNavigationBar from "./CommunityNavigationBar";
import NavigationBar from "../NavigationBar";

export default function FAQs() {
  const { t } = useTranslation();
  const { user } = useAuthenticator((context) => [context.route]);
  const { userDetails } = useContext(UserContext);
  const { isMenuOpen, setMenuOpen } = useContext(MenuOpenContext);

  const generalQuestions = [
    {
      id: 1,
      question: "What is a wind farm permitting process?",
      answer:
        "The wind farm permitting process is the series of regulatory steps and approvals required before a project can be constructed and operated. This process varies by according to local laws and can involve several municipal agencies and public review (European Commission, n.d.a).",
    },
    {
      id: 2,
      question: "What permits does a wind farm need?",
      answer:
        "The type of permits varies based on the size and complexity of the wind farm. Some common permits include: environmental permits, land-use permits, zoning permits, building permits, and electrical interconnection permits (European Commission, n.d.a).",
    },
    {
      id: 3,
      question: "Who is responsible for obtaining these permits?",
      answer:
        "The wind farm developer, aka. project developer or just developer, is typically responsible for obtaining the necessary permits, but may work with consultants or legal counsel to navigate the permitting process (European Commission, n.d.b).",
    },
    {
      id: 4,
      question:
        "How long does the wind farm permitting process typically take?",
      answer:
        "The length of the permitting process can vary widely depending on the jurisdiction and complexity of the project. It can range from several months to several years. EU law requires public authorities to complete the process within two years for new wind farms, and within one year for repowered wind farms (when the turbines in an existing wind farm are being replaced with newer ones) (WindEurope, 2023).",
    },
    {
      id: 6,
      question:
        "How can I stay informed about the wind farm permitting process?",
      answer:
        "Many jurisdictions have public consultation periods and hearings as part of the permitting process. You can find the contact details of the agency in charge of the project you're interested on the project page of the municipality website.",
    },
    {
      id: 7,
      question: "What happens after a project is permitted?",
      answer:
        "Once a project is permitted, construction can begin. The developer will also need to obtain additional permits and approvals as construction progresses, such as building inspections and connections to the transmission networks (Danish Energy Agency, 2022).",
    },
  ];
  const permittingProcess = [
    {
      id: 1,
      question:
        "Why is it so difficult to get permits for wind farms in Europe?",
      answer:
        "Complex rules and slow procedures are the main reasons why it's difficult to get permits for wind farms. Wind farms face several spatial planning constraints, such as minimum distance to housing, height restrictions and exclusion zones around radar installations, or in specific areas. Additionally, numerous administrative authorities are involved, including at national, regional and municipal level (WindEurope, 2020).",
    },
    {
      id: 2,
      question:
        "What requirements do wind farm developers account for the permitting process?",
      answer:
        "Project developers need to account for several requirements when they consider building a wind farm on a specific site: wind speeds, grid connection supporting infrastructure such as roads, impact of the wind farm on surrounding natural ecosystems and communities, the right turbine technology for the site etc. (Domaingue, J.-C. and Jain, P., 2022).",
    },
    {
      id: 3,
      question:
        "How can developers seek participation from local residents and other stakeholders during the permitting process?",
      answer:
        "The wind industry follows three guiding principles as a model of best practice for good community engagement:",
    },
    {
      id: 4,
      question:
        "How is EasyPermits simplifying the permit application process?",
      answer:
        "The EasyPermits application makes it possible for permitting authorities, wind farm developers and community members to access all the information about a project in an easy, centralised way. The application provides permitting agents with a dedicated dashboard giving them a concise snapshot of each permit application, encompassing critical tasks, notifications, project summaries, associated activities, and productivity KPIs.",
    },
    {
      id: 5,
      question:
        "How does EasyPermits benefit project developers and community members?",
      answer:
        "EasyPermits allows permitting agents to share and centralise all the information related to a specific project more easy and more transparently with project developers and community members.",
    },
  ];
  const referenceList = [
    {
      id: 1,
      title:
        "European Commission, (n.d.a). Clean Energy for EU Islands - Administrative Authorization Process.",
      link: "https://clean-energy-islands.ec.europa.eu/countries/denmark/legal/permits-and-authorisation-processes/administrative-authorization-process",
      accessedDate: "31/8/2023",
    },
    {
      id: 2,
      title:
        "European Commission (n.d.b). Clean Energy for EU Islands - Application Preparation Process.",
      link: "https://clean-energy-islands.ec.europa.eu/countries/denmark/legal/permits-and-authorisation-processes/application-preparation-process#technologies",
      accessedDate: "31/8/2023",
    },
    {
      id: 3,
      title:
        "WindEurope (2023). 'Revised EU Renewables Directive set to speed up wind permitting', 14 April [press release].",
      link: "https://windeurope.org/newsroom/news/revised-eu-renewables-directive-set-to-speed-up-wind-permitting/",
      accessedDate: "31/8/2023",
    },
    {
      id: 4,
      title:
        "Danish Energy Agency (2022). 'The Danish Energy Agency invites to a supplementary hearing regarding Hesselø Offshore Wind Farm', 10 October [press release].",
      link: "https://ens.dk/en/press/danish-energy-agency-invites-supplementary-hearing-regarding-hesselo-offshore-wind-farm",
      accessedDate: "31/8/2023",
    },
    {
      id: 5,
      title:
        "Domaingue, J.-C. and Jain, P. (2022). 'Barriers to Wind', MX Underwriting Europe.",
      link: "https://www.energymonitor.ai/wp-content/uploads/sites/2/2023/03/Barriers-to-Wind.pdf",
      accessedDate: "1/9/2023",
    },
    {
      id: 6,
      title: "WindEurope (2020). Permitting.",
      link: "https://windeurope.org/policy/topics/permitting/",
      accessedDate: "1/9/2023",
    },
  ];

  useEffect(() => {
    document.title = "EasyPermits® FAQs";
  }, []);

  return (
    <>
      {
        // Dynamically render nav bar depending on whether user is logged in
        user ? <NavigationBar /> : <CommunityNavigationBar />
      }
      <AppLayout
        navigationOpen={isMenuOpen}
        navigation={<SideNavigationContents userRole={userDetails.role} />}
        onNavigationChange={(event) => setMenuOpen(event.detail.open)}
        toolsHide={true}
        content={
          <ContentLayout
            header={
              <SpaceBetween size="xxl">
                <div />
                <BreadcrumbGroup
                  ariaLabel="Breadcrumbs"
                  items={[
                    { text: t("Home"), href: "/map" },
                    {
                      text: "FAQs",
                      href: "#",
                    },
                  ]}
                />
                <Header variant="h1">{t("Frequently Asked Questions")}</Header>
                <div />
              </SpaceBetween>
            }
          >
            <SpaceBetween size="l">
              <Box>
                <SpaceBetween size="l">
                  <Container
                    header={
                      <Header variant="h2">{t("General Questions")}</Header>
                    }
                  >
                    <div style={{ height: 20 }} />
                    {generalQuestions.map((item) => (
                      <ExpandableSection
                        key={item.id}
                        id={item.ref}
                        variant="footer"
                        headerText={t(item.question)}
                      >
                        <p>{t(item.answer)}</p>
                      </ExpandableSection>
                    ))}
                  </Container>
                  <Container
                    header={
                      <Header variant="h2">{t("Permitting Process")}</Header>
                    }
                  >
                    <div style={{ height: 20 }} />
                    {permittingProcess.map((item) => (
                      <ExpandableSection
                        key={item.id}
                        id={item.ref}
                        variant="footer"
                        headerText={t(item.question)}
                      >
                        <p>{t(item.answer)}</p>
                        {item.id === 3 && (
                          <ul>
                            <li>
                              {t(
                                "early, transparent and comprehensive information and communication;"
                              )}
                            </li>
                            <li>
                              {t(
                                "direct engagement of key local actors and activation of the local economy; and"
                              )}
                            </li>
                            <li>
                              {t(
                                "highlighting how communities will benefit from the investments being made. These principles apply at every stage of a project, from site selection and pre-application, planning, construction to operation and decommissioning. (WindEurope, 2020)"
                              )}
                            </li>
                          </ul>
                        )}
                      </ExpandableSection>
                    ))}
                  </Container>
                  <Box>
                    <ExpandableSection
                      variant="footer"
                      headerText={t("Reference List")}
                    >
                      {referenceList.map((item) => (
                        <div key={item.id} style={{ paddingBottom: 15 }}>
                          <TextContent>
                            <small>
                              <b>{item.title} </b>
                              {t("Available at")}:{" "}
                              <a
                                target="_blank"
                                rel="noreferrer"
                                href={item.link}
                              >
                                {item.link}
                              </a>{" "}
                              ({t("Accessed")}: {item.accessedDate})
                            </small>
                          </TextContent>
                        </div>
                      ))}
                    </ExpandableSection>
                  </Box>
                </SpaceBetween>
              </Box>
            </SpaceBetween>
          </ContentLayout>
        }
      />
    </>
  );
}
