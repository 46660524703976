import { useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { UserContext, MenuOpenContext } from "../AppContexts";
import "../i18n";
import {
  AppLayout,
  ContentLayout,
  Header,
  SpaceBetween,
  BreadcrumbGroup,
  Container,
  Button,
  Link,
} from "@cloudscape-design/components";
import { SideNavigationContents } from "../shared";
import NavigationBar from "./NavigationBar";

export default function Support() {
  const { t } = useTranslation();
  const { userDetails } = useContext(UserContext);
  const { isMenuOpen, setMenuOpen } = useContext(MenuOpenContext);

  useEffect(() => {
    document.title = "EasyPermits® Support";
  }, []);

  return (
    <>
      <NavigationBar />
      <AppLayout
        navigation={<SideNavigationContents userRole={userDetails.role} />}
        navigationOpen={isMenuOpen}
        onNavigationChange={(event) => setMenuOpen(event.detail.open)}
        toolsHide={true}
        content={
          <ContentLayout
            header={
              <SpaceBetween size="xxl">
                <div />
                <BreadcrumbGroup
                  ariaLabel="Breadcrumbs"
                  items={[
                    { text: t("Home"), href: "/map" },
                    {
                      text: t("Support"),
                      href: "#",
                    },
                  ]}
                />
                <Header variant="h1">{t("Support")}</Header>
                <div />
              </SpaceBetween>
            }
          >
            <Container fitHeight>
              {t("Please see")}{" "}
              <Link external href="/faqs">
                {t("FAQs")}
              </Link>{" "}
              {t(
                "for common queries related to wind farm projects and/or permitting process."
              )}
              <br />
              <br />
              {t(
                "For anything else including technical support (such as updating your account), please send us an email using the button below:"
              )}
              <br />
              <br />
              <Button href="mailto:selimcan.sakar@accenture.com">
                {t("Contact support")}
              </Button>
            </Container>
          </ContentLayout>
        }
      />
    </>
  );
}
