import { useEffect, useState, useContext } from "react";
import { isEqual, uniqWith } from "lodash";
import { UserContext } from "../../AppContexts";
import { ProjectDetailsContext } from "./ProjectDashboard";
import { useParams } from "react-router-dom";
import { CSVLink } from "react-csv";
import { useTranslation } from "react-i18next";
import "../../i18n";
import {
  currentDate,
  Divider,
  VerticalDivider,
  primaryBlue,
  lightGray,
  primaryGreen,
  warningOrange,
  getDeadlineDate,
  getDeadlineAsNumber,
  getProgressColor,
  isDeadlinePassed,
  MapCodeToLabel,
} from "../../shared";
import {
  Alert,
  Container,
  Header,
  Box,
  Link,
  ColumnLayout,
  TextContent,
  Button,
  Modal,
  SpaceBetween,
  DatePicker,
  FormField,
  Icon,
  Grid,
  StatusIndicator,
  RadioGroup,
  Multiselect,
  ExpandableSection,
  Popover,
} from "@cloudscape-design/components";
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from "../../graphql/mutations";
import {
  Popover as MuiPopover,
  Typography,
  Tooltip,
  IconButton,
  CircularProgress,
} from "@mui/material";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import WarningIcon from "@mui/icons-material/Warning";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

/* STAGES AND MILESTONE DEADLINE NAMES */
// Stages and deadlines are defined in an array instead of hardcoded variables
// so that they can be dynamically updated for each country/client requirements
const stageName1 = "a_preplanning";
const stageName2 = "b_initial_consultation";
const stageName3 = "c_planning";
const stageName4 = "d_final_consultation";
const stageName5 = "e_approval";
const deadlineEnum1 = "documents_handover";
const deadlineEnum2 = "public_consultation";
const deadlineEnum3 = "city_council";

export default function Overview(props) {
  const { id } = useParams();
  const { t } = useTranslation();
  const { userDetails } = useContext(UserContext);
  const { projectDetails, assigneeOptions } = useContext(ProjectDetailsContext);
  const [startDateModal, showStartDateModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [loadingCircle, showLoadingCircle] = useState(false);
  const [startDateLoadingCircle, showStartDateLoadingCircle] = useState(false);
  const [deadlinesLoadingCircle, showDeadlinesLoadingCircle] = useState(false);
  const [editDeadlinesModal, showEditDeadlinesModal] = useState(false);

  // ------------------------ MILESTONES DEADLINE DATES ------------------------
  // pre-planning
  var [pp_Documents_Deadline, set_pp_Documents_Deadline] = useState("");
  var [pp_CityCouncil_Deadline, set_pp_CityCouncil_Deadline] = useState("");

  // Initial Public Consultation
  var [ipc_Documents_Deadline, set_ipc_Documents_Deadline] = useState("");
  var [ipc_Consultation_Deadline, set_ipc_consultation_Deadline] = useState("");
  var [ipc_CityCouncil_Deadline, set_ipc_CityCouncil_Deadline] = useState("");

  // Planning Process
  var [process_Documents_Deadline, set_process_Documents_Deadline] =
    useState("");
  var [process_CityCouncil_Deadline, set_process_CityCouncil_Deadline] =
    useState("");

  // Public Consultation
  var [pc_Documents_Deadline, set_pc_Documents_Deadline] = useState("");
  var [pc_Consultation_Deadline, set_pc_consultation_Deadline] = useState("");
  var [pc_CityCouncil_Deadline, set_pc_CityCouncil_Deadline] = useState("");

  // Final Approval
  var [fa_Documents_Deadline, set_fa_Documents_Deadline] = useState("");
  var [fa_Approval_Deadline, set_fa_Approval_Deadline] = useState("");

  var [prePlanningModal, showPrePlanningModal] = useState(false);
  var [initialConsultationModal, showInitialConsultationModal] =
    useState(false);
  var [planningProcessModal, showPlanningProcessModal] = useState(false);
  var [publicConsultationModal, showPublicConsultationModal] = useState(false);
  var [approvalModal, showApprovalModal] = useState(false);

  // Appsync
  const setDeadline = async (stage, deadlineEnum) => {
    let variables = {};
    // By having if statement to first check for the stage, we prevent saving duplicate entries to DynamoDB if user only change one deadline
    if (stage === "a_preplanning") {
      if (deadlineEnum === "documents_handover")
        variables = {
          date: pp_Documents_Deadline,
          stage: stage,
          deadlineEnum: deadlineEnum,
          projectDeadlinesId: projectDetails?.id,
        };
      else if (deadlineEnum === "city_council")
        variables = {
          date: pp_CityCouncil_Deadline,
          stage: stage,
          deadlineEnum: deadlineEnum,
          projectDeadlinesId: projectDetails?.id,
        };
    } else if (stage === "b_initial_consultation") {
      if (deadlineEnum === "documents_handover")
        variables = {
          date: ipc_Documents_Deadline,
          stage: stage,
          deadlineEnum: deadlineEnum,
          projectDeadlinesId: projectDetails?.id,
        };
      else if (deadlineEnum === "public_consultation")
        variables = {
          date: ipc_Consultation_Deadline,
          stage: stage,
          deadlineEnum: deadlineEnum,
          projectDeadlinesId: projectDetails?.id,
        };
      else if (deadlineEnum === "city_council")
        variables = {
          date: ipc_CityCouncil_Deadline,
          stage: stage,
          deadlineEnum: deadlineEnum,
          projectDeadlinesId: projectDetails?.id,
        };
    } else if (stage === "c_planning") {
      if (deadlineEnum === "documents_handover")
        variables = {
          date: process_Documents_Deadline,
          stage: stage,
          deadlineEnum: deadlineEnum,
          projectDeadlinesId: projectDetails?.id,
        };
      else if (deadlineEnum === "city_council")
        variables = {
          date: process_CityCouncil_Deadline,
          stage: stage,
          deadlineEnum: deadlineEnum,
          projectDeadlinesId: projectDetails?.id,
        };
    } else if (stage === "d_final_consultation") {
      if (deadlineEnum === "documents_handover")
        variables = {
          date: pc_Documents_Deadline,
          stage: stage,
          deadlineEnum: deadlineEnum,
          projectDeadlinesId: projectDetails?.id,
        };
      else if (deadlineEnum === "public_consultation")
        variables = {
          date: pc_Consultation_Deadline,
          stage: stage,
          deadlineEnum: deadlineEnum,
          projectDeadlinesId: projectDetails?.id,
        };
      else if (deadlineEnum === "city_council")
        variables = {
          date: pc_CityCouncil_Deadline,
          stage: stage,
          deadlineEnum: deadlineEnum,
          projectDeadlinesId: projectDetails?.id,
        };
    } else if (stage === "e_approval") {
      if (deadlineEnum === "documents_handover")
        variables = {
          date: fa_Documents_Deadline,
          stage: stage,
          deadlineEnum: deadlineEnum,
          projectDeadlinesId: projectDetails?.id,
        };
      else if (deadlineEnum === "city_council")
        variables = {
          date: fa_Approval_Deadline,
          stage: stage,
          deadlineEnum: deadlineEnum,
          projectDeadlinesId: projectDetails?.id,
        };
    }

    await API.graphql(
      graphqlOperation(mutations.createProjectDeadline, {
        input: variables,
      })
    )
      .then((res) => {
        if (res) {
          showLoadingCircle(false);
          props.getProjectDetails();
        }
      })
      .catch((error) => console.error(error));
  };

  const validateDate = (date, nextDeadlineDate) => {
    if (date && nextDeadlineDate) {
      if (date > nextDeadlineDate) {
        return "Cannot be after the deadline of next stage";
      }
    } else return;
  };
  // ------------

  const saveStartDate = async () => {
    await API.graphql(
      graphqlOperation(mutations.updateProject, {
        input: {
          id: id,
          startDate: startDate,
        },
      })
    )
      .then((res) => {
        console.log("Successfully set start date!");
        showStartDateLoadingCircle(false);
        showStartDateModal(false);
        // auto-refresh page after 2 seconds delay to see new changes
        setTimeout(() => {
          window.location.reload(1);
        }, 1000);
      })
      .catch((error) => console.error(error.message));
  };

  const deleteStartDate = async () => {
    await API.graphql(
      graphqlOperation(mutations.updateProject, {
        input: {
          id: id,
          startDate: null,
        },
      })
    )
      .then((res) => {
        console.log("Successfully removed start date!");
        setStartDate("");
        setTimeout(() => {
          window.location.reload(1);
        }, 1000);
      })
      .catch((error) => console.error(error));
  };

  // Get deadline dates for DatePicker
  const getDeadlineDatePicker = (stage, deadlineEnum) => {
    let deadlineDate = "";
    if (projectDetails?.deadlines?.items?.length) {
      // only get latest deadline by sorting by createdAt value and get the first item in the list
      let sortByDate = projectDetails?.deadlines?.items
        ?.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
        .filter(
          (item) => item.stage === stage && item.deadlineEnum === deadlineEnum
        );
      deadlineDate = sortByDate[0]?.date;
    }
    return deadlineDate || "";
  };

  const setExistingDeadlines = () => {
    // Get existing deadline values for DatePicker
    set_pp_Documents_Deadline(
      getDeadlineDatePicker("a_preplanning", "documents_handover")
    );
    set_pp_CityCouncil_Deadline(
      getDeadlineDatePicker("a_preplanning", "city_council")
    );
    set_ipc_Documents_Deadline(
      getDeadlineDatePicker("b_initial_consultation", "documents_handover")
    );
    set_ipc_consultation_Deadline(
      getDeadlineDatePicker("b_initial_consultation", "public_consultation")
    );
    set_ipc_CityCouncil_Deadline(
      getDeadlineDatePicker("b_initial_consultation", "city_council")
    );
    set_process_Documents_Deadline(
      getDeadlineDatePicker("c_planning", "documents_handover")
    );
    set_process_CityCouncil_Deadline(
      getDeadlineDatePicker("c_planning", "city_council")
    );
    set_pc_Documents_Deadline(
      getDeadlineDatePicker("d_final_consultation", "documents_handover")
    );
    set_pc_consultation_Deadline(
      getDeadlineDatePicker("d_final_consultation", "public_consultation")
    );
    set_pc_CityCouncil_Deadline(
      getDeadlineDatePicker("d_final_consultation", "city_council")
    );
    set_fa_Documents_Deadline(
      getDeadlineDatePicker("e_approval", "documents_handover")
    );
    set_fa_Approval_Deadline(
      getDeadlineDatePicker("e_approval", "city_council")
    );
  };

  // ------------- MANAGE TEAM / COLLABORATORS -----------------
  const [assignedDevelopers, setAssignedDevelopers] = useState([]);
  const [assignedAgents, setAssignedAgents] = useState([]);
  const [assignedSMEs, setAssignedSMEs] = useState([]);
  const [manageTeamModal, showManageTeamModal] = useState(false);

  const updateCollaborators = async () => {
    const currentAgents = projectDetails?.agents?.items?.map(
      (relationship) => relationship.persona.id
    );
    const targetAgents = assignedAgents.map((agent) => agent.value);
    const agentsToUnrelate = currentAgents.filter(
      (aid) => !targetAgents.includes(aid)
    );
    const agentsToRelate = targetAgents.filter(
      (aid) => !currentAgents.includes(aid)
    );
    for (const personaId of agentsToRelate) {
      await API.graphql(
        graphqlOperation(mutations.createProjectAgents, {
          input: {
            personaId,
            projectId: projectDetails?.id,
          },
        })
      );
    }
    const agentRelationshipsToEnd = projectDetails?.agents?.items?.filter(
      (relationship) => agentsToUnrelate.includes(relationship.persona.id)
    );
    for (const relationshipToEnd of agentRelationshipsToEnd) {
      await API.graphql(
        graphqlOperation(mutations.deleteProjectAgents, {
          input: { id: relationshipToEnd.id },
        })
      );
    }
    const currentDevelopers = projectDetails?.developers?.items?.map(
      (relationship) => relationship.persona.id
    );
    const targetDevelopers = assignedDevelopers.map(
      (developer) => developer.value
    );
    const developersToUnrelate = currentDevelopers.filter(
      (did) => !targetDevelopers.includes(did)
    );
    const developersToRelate = targetDevelopers.filter(
      (did) => !currentDevelopers.includes(did)
    );
    for (const personaId of developersToRelate) {
      await API.graphql(
        graphqlOperation(mutations.createProjectDevelopers, {
          input: {
            personaId,
            projectId: projectDetails?.id,
          },
        })
      );
    }
    const developerRelationshipsToEnd =
      projectDetails?.developers?.items?.filter((relationship) =>
        developersToUnrelate.includes(relationship.persona.id)
      );
    for (const relationshipToEnd of developerRelationshipsToEnd) {
      await API.graphql(
        graphqlOperation(mutations.deleteProjectDevelopers, {
          input: { id: relationshipToEnd.id },
        })
      );
    }
    const currentSMEs = projectDetails?.smes?.items?.map(
      (relationship) => relationship.persona.id
    );
    const targetSMEs = assignedSMEs.map((sme) => sme.value);
    const SMEsToUnrelate = currentSMEs.filter(
      (sid) => !targetSMEs.includes(sid)
    );
    const SMEsToRelate = targetSMEs.filter((sid) => !currentSMEs.includes(sid));
    for (const personaId of SMEsToRelate) {
      await API.graphql(
        graphqlOperation(mutations.createProjectSmes, {
          input: {
            personaId,
            projectId: projectDetails?.id,
          },
        })
      );
    }
    const SMERelationshipsToEnd = projectDetails?.smes?.items?.filter(
      (relationship) => SMEsToUnrelate.includes(relationship.persona.id)
    );
    for (const relationshipToEnd of SMERelationshipsToEnd) {
      await API.graphql(
        graphqlOperation(mutations.deleteProjectSmes, {
          input: { id: relationshipToEnd.id },
        })
      );
    }
    await props.getProjectDetails();
  };

  // ------------------ CITY COUNCIL MEETING ACTIONS -------------------
  const [cityCouncilOutcomeModal, showCityCouncilOutcomeModal] =
    useState(false);
  const [outcomeAnswer, setOutcomeAnswer] = useState("yes");
  const [closeStageConfirmModal, showCloseStageConfirmModal] = useState(false);
  const [closeProjectConfirmModal, showCloseProjectConfirmModal] =
    useState(false);
  const [skipStageModal, showSkipStageModal] = useState(false);
  const now = new Date().toISOString();

  const autoCloseOptionalTasks = async () => {
    for (const task of projectDetails?.tasks?.items) {
      if (task.stage === projectDetails?.stage) {
        await API.graphql(
          graphqlOperation(mutations.updateTask, {
            input: {
              id: task.id,
              done: true,
              done_at: now,
            },
          })
        )
          .then(async (res) => console.log("All optional tasks closed!"))
          .catch((error) => console.error(error));
      }
    }
  };

  const autoCloseOptionalDocuments = async () => {
    for (const document of projectDetails?.documents?.items) {
      if (document.stage === projectDetails.stage) {
        const input = {
          id: document.id,
          status: "d_done",
        };
        input[`d_done_at`] = now;
        await API.graphql(
          graphqlOperation(mutations.updateDocument, {
            input,
          })
        )
          .then(async (res) => console.log("All optional documents closed!"))
          .catch((error) => console.error(error));
      }
    }
  };

  const closeStage = async () => {
    const stages = [
      "a_preplanning",
      "b_initial_consultation",
      "c_planning",
      "d_final_consultation",
      "e_approval",
      "f_closed",
    ];
    let nextStage = 0;
    for (const stage in stages) {
      if (stages[stage] === projectDetails?.stage) {
        nextStage = parseInt(stage) + 1;
      }
    }
    const input = {
      id,
      stage: stages[nextStage],
      isInRevision: false,
      projectClosed: nextStage === 5 ? "true" : "false",
      notificationMessage: `${projectDetails?.stage}_closed`,
    };
    input[`${projectDetails?.stage}_closed`] = new Date().toISOString();
    await API.graphql(
      graphqlOperation(mutations.updateProject, {
        input,
      })
    )
      .then((res) => {
        console.log("Successfully updated project!");
        props.getProjectDetails();
      })
      .catch((error) => console.error(error.message));
  };

  const setProjectInRevision = async () => {
    await API.graphql(
      graphqlOperation(mutations.updateProject, {
        input: { id, isInRevision: true },
      })
    )
      .then(async (res) => {
        console.log("Successfully revising project!");
        await props.getProjectDetails();
      })
      .catch((error) => console.error(error.message));
  };

  const closeProject = async (projectClosed) => {
    await API.graphql(
      graphqlOperation(mutations.updateProject, {
        input: { id, projectClosed },
      })
    )
      .then(async (res) => {
        console.log("Successfully closed project!");
        await props.getProjectDetails();
      })
      .catch((error) => console.error(error.message));
  };

  const reportAppeal = async () => {
    await API.graphql(
      graphqlOperation(mutations.updateProject, {
        input: { id, isInRevision: true },
      })
    )
      .then(async (res) => {
        console.log("Successfully reported appeal project!");
        await props.getProjectDetails();
      })
      .catch((error) => console.error(error.message));
  };

  const handleAppealOutcome = async (outcome) => {
    if (outcome === "appealOverruled") {
      await API.graphql(
        graphqlOperation(mutations.updateProject, {
          input: { id, isInRevision: false },
        })
      )
        .then(async (res) => {
          console.log(
            "Successfully resolved the appeal status of the project!"
          );
          await props.getProjectDetails();
        })
        .catch((error) => console.error(error.message));
    } else {
      await API.graphql(
        graphqlOperation(mutations.updateProject, {
          input: {
            id,
            isInRevision: false,
            projectClosed: true,
            stage: "e_approval",
          },
        })
      )
        .then(async (res) => {
          console.log("Successfully closed appealed project!");
          await props.getProjectDetails();
        })
        .catch((error) => console.error(error.message));
    }
  };

  // const goBackAStage = async () => {
  //   const stages = [
  //     "a_preplanning",
  //     "b_initial_consultation",
  //     "c_planning",
  //     "d_final_consultation",
  //     "e_approval",
  //     "f_closed",
  //   ];
  //   let previousStage = 0;
  //   for (const stage in stages) {
  //     if (stages[stage] === projectDetails?.stage) {
  //       previousStage = parseInt(stage) - 1;
  //     }
  //   }
  //   await API.graphql(
  //     graphqlOperation(mutations.updateProject, {
  //       input: { id, stage: stages[previousStage] },
  //     })
  //   )
  //     .then((res) => {
  //       console.log("Successfully updated project!");
  //       console.log(res);
  //       props.getProjectDetails();
  //     })
  //     .catch((error) => console.error(error.message));
  // };

  // const goBack = async () => {
  //   await API.graphql(
  //     graphqlOperation(mutations.updateProject, {
  //       input: { id, stage: "a_preplanning" },
  //     })
  //   )
  //     .then((res) => {
  //       console.log("Successfully updated project worfklow flag!");
  //       console.log(res);
  //       props.getProjectDetails();
  //     })
  //     .catch((error) =>
  //       console.error("Error updating project: ", error.message)
  //     );
  // };

  // Appeal outcome
  const [reportAppealModal, showReportAppealModal] = useState(false);
  const [shareAppealOutcomeModal, showShareAppealOutcomeModal] =
    useState(false);

  const updateProductivityPreference = async (tasksExpanded) => {
    await API.graphql(
      graphqlOperation(mutations.updatePersona, {
        input: {
          id: userDetails.id,
          tasksExpanded,
        },
      })
    );
  };

  // Get the latest City Council Meeting deadline as there can be many deadlines for the same stage
  const getLatestCityCouncilDeadlineInCloseStageCheck = (project) => {
    let latestDate = "";
    if (project.deadlines?.items.length) {
      let sortByDate = project.deadlines?.items
        ?.sort((a, b) => {
          return new Date(b.createdAt) - new Date(a.createdAt);
        })
        .filter(
          (deadline) =>
            deadline.stage === projectDetails?.stage &&
            deadline.deadlineEnum === "city_council"
        );
      if (sortByDate[0]?.date) latestDate = sortByDate[0]?.date;
    }
    return latestDate;
  };

  useEffect(() => {
    if (projectDetails?.startDate) {
      setStartDate(projectDetails?.startDate);
    }
    setExistingDeadlines();
    setAssignedDevelopers(
      projectDetails?.developers?.items.map((developer) => ({
        value: developer.personaId,
        label: `${developer?.persona.given_name} ${developer?.persona.family_name}`,
      }))
    );
    setAssignedAgents(
      projectDetails?.agents?.items.map((agent) => ({
        value: agent.personaId,
        label: `${agent?.persona.given_name} ${agent?.persona.family_name}`,
      }))
    );
    setAssignedSMEs(
      projectDetails?.smes?.items.map((sme) => ({
        value: sme.personaId,
        label: `${sme?.persona.given_name} ${sme?.persona.family_name}`,
      }))
    );
  }, [projectDetails]);

  return (
    <Container
      fitHeight
      header={
        <Header
          variant="h2"
          description={t(
            "Overview of the project including timeline and key highlights"
          )}
          actions={
            !projectDetails?.name ? (
              <>
                <CircularProgress size={20} />
                <span style={{ paddingLeft: 10 }}>
                  <b>{t("Fetching data")}...</b>
                </span>
              </>
            ) : (
              userDetails.role === "agents" && (
                <SpaceBetween direction="horizontal" size="s">
                  {loadingCircle && (
                    <StatusIndicator type="loading">
                      {t("Saving changes")} ...
                    </StatusIndicator>
                  )}
                  {projectDetails?.mainAgentId === userDetails.id && (
                    <Button
                      variant="primary"
                      disabled={
                        projectDetails?.projectClosed ||
                        projectDetails?.stage[0] === "f"
                      }
                      onClick={() => {
                        showStartDateModal(true);
                      }}
                    >
                      {startDate ? t("Update") : t("Set")} {t("Start Date")}
                    </Button>
                  )}
                  {!projectDetails?.workflowSet &&
                    userDetails.id === projectDetails?.mainAgentId && (
                      <Button variant="primary" href={"/workflow-setup/" + id}>
                        {t("Setup Workflow")}
                      </Button>
                    )}
                  {projectDetails?.workflowSet &&
                    projectDetails?.startDate <= currentDate && (
                      <>
                        <Button
                          variant="primary"
                          iconAlign="left"
                          iconName="download"
                        >
                          <CSVLink
                            separator={";"}
                            style={{ textDecoration: "none", color: "white" }}
                            filename={`${projectDetails?.name}-${t(
                              "Project Deadlines"
                            )}-${new Date().toLocaleDateString()}.csv`}
                            data={uniqWith(
                              projectDetails.deadlines?.items?.map((c) => ({
                                stage: t(c.stage),
                                deadline: t(c.deadlineEnum),
                                date: getDeadlineDatePicker(
                                  c.stage,
                                  c.deadlineEnum
                                ),
                              })),
                              (a, b) => {
                                return isEqual(a, b);
                              }
                            )}
                            headers={[
                              { label: t("Date"), key: "date" },
                              { label: t("Stage"), key: "stage" },
                              { label: t("Deadline"), key: "deadline" },
                            ]}
                          >
                            {t("Export Deadlines")}
                          </CSVLink>
                        </Button>
                        <Tooltip title="Edit milestone deadlines">
                          <IconButton
                            aria-label="Edit milestone deadlines"
                            disabled={
                              projectDetails?.projectClosed ||
                              projectDetails?.stage[0] === "f"
                            }
                            onClick={() => {
                              showEditDeadlinesModal(true);
                            }}
                          >
                            <EditCalendarIcon style={{ color: primaryBlue }} />
                          </IconButton>
                        </Tooltip>
                      </>
                    )}
                </SpaceBetween>
              )
            )
          }
        >
          {t("Project Overview")}
        </Header>
      }
    >
      <br />
      {/* ============================= PROJECT TIMELINE ============================= */}
      {!projectDetails?.name ? (
        <p style={{ textAlign: "center" }}>Loading...</p>
      ) : projectDetails?.startDate &&
        projectDetails?.startDate <= currentDate ? (
        <ProjectTimelineDynamic
          t={t}
          projectDetails={projectDetails}
          viewingStage={props.viewingStage}
          setViewingStage={props.setViewingStage}
        />
      ) : (
        <PrePlanningPhase
          projectDetails={projectDetails}
          role={userDetails.role}
          t={t}
        />
      )}
      <br />
      {/* ============================= PROJECT OVERVIEW STATS ============================= */}
      <Divider />
      <Box>
        <ColumnLayout
          borders="vertical"
          columns={
            projectDetails?.deadlines?.items?.find(
              (d) =>
                d.stage === projectDetails?.stage &&
                d.deadlineEnum === "public_consultation"
            )?.date
              ? 4
              : 3
          }
        >
          {/* -------------------- Open Tasks -------------------- */}
          <TextContent>
            <SpaceBetween size="s">
              <ExpandableSection
                variant="footer"
                defaultExpanded={userDetails?.tasksExpanded}
                onChange={(e) =>
                  updateProductivityPreference(e.detail.expanded)
                }
                headerText={
                  <h3 style={{ marginTop: "-5px" }}>{t("Open tasks")}</h3>
                }
              >
                <div style={{ display: "block" }}>
                  <h1
                    style={{
                      float: "left",
                      color: primaryBlue,
                      fontSize: "50px",
                      marginRight: 20,
                      display: "block",
                    }}
                  >
                    {
                      projectDetails?.tasks?.items?.filter(
                        (t) => t.stage === props.viewingStage && !t.done
                      ).length
                    }
                  </h1>
                  <div
                    style={{
                      textAlign: "left",
                      width: "100%",
                      marginLeft: 10,
                      marginRight: 20,
                    }}
                  >
                    <b>
                      {t("Tasks")} {t("in delay")}
                      <span
                        style={{
                          color: primaryBlue,
                          float: "left",
                          marginRight: 5,
                        }}
                      >
                        {projectDetails?.tasks?.items.filter(
                          (task) =>
                            task.stage === props?.viewingStage &&
                            !task.done &&
                            task.deadline &&
                            isDeadlinePassed(task.deadline)
                        ).length || 0}
                      </span>
                    </b>
                    <br />
                    <b>
                      {t("Documents")} {t("in delay")}
                      <span
                        style={{
                          color: primaryBlue,
                          float: "left",
                          marginRight: 5,
                        }}
                      >
                        {projectDetails?.documents?.items.filter(
                          (documents) =>
                            documents.stage === props?.viewingStage &&
                            document.status !== "d_done" &&
                            documents.deadline &&
                            isDeadlinePassed(documents.deadline)
                        ).length || 0}
                      </span>
                    </b>
                  </div>
                </div>
              </ExpandableSection>
            </SpaceBetween>
          </TextContent>
          {/* -------------------- Collaborators -------------------- */}
          <TextContent>
            <SpaceBetween size="s">
              <h4>{t("Collaborators")}</h4>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h1
                  style={{
                    float: "left",
                    color: primaryBlue,
                    fontSize: "50px",
                  }}
                >
                  {assignedDevelopers?.length +
                    assignedAgents?.length +
                    assignedSMEs?.length +
                    1 +
                    (projectDetails?.mainAgent?.id ? 1 : 0)}
                </h1>
                {(projectDetails?.mainDeveloperId === userDetails.id ||
                  projectDetails?.mainAgentId === userDetails.id) && (
                  <div style={{ marginRight: 20 }}>
                    <Button
                      disabled={projectDetails?.projectClosed}
                      onClick={() => {
                        showManageTeamModal(true);
                      }}
                    >
                      {t("Manage team")}
                    </Button>
                  </div>
                )}
              </div>
            </SpaceBetween>
          </TextContent>
          {/* -------------------- Public Consultation -------------------- */}
          {/* Only show Public Consultaion box if the stage exists */}
          {projectDetails?.deadlines?.items?.find(
            (d) =>
              d.stage === projectDetails?.stage &&
              d.deadlineEnum === "public_consultation"
          )?.date && (
            <TextContent>
              <SpaceBetween size="s">
                <h4>{t("Public Consultation")}</h4>
                <h1
                  style={{
                    color: primaryBlue,
                    fontSize: "24px",
                    whiteSpace: "nowrap",
                  }}
                >
                  {
                    projectDetails?.deadlines?.items?.find(
                      (d) =>
                        d.stage === projectDetails?.stage &&
                        d.deadlineEnum === "public_consultation"
                    )?.date
                  }
                </h1>
              </SpaceBetween>
            </TextContent>
          )}
          {/* -------------------- City Council Meeting -------------------- */}
          <TextContent>
            <SpaceBetween size="s">
              <h4>{t("City Council Meeting")}</h4>
              <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h1
                  style={{
                    color: primaryBlue,
                    fontSize: "24px",
                    whiteSpace: "nowrap",
                    marginRight: "10%",
                  }}
                >
                  {
                    projectDetails?.deadlines?.items?.find(
                      (d) =>
                        d.stage === projectDetails?.stage &&
                        d.deadlineEnum === "city_council"
                    )?.date
                  }
                </h1>
                <Box float="right">
                  {projectDetails?.mainAgentId === userDetails.id &&
                    (!projectDetails?.projectClosed ? (
                      <SpaceBetween size="s">
                        <Button
                          // disabled={
                          //   projectDetails?.projectClosed ||
                          //   !projectDetails?.summaryVerified ||
                          //   userDetails.id !== projectDetails?.mainAgentId ||
                          //   new Date(
                          //     projectDetails?.deadlines?.items?.find(
                          //       (d) =>
                          //         d.stage === projectDetails?.stage &&
                          //         d.deadlineEnum === "city_council"
                          //     )?.date
                          //   ) > new Date()
                          // }
                          disabled={projectDetails?.projectClosed}
                          onClick={() => showCityCouncilOutcomeModal(true)}
                        >
                          {t("Share Outcome")}
                        </Button>

                        {/* NEW FEATURE - To enable in January for Denmark only */}
                        {projectDetails?.mainAgent?.municipality.slice(0, 2) ===
                          "DK" &&
                          projectDetails?.stage ===
                            "b_initial_consultation" && (
                            <Button onClick={() => showSkipStageModal(true)}>
                              {t("Skip Stage")}
                            </Button>
                          )}
                      </SpaceBetween>
                    ) : (
                      projectDetails?.stage[0] === "f" && (
                        <SpaceBetween size="s">
                          {projectDetails.isInRevision ? (
                            <Button
                              onClick={() => showShareAppealOutcomeModal(true)}
                            >
                              {t("Share Appeal Outcome")}
                            </Button>
                          ) : (
                            <div style={{ display: "flex" }}>
                              <Button
                                disabled={
                                  // disable Report Appeal button if more than 4 weeks has passed (=2419200066 milliseconds)
                                  new Date() -
                                    new Date(
                                      projectDetails?.e_approval_closed
                                    ) >
                                  2419200066
                                }
                                onClick={() => showReportAppealModal(true)}
                              >
                                {t("Report Appeal")}
                              </Button>
                              <div style={{ marginTop: 15, marginLeft: 10 }}>
                                <Popover
                                  position="right"
                                  size="small"
                                  triggerType="custom"
                                  content={t(
                                    "You have 4 weeks to report an appeal. After this, the button  will be disabled."
                                  )}
                                >
                                  <Icon name="status-info" variant="link" />
                                </Popover>
                              </div>
                            </div>
                          )}
                        </SpaceBetween>
                      )
                    ))}
                </Box>
              </div>
            </SpaceBetween>
          </TextContent>
        </ColumnLayout>
      </Box>

      {/* ============================= POP-UP MODALS ================================ */}
      {/* --------------- SET START DATE Modal Popup --------------- */}
      <Modal
        onDismiss={() => showStartDateModal(false)}
        visible={startDateModal}
        closeAriaLabel="Close modal"
        header={t("Set Project Start Date")}
        footer={
          <>
            {startDateLoadingCircle && (
              <StatusIndicator type="loading">
                {t("Saving changes")}...
              </StatusIndicator>
            )}
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="link"
                  onClick={() => showStartDateModal(false)}
                >
                  {t("Cancel")}
                </Button>
                {
                  // Only show remove button if start date is already set
                  projectDetails?.startDate && (
                    <Button onClick={deleteStartDate}>
                      {t("Remove start date")}
                    </Button>
                  )
                }
                <Button
                  variant="primary"
                  onClick={() => {
                    saveStartDate();
                    showStartDateLoadingCircle(true);
                  }}
                >
                  {t("Save Changes")}
                </Button>
              </SpaceBetween>
            </Box>
          </>
        }
      >
        <SpaceBetween direction="vertical" size="l">
          This date will mark the approximate beginning of the permitting
          process. This date can be updated later if needed.
          <FormField label="Set project start date" constraintText="YYYY/MM/DD">
            <DatePicker
              isDateEnabled={(date) =>
                // Datepicker only allows selecting date starting tomorrow
                // so we have to  minus a day to be able to select today's date
                date >= new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
              }
              placeholder="YYYY/MM/DD"
              onChange={({ detail }) => setStartDate(detail.value)}
              value={startDate}
              openCalendarAriaLabel={(selectedDate) =>
                "Select project start date" +
                (selectedDate ? `, selected date is ${selectedDate}` : "")
              }
            />
          </FormField>
        </SpaceBetween>
      </Modal>

      {/* -------- EDIT MILESTONES DEADLINES Modal Popup -------- */}
      <Modal
        header={t("Edit Milestones Deadlines")}
        size="max"
        onDismiss={() => showEditDeadlinesModal(false)}
        visible={editDeadlinesModal}
        footer={
          <>
            {deadlinesLoadingCircle && (
              <Box float="left">
                <StatusIndicator type="loading">
                  {t("Saving changes")}...
                </StatusIndicator>
              </Box>
            )}
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="link"
                  onClick={() => {
                    showEditDeadlinesModal(false);
                  }}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  variant="primary"
                  onClick={async () => {
                    showDeadlinesLoadingCircle(true);
                    await setDeadline("a_preplanning", "documents_handover");
                    await setDeadline("a_preplanning", "city_council");
                    await setDeadline(
                      "b_initial_consultation",
                      "documents_handover"
                    );
                    await setDeadline(
                      "b_initial_consultation",
                      "public_consultation"
                    );
                    await setDeadline("b_initial_consultation", "city_council");
                    await setDeadline("c_planning", "documents_handover");
                    await setDeadline("c_planning", "city_council");
                    await setDeadline(
                      "d_final_consultation",
                      "documents_handover"
                    );
                    await setDeadline(
                      "d_final_consultation",
                      "public_consultation"
                    );
                    await setDeadline("d_final_consultation", "city_council");
                    await setDeadline("e_approval", "documents_handover");
                    await setDeadline("e_approval", "city_council");

                    showEditDeadlinesModal(false);
                  }}
                >
                  {t("Save Changes")}
                </Button>
              </SpaceBetween>
            </Box>
          </>
        }
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginBottom: 20,
          }}
        >
          {/* ------------------ Pre-planning ------------------ */}
          <Box>
            <h3>{t("Pre-planning")}</h3>
            {/* Because the deadline names have varying lengths in different languages, the alignment is messed up if the texts are too long/too short
                So we use Grid so that the contents auto-align correctly instead of <div> with display flex */}
            <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
              <EditDeadlinesPopupLinearProgress
                t={t}
                projectDetails={projectDetails}
                prevStage={null}
                prevDeadlineEnum={null}
                currentStage={stageName1} // "a_preplanning"
                currentDeadlineEnum={deadlineEnum1} // "documents_handover"
                deadlineName="Documents Review"
                deadlineDate={pp_Documents_Deadline}
              />
              <EditDeadlinesPopupLinearProgress
                t={t}
                projectDetails={projectDetails}
                prevStage={stageName1} // "a_preplanning"
                prevDeadlineEnum={deadlineEnum1} // "documents_handover"
                currentStage={stageName1} // "a_preplanning"
                currentDeadlineEnum={deadlineEnum3} //  "city_council"
                deadlineName="City Council Meeting"
                deadlineDate={pp_CityCouncil_Deadline}
              />
            </Grid>
            <div style={{ height: 20 }} />
            <Button onClick={() => showPrePlanningModal(true)}>
              {t("Update deadlines")}
            </Button>
            <Modal
              header={t("Pre-planning")}
              onDismiss={() => showPrePlanningModal(false)}
              visible={prePlanningModal}
              footer={
                <>
                  <Box float="left">
                    {loadingCircle && (
                      <StatusIndicator type="loading">
                        {t("Saving changes")}...
                      </StatusIndicator>
                    )}
                  </Box>
                  <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        variant="link"
                        onClick={() => showPrePlanningModal(false)}
                      >
                        {t("Cancel")}
                      </Button>
                      <Button
                        variant="primary"
                        disabled={
                          !(pp_Documents_Deadline && pp_CityCouncil_Deadline)
                        }
                        onClick={async () => showPrePlanningModal(false)}
                      >
                        {t("Save Changes")}
                      </Button>
                    </SpaceBetween>
                  </Box>
                </>
              }
            >
              {t("Update deadlines")}
              <div style={{ height: 30 }} />
              <ColumnLayout columns={2}>
                <FormField
                  label={t("Documents Review")}
                  constraintText="YYYY/MM/DD"
                  errorText={validateDate(
                    pp_Documents_Deadline,
                    pp_CityCouncil_Deadline
                  )}
                >
                  <DatePicker
                    isDateEnabled={(date) =>
                      date >=
                      new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
                    }
                    onChange={({ detail }) => {
                      set_pp_Documents_Deadline(
                        (pp_Documents_Deadline = detail.value)
                      );
                    }}
                    value={pp_Documents_Deadline}
                    openCalendarAriaLabel={(selectedDate) =>
                      `selected date is ${selectedDate}`
                    }
                    placeholder="YYYY/MM/DD"
                  />
                </FormField>
                <FormField
                  label={t("City Council Meeting")}
                  constraintText="YYYY/MM/DD"
                >
                  <DatePicker
                    isDateEnabled={(date) =>
                      date > new Date(pp_Documents_Deadline)
                    }
                    onChange={({ detail }) => {
                      set_pp_CityCouncil_Deadline(
                        (pp_CityCouncil_Deadline = detail.value)
                      );
                    }}
                    value={pp_CityCouncil_Deadline}
                    openCalendarAriaLabel={(selectedDate) =>
                      `selected date is ${selectedDate}`
                    }
                    placeholder="YYYY/MM/DD"
                  />
                </FormField>
              </ColumnLayout>
            </Modal>
          </Box>
          <VerticalDivider />
          {/* ------------- Initial Public consultation ----------- */}
          <Box>
            <h3>{t("Initial Public Consultation")}</h3>
            <Grid
              gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}
            >
              <EditDeadlinesPopupLinearProgress
                t={t}
                projectDetails={projectDetails}
                prevStage={stageName1} // "a_preplanning"
                prevDeadlineEnum={deadlineEnum3} // "city_council"
                currentStage={stageName2} // "b_initial_consultation"
                currentDeadlineEnum={deadlineEnum1} //  "documents_handover"
                deadlineName="Documents Review"
                deadlineDate={ipc_Documents_Deadline}
              />
              <EditDeadlinesPopupLinearProgress
                t={t}
                projectDetails={projectDetails}
                prevStage={stageName2} // "b_initial_consultation"
                prevDeadlineEnum={deadlineEnum1} // "documents_handover"
                currentStage={stageName2} // "b_initial_consultation"
                currentDeadlineEnum={deadlineEnum2} //  "public_consultation"
                deadlineName="Public Consultation"
                deadlineDate={ipc_Consultation_Deadline}
              />
              <EditDeadlinesPopupLinearProgress
                t={t}
                projectDetails={projectDetails}
                prevStage={stageName2} // "b_initial_consultation"
                prevDeadlineEnum={deadlineEnum2} // "public_consultation"
                currentStage={stageName2} // "b_initial_consultation"
                currentDeadlineEnum={deadlineEnum3} //  "city_council"
                deadlineName="City Council Meeting"
                deadlineDate={ipc_CityCouncil_Deadline}
              />
            </Grid>
            <div style={{ height: 20 }} />
            <Button onClick={() => showInitialConsultationModal(true)}>
              {t("Update deadlines")}
            </Button>
            <Modal
              header={t("Initial Public Consultation")}
              size="large"
              onDismiss={() => showInitialConsultationModal(false)}
              visible={initialConsultationModal}
              footer={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      variant="link"
                      onClick={() => showInitialConsultationModal(false)}
                    >
                      {t("Cancel")}
                    </Button>
                    <Button
                      variant="primary"
                      disabled={
                        !(
                          ipc_Documents_Deadline &&
                          ipc_Consultation_Deadline &&
                          ipc_CityCouncil_Deadline
                        )
                      }
                      onClick={() => showInitialConsultationModal(false)}
                    >
                      {t("Save Changes")}
                    </Button>
                  </SpaceBetween>
                </Box>
              }
            >
              {t("Update deadlines")}
              <div style={{ height: 30 }} />
              <ColumnLayout columns={3}>
                <FormField
                  label={t("Documents Review")}
                  constraintText="YYYY/MM/DD"
                  errorText={validateDate(
                    ipc_Documents_Deadline,
                    ipc_Consultation_Deadline
                  )}
                >
                  <DatePicker
                    isDateEnabled={(date) =>
                      date > new Date(pp_CityCouncil_Deadline)
                    }
                    onChange={({ detail }) =>
                      set_ipc_Documents_Deadline(
                        (ipc_Documents_Deadline = detail.value)
                      )
                    }
                    value={ipc_Documents_Deadline}
                    openCalendarAriaLabel={(selectedDate) =>
                      `selected date is ${selectedDate}`
                    }
                    placeholder="YYYY/MM/DD"
                  />
                </FormField>
                <FormField
                  label="Public Consultation"
                  constraintText="YYYY/MM/DD"
                  errorText={validateDate(
                    ipc_Consultation_Deadline,
                    ipc_CityCouncil_Deadline
                  )}
                >
                  <DatePicker
                    isDateEnabled={(date) =>
                      date > new Date(ipc_Documents_Deadline)
                    }
                    onChange={({ detail }) =>
                      set_ipc_consultation_Deadline(
                        (ipc_Consultation_Deadline = detail.value)
                      )
                    }
                    value={ipc_Consultation_Deadline}
                    openCalendarAriaLabel={(selectedDate) =>
                      `selected date is ${selectedDate}`
                    }
                    placeholder="YYYY/MM/DD"
                  />
                </FormField>
                <FormField
                  label={t("City Council Meeting")}
                  constraintText="YYYY/MM/DD"
                >
                  <DatePicker
                    isDateEnabled={(date) =>
                      date > new Date(ipc_Consultation_Deadline)
                    }
                    onChange={({ detail }) =>
                      set_ipc_CityCouncil_Deadline(
                        (ipc_CityCouncil_Deadline = detail.value)
                      )
                    }
                    value={ipc_CityCouncil_Deadline}
                    openCalendarAriaLabel={(selectedDate) =>
                      `selected date is ${selectedDate}`
                    }
                    placeholder="YYYY/MM/DD"
                  />
                </FormField>
              </ColumnLayout>
            </Modal>
          </Box>
          <VerticalDivider />
          {/* ------------------ Planning process ----------------- */}
          <Box>
            <h3>{t("Planning Process")}</h3>
            <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
              <EditDeadlinesPopupLinearProgress
                t={t}
                projectDetails={projectDetails}
                prevStage={stageName1} // "a_preplanning"
                prevDeadlineEnum={deadlineEnum3} // "city_council"
                currentStage={stageName3} // "c_planning"
                currentDeadlineEnum={deadlineEnum1} //  "documents_handover"
                deadlineName="Documents Review"
                deadlineDate={process_Documents_Deadline}
              />
              <EditDeadlinesPopupLinearProgress
                t={t}
                projectDetails={projectDetails}
                prevStage={stageName3} // "c_planning"
                prevDeadlineEnum={deadlineEnum1} //  "documents_handover"
                currentStage={stageName3} // "c_planning"
                currentDeadlineEnum={deadlineEnum3} // "city_council"
                deadlineName="City Council Meeting"
                deadlineDate={process_CityCouncil_Deadline}
              />
            </Grid>
            <div style={{ height: 20 }} />
            <Button onClick={() => showPlanningProcessModal(true)}>
              {t("Update deadlines")}
            </Button>
            <Modal
              onDismiss={() => showPlanningProcessModal(false)}
              visible={planningProcessModal}
              footer={
                <>
                  <Box float="left">
                    {loadingCircle && (
                      <StatusIndicator type="loading">
                        {t("Saving changes")}...
                      </StatusIndicator>
                    )}
                  </Box>
                  <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        variant="link"
                        onClick={() => showPlanningProcessModal(false)}
                      >
                        {t("Cancel")}
                      </Button>
                      <Button
                        variant="primary"
                        disabled={
                          !(
                            process_Documents_Deadline &&
                            process_CityCouncil_Deadline
                          )
                        }
                        onClick={async () => {
                          showPlanningProcessModal(false);
                        }}
                      >
                        {t("Save Changes")}
                      </Button>
                    </SpaceBetween>
                  </Box>
                </>
              }
              header={t("Planning Process")}
            >
              {t("Update deadlines")}
              <div style={{ height: 30 }} />
              <ColumnLayout columns={2}>
                <FormField
                  label={t("Documents Review")}
                  constraintText="YYYY/MM/DD"
                  errorText={validateDate(
                    process_Documents_Deadline,
                    process_CityCouncil_Deadline
                  )}
                >
                  <DatePicker
                    isDateEnabled={(date) =>
                      date > new Date(ipc_CityCouncil_Deadline)
                    }
                    onChange={({ detail }) => {
                      set_process_Documents_Deadline(
                        (process_Documents_Deadline = detail.value)
                      );
                    }}
                    value={process_Documents_Deadline}
                    openCalendarAriaLabel={(selectedDate) =>
                      `selected date is ${selectedDate}`
                    }
                    placeholder="YYYY/MM/DD"
                  />
                </FormField>
                <FormField
                  label={t("City Council Meeting")}
                  constraintText="YYYY/MM/DD"
                >
                  <DatePicker
                    isDateEnabled={(date) =>
                      date > new Date(process_Documents_Deadline)
                    }
                    onChange={({ detail }) => {
                      set_process_CityCouncil_Deadline(
                        (process_CityCouncil_Deadline = detail.value)
                      );
                    }}
                    value={process_CityCouncil_Deadline}
                    openCalendarAriaLabel={(selectedDate) =>
                      `selected date is ${selectedDate}`
                    }
                    placeholder="YYYY/MM/DD"
                  />
                </FormField>
              </ColumnLayout>
            </Modal>
          </Box>
          <VerticalDivider />
          {/* ------------------ Final consultation --------------- */}
          <Box>
            <h3>{t("Final Public Consultation")}</h3>
            <Grid
              gridDefinition={[{ colspan: 4 }, { colspan: 4 }, { colspan: 4 }]}
            >
              <EditDeadlinesPopupLinearProgress
                t={t}
                projectDetails={projectDetails}
                prevStage={stageName3} // "c_planning"
                prevDeadlineEnum={deadlineEnum3} // "city_council"
                currentStage={stageName4} // "d_final_consultation"
                currentDeadlineEnum={deadlineEnum1} //  "documents_handover"
                deadlineName="Documents Review"
                deadlineDate={pc_Documents_Deadline}
              />
              <EditDeadlinesPopupLinearProgress
                t={t}
                projectDetails={projectDetails}
                prevStage={stageName4} // "d_final_consultation"
                prevDeadlineEnum={deadlineEnum1} //  "documents_handover"
                currentStage={stageName4} // "d_final_consultation"
                currentDeadlineEnum={deadlineEnum2} //   "public_consultation"
                deadlineName="Public Consultation"
                deadlineDate={pc_Consultation_Deadline}
              />
              <EditDeadlinesPopupLinearProgress
                t={t}
                projectDetails={projectDetails}
                prevStage={stageName4} // "d_final_consultation"
                prevDeadlineEnum={deadlineEnum2} //   "public_consultation"
                currentStage={stageName4} // "d_final_consultation"
                currentDeadlineEnum={deadlineEnum3} // "city_council"
                deadlineName="City Council Meeting"
                deadlineDate={pc_CityCouncil_Deadline}
              />
            </Grid>
            <div style={{ height: 20 }} />
            <Button onClick={() => showPublicConsultationModal(true)}>
              {t("Update deadlines")}
            </Button>
            <Modal
              header={t("Final Public Consultation")}
              size="large"
              onDismiss={() => showPublicConsultationModal(false)}
              visible={publicConsultationModal}
              footer={
                <Box float="right">
                  <SpaceBetween direction="horizontal" size="xs">
                    <Button
                      variant="link"
                      onClick={() => showPublicConsultationModal(false)}
                    >
                      {t("Cancel")}
                    </Button>
                    <Button
                      variant="primary"
                      disabled={
                        !(
                          pc_Documents_Deadline &&
                          pc_Consultation_Deadline &&
                          pc_CityCouncil_Deadline
                        )
                      }
                      onClick={async () => {
                        showPublicConsultationModal(false);
                      }}
                    >
                      {t("Save Changes")}
                    </Button>
                  </SpaceBetween>
                </Box>
              }
            >
              {t("Update deadlines")}
              <div style={{ height: 30 }} />
              <ColumnLayout columns={3}>
                <FormField
                  label={t("Documents Review")}
                  constraintText="YYYY/MM/DD"
                  errorText={validateDate(
                    process_Documents_Deadline,
                    process_CityCouncil_Deadline
                  )}
                >
                  <DatePicker
                    isDateEnabled={(date) =>
                      date > new Date(process_CityCouncil_Deadline)
                    }
                    onChange={({ detail }) =>
                      set_pc_Documents_Deadline(
                        (pc_Documents_Deadline = detail.value)
                      )
                    }
                    value={pc_Documents_Deadline}
                    openCalendarAriaLabel={(selectedDate) =>
                      `selected date is ${selectedDate}`
                    }
                    placeholder="YYYY/MM/DD"
                  />
                </FormField>
                <FormField
                  label="Public Consultation"
                  constraintText="YYYY/MM/DD"
                  errorText={validateDate(
                    pc_Consultation_Deadline,
                    pc_CityCouncil_Deadline
                  )}
                >
                  <DatePicker
                    isDateEnabled={(date) =>
                      date > new Date(pc_Documents_Deadline)
                    }
                    onChange={({ detail }) =>
                      set_pc_consultation_Deadline(
                        (pc_Consultation_Deadline = detail.value)
                      )
                    }
                    value={pc_Consultation_Deadline}
                    openCalendarAriaLabel={(selectedDate) =>
                      `selected date is ${selectedDate}`
                    }
                    placeholder="YYYY/MM/DD"
                  />
                </FormField>
                <FormField
                  label={t("City Council Meeting")}
                  constraintText="YYYY/MM/DD"
                >
                  <DatePicker
                    isDateEnabled={(date) =>
                      date > new Date(pc_Consultation_Deadline)
                    }
                    onChange={({ detail }) =>
                      set_pc_CityCouncil_Deadline(
                        (pc_CityCouncil_Deadline = detail.value)
                      )
                    }
                    value={pc_CityCouncil_Deadline}
                    openCalendarAriaLabel={(selectedDate) =>
                      `selected date is ${selectedDate}`
                    }
                    placeholder="YYYY/MM/DD"
                  />
                </FormField>
              </ColumnLayout>
            </Modal>
          </Box>
          <VerticalDivider />
          {/* ------------------ Approval ------------------ */}
          <Box>
            <h3>{t("Final Approval")}</h3>
            <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
              <EditDeadlinesPopupLinearProgress
                t={t}
                projectDetails={projectDetails}
                prevStage={stageName4} // "d_final_consultation"
                prevDeadlineEnum={deadlineEnum3} // "city_council"
                currentStage={stageName5} // "e_approval"
                currentDeadlineEnum={deadlineEnum1} //  "documents_handover"
                deadlineName="Documents Review"
                deadlineDate={fa_Documents_Deadline}
              />
              <EditDeadlinesPopupLinearProgress
                t={t}
                projectDetails={projectDetails}
                prevStage={stageName5} // "e_approval"
                prevDeadlineEnum={deadlineEnum1} //  "documents_handover"
                currentStage={stageName5} // "e_approval"
                currentDeadlineEnum={deadlineEnum3} // "city_council"
                deadlineName="Appeal"
                deadlineDate={fa_Approval_Deadline}
              />
            </Grid>
            <div style={{ height: 20 }} />
            <Button onClick={() => showApprovalModal(true)}>
              {t("Update deadlines")}
            </Button>
            <Modal
              onDismiss={() => showApprovalModal(false)}
              visible={approvalModal}
              footer={
                <>
                  <Box float="left">
                    {loadingCircle && (
                      <StatusIndicator type="loading">
                        {t("Saving changes")}...
                      </StatusIndicator>
                    )}
                  </Box>
                  <Box float="right">
                    <SpaceBetween direction="horizontal" size="xs">
                      <Button
                        variant="link"
                        onClick={() => showApprovalModal(false)}
                      >
                        {t("Cancel")}
                      </Button>
                      <Button
                        variant="primary"
                        disabled={
                          !(fa_Documents_Deadline && fa_Approval_Deadline)
                        }
                        onClick={async () => {
                          showApprovalModal(false);
                        }}
                      >
                        {t("Save Changes")}
                      </Button>
                    </SpaceBetween>
                  </Box>
                </>
              }
              header={t("Final Approval")}
            >
              {t("Update deadlines")}
              <div style={{ height: 30 }} />
              <ColumnLayout columns={2}>
                <FormField
                  label={t("Documents Review")}
                  constraintText="YYYY/MM/DD"
                  errorText={validateDate(
                    fa_Documents_Deadline,
                    fa_Approval_Deadline
                  )}
                >
                  <DatePicker
                    isDateEnabled={(date) =>
                      date > new Date(pc_CityCouncil_Deadline)
                    }
                    onChange={({ detail }) => {
                      set_fa_Documents_Deadline(
                        (fa_Documents_Deadline = detail.value)
                      );
                    }}
                    value={fa_Documents_Deadline}
                    openCalendarAriaLabel={(selectedDate) =>
                      `selected date is ${selectedDate}`
                    }
                    placeholder="YYYY/MM/DD"
                  />
                </FormField>
                <FormField
                  label={t("City Council Meeting")}
                  constraintText="YYYY/MM/DD"
                >
                  <DatePicker
                    isDateEnabled={(date) =>
                      date > new Date(fa_Documents_Deadline)
                    }
                    onChange={({ detail }) => {
                      set_fa_Approval_Deadline(
                        (fa_Approval_Deadline = detail.value)
                      );
                    }}
                    value={fa_Approval_Deadline}
                    openCalendarAriaLabel={(selectedDate) =>
                      `selected date is ${selectedDate}`
                    }
                    placeholder="YYYY/MM/DD"
                  />
                </FormField>
              </ColumnLayout>
            </Modal>
          </Box>
        </div>
      </Modal>

      {/* ---------------- MANAGE TEAM Modal Popup ---------------- */}
      <Modal
        size="large"
        onDismiss={() => showManageTeamModal(false)}
        visible={manageTeamModal}
        footer={
          <>
            <Box float="left">
              {loadingCircle && (
                <StatusIndicator type="loading">
                  {t("Saving changes")}...
                </StatusIndicator>
              )}
            </Box>
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="link"
                  onClick={() => showManageTeamModal(false)}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  variant="primary"
                  disabled={loadingCircle}
                  onClick={async () => {
                    showLoadingCircle(true);
                    await updateCollaborators();
                    showLoadingCircle(false);
                    showManageTeamModal(false);
                  }}
                >
                  {t("Save Changes")}
                </Button>
              </SpaceBetween>
            </Box>
          </>
        }
        header={t("Manage Team")}
      >
        <div style={{ height: 15 }} />
        {/* Main project agent and developer */}
        <ColumnLayout columns={2}>
          <div>
            <b>{t("Project Developer")}</b>
            <br />
            {projectDetails?.mainDeveloper ? (
              <Popover
                position="top"
                dismissButton={false}
                size="medium"
                triggerType="custom"
                header={
                  projectDetails?.mainDeveloper?.given_name +
                  " " +
                  projectDetails?.mainDeveloper?.family_name
                }
                content={
                  <SpaceBetween size="s">
                    <Link>{projectDetails?.mainDeveloper?.email}</Link>
                    <span>{projectDetails?.mainDeveloper?.phone_number}</span>
                    <span>
                      {projectDetails?.mainAgent?.role === "agents"
                        ? "Agent"
                        : "Developer"}
                    </span>
                    <span>
                      {t("Municipality")}:{" "}
                      {MapCodeToLabel(
                        projectDetails?.mainAgent?.municipality
                      ) || "None"}
                    </span>
                  </SpaceBetween>
                }
              >
                <div
                  style={{
                    display: "flex",
                    cursor: "pointer",
                  }}
                >
                  <AccountCircleIcon style={{ marginRight: 5 }} />
                  <b style={{ color: primaryBlue }}>
                    {projectDetails?.mainDeveloper?.given_name +
                      " " +
                      projectDetails?.mainDeveloper?.family_name}
                  </b>
                </div>
              </Popover>
            ) : (
              <i style={{ color: "gray" }}>{t("Not yet assigned")}</i>
            )}
          </div>
          <div>
            <b>{t("Project Agent")}</b>
            <br />
            {projectDetails?.mainAgent ? (
              <Popover
                position="top"
                dismissButton={false}
                size="medium"
                triggerType="custom"
                header={
                  projectDetails?.mainAgent?.given_name +
                  " " +
                  projectDetails?.mainAgent?.family_name
                }
                content={
                  <SpaceBetween size="s">
                    <Link>{projectDetails?.mainAgent?.email}</Link>
                    <span>{projectDetails?.mainAgent?.phone_number}</span>
                    <span>
                      {projectDetails?.mainAgent?.role === "agents"
                        ? "Agent"
                        : "Developer"}
                    </span>
                    <span>
                      {t("Municipality")}:{" "}
                      {MapCodeToLabel(
                        projectDetails?.mainAgent?.municipality
                      ) || "None"}
                    </span>
                  </SpaceBetween>
                }
              >
                <div
                  style={{
                    display: "flex",
                    cursor: "pointer",
                  }}
                >
                  <AccountCircleIcon style={{ marginRight: 5 }} />
                  <b style={{ color: primaryBlue }}>
                    {projectDetails?.mainAgent?.given_name +
                      " " +
                      projectDetails?.mainAgent?.family_name}
                  </b>
                </div>
              </Popover>
            ) : (
              <i style={{ color: "gray" }}>{t("Not yet assigned")}</i>
            )}
          </div>
        </ColumnLayout>
        <Divider />
        {t("Update additional collaborators for your project")}:
        <ColumnLayout columns={3}>
          <div>
            <h3>{t("Project Developer")}</h3>
            <Multiselect
              placeholder={t("Select developer(s)")}
              selectedAriaLabel="developers selected"
              disabled={userDetails?.role !== "developers"}
              selectedOptions={assignedDevelopers}
              onChange={({ detail }) =>
                setAssignedDevelopers(detail.selectedOptions)
              }
              options={assigneeOptions
                .filter((assignee) => assignee.role === "developers")
                .map((assignee) => ({
                  value: assignee?.id,
                  label: `${assignee?.given_name} ${assignee?.family_name}`,
                }))}
            />
          </div>
          <div>
            <h3>{t("Permitting Agent")}</h3>
            <Multiselect
              placeholder={t("Select agents(s)")}
              selectedAriaLabel="agents selected"
              disabled={userDetails?.role !== "agents"}
              selectedOptions={assignedAgents}
              onChange={({ detail }) =>
                setAssignedAgents(detail.selectedOptions)
              }
              options={assigneeOptions
                .filter(
                  (assignee) =>
                    assignee.role === "agents" &&
                    assignee.domain === userDetails.domain
                )
                .map((assignee) => ({
                  value: assignee?.id,
                  label: `${assignee?.given_name} ${assignee?.family_name}`,
                }))}
            />
          </div>
          <div>
            <h3>{t("SME Reviewer")}</h3>
            <Multiselect
              placeholder={t("Select SME(s)")}
              selectedAriaLabel="SMEs selected"
              selectedOptions={assignedSMEs}
              disabled={userDetails?.role !== "agents"}
              onChange={({ detail }) => setAssignedSMEs(detail.selectedOptions)}
              options={assigneeOptions
                .filter(
                  (assignee) =>
                    assignee.role === "agents" &&
                    assignee.domain === userDetails.domain
                )
                .map((assignee) => ({
                  value: assignee?.id,
                  label: `${assignee?.given_name} ${assignee?.family_name}`,
                }))}
            />
          </div>
        </ColumnLayout>
      </Modal>

      {/* ---------------- CITY COUNCIL OUTCOME Modal Popup ---------------- */}
      <Modal
        header={t("City Council Meeting Outcome")}
        onDismiss={() => showCityCouncilOutcomeModal(false)}
        visible={cityCouncilOutcomeModal}
        footer={
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                variant="link"
                onClick={() => showCityCouncilOutcomeModal(false)}
              >
                {t("Cancel")}
              </Button>
              <Button
                variant="primary"
                disabled={loadingCircle}
                onClick={async () => {
                  showCityCouncilOutcomeModal(false);
                  if (outcomeAnswer === "yes") showCloseStageConfirmModal(true);
                  else if (outcomeAnswer === "maybe") setProjectInRevision();
                  else showCloseProjectConfirmModal(true);
                }}
              >
                {t("Confirm")}
              </Button>
            </SpaceBetween>
          </Box>
        }
      >
        <p>
          {projectDetails?.stage === "e_approval"
            ? t(
                "Please provide the outcome of the City Council meeting for the last stage"
              )
            : t(
                "Please provide the outcome of the City Council meeting for this stage"
              )}
          :
        </p>
        <RadioGroup
          onChange={({ detail }) => setOutcomeAnswer(detail.value)}
          value={outcomeAnswer}
          items={[
            {
              value: "yes",
              label: t("Yes"),
              description:
                projectDetails?.stage === "e_approval"
                  ? t("You can close the last stage and close the project")
                  : t("You can close this stage and proceed to the next one"),
            },
            {
              value: "maybe",
              label: t("Maybe"),
              description: t(
                "Further adjustments required. You may need to stay in this stage, re-open and modify the documents accordingly"
              ),
            },
            {
              value: "no",
              label: t("No"),
              description: t(
                "The project needs to be stopped and closed at this stage"
              ),
            },
          ]}
        />
      </Modal>

      {/* ---------------- CLOSE STAGE CONFIRMATION Modal Popup ---------------- */}
      <Modal
        header={t("Are you sure?")}
        onDismiss={() => showCloseStageConfirmModal(false)}
        visible={closeStageConfirmModal}
        footer={
          <>
            <Box float="left">
              {loadingCircle && (
                <StatusIndicator type="loading">
                  {t("Saving changes")}...
                </StatusIndicator>
              )}
            </Box>
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="link"
                  onClick={() => showCloseStageConfirmModal(false)}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  variant="primary"
                  // disable button if
                  disabled={
                    loadingCircle ||
                    // summary is not verified
                    !projectDetails?.summaryVerified ||
                    // start date is not set
                    !projectDetails?.startDate ||
                    // city council meeting deadline has not been set or has not yet passed
                    new Date(
                      getLatestCityCouncilDeadlineInCloseStageCheck(
                        projectDetails
                      )
                    ).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0) ||
                    // there are still open mandatory documents and tasks
                    projectDetails?.documents?.items.filter(
                      (document) =>
                        document.mandatory &&
                        document.stage === projectDetails?.stage &&
                        document.status !== "d_done"
                    ).length ||
                    projectDetails?.tasks?.items.filter(
                      (task) =>
                        task.mandatory &&
                        task.stage === projectDetails?.stage &&
                        !task.done
                    ).length
                  }
                  onClick={async () => {
                    await closeStage();
                    await autoCloseOptionalDocuments();
                    await autoCloseOptionalTasks();
                    showCloseStageConfirmModal(false);
                  }}
                >
                  {t("Close Stage")}
                </Button>
              </SpaceBetween>
            </Box>
          </>
        }
      >
        <p>
          {t(
            "Before proceeding to the next stage, please ensure the following checklist is completed:"
          )}
        </p>
        <SpaceBetween size="m">
          {/* Summary verified */}
          <Box>
            <b>{t("Summary")}</b>
            <div style={{ marginTop: 5, display: "flex" }}>
              {!projectDetails?.summaryVerified ? (
                <>
                  <WarningIcon style={{ color: warningOrange }} />
                  <span style={{ color: "gray", marginLeft: 10 }}>
                    {t("Not yet verified")}
                  </span>
                </>
              ) : (
                <>
                  <CheckCircleIcon style={{ color: primaryGreen }} />
                  <span style={{ color: "gray", marginLeft: 10, marginTop: 3 }}>
                    {t("Verified")}
                  </span>
                </>
              )}
            </div>
          </Box>
          {/* Start date set */}
          <Box>
            <b>{t("Start Date")}</b>
            <div style={{ marginTop: 5, display: "flex" }}>
              {!projectDetails?.startDate ? (
                <>
                  <WarningIcon style={{ color: warningOrange }} />
                  <span style={{ color: "gray", marginLeft: 10 }}>
                    {t("Not yet set")}
                  </span>
                </>
              ) : (
                <>
                  <CheckCircleIcon style={{ color: primaryGreen }} />
                  <span style={{ color: "gray", marginLeft: 10, marginTop: 3 }}>
                    {t("Start date set")}
                  </span>
                </>
              )}
            </div>
          </Box>
          {/* Check if City council meeting deadline has passed */}
          <Box>
            <b>{t("City Council Meeting")}</b>
            <div style={{ marginTop: 5, display: "flex" }}>
              {projectDetails?.deadlines?.items &&
              projectDetails?.deadlines?.items?.length ? (
                // Check if City Council deadline has passed
                // (reference: https://dev.to/dailydevtips1/vanilla-javascript-check-if-date-is-in-the-past-1508)
                new Date(
                  getLatestCityCouncilDeadlineInCloseStageCheck(projectDetails)
                ).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0) ? (
                  <>
                    <WarningIcon style={{ color: warningOrange }} />
                    <span
                      style={{ color: "gray", marginLeft: 10, paddingRight: 5 }}
                    >
                      {t("Not passed yet")} {"("}
                      {t("Deadline")}:{" "}
                      {getLatestCityCouncilDeadlineInCloseStageCheck(
                        projectDetails
                      )}
                      {")"}
                    </span>
                  </>
                ) : (
                  <>
                    <CheckCircleIcon style={{ color: primaryGreen }} />
                    <span
                      style={{ color: "gray", marginLeft: 10, marginTop: 3 }}
                    >
                      {t("Has passed ")}
                      {"("}
                      {t("Deadline")}:{" "}
                      {getLatestCityCouncilDeadlineInCloseStageCheck(
                        projectDetails
                      )}
                      {")"}
                    </span>
                  </>
                )
              ) : (
                <>
                  <WarningIcon style={{ color: warningOrange }} />
                  <span
                    style={{ color: "gray", marginLeft: 10, paddingRight: 5 }}
                  >
                    {t("Deadline not set")}
                  </span>
                </>
              )}
            </div>
          </Box>
          {/* Mandatory documents and tasks */}
          <Box>
            <b>{t("Mandatory Documents & Tasks")}</b>
            <div style={{ marginTop: 5, display: "flex" }}>
              {projectDetails?.documents?.items.filter(
                (document) =>
                  document.mandatory &&
                  document.stage === projectDetails?.stage &&
                  document.status !== "d_done"
              ).length ? (
                <>
                  <WarningIcon style={{ color: warningOrange }} />
                  <span style={{ color: "gray", marginLeft: 10, marginTop: 3 }}>
                    {t("There are still open mandatory documents")}
                  </span>
                </>
              ) : (
                <>
                  <CheckCircleIcon style={{ color: primaryGreen }} />
                  <span style={{ color: "gray", marginLeft: 10, marginTop: 3 }}>
                    {t("All mandatory documents are uploaded and reviewed")}
                  </span>
                </>
              )}
            </div>
            <div style={{ marginTop: 3, display: "flex" }}>
              {projectDetails?.tasks?.items.filter(
                (task) =>
                  task.mandatory &&
                  task.stage === projectDetails?.stage &&
                  !task.done
              ).length ? (
                <>
                  <WarningIcon style={{ color: warningOrange }} />
                  <span style={{ color: "gray", marginLeft: 10, marginTop: 3 }}>
                    {t("There are still open mandatory tasks")}
                  </span>
                </>
              ) : (
                <>
                  <CheckCircleIcon style={{ color: primaryGreen }} />
                  <span style={{ color: "gray", marginLeft: 10, marginTop: 3 }}>
                    {t("All tasks have been completed")}
                  </span>
                </>
              )}
            </div>
          </Box>
          {/* Optional documents and tasks */}
          <Box>
            <b>{t("Optional Documents & Tasks")}</b>
            <div style={{ marginTop: 5, display: "flex" }}>
              {projectDetails?.documents?.items.filter(
                (document) =>
                  !document.mandatory &&
                  document.stage === projectDetails?.stage &&
                  document.status !== "d_done"
              ).length ? (
                <>
                  <InfoIcon style={{ color: primaryBlue }} />
                  <span style={{ color: "gray", marginLeft: 10, marginTop: 3 }}>
                    {t("There are still open optional documents")}
                  </span>
                </>
              ) : (
                <>
                  <CheckCircleIcon style={{ color: primaryGreen }} />
                  <span style={{ color: "gray", marginLeft: 10, marginTop: 3 }}>
                    {t("All optional documents are uploaded and reviewed")}
                  </span>
                </>
              )}
            </div>
            <div style={{ marginTop: 3, display: "flex" }}>
              {projectDetails?.tasks?.items.filter(
                (task) =>
                  !task.mandatory &&
                  task.stage === projectDetails?.stage &&
                  !task.done
              ).length ? (
                <>
                  <InfoIcon style={{ color: primaryBlue }} />
                  <span style={{ color: "gray", marginLeft: 10, marginTop: 3 }}>
                    {t("There are still open optional tasks")}
                  </span>
                </>
              ) : (
                <>
                  <CheckCircleIcon style={{ color: primaryGreen }} />
                  <span style={{ color: "gray", marginLeft: 10, marginTop: 3 }}>
                    {t("All optional tasks have been completed")}
                  </span>
                </>
              )}
            </div>
          </Box>

          {/* ============================ CLOSE A STAGE CHECKLIST =========================== */}
          <Box margin={{ top: "s" }}>
            {
              /* ---------------- If all mandatory items are not yet completed ---------------- */
              !projectDetails?.summaryVerified ||
              !projectDetails?.startDate ||
              new Date(
                getLatestCityCouncilDeadlineInCloseStageCheck(projectDetails)
              ).setHours(0, 0, 0, 0) > new Date().setHours(0, 0, 0, 0) ||
              // check if all mandatory documents and tasks are completed
              projectDetails?.documents?.items.filter(
                (document) =>
                  document.mandatory &&
                  document.stage === projectDetails?.stage &&
                  document.status !== "d_done"
              ).length ||
              projectDetails?.tasks?.items.filter(
                (task) =>
                  task.mandatory &&
                  task.stage === projectDetails?.stage &&
                  !task.done
              ).length ? (
                <Alert statusIconAriaLabel="Warning" type="error">
                  {t(
                    "All items in the checklist must be completed before you can close this stage"
                  )}
                </Alert>
              ) : /* -------- If all mandatory items are completed but optional items still open -------- */
              projectDetails?.documents?.items.filter(
                  (document) =>
                    !document.mandatory &&
                    document.stage === projectDetails?.stage &&
                    document.status !== "d_done"
                ).length !== 0 ||
                projectDetails?.tasks?.items.filter(
                  (task) =>
                    !task.mandatory &&
                    task.stage === projectDetails?.stage &&
                    !task.done
                ).length !== 0 ? (
                <SpaceBetween size="s">
                  <Alert statusIconAriaLabel="Warning" type="warning">
                    {t("You still have open optional documents and tasks")}
                  </Alert>
                  <Alert statusIconAriaLabel="Info">
                    {t(
                      "Once you close this stage, all optional documents and tasks will be automatically closed and all relevant information will be published to the Community page"
                    )}
                  </Alert>
                </SpaceBetween>
              ) : (
                /* -------- If all checklist items are completed including mandatory and optional -------- */
                <Alert statusIconAriaLabel="Info">
                  {t(
                    "Once you close this stage, all relevant information will be published to the Community page"
                  )}
                </Alert>
              )
            }
          </Box>
        </SpaceBetween>
      </Modal>

      {/* ---------------- CLOSE PROJECT CONFIRMATION Modal Popup ---------------- */}
      <Modal
        header={t("Are you sure?")}
        onDismiss={() => showCloseProjectConfirmModal(false)}
        visible={closeProjectConfirmModal}
        footer={
          <>
            <Box float="left">
              {loadingCircle && (
                <StatusIndicator type="loading">
                  {t("Saving changes")}...
                </StatusIndicator>
              )}
            </Box>
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="link"
                  onClick={() => showCloseProjectConfirmModal(false)}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  variant="primary"
                  disabled={loadingCircle}
                  onClick={async () => {
                    showLoadingCircle(true);
                    await closeProject(true);
                    showCloseProjectConfirmModal(false);
                    showLoadingCircle(false);
                  }}
                >
                  {t("Close Project")}
                </Button>
              </SpaceBetween>
            </Box>
          </>
        }
      >
        {t("Are you sure you want to close this project?")}
      </Modal>

      {/* ---------------- REPORT APPEAL Modal Popup ---------------- */}
      <Modal
        header={t("Are you sure?")}
        onDismiss={() => showReportAppealModal(false)}
        visible={reportAppealModal}
        footer={
          <>
            <Box float="left">
              {loadingCircle && (
                <StatusIndicator type="loading">
                  {t("Saving changes")}...
                </StatusIndicator>
              )}
            </Box>
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="link"
                  onClick={() => showReportAppealModal(false)}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  variant="primary"
                  disabled={loadingCircle}
                  onClick={async () => {
                    showLoadingCircle(true);
                    await reportAppeal();
                    showReportAppealModal(false);
                    showLoadingCircle(false);
                  }}
                >
                  {t("Report Appeal")}
                </Button>
              </SpaceBetween>
            </Box>
          </>
        }
      >
        {t("Are you sure you want to report appeal this project?")}
      </Modal>

      {/* ---------------- SHARE APPEAL OUTCOME Modal Popup ---------------- */}
      <Modal
        header={t("Share Appeal Outcome")}
        onDismiss={() => showShareAppealOutcomeModal(false)}
        visible={shareAppealOutcomeModal}
        footer={
          <>
            <Box float="left">
              {loadingCircle && (
                <StatusIndicator type="loading">
                  {t("Saving changes")}...
                </StatusIndicator>
              )}
            </Box>
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="link"
                  onClick={() => showShareAppealOutcomeModal(false)}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  variant="primary"
                  disabled={loadingCircle}
                  onClick={async () => {
                    showLoadingCircle(true);
                    await handleAppealOutcome(outcomeAnswer);
                    showShareAppealOutcomeModal(false);
                    showLoadingCircle(false);
                  }}
                >
                  {t("Submit")}
                </Button>
              </SpaceBetween>
            </Box>
          </>
        }
      >
        <RadioGroup
          onChange={({ detail }) => setOutcomeAnswer(detail.value)}
          value={outcomeAnswer}
          items={[
            {
              value: "appealOverruled",
              label: t("Appeal is overruled"),
              // description: t("Appeal is overruled"),
            },
            {
              value: "appealSustained",
              label: t("Appeal is sustained"),
              description: t("This will also cancel the project"),
            },
          ]}
        />
      </Modal>

      {/* ---------------- SKIP 2ND STAGE Modal Popup ---------------- */}
      <Modal
        header={t("Are you sure?")}
        onDismiss={() => showSkipStageModal(false)}
        visible={skipStageModal}
        footer={
          <>
            <Box float="left">
              {loadingCircle && (
                <StatusIndicator type="loading">
                  {t("Saving changes")}...
                </StatusIndicator>
              )}
            </Box>
            <Box float="right">
              <SpaceBetween direction="horizontal" size="xs">
                <Button
                  variant="link"
                  onClick={() => showSkipStageModal(false)}
                >
                  {t("Cancel")}
                </Button>
                <Button
                  variant="primary"
                  disabled={loadingCircle}
                  onClick={async () => {
                    showLoadingCircle(true);
                    await closeStage();
                    await autoCloseOptionalDocuments();
                    await autoCloseOptionalTasks();
                    showSkipStageModal(false);
                    showLoadingCircle(false);
                  }}
                >
                  {t("Confirm")}
                </Button>
              </SpaceBetween>
            </Box>
          </>
        }
      >
        {t("Are you sure you want to skip this stage?")}
      </Modal>
    </Container>
  );
}

// Linear progress used in Edit Milestone Deadlines modal popup
const EditDeadlinesPopupLinearProgress = ({
  t,
  projectDetails,
  prevStage,
  prevDeadlineEnum,
  currentStage,
  currentDeadlineEnum,
  deadlineName,
  deadlineDate,
}) => {
  return (
    <SpaceBetween size="s">
      <b>{t(deadlineName)}</b>
      {deadlineDate ||
        getDeadlineDate(projectDetails, currentStage, currentDeadlineEnum) ||
        ""}
      <LinearProgress
        sx={{
          height: 10,
          width: "100%",
          borderRadius: 0,
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: lightGray,
            borderRight: "1px solid",
          },
          // progress colour
          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 0,
            backgroundColor: getProgressColor(
              projectDetails,
              currentStage,
              currentDeadlineEnum
            ),
          },
        }}
        variant="determinate"
        value={
          getDeadlineAsNumber(
            projectDetails,
            prevStage,
            prevDeadlineEnum,
            currentStage,
            currentDeadlineEnum
          ) || 0
        }
      />
    </SpaceBetween>
  );
};

// Linear progress used in Project Overview box
const ProjectOverviewLinearProgress = ({
  t,
  projectDetails,
  prevStage,
  prevDeadlineEnum,
  currentStage,
  currentDeadlineEnum,
  deadlineName,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  return (
    <>
      <LinearProgress
        variant="determinate"
        sx={{
          height: 10,
          width: "100%",
          borderRadius: 0,
          [`&.${linearProgressClasses.colorPrimary}`]: {
            backgroundColor: lightGray,
            borderRight: "1px solid",
          },
          // progress colour
          [`& .${linearProgressClasses.bar}`]: {
            borderRadius: 0,
            backgroundColor: getProgressColor(
              projectDetails,
              currentStage,
              currentDeadlineEnum
            ),
          },
        }}
        value={
          getDeadlineAsNumber(
            projectDetails,
            prevStage,
            prevDeadlineEnum,
            currentStage,
            currentDeadlineEnum
          ) || 0
        }
        onMouseEnter={(event) => setAnchorEl(event.currentTarget)}
        onMouseLeave={() => setAnchorEl(null)}
      />
      <MuiPopover
        id="milestone-deadline-popover"
        disableRestoreFocus
        sx={{
          pointerEvents: "none",
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        onClose={() => setAnchorEl(null)}
      >
        <Typography sx={{ p: 1 }}>
          <b>{t(deadlineName)}</b>
          <br />
          <span>
            {getDeadlineDate(
              projectDetails,
              currentStage,
              currentDeadlineEnum
            ) || ""}
          </span>
        </Typography>
      </MuiPopover>
    </>
  );
};

const ProjectTimelineDynamic = ({
  t,
  projectDetails,
  setViewingStage,
  viewingStage,
}) => {
  return (
    <>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        {/* -------------------- PRE-PLANNING --------------------*/}
        <Box
          onClick={() => setViewingStage(stageName1)}
          style={{
            opacity: projectDetails?.stage === stageName1 ? 1 : 0.5,
          }}
        >
          {viewingStage === stageName1 &&
            projectDetails?.stage !== viewingStage && (
              <div style={{ color: "gray" }}>
                <Icon name="caret-down-filled" />{" "}
                <b>{t("Currently viewing")}</b>
              </div>
            )}
          {projectDetails?.stage === stageName1 ? (
            <div style={{ color: primaryGreen }}>
              <Icon name="caret-down-filled" />{" "}
              <b>
                {t(
                  projectDetails?.projectClosed ? "Last stage" : "Current stage"
                )}
              </b>
            </div>
          ) : (
            viewingStage !== stageName1 && <div style={{ height: 20 }} />
          )}
          <h3>{t("Pre-planning")}</h3>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ProjectOverviewLinearProgress
              t={t}
              projectDetails={projectDetails}
              prevStage={null}
              prevDeadlineEnum={null}
              currentStage={stageName1} // "a_preplanning"
              currentDeadlineEnum={deadlineEnum1} // "documents_handover"
              deadlineName="Documents Review"
            />
            <ProjectOverviewLinearProgress
              t={t}
              projectDetails={projectDetails}
              prevStage={stageName1} // "a_preplanning"
              prevDeadlineEnum={deadlineEnum1} // "documents_handover"
              currentStage={stageName1} // "a_preplanning"
              currentDeadlineEnum={deadlineEnum3} //  "city_council"
              deadlineName="City Council Meeting"
            />
          </div>
        </Box>
        {/* -------------------- INITIAL PUBLIC CONSULTATION --------------------*/}
        <Box
          onClick={() => setViewingStage(stageName2)}
          style={{
            opacity: projectDetails?.stage === stageName2 ? 1 : 0.5,
          }}
        >
          {viewingStage === stageName2 &&
            projectDetails?.stage !== viewingStage && (
              <div style={{ color: "gray" }}>
                <Icon name="caret-down-filled" />{" "}
                <b>{t("Currently viewing")}</b>
              </div>
            )}
          {projectDetails?.stage === stageName2 ? (
            <div style={{ color: primaryGreen }}>
              <Icon name="caret-down-filled" />{" "}
              <b>
                {t(
                  projectDetails?.projectClosed ? "Last stage" : "Current stage"
                )}
              </b>
            </div>
          ) : (
            viewingStage !== stageName2 && <div style={{ height: 20 }} />
          )}
          <h3>{t("Initial Public Consultation")}</h3>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ProjectOverviewLinearProgress
              t={t}
              projectDetails={projectDetails}
              prevStage={stageName1} // "a_preplanning"
              prevDeadlineEnum={deadlineEnum3} // "city_council"
              currentStage={stageName2} // "b_initial_consultation"
              currentDeadlineEnum={deadlineEnum1} //  "documents_handover"
              deadlineName="Documents Review"
            />
            <ProjectOverviewLinearProgress
              t={t}
              projectDetails={projectDetails}
              prevStage={stageName2} // "b_initial_consultation"
              prevDeadlineEnum={deadlineEnum1} // "documents_handover"
              currentStage={stageName2} // "b_initial_consultation"
              currentDeadlineEnum={deadlineEnum2} //  "public_consultation"
              deadlineName="Public Consultation"
            />
            <ProjectOverviewLinearProgress
              t={t}
              projectDetails={projectDetails}
              prevStage={stageName2} // "b_initial_consultation"
              prevDeadlineEnum={deadlineEnum2} // "public_consultation"
              currentStage={stageName2} // "b_initial_consultation"
              currentDeadlineEnum={deadlineEnum3} //  "city_council"
              deadlineName="City Council Meeting"
            />
          </div>
        </Box>
        {/* -------------------- PLANNING PROCESS --------------------*/}
        <Box
          onClick={() => setViewingStage(stageName3)}
          style={{ opacity: projectDetails?.stage === stageName3 ? 1 : 0.5 }}
        >
          {viewingStage === stageName3 &&
            projectDetails?.stage !== viewingStage && (
              <div style={{ color: "gray" }}>
                <Icon name="caret-down-filled" />{" "}
                <b>{t("Currently viewing")}</b>
              </div>
            )}
          {projectDetails?.stage === stageName3 ? (
            <div style={{ color: primaryGreen }}>
              <Icon name="caret-down-filled" />{" "}
              <b>
                {t(
                  projectDetails?.projectClosed ? "Last stage" : "Current stage"
                )}
              </b>
            </div>
          ) : (
            viewingStage !== stageName3 && <div style={{ height: 20 }} />
          )}
          <h3>{t("Planning Process")}</h3>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ProjectOverviewLinearProgress
              t={t}
              projectDetails={projectDetails}
              prevStage={stageName1} // "a_preplanning"
              prevDeadlineEnum={deadlineEnum3} // "city_council"
              currentStage={stageName3} // "c_planning"
              currentDeadlineEnum={deadlineEnum1} //  "documents_handover"
              deadlineName="Documents Review"
            />
            <ProjectOverviewLinearProgress
              t={t}
              projectDetails={projectDetails}
              prevStage={stageName3} // "c_planning"
              prevDeadlineEnum={deadlineEnum1} //  "documents_handover"
              currentStage={stageName3} // "c_planning"
              currentDeadlineEnum={deadlineEnum3} // "city_council"
              deadlineName="City Council Meeting"
            />
          </div>
        </Box>
        {/* -------------------- FINAL CONSULTATION --------------------*/}
        <Box
          onClick={() => setViewingStage(stageName4)}
          style={{
            opacity: projectDetails?.stage === stageName4 ? 1 : 0.5,
          }}
        >
          {viewingStage === stageName4 &&
            projectDetails?.stage !== viewingStage && (
              <div style={{ color: "gray" }}>
                <Icon name="caret-down-filled" />{" "}
                <b>{t("Currently viewing")}</b>
              </div>
            )}
          {projectDetails?.stage === stageName4 ? (
            <div style={{ color: primaryGreen }}>
              <Icon name="caret-down-filled" />{" "}
              <b>
                {t(
                  projectDetails?.projectClosed ? "Last stage" : "Current stage"
                )}
              </b>
            </div>
          ) : (
            viewingStage !== stageName4 && <div style={{ height: 20 }} />
          )}
          <h3>{t("Final Public Consultation")}</h3>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ProjectOverviewLinearProgress
              t={t}
              projectDetails={projectDetails}
              prevStage={stageName3} // "c_planning"
              prevDeadlineEnum={deadlineEnum3} // "city_council"
              currentStage={stageName4} // "d_final_consultation"
              currentDeadlineEnum={deadlineEnum1} //  "documents_handover"
              deadlineName="Documents Review"
            />
            <ProjectOverviewLinearProgress
              t={t}
              projectDetails={projectDetails}
              prevStage={stageName4} // "d_final_consultation"
              prevDeadlineEnum={deadlineEnum1} //  "documents_handover"
              currentStage={stageName4} // "d_final_consultation"
              currentDeadlineEnum={deadlineEnum2} //   "public_consultation"
              deadlineName="Public Consultation"
            />
            <ProjectOverviewLinearProgress
              t={t}
              projectDetails={projectDetails}
              prevStage={stageName4} // "d_final_consultation"
              prevDeadlineEnum={deadlineEnum2} //   "public_consultation"
              currentStage={stageName4} // "d_final_consultation"
              currentDeadlineEnum={deadlineEnum3} // "city_council"
              deadlineName="City Council Meeting"
            />
          </div>
        </Box>
        {/* -------------------- APPROVAL -------------------- */}
        <Box
          onClick={() => setViewingStage(stageName5)}
          style={{ opacity: projectDetails?.stage === stageName5 ? 1 : 0.5 }}
        >
          {viewingStage === stageName5 &&
            projectDetails?.stage !== viewingStage && (
              <div style={{ color: "gray" }}>
                <Icon name="caret-down-filled" />{" "}
                <b>{t("Currently viewing")}</b>
              </div>
            )}
          {projectDetails?.stage === stageName5 ? (
            <div style={{ color: primaryGreen }}>
              <Icon name="caret-down-filled" />{" "}
              <b>
                {t(
                  projectDetails?.projectClosed ? "Last stage" : "Current stage"
                )}
              </b>
            </div>
          ) : (
            viewingStage !== stageName5 && <div style={{ height: 20 }} />
          )}
          <h3>{t("Final Approval")}</h3>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <ProjectOverviewLinearProgress
              t={t}
              projectDetails={projectDetails}
              prevStage={stageName4} // "d_final_consultation"
              prevDeadlineEnum={deadlineEnum3} // "city_council"
              currentStage={stageName5} // "e_approval"
              currentDeadlineEnum={deadlineEnum1} //  "documents_handover"
              deadlineName="Documents Review"
            />
            <ProjectOverviewLinearProgress
              t={t}
              projectDetails={projectDetails}
              prevStage={stageName5} // "e_approval"
              prevDeadlineEnum={deadlineEnum1} //  "documents_handover"
              currentStage={stageName5} // "e_approval"
              currentDeadlineEnum={deadlineEnum3} // "city_council"
              deadlineName="Appeal"
            />
          </div>
        </Box>
      </div>
    </>
  );
};

// Old project timeline - Not dynamic
// const ProjectTimeline = ({
//   t,
//   projectDetails,
//   setViewingStage,
//   viewingStage,
// }) => {
//   // Pre-planning: Documents review
//   const [pp_Documents_anchorEl, set_pp_Documents_AnchorEl] = useState(null);
//   const open_pp_Documents_Popover = Boolean(pp_Documents_anchorEl);
//   // Pre-planning: CC meeting
//   const [pp_CityCouncil_anchorEl, set_pp_CityCouncil_AnchorEl] = useState(null);
//   const open_pp_CityCouncil_Popover = Boolean(pp_CityCouncil_anchorEl);

//   // Initial Public Consultation: Documents review
//   const [ipc_Documents_anchorEl, set_ipc_Documents_AnchorEl] = useState(null);
//   const open_ipc_Documents_Popover = Boolean(ipc_Documents_anchorEl);
//   // Initial Public Consultation: Public consultation
//   const [ipc_Consultation_anchorEl, set_ipc_Consultation_AnchorEl] =
//     useState(null);
//   const open_ipc_Consultation_Popover = Boolean(ipc_Consultation_anchorEl);
//   // Initial Public Consultation: CC meeting
//   const [ipc_CityCouncil_anchorEl, set_ipc_CityCouncil_AnchorEl] =
//     useState(null);
//   const open_ipc_CityCouncil_Popover = Boolean(ipc_CityCouncil_anchorEl);

//   // Planning Process: Documents review
//   const [process_Documents_anchorEl, set_process_Documents_AnchorEl] =
//     useState(null);
//   const open_process_Documents_Popover = Boolean(process_Documents_anchorEl);
//   // Planning Process: CC meeting
//   const [process_CityCouncil_anchorEl, set_process_CityCouncil_AnchorEl] =
//     useState(null);
//   const open_process_CityCouncil_Popover = Boolean(
//     process_CityCouncil_anchorEl
//   );

//   // Public Consultation: Documents review
//   const [pc_Documents_anchorEl, set_pc_Documents_AnchorEl] = useState(null);
//   const open_pc_Documents_Popover = Boolean(pc_Documents_anchorEl);
//   // Public Consultation: Public consultation
//   const [pc_Consultation_anchorEl, set_pc_Consultation_AnchorEl] =
//     useState(null);
//   const open_pc_Consultation_Popover = Boolean(pc_Consultation_anchorEl);
//   // Public Consultation: CC meeting
//   const [pc_CityCouncil_anchorEl, set_pc_CityCouncil_AnchorEl] = useState(null);
//   const open_pc_CityCouncil_Popover = Boolean(pc_CityCouncil_anchorEl);

//   // Final Approval: Documents review
//   const [fa_Documents_anchorEl, set_fa_Documents_AnchorEl] = useState(null);
//   const open_fa_Documents_Popover = Boolean(fa_Documents_anchorEl);
//   // Final Approval: CC meeting / Appeal
//   const [fa_CityCouncil_anchorEl, set_fa_CityCouncil_AnchorEl] = useState(null);
//   const open_fa_CityCouncil_Popover = Boolean(fa_CityCouncil_anchorEl);

//   return (
//     <>
//       <div style={{ display: "flex", justifyContent: "space-between" }}>
//         {/* -------------------- PRE-PLANNING --------------------*/}
//         <div
//           onClick={() => setViewingStage("a_preplanning")}
//           style={{
//             opacity: projectDetails?.stage === "a_preplanning" ? 1 : 0.5,
//           }}
//         >
//           {viewingStage === "a_preplanning" &&
//             projectDetails?.stage !== viewingStage && (
//               <div style={{ color: "gray" }}>
//                 <Icon name="caret-down-filled" />{" "}
//                 <b>{t("Currently viewing")}</b>
//               </div>
//             )}
//           {projectDetails?.stage === "a_preplanning" ? (
//             <div style={{ color: primaryGreen }}>
//               <Icon name="caret-down-filled" />{" "}
//               <b>
//                 {t(
//                   projectDetails?.projectClosed ? "Last stage" : "Current stage"
//                 )}
//               </b>
//             </div>
//           ) : (
//             "a_preplanning" !== viewingStage && <div style={{ height: 20 }} />
//           )}
//           <h3>{t("Pre-planning")}</h3>
//           <div style={{ display: "flex", justifyContent: "space-between" }}>
//             {/* Documents review */}
//             <LinearProgress
//               sx={{
//                 height: 10,
//                 width: "100%",
//                 borderRadius: 0,
//                 [`&.${linearProgressClasses.colorPrimary}`]: {
//                   backgroundColor: lightGray,
//                   borderRight: "1px solid",
//                 },
//                 // progress colour
//                 [`& .${linearProgressClasses.bar}`]: {
//                   borderRadius: 0,
//                   backgroundColor: getProgressColor(
//                     projectDetails,
//                     "a_preplanning",
//                     "documents_handover"
//                   ),
//                 },
//               }}
//               variant="determinate"
//               value={
//                 getDeadlineAsNumber(
//                   projectDetails,
//                   null,
//                   null,
//                   "a_preplanning",
//                   "documents_handover"
//                 ) || 0
//               }
//               onMouseEnter={(event) =>
//                 set_pp_Documents_AnchorEl(event.currentTarget)
//               }
//               onMouseLeave={() => set_pp_Documents_AnchorEl(null)}
//             />
//             <MuiPopover
//               id="pre-planning-documents-review-popover"
//               disableRestoreFocus
//               sx={{
//                 pointerEvents: "none",
//               }}
//               open={open_pp_Documents_Popover}
//               anchorEl={pp_Documents_anchorEl}
//               anchorOrigin={{
//                 vertical: "bottom",
//                 horizontal: "left",
//               }}
//               onClose={() => set_pp_Documents_AnchorEl(null)}
//             >
//               <Typography sx={{ p: 1 }}>
//                 <b>{t("Documents Review")}</b>
//                 <br />
//                 <span>
//                   {getDeadlineDate(
//                     projectDetails,
//                     "a_preplanning",
//                     "documents_handover"
//                   ) || ""}
//                 </span>
//               </Typography>
//             </MuiPopover>

//             {/* City council meeting */}
//             <LinearProgress
//               sx={{
//                 height: 10,
//                 width: "100%",
//                 borderRadius: 0,
//                 [`&.${linearProgressClasses.colorPrimary}`]: {
//                   backgroundColor: lightGray,
//                   borderRight: "1px solid",
//                 },
//                 // progress colour
//                 [`& .${linearProgressClasses.bar}`]: {
//                   borderRadius: 0,
//                   backgroundColor: getProgressColor(
//                     projectDetails,
//                     "a_preplanning",
//                     "city_council"
//                   ),
//                 },
//               }}
//               variant="determinate"
//               value={
//                 getDeadlineAsNumber(
//                   projectDetails,
//                   "a_preplanning",
//                   "documents_handover",
//                   "a_preplanning",
//                   "city_council"
//                 ) || 0
//               }
//               onMouseEnter={(event) =>
//                 set_pp_CityCouncil_AnchorEl(event.currentTarget)
//               }
//               onMouseLeave={() => set_pp_CityCouncil_AnchorEl(null)}
//             />
//             <MuiPopover
//               id="pre-planning-documents-review-popover"
//               disableRestoreFocus
//               sx={{
//                 pointerEvents: "none",
//               }}
//               open={open_pp_CityCouncil_Popover}
//               anchorEl={pp_CityCouncil_anchorEl}
//               anchorOrigin={{
//                 vertical: "bottom",
//                 horizontal: "left",
//               }}
//               onClose={() => set_pp_CityCouncil_AnchorEl(null)}
//             >
//               <Typography sx={{ p: 1 }}>
//                 <b>{t("City Council Meeting")}</b>
//                 <br />
//                 <span>
//                   {getDeadlineDate(
//                     projectDetails,
//                     "a_preplanning",
//                     "city_council"
//                   ) || ""}
//                 </span>
//               </Typography>
//             </MuiPopover>
//           </div>
//         </div>
//         {/* -------------------- INITIAL PUBLIC CONSULTATION --------------------*/}
//         <div
//           onClick={() => setViewingStage("b_initial_consultation")}
//           style={{
//             opacity:
//               projectDetails?.stage === "b_initial_consultation" ? 1 : 0.5,
//           }}
//         >
//           {viewingStage === "b_initial_consultation" &&
//             projectDetails?.stage !== viewingStage && (
//               <div style={{ color: "gray" }}>
//                 <Icon name="caret-down-filled" />{" "}
//                 <b>{t("Currently viewing")}</b>
//               </div>
//             )}
//           {projectDetails?.stage === "b_initial_consultation" ? (
//             <div style={{ color: primaryGreen }}>
//               <Icon name="caret-down-filled" />{" "}
//               <b>
//                 {t(
//                   projectDetails?.projectClosed ? "Last stage" : "Current stage"
//                 )}
//               </b>
//             </div>
//           ) : (
//             "b_initial_consultation" !== viewingStage && (
//               <div style={{ height: 20 }} />
//             )
//           )}
//           <h3>{t("Initial Public Consultation")}</h3>
//           <div style={{ display: "flex", justifyContent: "space-between" }}>
//             {/* Documents review */}
//             <LinearProgress
//               sx={{
//                 height: 10,
//                 width: "100%",
//                 borderRadius: 0,
//                 [`&.${linearProgressClasses.colorPrimary}`]: {
//                   backgroundColor: lightGray,
//                   borderRight: "1px solid",
//                 },
//                 // progress colour
//                 [`& .${linearProgressClasses.bar}`]: {
//                   borderRadius: 0,
//                   backgroundColor: getProgressColor(
//                     projectDetails,
//                     "b_initial_consultation",
//                     "documents_handover"
//                   ),
//                 },
//               }}
//               variant="determinate"
//               value={
//                 getDeadlineAsNumber(
//                   projectDetails,
//                   "a_preplanning",
//                   "city_council",
//                   "b_initial_consultation",
//                   "documents_handover"
//                 ) || 0
//               }
//               onMouseEnter={(event) =>
//                 set_ipc_Documents_AnchorEl(event.currentTarget)
//               }
//               onMouseLeave={() => set_ipc_Documents_AnchorEl(null)}
//             />
//             <MuiPopover
//               id="pre-planning-documents-review-popover"
//               disableRestoreFocus
//               sx={{
//                 pointerEvents: "none",
//               }}
//               open={open_ipc_Documents_Popover}
//               anchorEl={ipc_Documents_anchorEl}
//               anchorOrigin={{
//                 vertical: "bottom",
//                 horizontal: "left",
//               }}
//               onClose={() => set_ipc_Documents_AnchorEl(null)}
//             >
//               <Typography sx={{ p: 1 }}>
//                 <b>{t("Documents Review")}</b>
//                 <br />
//                 <span>
//                   {getDeadlineDate(
//                     projectDetails,
//                     "b_initial_consultation",
//                     "documents_handover"
//                   ) || ""}
//                 </span>
//               </Typography>
//             </MuiPopover>

//             {/* Public consultation */}
//             <LinearProgress
//               sx={{
//                 height: 10,
//                 width: "100%",
//                 borderRadius: 0,
//                 [`&.${linearProgressClasses.colorPrimary}`]: {
//                   backgroundColor: lightGray,
//                   borderRight: "1px solid",
//                 },
//                 // progress colour
//                 [`& .${linearProgressClasses.bar}`]: {
//                   borderRadius: 0,
//                   backgroundColor: getProgressColor(
//                     projectDetails,
//                     "b_initial_consultation",
//                     "public_consultation"
//                   ),
//                 },
//               }}
//               variant="determinate"
//               value={
//                 getDeadlineAsNumber(
//                   projectDetails,
//                   "b_initial_consultation",
//                   "documents_handover",
//                   "b_initial_consultation",
//                   "public_consultation"
//                 ) || 0
//               }
//               onMouseEnter={(event) =>
//                 set_ipc_Consultation_AnchorEl(event.currentTarget)
//               }
//               onMouseLeave={() => set_ipc_Consultation_AnchorEl(null)}
//             />
//             <MuiPopover
//               id="pre-planning-documents-review-popover"
//               disableRestoreFocus
//               sx={{
//                 pointerEvents: "none",
//               }}
//               open={open_ipc_Consultation_Popover}
//               anchorEl={ipc_Consultation_anchorEl}
//               anchorOrigin={{
//                 vertical: "bottom",
//                 horizontal: "left",
//               }}
//               onClose={() => set_ipc_Consultation_AnchorEl(null)}
//             >
//               <Typography sx={{ p: 1 }}>
//                 <b>{t("Public Consultation")}</b>
//                 <br />
//                 <span>
//                   {getDeadlineDate(
//                     projectDetails,
//                     "b_initial_consultation",
//                     "public_consultation"
//                   ) || ""}
//                 </span>
//               </Typography>
//             </MuiPopover>

//             {/* City council meeting */}
//             <LinearProgress
//               sx={{
//                 height: 10,
//                 width: "100%",
//                 borderRadius: 0,
//                 [`&.${linearProgressClasses.colorPrimary}`]: {
//                   backgroundColor: lightGray,
//                   borderRight: "1px solid",
//                 },
//                 // progress colour
//                 [`& .${linearProgressClasses.bar}`]: {
//                   borderRadius: 0,
//                   backgroundColor: getProgressColor(
//                     projectDetails,
//                     "b_initial_consultation",
//                     "city_council"
//                   ),
//                 },
//               }}
//               variant="determinate"
//               value={
//                 getDeadlineAsNumber(
//                   projectDetails,
//                   "b_initial_consultation",
//                   "public_consultation",
//                   "b_initial_consultation",
//                   "city_council"
//                 ) || 0
//               }
//               onMouseEnter={(event) =>
//                 set_ipc_CityCouncil_AnchorEl(event.currentTarget)
//               }
//               onMouseLeave={() => set_ipc_CityCouncil_AnchorEl(null)}
//             />
//             <MuiPopover
//               id="pre-planning-documents-review-popover"
//               disableRestoreFocus
//               sx={{
//                 pointerEvents: "none",
//               }}
//               open={open_ipc_CityCouncil_Popover}
//               anchorEl={ipc_CityCouncil_anchorEl}
//               anchorOrigin={{
//                 vertical: "bottom",
//                 horizontal: "left",
//               }}
//               onClose={() => set_ipc_CityCouncil_AnchorEl(null)}
//             >
//               <Typography sx={{ p: 1 }}>
//                 <b>{t("City Council Meeting")}</b>
//                 <br />
//                 <span>
//                   {getDeadlineDate(
//                     projectDetails,
//                     "b_initial_consultation",
//                     "city_council"
//                   ) || ""}
//                 </span>
//               </Typography>
//             </MuiPopover>
//           </div>
//         </div>
//         {/* -------------------- PLANNING PROCESS --------------------*/}
//         <div
//           onClick={() => setViewingStage("c_planning")}
//           style={{ opacity: projectDetails?.stage === "c_planning" ? 1 : 0.5 }}
//         >
//           {viewingStage === "c_planning" &&
//             projectDetails?.stage !== viewingStage && (
//               <div style={{ color: "gray" }}>
//                 <Icon name="caret-down-filled" />{" "}
//                 <b>{t("Currently viewing")}</b>
//               </div>
//             )}
//           {projectDetails?.stage === "c_planning" ? (
//             <div style={{ color: primaryGreen }}>
//               <Icon name="caret-down-filled" />{" "}
//               <b>
//                 {t(
//                   projectDetails?.projectClosed ? "Last stage" : "Current stage"
//                 )}
//               </b>
//             </div>
//           ) : (
//             "c_planning" !== viewingStage && <div style={{ height: 20 }} />
//           )}
//           <h3>{t("Planning Process")}</h3>
//           <div style={{ display: "flex", justifyContent: "space-between" }}>
//             {/* Documents review */}
//             <LinearProgress
//               sx={{
//                 height: 10,
//                 width: "100%",
//                 borderRadius: 0,
//                 [`&.${linearProgressClasses.colorPrimary}`]: {
//                   backgroundColor: lightGray,
//                   borderRight: "1px solid",
//                 },
//                 // progress colour
//                 [`& .${linearProgressClasses.bar}`]: {
//                   borderRadius: 0,
//                   backgroundColor: getProgressColor(
//                     projectDetails,
//                     "c_planning",
//                     "documents_handover"
//                   ),
//                 },
//               }}
//               variant="determinate"
//               value={
//                 getDeadlineAsNumber(
//                   projectDetails,
//                   "b_initial_consultation",
//                   "city_council",
//                   "c_planning",
//                   "documents_handover"
//                 ) || 0
//               }
//               onMouseEnter={(event) =>
//                 set_process_Documents_AnchorEl(event.currentTarget)
//               }
//               onMouseLeave={() => set_process_Documents_AnchorEl(null)}
//             />
//             <MuiPopover
//               id="pre-planning-documents-review-popover"
//               disableRestoreFocus
//               sx={{
//                 pointerEvents: "none",
//               }}
//               open={open_process_Documents_Popover}
//               anchorEl={process_Documents_anchorEl}
//               anchorOrigin={{
//                 vertical: "bottom",
//                 horizontal: "left",
//               }}
//               onClose={() => set_process_Documents_AnchorEl(null)}
//             >
//               <Typography sx={{ p: 1 }}>
//                 <b>{t("Documents Review")}</b>
//                 <br />
//                 <span>
//                   {getDeadlineDate(
//                     projectDetails,
//                     "c_planning",
//                     "documents_handover"
//                   ) || ""}
//                 </span>
//               </Typography>
//             </MuiPopover>

//             {/* City council meeting */}
//             <LinearProgress
//               sx={{
//                 height: 10,
//                 width: "100%",
//                 borderRadius: 0,
//                 [`&.${linearProgressClasses.colorPrimary}`]: {
//                   backgroundColor: lightGray,
//                   borderRight: "1px solid",
//                 },
//                 // progress colour
//                 [`& .${linearProgressClasses.bar}`]: {
//                   borderRadius: 0,
//                   backgroundColor: getProgressColor(
//                     projectDetails,
//                     "c_planning",
//                     "city_council"
//                   ),
//                 },
//               }}
//               variant="determinate"
//               value={
//                 getDeadlineAsNumber(
//                   projectDetails,
//                   "c_planning",
//                   "documents_handover",
//                   "c_planning",
//                   "city_council"
//                 ) || 0
//               }
//               onMouseEnter={(event) =>
//                 set_process_CityCouncil_AnchorEl(event.currentTarget)
//               }
//               onMouseLeave={() => set_process_CityCouncil_AnchorEl(null)}
//             />
//             <MuiPopover
//               id="pre-planning-documents-review-popover"
//               disableRestoreFocus
//               sx={{
//                 pointerEvents: "none",
//               }}
//               open={open_process_CityCouncil_Popover}
//               anchorEl={process_CityCouncil_anchorEl}
//               anchorOrigin={{
//                 vertical: "bottom",
//                 horizontal: "left",
//               }}
//               onClose={() => set_process_CityCouncil_AnchorEl(null)}
//             >
//               <Typography sx={{ p: 1 }}>
//                 <b>{t("City Council Meeting")}</b>
//                 <br />
//                 <span>
//                   {getDeadlineDate(
//                     projectDetails,
//                     "c_planning",
//                     "city_council"
//                   ) || ""}
//                 </span>
//               </Typography>
//             </MuiPopover>
//           </div>
//         </div>
//         {/* -------------------- FINAL CONSULTATION --------------------*/}
//         <div
//           onClick={() => setViewingStage("d_final_consultation")}
//           style={{
//             opacity: projectDetails?.stage === "d_final_consultation" ? 1 : 0.5,
//           }}
//         >
//           {viewingStage === "d_final_consultation" &&
//             projectDetails?.stage !== viewingStage && (
//               <div style={{ color: "gray" }}>
//                 <Icon name="caret-down-filled" />{" "}
//                 <b>{t("Currently viewing")}</b>
//               </div>
//             )}
//           {projectDetails?.stage === "d_final_consultation" ? (
//             <div style={{ color: primaryGreen }}>
//               <Icon name="caret-down-filled" />{" "}
//               <b>
//                 {t(
//                   projectDetails?.projectClosed ? "Last stage" : "Current stage"
//                 )}
//               </b>
//             </div>
//           ) : (
//             "d_final_consultation" !== viewingStage && (
//               <div style={{ height: 20 }} />
//             )
//           )}
//           <h3>{t("Final Public Consultation")}</h3>
//           <div style={{ display: "flex", justifyContent: "space-between" }}>
//             {/* Documents review */}
//             <LinearProgress
//               sx={{
//                 height: 10,
//                 width: "100%",
//                 borderRadius: 0,
//                 [`&.${linearProgressClasses.colorPrimary}`]: {
//                   backgroundColor: lightGray,
//                   borderRight: "1px solid",
//                 },
//                 // progress colour
//                 [`& .${linearProgressClasses.bar}`]: {
//                   borderRadius: 0,
//                   backgroundColor: getProgressColor(
//                     projectDetails,
//                     "d_final_consultation",
//                     "documents_handover"
//                   ),
//                 },
//               }}
//               variant="determinate"
//               value={
//                 getDeadlineAsNumber(
//                   projectDetails,
//                   "c_planning",
//                   "city_council",
//                   "d_final_consultation",
//                   "documents_handover"
//                 ) || 0
//               }
//               onMouseEnter={(event) =>
//                 set_pc_Documents_AnchorEl(event.currentTarget)
//               }
//               onMouseLeave={() => set_pc_Documents_AnchorEl(null)}
//             />
//             <MuiPopover
//               id="pre-planning-documents-review-popover"
//               disableRestoreFocus
//               sx={{
//                 pointerEvents: "none",
//               }}
//               open={open_pc_Documents_Popover}
//               anchorEl={pc_Documents_anchorEl}
//               anchorOrigin={{
//                 vertical: "bottom",
//                 horizontal: "left",
//               }}
//               onClose={() => set_pc_Documents_AnchorEl(null)}
//             >
//               <Typography sx={{ p: 1 }}>
//                 <b>{t("Documents Review")}</b>
//                 <br />
//                 <span>
//                   {getDeadlineDate(
//                     projectDetails,
//                     "d_final_consultation",
//                     "documents_handover"
//                   )}
//                 </span>
//               </Typography>
//             </MuiPopover>

//             {/* Public consultation */}
//             <LinearProgress
//               sx={{
//                 height: 10,
//                 width: "100%",
//                 borderRadius: 0,
//                 [`&.${linearProgressClasses.colorPrimary}`]: {
//                   backgroundColor: lightGray,
//                   borderRight: "1px solid",
//                 },
//                 // progress colour
//                 [`& .${linearProgressClasses.bar}`]: {
//                   borderRadius: 0,
//                   backgroundColor: getProgressColor(
//                     projectDetails,
//                     "d_final_consultation",
//                     "public_consultation"
//                   ),
//                 },
//               }}
//               variant="determinate"
//               value={
//                 getDeadlineAsNumber(
//                   projectDetails,
//                   "d_final_consultation",
//                   "documents_handover",
//                   "d_final_consultation",
//                   "public_consultation"
//                 ) || 0
//               }
//               onMouseEnter={(event) =>
//                 set_pc_Consultation_AnchorEl(event.currentTarget)
//               }
//               onMouseLeave={() => set_pc_Consultation_AnchorEl(null)}
//             />
//             <MuiPopover
//               id="pre-planning-documents-review-popover"
//               disableRestoreFocus
//               sx={{
//                 pointerEvents: "none",
//               }}
//               open={open_pc_Consultation_Popover}
//               anchorEl={pc_Consultation_anchorEl}
//               anchorOrigin={{
//                 vertical: "bottom",
//                 horizontal: "left",
//               }}
//               onClose={() => set_pc_Consultation_AnchorEl(null)}
//             >
//               <Typography sx={{ p: 1 }}>
//                 <b>{t("Public Consultation")}</b>
//                 <br />
//                 <span>
//                   {getDeadlineDate(
//                     projectDetails,
//                     "d_final_consultation",
//                     "public_consultation"
//                   ) || ""}
//                 </span>
//               </Typography>
//             </MuiPopover>

//             {/* City council meeting */}
//             <LinearProgress
//               sx={{
//                 height: 10,
//                 width: "100%",
//                 borderRadius: 0,
//                 [`&.${linearProgressClasses.colorPrimary}`]: {
//                   backgroundColor: lightGray,
//                   borderRight: "1px solid",
//                 },
//                 // progress colour
//                 [`& .${linearProgressClasses.bar}`]: {
//                   borderRadius: 0,
//                   backgroundColor: getProgressColor(
//                     projectDetails,
//                     "d_final_consultation",
//                     "city_council"
//                   ),
//                 },
//               }}
//               variant="determinate"
//               value={
//                 getDeadlineAsNumber(
//                   projectDetails,
//                   "d_final_consultation",
//                   "public_consultation",
//                   "d_final_consultation",
//                   "city_council"
//                 ) || 0
//               }
//               onMouseEnter={(event) =>
//                 set_pc_CityCouncil_AnchorEl(event.currentTarget)
//               }
//               onMouseLeave={() => set_pc_CityCouncil_AnchorEl(null)}
//             />
//             <MuiPopover
//               id="pre-planning-documents-review-popover"
//               disableRestoreFocus
//               sx={{
//                 pointerEvents: "none",
//               }}
//               open={open_pc_CityCouncil_Popover}
//               anchorEl={pc_CityCouncil_anchorEl}
//               anchorOrigin={{
//                 vertical: "bottom",
//                 horizontal: "left",
//               }}
//               onClose={() => set_pc_CityCouncil_AnchorEl(null)}
//             >
//               <Typography sx={{ p: 1 }}>
//                 <b>{t("City Council Meeting")}</b>
//                 <br />
//                 <span>
//                   {getDeadlineDate(
//                     projectDetails,
//                     "d_final_consultation",
//                     "city_council"
//                   ) || ""}
//                 </span>
//               </Typography>
//             </MuiPopover>
//           </div>
//         </div>
//         {/* -------------------- APPROVAL --------------------*/}
//         <div
//           onClick={() => setViewingStage("e_approval")}
//           style={{ opacity: projectDetails?.stage === "e_approval" ? 1 : 0.5 }}
//         >
//           {viewingStage === "e_approval" &&
//             projectDetails?.stage !== viewingStage && (
//               <div style={{ color: "gray" }}>
//                 <Icon name="caret-down-filled" />{" "}
//                 <b>{t("Currently viewing")}</b>
//               </div>
//             )}
//           {projectDetails?.stage === "e_approval" ? (
//             <div style={{ color: primaryGreen }}>
//               <Icon name="caret-down-filled" />{" "}
//               <b>
//                 {t(
//                   projectDetails?.projectClosed ? "Last stage" : "Current stage"
//                 )}
//               </b>
//             </div>
//           ) : (
//             "e_approval" !== viewingStage && <div style={{ height: 20 }} />
//           )}
//           <h3>{t("Final Approval")}</h3>
//           <div style={{ display: "flex", justifyContent: "space-between" }}>
//             {/* Documents review */}
//             <LinearProgress
//               sx={{
//                 height: 10,
//                 width: "100%",
//                 borderRadius: 0,
//                 [`&.${linearProgressClasses.colorPrimary}`]: {
//                   backgroundColor: lightGray,
//                   borderRight: "1px solid",
//                 },
//                 // progress colour
//                 [`& .${linearProgressClasses.bar}`]: {
//                   borderRadius: 0,
//                   backgroundColor: getProgressColor(
//                     projectDetails,
//                     "e_approval",
//                     "documents_handover"
//                   ),
//                 },
//               }}
//               variant="determinate"
//               value={
//                 getDeadlineAsNumber(
//                   projectDetails,
//                   "d_final_consultation",
//                   "city_council",
//                   "e_approval",
//                   "documents_handover"
//                 ) || 0
//               }
//               onMouseEnter={(event) =>
//                 set_fa_Documents_AnchorEl(event.currentTarget)
//               }
//               onMouseLeave={() => set_fa_Documents_AnchorEl(null)}
//             />
//             <MuiPopover
//               id="pre-planning-documents-review-popover"
//               disableRestoreFocus
//               sx={{
//                 pointerEvents: "none",
//               }}
//               open={open_fa_Documents_Popover}
//               anchorEl={fa_Documents_anchorEl}
//               anchorOrigin={{
//                 vertical: "bottom",
//                 horizontal: "left",
//               }}
//               onClose={() => set_fa_Documents_AnchorEl(null)}
//             >
//               <Typography sx={{ p: 1 }}>
//                 <b>{t("Documents Review")}</b>
//                 <br />
//                 <span>
//                   {getDeadlineDate(
//                     projectDetails,
//                     "e_approval",
//                     "documents_handover"
//                   ) || ""}
//                 </span>
//               </Typography>
//             </MuiPopover>

//             {/* Appeal */}
//             <LinearProgress
//               sx={{
//                 height: 10,
//                 width: "100%",
//                 borderRadius: 0,
//                 [`&.${linearProgressClasses.colorPrimary}`]: {
//                   backgroundColor: lightGray,
//                   borderRight: "1px solid",
//                 },
//                 // progress colour
//                 [`& .${linearProgressClasses.bar}`]: {
//                   borderRadius: 0,
//                   backgroundColor: getProgressColor(
//                     projectDetails,
//                     "e_approval",
//                     "city_council"
//                   ),
//                 },
//               }}
//               variant="determinate"
//               value={
//                 getDeadlineAsNumber(
//                   projectDetails,
//                   "e_approval",
//                   "documents_handover",
//                   "e_approval",
//                   "city_council"
//                 ) || 0
//               }
//               onMouseEnter={(event) =>
//                 set_fa_CityCouncil_AnchorEl(event.currentTarget)
//               }
//               onMouseLeave={() => set_fa_CityCouncil_AnchorEl(null)}
//             />
//             <MuiPopover
//               id="pre-planning-documents-review-popover"
//               disableRestoreFocus
//               sx={{
//                 pointerEvents: "none",
//               }}
//               open={open_fa_CityCouncil_Popover}
//               anchorEl={fa_CityCouncil_anchorEl}
//               anchorOrigin={{
//                 vertical: "bottom",
//                 horizontal: "left",
//               }}
//               onClose={() => set_fa_CityCouncil_AnchorEl(null)}
//             >
//               <Typography sx={{ p: 1 }}>
//                 <b>{t("Appeal")}</b>
//                 <br />
//                 <span>
//                   {getDeadlineDate(
//                     projectDetails,
//                     "e_approval",
//                     "city_council"
//                   ) || ""}
//                 </span>
//               </Typography>
//             </MuiPopover>
//           </div>
//         </div>
//       </div>
//     </>
//   );
// };

const PrePlanningPhase = ({ projectDetails, role, t }) => {
  return (
    <Grid gridDefinition={[{ colspan: 3 }, { colspan: 9 }]}>
      <div style={{ color: primaryBlue }}>
        <h3>{t("Pre-planning")}</h3>
        {!projectDetails?.startDate
          ? t("Start date not set yet")
          : projectDetails?.startDate >= currentDate && (
              <span>
                {t("Project will start on")} <b>{projectDetails?.startDate}</b>
              </span>
            )}
      </div>
      <div>
        <Alert
          statusIconAriaLabel="Info"
          header={t("You are in the Pre-Planning phase")}
        >
          {role === "developers"
            ? t(
                "Once the Permitting Agent have reviewed and validated all the documents and Summary, they will set a start date for the project"
              )
            : t(
                "Once you have reviewed all the documents and the Summary, you can set the start date for the project"
              )}
        </Alert>
      </div>
    </Grid>
  );
};
