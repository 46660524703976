import { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UserContext, MenuOpenContext } from "../AppContexts";
import "../i18n";
import {
  AppLayout,
  ContentLayout,
  Header,
  SpaceBetween,
  BreadcrumbGroup,
  Container,
} from "@cloudscape-design/components";
import { SideNavigationContents } from "../shared";
import NavigationBar from "./NavigationBar";

export default function QuickSight() {
  const { t } = useTranslation();
  const { userDetails } = useContext(UserContext);
  const { isMenuOpen, setMenuOpen } = useContext(MenuOpenContext);

  useEffect(() => {
    document.title = "QuickSight Dashboard";
  }, []);

  return (
    <>
      <NavigationBar />
      <AppLayout
        navigationOpen={isMenuOpen}
        navigation={<SideNavigationContents userRole={userDetails.role} />}
        onNavigationChange={(event) => setMenuOpen(event.detail.open)}
        toolsHide={true}
        content={
          <ContentLayout
            header={
              <SpaceBetween size="xxl">
                <div />
                <BreadcrumbGroup
                  ariaLabel="Breadcrumbs"
                  items={[
                    { text: t("Home"), href: "/map" },
                    {
                      text: t("QuickSight Dashboard"),
                      href: "#",
                    },
                  ]}
                />
                <Header variant="h1">QuickSight Dashboard</Header>
                <div />
              </SpaceBetween>
            }
          >
            <Container fitHeight>
              <iframe
                title="Quicksight dashboard"
                width="1200"
                height="720"
                src="https://eu-north-1.quicksight.aws.amazon.com/sn/embed/share/accounts/508278435462/dashboards/1d059f3f-3154-4975-9287-6a41a7b82b0c?directory_alias=easypermits"
              />
            </Container>
          </ContentLayout>
        }
      />
    </>
  );
}
