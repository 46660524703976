import { useState, useContext } from "react";
import { UserContext } from "./AppContexts";
import { Auth, API, graphqlOperation } from "aws-amplify";
import { Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { currentDate } from "./shared";
import {
  Button,
  Modal,
  Checkbox,
  Box,
  Link,
  SpaceBetween,
} from "@cloudscape-design/components";
import * as mutations from "./graphql/mutations";

export default function SignIn() {
  const { userDetails } = useContext(UserContext);
  const { t, i18n } = useTranslation();
  const [dataPrivacyModal, setDataPrivacyModal] = useState(true);
  const [checked, setChecked] = useState(false);

  const updateDataPrivacyConsentDate = async () => {
    await API.graphql(
      graphqlOperation(mutations.updatePersona, {
        input: {
          id: userDetails.id,
          dateOfPrivacyConsent: currentDate,
        },
      })
    );
  };

  return (
    <Modal
      visible={dataPrivacyModal}
      footer={
        <>
          <Box>
            <Checkbox
              onChange={({ detail }) => setChecked(detail.checked)}
              checked={checked}
            >
              {t(
                "WindEurope can process my personal data in accordance with this"
              )}{" "}
              <Link external href={window.location.origin + "/data-privacy"}>
                {t("Data Privacy")}
              </Link>
            </Checkbox>
          </Box>
          <br />
          <Box float="right">
            <SpaceBetween direction="horizontal" size="xs">
              <Button
                disabled={!checked}
                onClick={() => {
                  // Sign out if user does not accept
                  Auth.signOut({ global: true });
                  <Navigate to={"/signin/" + i18n.language} />;
                }}
              >
                {t("I do not agree")}
              </Button>
              <Button
                variant="primary"
                disabled={!checked}
                onClick={() => {
                  setDataPrivacyModal(false);
                  updateDataPrivacyConsentDate();
                }}
              >
                {t("I agree")}
              </Button>
            </SpaceBetween>
          </Box>
        </>
      }
      header={t("Data Privacy")}
    >
      {t(
        "Please note that the personal data in this system cannot be used for other purposes than the purposes of this tool/system. If you plan to do so, seek advice from your legal department regarding required steps."
      )}
      <p>
        {t("For more information, please see") + " "}
        <Link external href={window.location.origin + "/data-privacy"}>
          {t("Data Privacy")}
        </Link>
        .
      </p>
    </Modal>
  );
}
