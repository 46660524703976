import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import "./i18n";
import {
  AppLayout,
  ContentLayout,
  Button,
  Container,
  SpaceBetween,
} from "@cloudscape-design/components";
import NavigationBar from "./components/NavigationBar";

export default function PageNotFound() {
  const { t } = useTranslation();

  useEffect(() => {
    document.title = "404 Not Found";
  }, []);

  return (
    <>
      <NavigationBar />
      <AppLayout
        navigationHide={true}
        toolsHide={true}
        content={
          <ContentLayout>
            <Container>
              <div style={{ textAlign: "center" }}>
                <SpaceBetween size="s">
                  <h1 style={{ color: "#b71c1c" }}>{t("404 Not Found")}</h1>
                  <h3>
                    {t(
                      "Oops! The page you're trying to access does not exist."
                    )}
                  </h3>
                  <Button href="/" variant="primary">
                    {t("Back to Homepage")}
                  </Button>
                </SpaceBetween>
              </div>
            </Container>
          </ContentLayout>
        }
      />
    </>
  );
}
